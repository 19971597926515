import React, { useEffect, useState } from 'react';

// import Button from '../buttons/Button/Button';
import SvgIcon from '../SvgIcon/SvgIcon';
import './Pagination.scss';

type Props = {
  selectPage: number;
  countPages: number;
  setSelectPage: (value: React.SetStateAction<number>) => void;
};

const Pagination = ({ selectPage, countPages, setSelectPage }: Props) => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    const changePages = setTimeout(() => {
      const value = Number(inputValue);
      if (value <= countPages && value >= 1) {
        setSelectPage(value);
        setInputValue('');
      }
    }, 400);

    return () => {
      clearTimeout(changePages);
    };
  }, [inputValue]);

  const pagePaginate = [];

  if (countPages - selectPage >= 2) {
    for (let number = selectPage; number <= countPages; ++number) {
      if (pagePaginate.length < 2) {
        pagePaginate.push(number);
      } else {
        break;
      }
    }
  } else {
    const firstItem = countPages - 2 > 0 && pagePaginate.push(countPages - 2);
    const secondItem = countPages - 1 > 0 && pagePaginate.push(countPages - 1);
  }

  if (countPages <= 1) {
    return null;
  }

  return (
    <div className="transactions__pagination">
      <div className="transaction__pagination--prev">
        <button disabled={selectPage === 1} type="button" onClick={() => setSelectPage(selectPage - 1)}>
          <SvgIcon className='transaction__pagination-arrow' name='arrow-right' width={11} height={11} />
        </button>
      </div>
      <div className="transactions__pagination-numbers">
        {pagePaginate.map((item) => (
          <button
            key={item}
            disabled={selectPage === item}
            onClick={() => setSelectPage(item)}
            type="button"
          >
            {item}
          </button>
        ))}
        <input
          value={inputValue}
          type="number"
          placeholder="..."
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button
          disabled={selectPage === countPages}
          onClick={() => setSelectPage(countPages)}
          type="button"
        >
          {countPages}
        </button>
      </div>
      <div className="transaction__pagination--next">
        <button type="button" disabled={selectPage === countPages} onClick={() => setSelectPage(selectPage + 1)}>
          <SvgIcon className='transaction__pagination-arrow' name='arrow-right' width={11} height={11} />
        </button>
      </div>
    </div>
  );
};

export default React.memo(Pagination);
