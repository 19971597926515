import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { useLocalizedBanners } from 'hooks/useLocalizedBanners';
import Loader from 'components/shared/loader/Loader';
import './BannerSlider.scss';

const BannerSlider = () => {
  const { bannersMobile, onSlideClick, isLoading } = useLocalizedBanners();
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="banner-slider">
      <Swiper
        pagination
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={1500}
        slidesPerView={1}
        loop
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {bannersMobile.map((banner) => (
          <SwiperSlide key={banner}>
            <button type="button" onClick={() => onSlideClick('/live')}>
              <img src={banner} alt="" />
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerSlider;
