import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { SiteSection } from 'types/siteSections';
import SectionIcon from 'components/shared/SectionIcon/SectionIcon';

import './TabBarItem.scss';

type Props = {
  item: {
    id: SiteSection;
    link: string;
    title: string;
  };
  isActive: boolean;
};

const TabBarItem = ({ isActive, item }: Props) => (
  <Link
    className="tab-bar-item"
    to={item.link}
    key={item.id}
  >
    <SectionIcon
      className="tab-item__section-icon"
      id={item.id}
      isBackgroundShowing={isActive}
      variant="rounded-shadow"
    />
    <p className={cn(
      'tab-item__title',
      { 'tab-item__title--active': isActive }
    )}
    >
      {item.title}
    </p>
  </Link>
);

export default React.memo(TabBarItem);
