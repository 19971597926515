import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { useLocalizedBanners } from 'hooks/useLocalizedBanners';
import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import './BannerDesktop.scss';

const BannerDesktop = () => {
  const { isLoading, bannersDesktop } = useLocalizedBanners();

  const b = bemCn('banner-desktop');

  return (
    <div className={b()}>
      <div className={b('slider')}>
        <Swiper
          pagination
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={1500}
          slidesPerView={1}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {isLoading
            ? <Loader />
            : bannersDesktop.big.map((banner) => (
              <SwiperSlide key={banner}>
                <Link to='/line'>
                  <div className={b('item')}>
                    <img src={banner} alt="" className={b('item-bg')} />
                  </div>
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div className={b('promo')}>
        {bannersDesktop.small.map((banner) => (
          <Link to="#" key={banner}>
            <img src={banner} alt="" className={b('promo-view')} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BannerDesktop;
