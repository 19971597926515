import { api } from 'store/api';

interface BetGamesTokenRes {
  'id': number;
  'token': string;
  'account_id': number;
  'updated': string;
  'currency': string;
}

interface BetGamesToken {
  'id': number;
  'token': string;
  'accountId': number;
  'updated': string;
  'currency': string;
}

export const gamesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBetgamesToken: builder.query<BetGamesToken, {currency: string}>({
      query: (params) => ({
        url: 'v1/betgames/token',
        params: {
          currency: params.currency
        },
      }),
      transformResponse: (response: BetGamesTokenRes) => ({
        id: response.id,
        token: response.token,
        accountId: response.account_id,
        updated: response.updated,
        currency: response.currency
      }),
    })
  })
});

export const {useGetBetgamesTokenQuery } = gamesApi;
