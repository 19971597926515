import { Coupon, FullCoupon, ServerCoupons, ServerCouponsDetails, ServerWallet, ServerWithdrawal, Wallet, WithdrawalCollection } from 'types/user-data';
import { snakeToCamelCase } from 'utils/transformCase';

export const transformWithdrawals = (response: ServerWithdrawal): WithdrawalCollection =>
  response.items.reduce<WithdrawalCollection>((acc, item) => ({
    ...acc,
    [item.transaction_id]: snakeToCamelCase(item),
  }), {});


export const transformFullCouponsInfo = (response: ServerCouponsDetails[]):FullCoupon => {
  const result = response.reduce<FullCoupon>((acc, item) => ({
    ...acc,
    [item.coupon_id]: {
      bets: item.bets.map((bet) => ({
        ...snakeToCamelCase(bet),
        gameDatetime: `${bet.game_datetime}Z`
      })),
      status: item.status,
      coef: item.coef,
      created: item.created,
      expectedWinAmount: item.expected_win_amount,
      currency: item.currency,
    },
  }), {});
  return result;
};

export const transformWallets = (response: ServerWallet[]): Wallet[] =>
  response.map((wallet) => ({
    isPrimary: wallet.is_primary,
    currency: wallet.currency,
    amount: wallet.amount,
  }));

export const transfortCoupons = (response: ServerCoupons): { totalCount: number; items: Coupon[] } => ({
  totalCount: response.count,
  items: response.items.map((item) => snakeToCamelCase(item))
});
