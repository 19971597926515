import { api } from 'store/api';
import { snakeToCamelCase } from 'utils/transformCase';
import { TRANSACTIONS_ITEMS_PER_PAGE } from 'utils/constants';
import { APIRoute } from 'utils/routes';

import {
  transformFullCouponsInfo,
  transformWithdrawals,
  transformWallets,
  transfortCoupons,
} from './converters';

import type {
  AccountData,
  AccountDataResponse,
  ServerWallet,
  Wallet,
  Transaction,
  TransactionResponse,
  SlotsSeccionResponce,
  transactionsWidthrawal,
  CouponsFilter,
  ServerCoupons,
  WithdrawalCollection,
  ServerWithdrawal,
  CancelWithdrawalResponse,
  FullCoupon,
  ServerCouponsDetails,
  UserGeoType,
  IpApiResponseType,
  Coupon
} from 'types/user-data';


// const getArrayParams = (coupons: string[]) => coupons.reduce((acc, coupon, index) => (
//   `${acc}${index > 0 ? '&coupons=' : ''}${coupon}`
// ), '');

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    account: builder.query<AccountData, void>({
      query: () => APIRoute.UserMe,
      providesTags: ['Auth', 'User'],
      transformResponse: (response: AccountDataResponse): AccountData => ({
        ...response,
        id: response.id.toString(),
      }),
    }),

    transactions: builder.query<TransactionResponse, number>({
      query: (page: number) => ({
        url: APIRoute.Transactions,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page
        }
      }),
    }),

    deposits: builder.query<TransactionResponse, number>({
      query: (page: number) => ({
        url: APIRoute.Deposits,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
    }),

    bets: builder.query<TransactionResponse, number>({
      query: (page: number) => ({
        url: APIRoute.Bets,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
    }),

    transfers: builder.query<TransactionResponse, number>({
      query: (page: number) => ({
        url: APIRoute.Transfers,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
      transformResponse: (response: TransactionResponse): { count: number; items: Transaction[] } => ({
        count: response.count,
        items: response.items.map((item) => snakeToCamelCase(item))
      }),
    }),

    transactionsWidthrawals: builder.query<transactionsWidthrawal, number>({
      query: (page: number) => ({
        url: APIRoute.Withdrawals,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
      providesTags: ['Auth', 'User', 'Transactions'],
    }),

    slotsSessioncs: builder.query<SlotsSeccionResponce, number>({
      query: (page: number) => ({
        url: APIRoute.SlotSessions,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
          'ascending': true
        }
      })
    }),

    coupons: builder.query<{ totalCount: number; items: Coupon[] }, CouponsFilter>({
      query: (params:CouponsFilter) => ({
        url: APIRoute.Coupons,
        params: {
          'page_size': params?.pageSize || 50,
          'page': params?.pageNumber || 1,
          'type': params?.type,
        }
      }),
      providesTags: ['Auth', 'User', 'BetsHistory'],
      transformResponse: (response: ServerCoupons) : { totalCount: number; items: Coupon[] } =>
        transfortCoupons(response),
    }),

    withdrawals: builder.query<WithdrawalCollection, void>({
      query: () => APIRoute.Withdrawals,
      providesTags: ['Auth', 'User', 'Transactions'],
      transformResponse: (response: ServerWithdrawal): WithdrawalCollection =>
        transformWithdrawals(response),
    }),

    cancelWithdrawal: builder.mutation<CancelWithdrawalResponse, number>({
      query: (withdrawalId : number) => ({
        url: APIRoute.CancelWithdrawal,
        method: 'POST',
        params: {
          'withdrawal_id': withdrawalId
        }
      }),
      invalidatesTags: ['Transactions', 'Wallets']
    }),

    fullCouponsInfo: builder.query<FullCoupon, string[]>({
      query: (coupons: string[]) => ({
        url: APIRoute.CouponsInfo,
        method: 'POST',
        body: coupons,
        params: {
          lang: 'ru',
        }
      }),
      providesTags: ['Auth', 'User'],
      transformResponse: (response: ServerCouponsDetails[]): FullCoupon =>
        transformFullCouponsInfo(response),
    }),

    wallets: builder.query<Wallet[], void>({
      query: () => APIRoute.Wallets,
      providesTags: ['Auth', 'User', 'Wallets'],
      transformResponse: (response: ServerWallet[]): Wallet[] =>
        transformWallets(response),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(api.util.invalidateTags(['Wallets']));
        }, 30000);
      },
    }),

    addWallet: builder.mutation<string, string>({
      query: (currency: string) => ({
        url: APIRoute.Wallets,
        method: 'POST',
        params: {
          'currency': currency
        }
      }),
      invalidatesTags: ['Auth', 'User', 'Wallets']
    }),

    updateWallet: builder.mutation<string, string>({
      query: (currency: string) => ({
        url: APIRoute.Wallets,
        method: 'PUT',
        params: {
          'currency': currency
        }
      }),
      invalidatesTags: ['Auth', 'User', 'Wallets']
    }),

    getUserCountry: builder.query<UserGeoType, void>({
      query: () => APIRoute.IpAPI,
      transformResponse: (response: IpApiResponseType): UserGeoType => ({
        country: response.country || null,
        countryName: response.country_name || null,
      })
    }),
  }),
});

export const {
  useAccountQuery,
  useWalletsQuery,
  useCouponsQuery,
  useFullCouponsInfoQuery,
  useTransactionsQuery,
  useWithdrawalsQuery,
  useCancelWithdrawalMutation,
  useDepositsQuery,
  useBetsQuery,
  useTransfersQuery,
  useTransactionsWidthrawalsQuery,
  useSlotsSessioncsQuery,
  useAddWalletMutation,
  useUpdateWalletMutation,
  useGetUserCountryQuery
} = userApi;
