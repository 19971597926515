import React from 'react';
import cn from 'classnames';

import './CoefDisplay.scss';

type Props = {
  value: string | number;
  variant?: 'primary' | 'secondary' | 'shaded' | 'shaded-highlighted';
};

const CoefDisplay = ({ value, variant = 'primary' }: Props) => (
  <div className={cn('coef-display', `coef-display--${variant}`)}>
    {value}
  </div>
);

export default CoefDisplay;
