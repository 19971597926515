import speedy7 from './bet-games-speedy7.jpg';
import warOfBets from './bet-games-war-of-bets.jpg';
import baccarat from './bet-games-baccarat.jpg';
import wheelRNG from './bet-games-wheel-rng.jpg';
import sattaMatka from './bet-games-satta-matka.jpg';
import lucky5 from './bet-games-lucky5.jpg';
import lucky6 from './bet-games-lucky6.jpg';
import lucky7 from './bet-games-lucky7.jpg';
import diceDuel from './bet-games-dice-duel.jpg';
import anderBaha from './bet-games-andar-baha.jpg';
import poker6 from './bet-games-6-poker.jpg';
import wheel from './bet-games-wheel.jpg';
import football from './bet-games-football.jpg';
import poker from './bet-games-poker.jpg';

export default {
  speedy7,
  warOfBets,
  baccarat,
  wheelRNG,
  sattaMatka,
  lucky5,
  lucky6,
  lucky7,
  diceDuel,
  anderBaha,
  poker6,
  wheel,
  football,
  poker,
};
