import { initialLoginError, initialRegisterError } from './authSlice';

import type { LoginErrors, RegisterErrors } from 'types/auth-data';

export const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const getToken = (): string | null => localStorage.getItem('token');

export const deleteToken = () => {
  localStorage.removeItem('token');
};

export type ShortError = {
  detail: string;
};

export type LogicError = {
  detail: {
    loc: string[];
    msg: string;
    type: string;
  }[];
};

export type BackendError = ShortError | LogicError;

export const handleLoginErrors = (status: number, data: BackendError): LoginErrors => {
  const { detail } = data;
  const errors: LoginErrors = { ...initialLoginError };

  if (typeof detail === 'string') {
    if (detail === 'Password incorrect') {
      errors.password = 'Неверный  пароль';
    } else if (detail === 'User not found') {
      errors.email = 'Пользователь не найден';
    } else {
      errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach((err) => {
      const location = err.loc?.[1];
      const message = err.msg;
      if (location === 'email') {
        errors.email = message;
      } else if (location === 'password') {
        errors.password = message;
      } else {
        errors.common = message;
      }
    });
  }
  return errors;
};

export const handleRestoreErrors = (status: number, data: ShortError): string => data.detail === 'User not found' ? 'Пользователь не найден' : data.detail;

const errorsTranslates: Record<string, string> = { 'Invalid name': 'Неправильное имя пользователя', };

export const handleRegisterErrors = (status: number, data: BackendError): RegisterErrors => {
  const { detail } = data;
  const errors: RegisterErrors = { ...initialRegisterError };

  if (typeof detail === 'string') {
    if (detail === 'User exists') {
      errors.email = 'Пользователь уже зарегистрирован';
    } else {
      errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach((err) => {
      const location = err.loc?.[1];
      if (!err.msg) { return; }
      const message = errorsTranslates[err.msg] || err.msg;

      if ((Object.keys(errors)).includes(location)) {
        errors[location as keyof RegisterErrors] = message;
      } else {
        errors.common = message;
      }
    });
  }

  return errors;
};
