import cn from 'classnames';
import React from 'react';

import './CloseIcon.scss';

type Props = {
  closeModal: () => void;
  variant?: 'basket';
};

const CloseIcon = ({ closeModal, variant }: Props) => (
  <button type="button" aria-label="Close" className="close-icon__wrapper" onClick={closeModal}>
    <div
      className={cn('close-icon', variant && `close-icon--${variant}`)}
    />
  </button>
);

export default CloseIcon;
