import cn from 'classnames';

import Flag from 'components/shared/Flag/Flag';

import type { CountryItem } from 'types/line-data';

type Props = {
  item: CountryItem;
  isActive: boolean;
  onClick: () => void;
}

const SideBarCountryItemDesktop = ({ item, isActive, onClick }: Props) => (
  <button
    className={cn('side-bar-country-desktop__button', { 'active': isActive })}
    type="button"
    onClick={onClick}
  >
    <Flag countryId={item.slug} />
    <h2>{item.name}</h2>
  </button>
);

export default SideBarCountryItemDesktop;
