import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { selectPrimaryWallet, selectShowBalanceHeader } from 'store/user/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { useWalletsQuery } from 'store/user/userApi';
import WalletsManagement from 'components/profile/components/WalletsManagement/WalletsManagement';
import Button from 'components/shared/buttons/Button/Button';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import OtherWallets from './components/OtherWallets/OtherWallets';
import './DesktopHeaderBalance.scss';

const DesktopHeaderBalance = () => {
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { amount, currency } = useAppSelector(selectPrimaryWallet);
  const isShowBalance = useAppSelector(selectShowBalanceHeader);

  const { isLoading: isWalletsLoading } = useWalletsQuery();

  const balanceBlock = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('click', (e) => {
      const tar = e?.target as HTMLElement;
      if (balanceBlock.current && !tar.closest(`.${balanceBlock.current.className}`)) {
        setIsOpenInfo(false);
      }
    });

    return () => {
      window.removeEventListener('click', (e) => {
        if (e.target !== balanceBlock.current) {
          setIsOpenInfo(false);
        }
      });
    };
  }, []);

  const handleBalanceClick = () => {
    setIsOpenInfo(!isOpenInfo);
  };

  const handleHiddenClick = () => {
    dispatch(changeActiveModal('settings'));
  };

  const handleWalletManageClick = () => {
    dispatch(changeActiveModal('wallet-manage'));
  };

  const b = bemCn('desktop-header-balance');

  return (
    <div className={b()} ref={balanceBlock}>
      <button className={b('dropdow-btn')} type="button" onClick={handleBalanceClick}>
        <p>{t('user-nav.balance', 'Баланс')}</p>
        <SvgIcon className={b('dropdow-icon')} name='chevron-down' width={7} height={7} />
      </button>
      {isShowBalance ? (
        <h2>{amount} {currency}</h2>
      ) : (
        <button type="button" onClick={handleHiddenClick}>
          <h2>{t('user-nav.balance-hide', 'Скрыто')}</h2>
        </button>
      )}
      {isOpenInfo && (
        <div className={b('info')}>
          <WalletsManagement
            balance={amount}
            currency={currency}
            isWalletsLoading={isWalletsLoading}
          />
          <OtherWallets />
          <div className={b('all')}>
            <Button onClick={handleWalletManageClick}>
              {t('user-nav.wallets-manage', 'Управление счетами')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopHeaderBalance;
