import React from 'react';
import cn from 'classnames';

import { ReactComponent as IconAllert } from 'assets/icons/icon-allert.svg';
import { ReactComponent as IconAndroid } from 'assets/icons/icon-android.svg';
import { ReactComponent as IconApple } from 'assets/icons/icon-apple.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon-arrow-right.svg';
import { ReactComponent as IconBadminton } from 'assets/icons/icon-badminton.svg';
import { ReactComponent as IconBaseball } from 'assets/icons/icon-baseball.svg';
import { ReactComponent as IconBasketball } from 'assets/icons/icon-basketball.svg';
import { ReactComponent as IconBoxing } from 'assets/icons/icon-boxing.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/icon-calendar.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconChess } from 'assets/icons/icon-chess.svg';
import { ReactComponent as IconChevronDown } from 'assets/icons/icon-chevron-down.svg';
import { ReactComponent as IconChevronRight } from 'assets/icons/icon-chevron-right.svg';
import { ReactComponent as IconClock } from 'assets/icons/icon-clock.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconCoinFlat } from 'assets/icons/icon-coin-flat.svg';
import { ReactComponent as IconCoin } from 'assets/icons/icon-coin.svg';
import { ReactComponent as IconCopy } from 'assets/icons/icon-copy.svg';
import { ReactComponent as IconCoupon } from 'assets/icons/icon-coupon.svg';
import { ReactComponent as IconCollapseAll } from 'assets/icons/icon-collapse-all.svg';
import { ReactComponent as IconCricket } from 'assets/icons/icon-cricket.svg';
import { ReactComponent as IconCup } from 'assets/icons/icon-cup.svg';
import { ReactComponent as IconDesktop } from 'assets/icons/icon-desktop.svg';
import { ReactComponent as IconDota2 } from 'assets/icons/icon-dota-2.svg';
import { ReactComponent as IconDotsMenu } from 'assets/icons/icon-dots-menu.svg';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { ReactComponent as IconExit } from 'assets/icons/icon-exit.svg';
import { ReactComponent as IconFire } from 'assets/icons/icon-fire.svg';
import { ReactComponent as IconFootball } from 'assets/icons/icon-football.svg';
import { ReactComponent as IconFutsal } from 'assets/icons/icon-futsal.svg';
import { ReactComponent as IconGames } from 'assets/icons/icon-games.svg';
import { ReactComponent as IconGmail } from 'assets/icons/icon-gmail.svg';
import { ReactComponent as IconHandball } from 'assets/icons/icon-handball.svg';
import { ReactComponent as IconHide } from 'assets/icons/icon-hide.svg';
import { ReactComponent as IconHockey } from 'assets/icons/icon-hockey.svg';
import { ReactComponent as IconHome } from 'assets/icons/icon-home.svg';
import { ReactComponent as IconInstagram } from 'assets/icons/icon-instagram.svg';
import { ReactComponent as IconLive } from 'assets/icons/icon-live.svg';
import { ReactComponent as IconMailRu } from 'assets/icons/icon-mailru.svg';
import { ReactComponent as IconMMA } from 'assets/icons/icon-mma.svg';
import { ReactComponent as IconOdnoklassniki } from 'assets/icons/icon-odnoklassniki.svg';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';
import { ReactComponent as IconPieChart } from 'assets/icons/icon-pie-chart.svg';
import { ReactComponent as IconPlus } from 'assets/icons/icon-plus.svg';
import { ReactComponent as IconPrint } from 'assets/icons/icon-print.svg';
import { ReactComponent as IconRugby } from 'assets/icons/icon-rugby.svg';
import { ReactComponent as IconSearch } from 'assets/icons/icon-search.svg';
import { ReactComponent as IconSettings } from 'assets/icons/icon-settings.svg';
import { ReactComponent as IconShow } from 'assets/icons/icon-show.svg';
import { ReactComponent as IconStar } from 'assets/icons/icon-star.svg';
import { ReactComponent as IconSteam } from 'assets/icons/icon-steam.svg';
import { ReactComponent as IconSupport } from 'assets/icons/icon-support.svg';
import { ReactComponent as IconSupportTg } from 'assets/icons/icon-support-tg.svg';
import { ReactComponent as IconSwitch } from 'assets/icons/icon-switch.svg';
import { ReactComponent as IconTableTennis } from 'assets/icons/icon-table-tennis.svg';
import { ReactComponent as IconTelegram } from 'assets/icons/icon-telegram.svg';
import { ReactComponent as IconTennis } from 'assets/icons/icon-tennis.svg';
import { ReactComponent as IconTimeMachine } from 'assets/icons/icon-time-machine.svg';
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg';
import { ReactComponent as IconVkontakte } from 'assets/icons/icon-vkontakte.svg';
import { ReactComponent as IconVolleyball } from 'assets/icons/icon-volleyball.svg';
import { ReactComponent as IconWarning } from 'assets/icons/icon-warning.svg';
import { ReactComponent as IconYandex } from 'assets/icons/icon-yandex.svg';

const iconsSet: Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  'allert': IconAllert,
  'android': IconAndroid,
  'apple': IconApple,
  'arrow-right': IconArrowRight,
  'badminton': IconBadminton,
  'baseball': IconBaseball,
  'basketball': IconBasketball,
  'boxing': IconBoxing,
  'calendar': IconCalendar,
  'chess': IconChess,
  'check': IconCheck,
  'chevron-down': IconChevronDown,
  'chevron-right': IconChevronRight,
  'clock': IconClock,
  'close': IconClose,
  'coin-flat': IconCoinFlat,
  'coin': IconCoin,
  'collapse-all': IconCollapseAll,
  'copy': IconCopy,
  'coupon': IconCoupon,
  'cricket': IconCricket,
  'cup': IconCup,
  'desktop': IconDesktop,
  'dota-2': IconDota2,
  'dots-menu': IconDotsMenu,
  'edit': IconEdit,
  'exit': IconExit,
  'fire': IconFire,
  'football': IconFootball,
  'futsal': IconFutsal,
  'games': IconGames,
  'gmail': IconGmail,
  'handball': IconHandball,
  'hide': IconHide,
  'hockey': IconHockey,
  'home': IconHome,
  'instagram': IconInstagram,
  'live': IconLive,
  'mailru': IconMailRu,
  'mma': IconMMA,
  'odnoklassniki': IconOdnoklassniki,
  'phone': IconPhone,
  'pie-chart': IconPieChart,
  'plus': IconPlus,
  'print': IconPrint,
  'rugby': IconRugby,
  'search': IconSearch,
  'settings': IconSettings,
  'show': IconShow,
  'star': IconStar,
  'steam': IconSteam,
  'support': IconSupport,
  'support-tg': IconSupportTg,
  'switch': IconSwitch,
  'table-tennis': IconTableTennis,
  'telegram': IconTelegram,
  'tennis': IconTennis,
  'time-machine': IconTimeMachine,
  'trash': IconTrash,
  'vkontakte': IconVkontakte,
  'volleyball': IconVolleyball,
  'warning': IconWarning,
  'yandex': IconYandex,
};

type Props = {
  name: string;
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}

const SvgIcon = (props: Props) => {
  const {
    name,
    fill = 'currentColor',
    width = 32,
    height = 32,
    className
  } = props;

  const Icon = iconsSet[name] ?? 'div';

  return (
    <Icon className={cn('svg-icon', className)} fill={fill} width={width} height={height} />
  );

  // return (
  //   <svg className={cn('svg-icon', className)} fill={fill} width={width} height={height}>
  //     <use xlinkHref={`#icon-${name}`}></use>
  //   </svg>
  // );
};

export default React.memo(SvgIcon);
