import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { useAppDispatch } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import './DesktopHeaderNoAuthorizedSingle.scss';

const DesktopHeaderNoAuthorizedSingle = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSignInClick = () => {
    dispatch(changeActiveModal('sign-in'));
  };

  const handleSignUpClick = () => {
    dispatch(changeActiveModal('sign-up'));
  };

  return (
    <>
      <div className="main-header-no-authorized__btn-wrapper main-header-no-authorized__login-btn-wrapper">
        <Button onClick={handleSignInClick} variant="shaded" >
          {t('user-nav.login', 'Вход')}
        </Button>
      </div>
      <div className="main-header-no-authorized__btn-wrapper main-header-no-authorized__reg-btn-wrapper">
        <Button onClick={handleSignUpClick} animated>
          {t('user-nav.registration', 'Регистрация')}
        </Button>
      </div>
    </>

  );
};

export default DesktopHeaderNoAuthorizedSingle;
