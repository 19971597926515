import React from 'react';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { bemCn } from 'utils/bem-cn';
import './ProfileHeader.scss';

type Props = {
  breadcrumbsLink: string;
  breadcrumbsTitle: string;
  title: string;
  subtitle?: string;
};

const ProfileHeader = (props: Props) => {
  const { breadcrumbsLink, breadcrumbsTitle, title, subtitle } = props;

  const b = bemCn('profile-header');
  return (
    <div className={b()}>
      <Breadcrumbs link={breadcrumbsLink} title={breadcrumbsTitle} />
      <h3 className={b('header')}>{title}</h3>
      {subtitle && <h3 className={b('sub-header')}>{subtitle}</h3>}
    </div>
  );
};

export default ProfileHeader;
