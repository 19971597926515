import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import './GradientIcon.scss';
import { bemCn } from 'utils/bem-cn';

type Props = {
  className?: string;
  children?: React.ReactNode;
  iconName?: string;
  color?: string | 'orange'
  | 'light-blue'
  | 'purple'
  | 'green'
  | 'blue'
  | 'yellow'
  | 'pink';
  hideColor?: boolean;
}

const GradientIcon = (props: Props) => {
  const b = bemCn('gradient-icon');
  const {
    className,
    children,
    color,
    hideColor,
    iconName = 'settings'
  } = props;


  const iconClassName = b({
    'hide-color': hideColor,
    'orange': color === 'orange',
    'light-blue': color === 'light-blue',
    'purple': color === 'purple',
    'green': color === 'green',
    'blue': color === 'blue',
    'yellow': color === 'yellow',
    'pink': color === 'pink',
  }, className);

  return (
    <div className={iconClassName}>
      {children ? children : (
        <SvgIcon className={b('image')}
          width={15}
          height={15}
          name={iconName}
        />
      )}
    </div>
  );
};

export default GradientIcon;
