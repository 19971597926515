import React, { useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { useChangePasswordMutation } from 'store/auth/authApi';
import Button from 'components/shared/buttons/Button/Button';
import { selectLoginError, selectPasswordResetId } from 'store/auth/selectors';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { bemCn } from 'utils/bem-cn';

import CloseIcon from '../../shared/icons/CloseIcon/CloseIcon';

import type { Entries } from 'types/common';
import type { LoginErrors } from 'types/auth-data';

import './ChangePassword.scss';

type FormFields = {
  password: string;
};

type Props = {
  modalIsOpen: boolean;
  closeModal(): void;
  contentClassName?: string;
  overlayClassName?: string;
};

const schema = yup.object({
  password: yup.string()
    .min(3, `${i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')}`),
}).required();

const ChangePassword = ({ modalIsOpen, closeModal, contentClassName, overlayClassName }: Props) => {
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const loginErrors = useAppSelector(selectLoginError);
  const passwordResetId = useAppSelector(selectPasswordResetId);
  const { t } = useTranslation();

  const { handleSubmit, setError, control } = useForm<FormFields>({
    defaultValues: { password: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fieldsErrors: Partial<LoginErrors> = { ...loginErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<FormFields>;
    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [loginErrors]);

  const handleLoginClick = async (data: FormFields) => {
    if (passwordResetId) {
      await changePassword({ password: data.password, passwordResetId });
    }
  };

  const b = bemCn('login-modal');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={b(null, contentClassName)}
      overlayClassName={overlayClassName}
    >
      <div className={b('content')}>
        <div className={b('close-section')}>
          <CloseIcon closeModal={closeModal} />
        </div>
        <div className={b('header')}>
          <h1 className={b('header-title')}>
            {t('reset-pass.title', 'Восстановление пароля')}
          </h1>
          <p className={b('header-description')}>
            {t('reset-pass.description', 'Введите новый пароль')}
          </p>
        </div>
        {!!loginErrors.common && (
          <ErrorBlock isDisplayed={!!loginErrors.common} message={loginErrors.common} align="center" />
        )}
        <div className={b('fields')}>
          <FormInputWrapper<FormFields>
            showError
            type="password"
            control={control}
            name="password"
            placeholder={`${i18n.t('reset-pass.password', 'Пароль')}`}
            autoComplete="current-password"
            disabled={isLoading}
          />
        </div>

        <div className={b('shared-group')}>
          <div className={b('buttons-sections')}>
            <div className={b('button-wrapper')}>
              <Button onClick={useCallback(handleSubmit(handleLoginClick), [])} isLoading={isLoading}>
                {t('reset-pass.submit-btn', 'Изменить пароль')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
