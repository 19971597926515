import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import CloseIcon from '../../shared/icons/CloseIcon/CloseIcon';
import ModalsHead from '../ModalsHead/ModalsHead';
import ThemePicker from '../Settings/components/ThemePicker/ThemePicker';
import SwapShowBalance from './component/SwapShowBalance/SwapShowBalance';

import './SettingsModal.scss';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  contentClassName?: string;
  overlayClassName?: string;
};

const SettingsModal = (props: Props) => {
  const { modalIsOpen, closeModal, contentClassName, overlayClassName } = props;
  const { t } = useTranslation();

  const b = bemCn('settings-modal');
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={b(null, contentClassName)}
      overlayClassName={overlayClassName}
    >
      <div>
        <CloseIcon closeModal={closeModal} />
      </div>
      <ModalsHead title={t('settings.title', 'Настройки')} />
      <div className={b('body')}>
        <div className={b('block', b('balance'))}>
          <div className={b('block-title')}>
            {t('settings.balance', 'Баланс')}
          </div>
          <div>
            <SwapShowBalance classTitle={b('block-text')} />
          </div>
        </div>
        <div className={b('block', b('theme'))}>
          <div className={b('block-title')}>
            {t('settings.site-theme', 'Цвет сайта')}
          </div>
          <div className={b('block-text')}>
            {t('settings.site-theme-description', 'Смена цветовой схемы сайта')}
          </div>
          <ThemePicker />
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;
