import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate, useParams } from 'react-router-dom';

import FullEvent from 'components/line/FullEvent/FullEvent';
import { routes } from 'utils/routes';
import { LINE_TYPES } from 'store/line/constants';

import type { LineType } from 'types/line-data';

const EventPageMobile = () => {
  const { eventId, lineType } = useParams();
  const navigate = useNavigate();

  const isEventIdValid = eventId !== undefined;
  const isLineTypeValid = lineType !== undefined && LINE_TYPES.includes(lineType);

  if (!isEventIdValid || !isLineTypeValid) {
    const loc = isLineTypeValid ? routes[lineType as LineType]?.path : '/';
    navigate(loc);
    return <div />;
  }

  return (
    <FullEvent eventId={eventId} lineType={lineType as LineType} />
  );
};

export default EventPageMobile;
