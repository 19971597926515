import React from 'react';

import Loader from 'components/shared/loader/Loader';
import { useGetEventDetailsQuery } from 'store/line/lineApi';
import { seletIsLineActive } from 'store/app/selectores';
import { useAppSelector } from 'hooks';

import FullEventInfoDesktop from './components/FullEventInfoDesktop/FullEventInfoDesktop';
import FullEventTeamsDesktop from './components/FullEventTeamsDesktop/FullEventTeamsDesktop';
import FullEventBets from '../FullEvent/FullEventBets/FullEventBets';
import ScorePeriods from '../FullEvent/ScorePeriods/ScorePeriods';
import EventCardBoard from '../EventCard/components/EventCardBoard/EventCardBoard';

import type { LineType } from 'types/line-data';

import './FullEventDesktop.scss';

type Props = {
  eventId: string;
  lineType: LineType;
};

// const mainTitle: Record<string, string> = {
//   0: 'Основные',
// };

const FullEventDesktop = ({ eventId, lineType }: Props) => {
  const isLineActive = useAppSelector(seletIsLineActive);
  const { data: event, isLoading } = useGetEventDetailsQuery({
    eventId,
    type: lineType
  }, { pollingInterval: 1000, skip: !isLineActive });

  if (!event || isLoading) {
    return <Loader />;
  }

  return (
    <div className='full-event-desktop'>
      <div className="full-event-desktop__widgets">
        <FullEventInfoDesktop
          eventDate={event.eventDate}
          tournament={event.tournament}
          lineType={lineType}
          scoreFull={event.scoreFull}
          periodName={event.periodName}
        />
        <EventCardBoard
          event={event}
        />
        {lineType === 'live'
          && (
            <div className="full-event-desktop__scores">
              <ScorePeriods
                scorePeriods={event.scorePeriod}
                team1={event.team1}
                team2={event.team2}
                scoreFull={event.scoreFull}
                roundName={event.periodName.split(' ')[1]}
              />
            </div>
          )}
      </div>
      <div className="full-event-desktop__bets">
        <FullEventBets event={event} lineType={lineType} />
      </div>
    </div>
  );
};

export default FullEventDesktop;
