import i18n from 'i18n/i18n';

import type { WithdrawalStatus , TransacitonType } from 'types/user-data';

export const transactionTitles: Record<string, string> = {
  'bet_placement': i18n.t('transactions.type.bet-placement','Постановка ставки'),
  'bet_placement_error': i18n.t('transactions.type.bet_placement_error','Ошибка постановки ставки'),
  'bet_cancel': i18n.t('transactions.type.bet-cancel','Отмена ставки'),
  'bet_refund': i18n.t('transactions.type.bet-refund','Возврат ставки'),
  'bet_win': i18n.t('transactions.type.bet-win','Выигрыш по ставке'),
  'admin_refill': i18n.t('transactions.type.admin-refill','Начисление администратором'),
  'admin_withdrawal': i18n.t('transactions.type.admin-withdrawal','Списание администратором'),
  'user_deposit': i18n.t('transactions.type.user-deposit','Пополнение'),
  'user_withdrawal': i18n.t('transactions.type.user-withdrawal','Вывод'),
  'withdrawal_cancel': i18n.t('transactions.type.withdrawal-cancel','Отмена вывода'),
  'user_user_transfer': i18n.t('transactions.type.user-user-transfer','Перевод'),
  'user_self_transfer': i18n.t('transactions.type.user-self-transfer','Перевод между счетами'),
  'slots_bet': i18n.t('transactions.type.slots-bet','Постановка ставки (слоты)'),
  'slots_win': i18n.t('transactions.type.slots-win','Выигрыш по ставке (слоты)'),
  'slots_refund': i18n.t('transactions.type.slots-refund', 'Возврат ставки (слоты)'),
  'betgames_payin': 'Постановка ставки (Betgames)',
  'betgames_payout': 'Выигрыш по ставке (Betgames)',
  'betgames_combo_payin': 'Постановка комбинированой ставки (Betgames)',
  'betgames_combo_payout': 'Выигрыш по комбинированой ставке (Betgames)',
  'betgames_promo_payout': 'Выигрыш по промо ставке (Betgames)',
  'casino_bet': i18n.t('transactions.type.casino_bet', 'Постановка ставки (казино)'),
  'casino_win': i18n.t('transactions.type.casino_win','Выигрыш по ставке (Казино)'),
};

export const getTransactionTitle = (transactionType: string) => transactionTitles[transactionType] ?? transactionType;

export const withdrawalStatus: Record<WithdrawalStatus, string> = {
  created:  i18n.t('transactions.withdrawal-status.created','Создан'),
  approved: i18n.t('transactions.withdrawal-status.approved','Одобрен'),
  canceled: i18n.t('transactions.withdrawal-status.canceled','Отменен'),
};

export const getWithdrawalStatusFilter = (status: WithdrawalStatus | undefined) => (status && withdrawalStatus?.[status]) ?? withdrawalStatus.created;

export const balaceDiffType: Record<string, TransacitonType[]> = {
  increase: [
    'withdrawal_cancel',
    'admin_refill',
    'user_deposit',
    'bet_cancel',
    'bet_placement_error',
    'bet_refund',
    'bet_win',
    'slots_win',
    'slots_refund',
    'betgames_payout',
    'betgames_combo_payout',
    'betgames_promo_payout',
    'casino_win'
  ],
  decrease: [
    'user_withdrawal',
    'admin_withdrawal',
    'bet_placement',
    'slots_bet',
    'betgames_payin',
    'betgames_combo_payin',
    'casino_bet'
  ],
  same: [
    'user_self_transfer',
    'user_user_transfer',
    'admin_refill'
  ]
};

export const getBalanceDiffType = (type: TransacitonType) => {
  let result = 'same';
  Object.keys(balaceDiffType).forEach((key) => {
    if (balaceDiffType[key].includes(type)) {
      result = key;
    }
  });
  return result;
};

export const setShowBalanceHeader = (isShow:boolean) => {
  localStorage.setItem(
    'showBalanceHeader',
    JSON.stringify({ isShow }),
  );
};

export const getShowBalanceHeader = ():boolean => {
  const item = localStorage.getItem('showBalanceHeader');
  if (item === null) {
    return false;
  }
  const parsedItem = JSON.parse(item) as { isShow: boolean };

  return parsedItem.isShow;
};
