import React from 'react';
import cn from 'classnames';

import { getDateTimeSlashFormat } from 'utils/time';

import type { SlotsSeccionType } from 'types/user-data';

type Props = {
  slot: SlotsSeccionType;
};

const SlotsSectionItem = ({ slot }: Props) => (
  <div className="transaction-item">
    <div className="transaction-item__row">
      <h2>Сессия</h2>
      <p>{getDateTimeSlashFormat(slot.created)}</p>
    </div>
    <div className="transaction-item__row">
      <p />
      <h2 className={cn('transaction-item__amount', `transaction-item__amount--${slot.total > 0 ? 'increase' : 'decrease'}`)}>
        {slot.total > 0 ? '+' : ''}
        {`${slot.total} ${slot.currency}`}
      </h2>
    </div>
  </div>
);

export default SlotsSectionItem;
