import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

import type {BetForPlace, PlaceBetsParams} from 'types/basket-data';

const transformBasketBets = (bets: BetForPlace[]) => bets.map((bet) => ({
  'basket_id': bet.betId,
  'data_type': bet.lineType,
}));

export const basketApi = api.injectEndpoints({
  endpoints: (build) => ({
    placeBets: build.mutation<unknown, PlaceBetsParams>({
      query: ({ bets, amount }: PlaceBetsParams) => ({
        url: APIRoute.PlaceBet,
        method: 'POST',
        body: {
          bets: transformBasketBets(bets),
          amount,
        },
      }),
      invalidatesTags: ['Transactions', 'BetsHistory', 'Wallets']
    }),
  }),
  overrideExisting: false,
});

export const { usePlaceBetsMutation } = basketApi;
