import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { selectInbetSession } from 'store/slots/selectors';
import Loader from 'components/shared/loader/Loader';
import './InbetGames.scss';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import ScrollToTop from 'components/Layout/ScrollToTop/ScrollToTop';
import { useResponsive } from 'hooks/useResponsive';

const InbetGames = () => {
  const { isDesktop } = useResponsive();
  const inbetSession = useAppSelector(selectInbetSession);
  const { gameId } = useParams();
  const isLoaded = gameId && inbetSession !== null;

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = '';
      if (isDesktop) {
        window.location.href = `${window.location.href}`;
      }
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // @ts-ignore
      window.init_loader({
        'path': 'https://flashslots.s3.amazonaws.com/',
        'game': gameId,
        'billing': inbetSession.customerId,
        'token': inbetSession.session,
        'kf': 100,
        'kf_list': [100],
        'currency': inbetSession.currency,
        'language': 'ru_RU',
        'home_page': '/',
        'button': 'classic',
      });
    }
  }, [isLoaded, gameId]);


  return (
    <div className="inbet-games">
      <ScrollToTop />
      <div className="inbet-games__container">
        <Loader className='inbet-games__loader' wrapperClassName='inbet-games__loader-wrapper' />
        <div id="game-content" className='inbet-games__content'></div>
        <Link
          to='/games?filter=Inbet+Games+Slots'
          className='inbet-games__back'
        >
          <SvgIcon name='chevron-right' className='inbet-games__back-icon' />
          Список игр
        </Link>
      </div>
    </div>
  );
};

export default InbetGames;
