import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HomePage from 'pages/Home/Home';
import { AppRoute } from 'utils/routes';
import { useAppSelector } from 'hooks';
import { useGetCurrenciesQuery } from 'store/wallets/walletsApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useGetLineSportsQuery, useGetLiveSportsQuery } from 'store/line/lineApi';
import { useAccountQuery, useGetUserCountryQuery } from 'store/user/userApi';
import NotFounded from 'pages/NotFound/NotFound';
import Profile from 'pages/Profile/Profile';
import VerifyEmail from 'pages/VerifyEmail/VerifyEmail';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import LivePage from 'pages/LivePage/LivePage';
import LinePage from 'pages/LinePage/LinePage';
import BonusPage from 'pages/StaticPages/BonusPage/BonusPage';
import RulesPage from 'pages/StaticPages/RulesPage/RulesPage';
// import SlotsPages from 'pages/Slots/SlotsPages';
import ProtectedRoute from 'components/shared/ProtectedRoute/ProtectedRoute';
import Basket from 'components/basket/Basket/Basket';
import Layout from 'components/Layout/Layout';
import EventPage from 'pages/EventPage/EventPage';
import BetHistoryPage from 'pages/BetHistoryPage/BetHistoryPage';
import ReferralLogin from 'components/ReferralLogin/ReferralLogin';
import GamesPage from 'pages/GamesPage/GamesPage';
import { useGetInbetSessionQuery } from 'store/slots/slotsApi';
import { selectIsInbetActive, selectIsSystemActive, selectSystemStatus, seletIsLineActive } from 'store/app/selectores';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Status } from 'store/app/appSlice';
import { useGetAppStatusQuery } from 'store/app/appApi';
import { Maintenance } from 'utils/constants/maintenanceMessage';
import ClickLogin from 'components/ClickLogin/ClickLogin';
import './App.scss';

const updateWindowDimensions = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};

const App = () => {
  const { isLoading: isStatusLoading } = useGetAppStatusQuery();
  const { i18n } = useTranslation();
  const appStatus = useAppSelector(selectSystemStatus);
  const isAppActive = useAppSelector(selectIsSystemActive);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const isLineActive = useAppSelector(seletIsLineActive);

  const { isLoading: isAccountLoading } = useAccountQuery(undefined, { skip: !isAppActive });
  useGetInbetSessionQuery(undefined, { skip: !isAuthenticated && !isInbetActive });
  useGetUserCountryQuery();
  useGetCurrenciesQuery(undefined, { skip: !isAppActive });
  useGetLineSportsQuery(undefined, { skip: !isLineActive });
  useGetLiveSportsQuery(undefined, { skip: !isLineActive });


  useEffect(() => undefined, [i18n.resolvedLanguage]);
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  if (isStatusLoading) {
    return <div>Loading...</div>;
  }
  if (appStatus !== Status.Active) {
    return <SiteMaintenance />;
  }

  return (
    <div className="app" id="app">
      <Layout
        isAuthenticated={isAuthenticated}
        isAccountLoading={isAccountLoading}
      >
        <Routes>
          <Route index element={<Navigate to={AppRoute.Home} replace />} />
          <Route path={AppRoute.Home} element={<HomePage />} />
          <Route path={AppRoute.LivePage}
            element={isLineActive
              ? <LivePage />
              : <SiteMaintenance message={Maintenance.line} />}
          />
          <Route path={AppRoute.LinePage}
            element={isLineActive
              ? <LinePage />
              : <SiteMaintenance message={Maintenance.line} />}
          />
          <Route path={AppRoute.FulleventPage}
            element={isLineActive
              ? <EventPage />
              : <SiteMaintenance message={Maintenance.line} />}
          />
          <Route path={AppRoute.BetHistory} element={<BetHistoryPage />} />
          <Route path={AppRoute.Basket} element={<Basket />} />
          {/* <Route path={AppRoute.SlotsPage} element={<SlotsPages />} /> */}
          <Route path={AppRoute.VerifyEmail} element={<VerifyEmail />} />
          <Route path={AppRoute.ResetPassword} element={<ResetPassword />} />
          <Route path={AppRoute.ProfilePage}
            element={
              <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path={AppRoute.BonusPage} element={<BonusPage />} />
          <Route path={AppRoute.RulesPage} element={<RulesPage />} />
          <Route path={AppRoute.Referal} element={<ReferralLogin isAuthenticated={isAuthenticated} />} />
          <Route path="/click_id=:clickId" element={<ClickLogin isAuthenticated={isAuthenticated} />} />
          <Route path="/games/*" element={<GamesPage />} />
          <Route path={AppRoute.NotFound} element={<NotFounded />} />
        </Routes>
      </Layout>
    </div>
  );
};

export default App;
