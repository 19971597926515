import React from 'react';

import { selectActiveTournament } from 'store/line/selectors';
import { useAppSelector } from 'hooks';


import TournamentCardDesktop from '../TournamentCard/TournamentCardDesktop/TournamentCardDesktop';

import type { EventItem, LineType } from 'types/line-data';

export type EventsGroup = Record<string, EventItem[]>;

type Props = {
  lineType: LineType;
  events: EventItem[];
};

const TournamentsGroupDesktop = ({ lineType, events }: Props) => {
  const activeTournament = useAppSelector(selectActiveTournament);

  const eventsByTournament = events.reduce<EventsGroup>((acc, event) => {
    acc[event.tournament] = acc[event.tournament] || [];
    acc[event.tournament].push(event);
    return acc;
  }, {});

  let groupByTournament = Object.entries(eventsByTournament);

  if (activeTournament !== '0' && activeTournament) {
    groupByTournament = groupByTournament.filter((tournament) => tournament[0] === activeTournament);
  } else {
    groupByTournament = groupByTournament.splice(0, 5);
  }

  return (
    <div>
      {groupByTournament.map(([tournament, tournamentEvents]) => (
        <TournamentCardDesktop
          key={tournament}
          title={tournament}
          events={tournamentEvents}
          lineType={lineType}
        />
      ))}
    </div>
  );
};

export default TournamentsGroupDesktop;
