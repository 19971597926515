import { createSlice } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { appApi } from './appApi';

export enum Status {
  Unknown = 'UNKNOWN',
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE',
}

export type AppStatus = {
  system: Status;
  line: Status;
  betgames: Status;
  tgg: Status;
  inbet: Status;
  withdrawals: Status;
  systemMessage: {
    enable: boolean;
    message: string;
  };
}

type AppData = {
  status: AppStatus;
}

const initialState: AppData = {
  status: {
    system: Status.Unknown,
    line: Status.Unknown,
    betgames: Status.Unknown,
    tgg: Status.Unknown,
    inbet: Status.Unknown,
    withdrawals: Status.Unknown,
    systemMessage: {
      enable: false,
      message: '',
    }
  }
};

export const appData = createSlice({
  name: NameSpace.App,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        appApi.endpoints.getAppStatus.matchFulfilled,
        (state, action) => {
          state.status = action.payload;
        }
      )
      .addMatcher(
        appApi.endpoints.getAppStatus.matchRejected,
        (state) => {
          // state.status = {
          //   system: Status.Unknown,
          //   line: Status.Unknown,
          //   betgames: Status.Unknown,
          //   tgg: Status.Unknown,
          //   inbet: Status.Unknown,
          // };
        }
      );
  }
});
