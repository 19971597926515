import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { slotsApi } from './slotsApi';

import type { SlotsState } from 'types/state';
import type { GamesList, InbetSession } from 'types/slots-data';

const initialState: SlotsState = {
  gamesList: [],
  activeSlotLink: '',
  inbetSession: null
};

export const slotsData = createSlice({
  name: NameSpace.Slots,
  initialState,
  reducers: {
    clearSession: (state) => {
      state.inbetSession = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        slotsApi.endpoints.gamesList.matchFulfilled,
        (state, action: PayloadAction<GamesList>) => {
          state.gamesList = action.payload;
        },
      )
      .addMatcher(
        slotsApi.endpoints.getLink.matchFulfilled,
        (state, action: PayloadAction<string>) => {
          state.activeSlotLink = action.payload;
        }
      )
      .addMatcher(
        slotsApi.endpoints.getInbetSession.matchFulfilled,
        (state, action: PayloadAction<InbetSession>) => {
          state.inbetSession = action.payload;
        }
      );
  },
});

export const {
  clearSession
} = slotsData.actions;
