import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { walletsApi } from 'store/wallets/walletsApi';
import { NameSpace } from 'utils/constants';

import type { CurrenciesResponse } from 'types/wallets-data';
import type { WalletsState } from 'types/state';

const initialState: WalletsState = {
  currencies: [],
  isCurrenciesLoading: false
};

export const walletsData = createSlice({
  name: NameSpace.Wallets,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        walletsApi.endpoints.getCurrencies.matchFulfilled,
        (state, action: PayloadAction<CurrenciesResponse>) => {
          state.currencies = action.payload;
          state.isCurrenciesLoading = false;
        },
      )
      .addMatcher(
        walletsApi.endpoints.getCurrencies.matchPending,
        (state) => {
          state.isCurrenciesLoading = true;
        },
      )
      .addMatcher(
        walletsApi.endpoints.getCurrencies.matchRejected,
        (state) => {
          state.isCurrenciesLoading = false;
        },
      );
  },
});
