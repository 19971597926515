import React from 'react';
import { useLocation } from 'react-router-dom';

import { SiteSection } from 'types/siteSections';
import { routes } from 'utils/routes';
import i18n from 'i18n/i18n';

import TabBarItem from './components/TabBarItem/TabBarItem';
import BasketTabItem from './components/BasketItem/BasketItem';
import './TabBar.scss';


type TabBarData = {
  link: string;
  title: string;
  id: SiteSection;
};

const tabBarItems: TabBarData[] = [
  {
    link: '/home',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 'home',
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 'live',
  },
  {
    link: routes.line.path,
    title: i18n.t('main.nav.Line', routes.line.title),
    id: 'line',
  },
  // {
  //   link: routes.slots.path,
  //   title: routes.slots.title,
  //   id: 'casino',
  // },
];

const getTabBarItems = (): TabBarData[] => [
  {
    link: '/home',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 'home',
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 'live',
  },
  {
    link: routes.line.path,
    title: i18n.t('main.nav.Line', routes.line.title),
    id: 'line',
  },
  // {
  //   link: routes.slots.path,
  //   title: routes.slots.title,
  //   id: 'casino',
  // },
];

const TabBar = () => {
  const { pathname } = useLocation();
  const tabBarItemsLayout = getTabBarItems().map((menuItem) => (
    <TabBarItem
      key={menuItem.id}
      item={menuItem}
      isActive={pathname.startsWith(menuItem.link)}
    />
  ));

  const basketItem = <BasketTabItem key="basket" isActive={pathname.startsWith('.basket')} />;
  tabBarItemsLayout.splice(2, 0, basketItem);

  return (
    <div className="tab-bar">
      {tabBarItemsLayout}
    </div>
  );
};

export default TabBar;
