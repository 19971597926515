import React, { useEffect } from 'react';

import LivePageMobile from 'pages/LivePage/LivePageMobile/LivePageMobile';
import { useGetLiveSportsQuery } from 'store/line/lineApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import { useAppSelector } from 'hooks';
import { seletIsLineActive } from 'store/app/selectores';

import LivePageDesktop from './LivePageDesktop/LivePageDesktop';

const LivePage = () => {
  const isLineActive = useAppSelector(seletIsLineActive);
  const liveSports = useGetLiveSportsQuery(undefined, { skip: !isLineActive });
  useEffect(() => { liveSports.refetch(); }, []);

  return (
    <Adaptive
      desktop={<LivePageDesktop />}
      mobile={<LivePageMobile />}
    />
  );
};

export default LivePage;
