import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

import { transformGamesList } from './converters';

import type {
  GamesList,
  SlotLink,
  GetLinkParams,
  GamesListResponse,
  InbetGamesResponse,
  InbetGame,
  InbetSession,
  InbetSessionResponse,
} from 'types/slots-data';


const getLinkParams: GetLinkParams = {
  'platform': 'mobile',
  'exit_url': 'http://54.164.253.99:3000/slots',
  'cash_url': 'http://54.164.253.99:3000/slots',
  'language': 'en',
};

export const slotsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    gamesList: builder.query<GamesList, void>({
      query: () => APIRoute.SlotsGames,
      providesTags: ['Slots'],
      transformResponse: (response: GamesListResponse): GamesList =>
        transformGamesList(response),
    }),

    getLink: builder.query<string, { id: string }>({
      query: ({ id }) => ({
        url: APIRoute.SlotsCreateSession,
        method: 'POST',
        body: {
          ...getLinkParams,
          'game_id': parseInt(id, 10),
        },
      }),
      providesTags: ['Auth'],
      transformResponse: (response: SlotLink): string => response.data.link,
    }),

    getInbetGames: builder.query<InbetGame[], { gameType?: string }>({
      query: (params) => ({
        url: '/v1/inbet/games/list',
        params: {
          'game_type': params.gameType ?? 'slots'
        }
      }),
      transformResponse: (response: InbetGamesResponse): InbetGame[] => {
        const result: InbetGame[] = Object.entries(response).map(([gameId, game]) => ({
          id: gameId,
          name: game.name,
          preview: `https://flashslots.s3.amazonaws.com/thumb/300x188/${gameId}.jpeg`,
          link: `/games/inbetgames/${gameId}`
        }));

        return result;
      }
    }),

    getInbetSession: builder.query<InbetSession, void>({
      query: () => ({
        url: '/v1/inbet/session',
        method: 'POST',
      }),
      transformResponse: (res: InbetSessionResponse) => ({
        session: res.session,
        currency: res.currency,
        customerId: res.customer_id,
      })
    })
  }),
});

export const {
  useGamesListQuery,
  useGetLinkQuery,
  useGetInbetGamesQuery,
  useGetInbetSessionQuery,
} = slotsApi;
