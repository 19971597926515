import { topTournamentsID } from './constants';

import type { EventItem } from 'types/line-data';

export const separateNumberIntoUnits = (number: number) =>
  number.toString()
    .split('')
    .map((digit) => parseInt(digit, 10));

export const moveToStart = <T, >(array: Array<T>, value: T | null | undefined) => {
  if (!value) {
    return array;
  }
  const items = array.filter((el) => el !== value);
  items.unshift(value);
  return items;
};

const sportIconNames: Record<string, string> = {
  0: 'cup',
  1: 'football',
  2: 'hockey',
  3: 'basketball',
  4: 'tennis',
  5: 'baseball',
  6: 'volleyball',
  7: 'rugby',
  8: 'handball',
  9: 'boxing',
  10: 'table-tennis',
  11: 'chess',
  13: 'rugby',
  14: 'futsal',
  16: 'badminton',
  40: 'games',
  56: 'mma',
  66: 'cricket',
  189: 'mma'
};

export const getSportIconName = (sportId: string): string =>
  sportIconNames[sportId] || 'cup';

export const sortByTopEntries = <T, K>(items:T[], entries:K[], cb: (item:T) => K): T[] => {
  const temp1:T[] = [];
  const temp2:T[] = [];

  items.forEach((item) => {
    if (entries.includes(cb(item))) {
      temp1.push(item);
    } else {
      temp2.push(item);
    }
  });

  return temp1.sort((a,b) => entries.indexOf(cb(a)) - entries.indexOf(cb(b))).concat(temp2);
};

export const filterByEntries = <T, K>(items: T[], entries: K[], cb: (item: T) => K): T[] =>
  items.filter((item) => entries.includes(cb(item)));


export const checkMajorTournament = (tournamentId: string): boolean =>
  topTournamentsID.includes(tournamentId);

type EventGroupById = Record<string, { name: string; events: EventItem[]; id: string }>

export const reduceEventsByTournamentId = (events: EventItem[]): EventGroupById => events.reduce<EventGroupById>((acc, event) => {
  acc[event.tournamentId] = acc[event.tournamentId] || {
    id: event.tournamentId,
    name: event.tournament,
    events: []
  };
  acc[event.tournamentId].events.push(event);
  return acc;
}, {});
