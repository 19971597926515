import React from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import CloseIcon from '../../shared/icons/CloseIcon/CloseIcon';
import ModalsHead from '../ModalsHead/ModalsHead';
import Withdrawals from '../Withdrawals/Withdrawals';

import './WithdrawalsDesktop.scss';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  contentClassName?: string;
  overlayClassName?: string;
};

const WithdrawalsDesktop = (props: Props) => {
  const { modalIsOpen, closeModal, contentClassName, overlayClassName } = props;
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={cn(contentClassName, 'withdrawals-modal')}
      overlayClassName={overlayClassName}
    >
      <div>
        <CloseIcon closeModal={closeModal} />
      </div>
      <ModalsHead
        title={t('withdrawals.title', 'Вывод')}
        descr={`${t('withdrawals.description', 'Выберите метод для вывода')}`}
      />
      <div>
        <Withdrawals />
      </div>
    </Modal>
  );
};

export default WithdrawalsDesktop;
