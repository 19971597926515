import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import { ReactComponent as IconDocument } from './assets/icon-colored-document.svg';
import { ReactComponent as IconList } from './assets/icon-colored-list.svg';
import { ReactComponent as IconPromo } from './assets/icon-colored-promo.svg';
import { ReactComponent as IconSettings } from './assets/icon-colored-settings.svg';
import { ReactComponent as IconSupport } from './assets/icon-colored-support.svg';
import { ReactComponent as IconWithdrawal } from './assets/icon-colored-withdrawal.svg';

type Props = {
  name: string;
}

const LinkIcon = ({ name }: Props) => {
  switch (name) {
    case 'settings':
      return <IconSettings className='icon-colored' />;
    case 'wallets-manage':
      return <SvgIcon name='settings' className='icon-colored' />;
    case 'bet-history':
      return <IconList className='icon-colored' />;
    case 'transactions':
      return <IconDocument className='icon-colored' />;
    case 'promo-codes':
      return <IconPromo className='icon-colored' />;
    case 'withdrawals':
      return <IconWithdrawal className='icon-colored' />;
    case 'support':
      return <IconSupport className='icon-colored' />;
    case 'documents':
      return <IconDocument className='icon-colored' />;
  }

  return <IconSettings className='icon-colored' />;
};

export default LinkIcon;
