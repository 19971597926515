import React, { useEffect } from 'react';

import { useGetLineSportsQuery } from 'store/line/lineApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import { seletIsLineActive } from 'store/app/selectores';
import { useAppSelector } from 'hooks';

import LinePageMobile from './LinePageMobile/LinePageMobile';
import LinePageDesktop from './LinePageDesktop/LinePageDesktop';

const LinePage = () => {
  const isLineActive = useAppSelector(seletIsLineActive);
  const lineSports = useGetLineSportsQuery(undefined, { skip: !isLineActive });
  useEffect(() => { lineSports.refetch(); }, []);

  return (
    <Adaptive
      desktop={<LinePageDesktop />}
      mobile={<LinePageMobile />}
    />
  );
};

export default LinePage;
