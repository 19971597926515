import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { getCurrencySymbol } from 'store/wallets/constants';
import { defaultLimits } from 'store/payment/constants';
import Button from 'components/shared/buttons/Button/Button';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { selectInitError, selectWithdrawalMethodDetails, } from 'store/payment/selectors';
import SumSuggestButton from 'components/shared/buttons/SumSuggestButton/SumSuggestButton';
import { useInitWithdrawalMutation } from 'store/payment/paymentsApi';

import { suggestValues } from '../constants';

import type { WithdrawalMethod } from 'types/payments-data';
import type { Currency } from 'types/wallets-data';

import './WithdrawalForm.scss';

const getSuggestions = (currency: Currency): number[] => suggestValues[currency] ?? suggestValues.USD;

type FormFields = {
  amount: number | '';
  phone: string | null;
  credentials: string;
};

type Props = {
  activeMethod: WithdrawalMethod;
  currency: Currency | null;
  balance: number;
};

const credentialsInputTitle: Record<WithdrawalMethod, string> = {
  card: i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  mobile: i18n.t('withdrawals.modal.phone-number', 'Номер телефона'),
  qiwi: i18n.t('withdrawals.modal.wallet-number', 'Номер кошелька'),
};

const getCredentialsInputTitle = (method: string) =>
  credentialsInputTitle[method] ?? i18n.t('withdrawals.modal.account-number', 'Номер счета');

const phoneRegExp = /^\+?\d{9,15}$/;

const schema = ({ min, max, balance }: { min: number; max: number; balance: number }) => yup.object({
  amount: yup
    .number()
    .min(min, `${i18n.t('withdrawals.modal.errors.min-amount', 'Минимальная сумма вывода')} ${min}`)
    .max(max, `${i18n.t('withdrawals.modal.errors.max-amount', 'Максимальная сумма вывода')} ${max}`)
    .test('balanceTest', `${i18n.t('withdrawals.modal.errors.balance', 'Не достаточно средств')}`, (value) => (value ? value <= balance : false))
    .required('Введите сумму пополнения')
    .typeError('Введите сумму'),
  credentials: yup
    .string()
    .required(`${i18n.t('withdrawals.modal.errors.empty-credentials', 'Введите реквизиты')}`),
  phone: yup
    .string()
    .matches(phoneRegExp, `${i18n.t('withdrawals.modal.errors.phone', 'Введите номер телефона')}`)
    .nullable(true),
}).required();

const WithdrawalForm = ({ activeMethod, currency, balance }: Props) => {
  const { t } = useTranslation();
  const [init, { isLoading }] = useInitWithdrawalMutation();
  const initError = useAppSelector(selectInitError);
  const { limits } = useAppSelector((state) => selectWithdrawalMethodDetails(state, activeMethod));
  const isCard = activeMethod === 'card';

  if (!currency) {
    return <h1>no currency</h1>;
  }

  const { handleSubmit, setValue, control } = useForm<FormFields>({
    defaultValues: { amount: '', credentials: '', phone: isCard ? '+' : null },
    mode: 'all',
    resolver: yupResolver(schema({
      ...limits[currency] ?? defaultLimits,
      balance,
    })),
  });

  const setAmount = (value: number) => {
    setValue('amount', value, { shouldValidate: true, shouldTouch: true });
  };

  const handleSumClick = useCallback((suggestAmount: number) => () => {
    if (!isLoading) { setAmount(suggestAmount); }
  }, [currency]);

  const suggestButtonsLayout = currency && getSuggestions(currency)
    .filter((suggest) => suggest <= balance)
    .map((suggestAmount) => (
      <SumSuggestButton
        key={suggestAmount}
        onClick={handleSumClick(suggestAmount)}
        amount={suggestAmount}
        currency={currency}
      />
    ));

  const handleWithdrawalClick = async ({ amount, credentials, phone }: FormFields) => {
    const data = {
      credentials: activeMethod === 'qiwi' ? credentials.replace('+', '') : credentials,
      amount: amount as number,
      method: activeMethod,
      ...(phone === null ? null : { phone }),
    };

    await init(data);
  };


  const limitMin = limits[currency]?.min ?? '1 000';
  const limitMax = limits[currency]?.max ?? '50 000';
  return (
    <div>
      <ErrorBlock isDisplayed message={initError} align="center" />
      <div className="withdrawal-model__fields">
        <FormInputWrapper<FormFields>
          showError
          type="text"
          control={control}
          name="credentials"
          placeholder={getCredentialsInputTitle(activeMethod)}
          disabled={isLoading}
          currency={currency}
        />
      </div>
      <div className="withdrawal-model__fields">
        <FormInputWrapper<FormFields>
          showError
          type="money"
          control={control}
          name="amount"
          placeholder={`${t('withdrawals.modal.amount', 'Сумма')}`}
          disabled={isLoading}
          currency={currency}
        />
        <div className="withdrawal-model__input-info">
          <p>{t('withdrawals.modal.limit-description', 'Сумма одного депозита')}</p>
          <p>От {limitMin} {getCurrencySymbol(currency)} до {limitMax} {getCurrencySymbol(currency)}</p>
        </div>
      </div>
      {isCard && (
        <div className="withdrawal-model__fields">
          <FormInputWrapper<FormFields>
            showError
            type="phone"
            control={control}
            name="phone"
            placeholder={`${t('withdrawals.modal.phone-number', 'Номер телефона')}`}
            disabled={isLoading}
            currency={currency}
          />
          <div className="withdrawal-model__input-info">
            <p>{t('withdrawals.modal.card-description', 'Введите номер телефона, привязанный к карте')}</p>
          </div>
        </div>
      )}

      <div className="withdrawal-model__suggestions">
        {suggestButtonsLayout}
      </div>

      <div className="withdrawal-model__shared-group">
        <div className="withdrawal-model__buttons-sections">
          <div className="withdrawal-model__button-wrapper">
            <Button
              onClick={useCallback(handleSubmit(handleWithdrawalClick), [activeMethod])}
              isLoading={isLoading}
            >
              {t('withdrawals.modal.submit-btn', 'Вывести')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalForm;
