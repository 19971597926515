import { Route, Routes } from 'react-router-dom';

import SportsList from 'components/line/SportsList/SportsList';
import Sport from 'components/line/Sport/Sport';
import Tournament from 'components/line/Tournament/Tournament';


const LinePageMobile = () => (
  <Routes>
    <Route index element={<SportsList />} />
    <Route path="sport/:id/:name" element={<Sport />} />
    <Route path="tournament/:sportId/:tournamentId/:tournamentName" element={<Tournament />} />
  </Routes>
);

export default LinePageMobile;
