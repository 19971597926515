import React from 'react';
import cn from 'classnames';

import { getMethodIcons } from 'components/shared/icons/PaymentMethodIcons';

import type { TopUpMethod, WithdrawalMethod } from 'types/payments-data';

import './PaymentMethod.scss';

type Props = {
  onClick?: () => void;
  title: string;
  id: WithdrawalMethod | TopUpMethod;
  variant?: 'default' | 'inverted';
  active?: boolean;
};

const PaymentMethod = (props: Props) => {
  const { onClick, title, variant = 'default', id, active } = props;
  const layout = getMethodIcons(id);

  return (
    <button
      type="button"
      className={cn('payment-system', `payment-system--${variant}`, { 'payment-system--active': active })}
      onClick={onClick}
    >
      <p className="payment-system__title">
        {title}
      </p>
      <div className="payment-system__icons">
        {layout}
      </div>
    </button>
  );
};

export default React.memo(PaymentMethod);
