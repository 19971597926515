// import CasinoWidget from 'components/casino/CasinoWidget/CasinoWidget';
import DesktopLineWidgets from 'components/line/desktopLineWidgets/DesktopLineWidgets';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';
import { batgamesList, twainSportList } from 'utils/constants';
import { useGetInbetGamesQuery } from 'store/slots/slotsApi';
import { useAppSelector } from 'hooks';
import { selectIsInbetActive, seletIsBetgameActive } from 'store/app/selectores';

import BannerDesktop from '../components/BannerDesktop/BannerDesktop';
// import LargeBannerDesktop from '../components/LargeBannerDesktop/LargeBannerDesktop';


// import type { SiteSection } from 'types/siteSections';
// import type { LineType } from 'types/line-data';

// type WidgetData = {
//   id: SiteSection;
//   title: string;
//   link: string;
//   lineType: LineType;
// };

// type CasinoData = {
//   gamesCount: number;
// };

// const casinoData: WidgetData & CasinoData = {
//   id: 'casino',
//   title: 'Казино',
//   link: '/slots',
//   gamesCount: 58,
// };

const HomeDesktop = () => {
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const isBetgameActive = useAppSelector(seletIsBetgameActive);
  const slotsList = useGetInbetGamesQuery({ gameType: 'slot' }, { skip: !isInbetActive });
  return (
    <main className="home-page">
      <BannerDesktop />
      <DesktopLineWidgets />
      {/* <DesktopLargeBanner /> */}
      {/* <CasinoWidget /> */}
      <GamesCardsWidgetDesktop
        title="BetGames TV"
        items={batgamesList}
        linkToAll={isBetgameActive ? '/games?filter=BetGames+TV' : ''}
        isAvailable={isBetgameActive}
        notAvailableMessage='Игры временно недоступны'
      />
      <GamesCardsWidgetDesktop
        title="Twain Sport"
        items={twainSportList}
        linkToAll={isBetgameActive ? '/games?filter=Twain+Sport' : ''}
        isAvailable={isBetgameActive}
        notAvailableMessage='Игры временно недоступны'
      />
      <GamesCardsWidgetDesktop
        title="Inbet Games"
        items={slotsList.data?.slice(0, 50) ?? []}
        linkToAll={isInbetActive ? '/games?filter=Inbet+Games+Slots' : ''}
        isAvailable={isInbetActive}
        notAvailableMessage='Игры временно недоступны'
      />
    </main>
  );
};

export default HomeDesktop;
