import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import './WidgetHeader.scss';

type Props = {
  link: string;
  subtitle: string;
};

const WidgetHeader = ({ link, subtitle }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="widget-header__show-more">
      <div className="widget-header__link">
        <Link to={link} className="widget-header__show-more-link">
          {t('top-events-widget.all', 'Все')}
          <SvgIcon
            className='widget-header__arrow-icon'
            name="arrow-right"
            width={9}
            height={9}
          />
        </Link>
      </div>
      <p className="widget-header__show-more-details">
        {subtitle}
      </p>
    </div>
  );
};

export default WidgetHeader;
