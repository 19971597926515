import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { basketApi } from './basketApi';
import { setBasket } from '../line/utils';

import type { BasketState } from 'types/state';
import type { BasketItem, BasketModalType } from 'types/basket-data';

const initialState: BasketState = {
  bets: {},
  amount: 0,
  activeModal: null,
};

export const basketData = createSlice({
  name: NameSpace.Basket,
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<number>) => {
      // if (action.payload !== '') {
      //   state.amount = action.payload;
      // }
    },
    addBet: (state, action: PayloadAction<BasketItem & { eventId: string }>) => {
      const { eventId, betId, ...betInfo } = action.payload;

      // const isSameType = Object.values(state.bets).every(bet => bet.lineType === lineType);

      // if (!isSameType) {
      //   return;
      // }

      const isBetInBasket = eventId in state.bets && state.bets[eventId].betId === betId;

      const prevBetsCount = Object.keys(state.bets).length;

      if (isBetInBasket) {
        delete state.bets[eventId];
      } else {
        state.bets[eventId] = {
          betId,
          ...betInfo
        };
      }

      if (Object.keys(state.bets).length === 1 && prevBetsCount === 0) {
        state.activeModal = 'basket';
      }
      // TODO: добавить хук для отслеживания изменения состояния
      setBasket(state.bets);
    },
    deleteBet: (state, action: PayloadAction<string>) => {
      delete state.bets[action.payload];
      setBasket(state.bets);
    },
    changeActiveModal: (state, action: PayloadAction<BasketModalType | null>) => {
      state.activeModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        basketApi.endpoints.placeBets.matchFulfilled,
        (state) => {
          state.activeModal = 'success';
          state.amount = 0;
          state.bets = {};
          setBasket({});
        },
      )
      .addMatcher(
        basketApi.endpoints.placeBets.matchRejected,
        (state) => {
          state.activeModal = 'error';
          state.amount = 0;
          // state.bets = {};
          // setBasket({});
        },
      );
  },
});

export const {
  addBet,
  deleteBet,
  setAmount,
  changeActiveModal,
} = basketData.actions;

export {initialState as initialBasketState};
