import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import {
  changeActiveModal,
  paymentLinkOpened,
  changeActiveTopupMethod,
} from 'store/payment/paymentsSlice';
import {
  selectPaymentUrl,
  selectActiveModal,
  selectTopupOverviewList,
  selectActiveTopUpMethod,
} from 'store/payment/selectors';
import { selectPrimaryWallet } from 'store/user/selectors';
import { useGetTopupMethodsQuery } from 'store/payment/paymentsApi';
import { telegramLink } from 'utils/constants';
import Loader from 'components/shared/loader/Loader';
import Discount from 'components/shared/Discount/Discount';
import { useResponsive } from 'hooks/useResponsive';
import { bemCn } from 'utils/bem-cn';

import PaymentMethod from '../PaymentSystem/PaymentMethod';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import TopupModal from '../TopupModal/TopupModal';

import './Topups.scss';

const Topups = () => {
  const { t } = useTranslation();
  const { Mobile } = useResponsive();
  const dispatch = useAppDispatch();
  const methods = useAppSelector(selectTopupOverviewList);
  const activeMethod = useAppSelector(selectActiveTopUpMethod);
  const activeModal = useAppSelector(selectActiveModal);
  const { currency } = useAppSelector(selectPrimaryWallet);
  const paymentUrl = useAppSelector(selectPaymentUrl);
  const { isLoading } = useGetTopupMethodsQuery();

  useEffect(() => {
    if (paymentUrl) {
      window.open(paymentUrl, '_self');
      dispatch(paymentLinkOpened());
    }
  }, [paymentUrl]);

  const handleModalClose = () => {
    dispatch(changeActiveModal(null));
    dispatch(changeActiveTopupMethod(null));
  };

  const layout = methods.map((method) => (
    <PaymentMethod
      id={method.id}
      title={method.title}
      key={method.id}
      onClick={() => {
        if (currency) {
          dispatch(changeActiveModal('withdrawal'));
          dispatch(changeActiveTopupMethod({ ...method }));
        }
      }}
    />
  ));

  const b = bemCn('topups-overview');

  return (
    <div className={b()}>
      <Mobile>
        <ProfileHeader
          breadcrumbsLink="/profile"
          breadcrumbsTitle={t('Profile', 'Личный кабинет')}
          title={t('topups.title', 'Пополнить счет')}
          subtitle={paymentUrl
            ? `${t('topups.redirect-description', 'Переход на страницу платежной системы')}`
            : `${t('topups.description', 'Выберите метод оплаты')}`}
        />
      </Mobile>
      {isLoading || paymentUrl ? (
        <div className={b('loader-wrapper')}>
          <Loader />
        </div>
      ) : (
        <div className={b('systems-list')}>
          <a className={b('operator')} href={telegramLink} target="_blank" rel="noreferrer">
            <PaymentMethod
              id="operator"
              title={t('topups.methods.operator', 'Через оператора')}
              key={-1}
              onClick={() => undefined}
            />
            <Discount value="+5%" />
          </a>
          {layout}
        </div>
      )}
      <TopupModal
        isOpen={activeModal === 'withdrawal'}
        activeMethod={activeMethod}
        closeModal={handleModalClose}
        currency={currency}
      />
    </div>
  );
};

export default Topups;
