import { Link } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { selectUserGeo } from 'store/user/selectors';
import Flag from 'components/shared/Flag/Flag';
import { logoText } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import './Logotype.scss';

type Props = {
  className?: string;
}

const Logotype = (props: Props) => {
  const userGeo = useAppSelector(selectUserGeo);

  const getFlagIcon = () => userGeo.country
    ? <Flag className="logotype__flag-icon" height={15} width={15} countryCode={userGeo.country} />
    : null;

  const b = bemCn('logotype');

  return (
    <div className={b(null, props.className)}>
      <Link to="/home" className={b('link')}>
        <p className={b('text')}>{logoText}</p>
        {getFlagIcon()}
      </Link>
    </div>
  );
};

export default Logotype;
