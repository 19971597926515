import React from 'react';

import SportIcon from 'components/shared/icons/SportIcon/SportIcon';
import { bemCn } from 'utils/bem-cn';

import type { SportItem } from 'types/line-data';
import './SportsFilterItem.scss';

type Props = {
  sport: SportItem;
  onClick: () => void;
  isActive: boolean;
};

const SportsFilterItem = ({ sport, onClick, isActive }: Props) => {
  const b = bemCn('sport-filter-item');
  return (
    <button className={b({ 'active': isActive })}
      type="button"
      onClick={onClick}
    >
      <SportIcon sportId={sport.slug} />
      <p>{sport.name}</p>
    </button>
  );
};

export default React.memo(SportsFilterItem);
