import React, { MouseEventHandler, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCopyToClipboard } from 'usehooks-ts';

import i18n from 'i18n/i18n';
import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';
import { getCurrencySymbol } from 'store/wallets/constants';
import { ReactComponent as PlacedIcon } from 'assets/bets/inProcess.svg';
import Loader from 'components/shared/loader/Loader';
import { domainName } from 'utils/constants';
import { getDateTimeCommaFormat, getDateTimeSlashFormat, } from 'utils/time';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import type { BetInfo, Coupon, PlaceType } from 'types/user-data';

import './HistoryItem.scss';

const typeTitles: Record<PlaceType, string> = {
  ordinar: i18n.t('bet-history.single', 'Ординар'),
  express: i18n.t('bet-history.express', 'Экспресс'),
};

export const statusTitles = {
  placed: `${i18n.t('bet-history.possible-prize', 'Возможный выигрыш')}:`,
  win: `${i18n.t('bet-history.prize', 'Выигрыш')}:`,
  loss: `${i18n.t('bet-history.prize', 'Выигрыш')}:`,
  refund: `${i18n.t('bet-history.refund', 'Возврат')}:`,
};

type Props = {
  coupon: Coupon;
  bets?: BetInfo[];
  isBetsLoading: boolean;
  expectedWinAmount?: number;
};

const b = bemCn('history-item');

const HistoryItem = (props: Props) => {
  const {
    coupon,
    bets,
    isBetsLoading,
    expectedWinAmount,
  } = props;

  const {
    id,
    type,
    winAmount,
    created,
    betAmount,
    currency,
    remoteId,
    status
  } = coupon;

  const [, copyBarCode] = useCopyToClipboard();
  const isOrdinar = type === 'ordinar';
  const totalCoef = bets && isOrdinar
    ? bets[0].coef
    : bets?.reduce<number>((acc, item) => item.coef * acc, 1);

  const betLayout = useMemo(() => (
    bets?.map((bet) => (
      <div className={b('row', b('bet-row'))} key={bet.barcode}>
        <div className={b('history-bet')}>
          <p className={b('history-tournament')}>
            {bet.tournament}
          </p>
          <p className={b('outcome-name')}>
            {bet.team1} - {bet.team2}
          </p>
        </div>
        <div className={b('left-group')}>
          <p>{getDateTimeCommaFormat(bet.gameDatetime)}</p>
          {!isOrdinar && <CoefDisplay value={bet.coef.toFixed(2)} />}
        </div>
      </div>
    ))
  ), [bets]);

  const handleBarCodeCopyClick: MouseEventHandler = (evt) => {
    evt.preventDefault();
    copyBarCode(`${id}`);
  };

  return (
    <div className={b()}>
      <Link className={b('content')} to={`/bet-history/${remoteId}`}>
        <div className={b('header')}>
          <div className={b('row', b('place-type'))}>
            <div className={b('bet-type-wrapper')}>
              <h2 className={b('team-name')}>{typeTitles[type]}</h2>
              {!!totalCoef && <CoefDisplay value={totalCoef.toFixed(2)} variant="secondary" />}
            </div>
            <button className={b('bar-code')}
              type='button'
              onClick={handleBarCodeCopyClick}
            >
              № {id} <SvgIcon className={b('bar-code-icon')} name='copy' width={13} height={13} />
            </button>
          </div>

          <div className={b('row')}>
            <p className={b('title')}>{getDateTimeSlashFormat(created)}</p>
            <h2>{`${betAmount} ${getCurrencySymbol(currency)}`}</h2>
          </div>
        </div>

        <div className={b('bets')}>
          <div className="history-details__site">{domainName}</div>
          {isBetsLoading
            ? <Loader />
            : betLayout}
        </div>

        <div className={b('header')}>
          <div className={b('row', { [`${status}`]: Boolean(status) })}>
            <h3>{statusTitles[status] ?? 'Выигрыш:'}</h3>
            {status === 'placed'
              ? (expectedWinAmount
                ? (
                  <p className={b('expected-win-amount')}>
                    {expectedWinAmount} {getCurrencySymbol(currency)}
                  </p>
                ) : <PlacedIcon className={b('placed-icon')} />
              ) : <h2>{`${winAmount ?? 0} ${getCurrencySymbol(currency)}`}</h2>}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HistoryItem;
