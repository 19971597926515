import { snakeToCamelCase } from 'utils/transformCase';

import type {
  EventItem,
  FullEventItem,
  Group,
  ServerEventItem,
  ServerGroup,
  ServerSubGame,
  SubGame,
  TournamentCollection,
  TournamentItem
} from 'types/line-data';

export const transformGroups = (group: ServerGroup): Group => ({
  name: group.name,
  bets: group.bets.map((bet) => snakeToCamelCase(bet)),
});

export const selectGroup = (groups: Group[]): Group => {
  const group = groups.find((g) => g.name === '1x2') ?? groups[0];

  return group ?? {
    bets: [],
    name: 'stopped',
  };
};

export const transformEvent = (event: ServerEventItem): EventItem => ({
  id: `${event.event_id}`,
  eventDate: `${event.event_date}Z`,
  team1: event.opp_1_name,
  team2: event.opp_2_name,
  tournament: event.tournament,
  sportId: `${event.sport_id}`,
  sportName: event.sport_name,
  group: selectGroup(event.groups.map((group) => transformGroups(group))),
  timer: event.timer,
  scorePeriod: event.score_period,
  scoreFull: event.score_full,
  allGroups: event.groups.map((group) => transformGroups(group)),
  gameDesk: event.game_desk,
  periodName: event.period_name,
  tournamentId: event.tournament_id,
});

export const transformSubGame = (game: ServerSubGame): SubGame => ({
  gameName: game.game_name,
  gameId: game.game_id,
  groups: game.groups.map((group) => transformGroups(group)),
});

const getIconLink = (iconName: string) => {
  const name = iconName.includes('.png') ? iconName : `${iconName}.png`;
  return `https://cdn.incub.space/v1/opp/icon/${name}`;
};

export const transformFullEvent = (event: ServerEventItem):FullEventItem => ({
  id: `${event.event_id}`,
  eventDate: `${event.event_date}Z`,
  team1: event.opp_1_name,
  team2: event.opp_2_name,
  scoreFull: event.score_full,
  scorePeriod: event.score_period,
  periodName: event.period_name,
  team1Icon: getIconLink(event.opp_1_icon),
  team2Icon: getIconLink(event.opp_2_icon),
  tournament: event.tournament,
  groups: event.groups.map((group) => transformGroups(group)),
  subGames: event.sub_games.map((game) => transformSubGame(game)),
  statList: event.stat_list,
  timer: event.timer,
  gameDesk: event.game_desk,
  finale: event.finale,
  sportName: event.sport_name,
});

export const transformTournaments = (tournaments: TournamentItem[]) =>
  tournaments.reduce<TournamentCollection>((acc, item) => {
    acc[item.country_id] = acc[item.country_id] || [];
    acc[item.country_id].push(item);
    return acc;
  }, {});
