import { NameSpace } from 'utils/constants';

import type { RootState } from 'types/state';

export const selectLoginError = (state: RootState) => state[NameSpace.Auth].loginError;

export const selectRegisterError = (state: RootState) => state[NameSpace.Auth].registerError;

export const selectIsAuthenticated = (state: RootState) => state[NameSpace.Auth].isAuthenticated;

export const selectActiveModal = (state: RootState) => state[NameSpace.Auth].activeModal;

export const selectPasswordResetId = (state: RootState) => state[NameSpace.Auth].passwordResetId;

export const selectRestoreError = (state: RootState) => state[NameSpace.Auth].restoreError;
