import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import * as yup from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';

import FormSelect from 'components/shared/form/FormSelect';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import { useGetCurrenciesQuery } from 'store/wallets/walletsApi';
import { useAddWalletMutation, useWalletsQuery } from 'store/user/userApi';

import './AddWalletForm.scss';

type FormFields = {
  currency: string;
};

// const schema = ({ currency }: { currency: string }) => yup
//   .object({
//     'send_to': yup
//       .string().required('Выберите валюту')
//   })
//   .required();

const AddWalletForm = () => {
  const { t } = useTranslation();
  const [isCanAdd, setIsCanAdd] = useState<boolean>(false);
  const { data: allWallets, } = useWalletsQuery();
  const { data: walletsList, isLoading } = useGetCurrenciesQuery();
  const [addWallet, { isError: isSendError }] = useAddWalletMutation();
  const [addWalletSuccess, setAddWalletSuccess] = useState<boolean>(false);
  const accountCurrency = allWallets?.map((item) => item.currency);

  const filteredCurrency = walletsList?.filter((currency) => !accountCurrency?.includes(currency) && currency !== 'DEMO');

  const { handleSubmit, watch, control, resetField } = useForm<FormFields>({
    defaultValues: { currency: '' },
    mode: 'all',
  });

  useEffect(() => {
    watch((value, { name, type }) => setIsCanAdd(true));
  }, [watch]);

  const handleWalletAdd = async ({ currency }: FormFields) => {
    if (currency.length > 0) {
      resetField('currency');
      await addWallet(currency);
      setAddWalletSuccess(true);
    }
  };
  return (
    <div>
      <h1 className="profile-section-link__title">{t('wallets.add-wallet', 'Добавить новый счет')}</h1>
      <div className="add-wallet__form">
        <div className="transfer-model__fields">
          <FormSelect
            options={filteredCurrency || ['']}
            type="currency"
            isLoading={isLoading}
            name="currency"
            control={control}
          />
        </div>
        <div className="add-wallet__add">
          <button
            type="button"
            className={cn({ 'active': isCanAdd })}
            onClick={() => { handleSubmit(handleWalletAdd)(); }}
          >
            <p>+</p>
          </button>
        </div>
      </div>
      <ResultModal
        title={
          isSendError
            ? t('result-modal.wallet.error.title', 'Произошла ошибка во время добавления валюты')
            : t('result-modal.wallet.success.title', 'Новая валюта добавлена в ваш аккаунт')
        }
        status={isSendError ? 'error' : 'success'}
        modalIsOpen={addWalletSuccess}
        closeModal={() => setAddWalletSuccess(false)}
      />
    </div>
  );
};

export default AddWalletForm;
