import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';

import { ReactComponent as AndroidIcon } from './assets/icon-android.svg';
import { ReactComponent as AppleIcon } from './assets/icon-apple.svg';

import './DownloadAppButton.scss';

const getIcon = (type: string) => {
  switch (type) {
    case 'android':
      return <AndroidIcon className="download-app-button__os-icon" width="27" height="27" />;
    case 'ios':
      return <AppleIcon className="download-app-button__os-icon" width="27" height="27" />;
  }
};

const getTitle = (type: 'android' | 'ios') =>
  type === 'android'
    ? i18n.t('downloadapp.For Android', 'Для Android')
    : i18n.t('downloadapp.For iOS', 'Для iOS');

type Props = {
  type: 'android' | 'ios';
  link?: string;
  className?: string;
}

const DownloadAppButton = (props: Props) => {
  const b = bemCn('download-app-button');

  return (
    <a className={b({ 'android': props.type === 'android', 'ios': props.type === 'ios' }, props.className)}
      href={props.link}
    >
      {getIcon(props.type)}
      <span className={b('text')}>
        {i18n.t('downloadapp.Application', 'Приложение')}
      </span>
      <span className={b('text-os')}>{getTitle(props.type)}</span>
    </a>
  );
};

export default DownloadAppButton;
