import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { getCurrencySymbol } from 'store/wallets/constants';
import { getDateTimeSlashFormat } from 'utils/time';
import { domainName } from 'utils/constants';
import { ReactComponent as WinIcon } from 'assets/bets/win.svg';
import { ReactComponent as LossIcon } from 'assets/bets/defeat.svg';
import { ReactComponent as PlacedIcon } from 'assets/bets/inProcess.svg';
import { bemCn } from 'utils/bem-cn';

import type { BetInfo, PlaceType, BetStatus, Coupon } from 'types/user-data';
import './BetHistoryCouponDesktop.scss';

const getBetStatusIcon = (betStatus: string) => {
  switch (betStatus) {
    case 'win':
      return <WinIcon className='bet-history-coupon-desktop__bet-status-icon' />;
    case 'loss':
      return <LossIcon className='bet-history-coupon-desktop__bet-status-icon' />;
    case 'no_result':
      return <PlacedIcon className='bet-history-coupon-desktop__bet-status-icon' />;
  }
  return null;
};

const typeTitles: Record<PlaceType, string> = {
  ordinar: 'Ординар',
  express: 'Экспресс',
};

const statusTitles: Record<BetStatus, string> = {
  win: 'Выигрыш',
  placed: 'Ожидание',
  loss: 'Проигрыш',
  refund: 'Возврат'
};

const statusItemTitles: Record<string, string> = {
  'no_result': 'Ожидание',
  loss: 'Проигрыш',
  win: 'Выигрыш',
  refund: 'Возврат'
};

type Props = {
  coupon: Coupon;
  bets?: BetInfo[];
  isBetsLoading: boolean;
  expectedWinAmount?: number;
};

const BetHistoryCouponDesktop = (props: Props) => {
  const { t } = useTranslation();

  const {
    coupon,
    bets,
    isBetsLoading,
    expectedWinAmount,
  } = props;

  const {
    id,
    type,
    winAmount,
    created,
    betAmount,
    currency,
    remoteId,
    status,
  } = coupon;

  const [isOpenBets, setIsOpenBets] = useState(false);
  const isWin = status === 'win';
  const isPlaced = status === 'placed';
  const isLoss = status === 'loss';
  const isRefund = status === 'refund';
  const isOrdinar = type === 'ordinar';
  const totalCoef = useMemo(() => (
    bets && isOrdinar ? bets[0].coef : bets?.reduce<number>((acc, item) => item.coef * acc, 1)
  ), [bets]);

  const b = bemCn('bet-history-coupon-desktop');
  return (
    <div className={b()}>
      <div className={b('wrapper')} role="button" onClick={() => setIsOpenBets(!isOpenBets)}>
        <h2 className={b('type')}>{typeTitles[type]}</h2>
        <div className={b('total-coef')}>
          <Button variant="secondary">{totalCoef?.toFixed(2)}</Button>
        </div>
        <h2 className={b('date')}>{getDateTimeSlashFormat(created)}</h2>
        <p className={b('id')}>{`№ ${id}`}</p>
        <h2 className={b('bet-amount')}>{`${betAmount} ${getCurrencySymbol(currency)}`}</h2>
        <h2 className={b('status', { [`${status}`]: Boolean(status) })}>
          {statusTitles[status]}
        </h2>
      </div>
      {isOpenBets && (
        <div className={b('list')}>
          <div className="history-details__site">{domainName}</div>
          {bets?.map((item) => (
            <div key={item.barcode} className={b('bet')}>
              <div className={b('item-info')}>
                <p>{item.tournament}</p>
                <h2>{item.team1} - {item.team2}</h2>
              </div>
              <p className={b('item-date')}>{getDateTimeSlashFormat(item.gameDatetime)}</p>
              <div className={b('item-main')}>
                <h2 className={b('item-name-game')}>{item.subGameName}</h2>
                <h2 className={b('item-name-game')}>{item.outcomeName}</h2>
                <h2 className={b('item-name-game')}>
                  {getBetStatusIcon(item.status)}
                  {item.coef}
                </h2>
                {item.status === 'no_result'
                  ? (
                    <h2 className={b('item-result', { [`${item.status}`]: Boolean(item.status) })}>
                      {statusItemTitles[item.status]}
                    </h2>
                  ) : (
                    <div className={b('item-score', { [`${item.status}`]: Boolean(item.status) })}>
                      <h2>{item.gameScore.length > 0 && item.gameScore !== '-:-' && item.gameScore.split('(')[0]}</h2>
                      <p>{item.gameScore.length > 0 && item.gameScore !== '-:-' && `(${item.gameScore.split('(')[1]}`}</p>
                    </div>
                  )}
              </div>
            </div>
          ))}
          <div className={b('totals')}>
            <h2 className={b('totals-status', { [`${status}`]: Boolean(status) })}>
              {isPlaced && `${t('bet-history.possible-prize', 'Возможный выигрыш')}:`}
              {(isWin || isLoss) && `${t('bet-history.prize', 'Выигрыш')}:`}
              {isRefund && `${t('bet-history.refund', 'Возврат')}:`}
            </h2>
            <h2>
              {isPlaced && `${expectedWinAmount ?? 0} ${getCurrencySymbol(currency)}`}
              {(isWin || isLoss) && `${winAmount ?? 0} ${getCurrencySymbol(currency)}`}
              {isRefund && `${betAmount} ${getCurrencySymbol(currency)}`}
            </h2>
            <h2>{totalCoef?.toFixed(2)}</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default BetHistoryCouponDesktop;
