import type { CompareBets } from 'types/line-data';
import type { BasketItem } from 'types/basket-data';
import type { DiffType } from 'components/line/components/Coefficient/Coefficient';

export const compareBets = ({ prevBet, bet, prevGroupName, groupName }: CompareBets): DiffType => {

  if (prevGroupName === 'stopped' && groupName !== 'stopped') {
    return 'increased';
  }

  if (!prevBet) {
    return 'same';
  }

  const { rate: rate1 } = prevBet;
  const { rate: rate2 } = bet;
  if (rate1 > rate2) {
    return 'decreased';
  } if (rate1 < rate2) {
    return 'increased';
  }
  return 'same';
};

export const setBasket = (bets: Record<string, BasketItem>) => {
  localStorage.setItem(
    'basket',
    JSON.stringify({ bets }),
  );
};

export const getBasket = (): Record<string, BasketItem> => {
  const item = localStorage.getItem('basket');
  return (JSON.parse(item || '{}').bets ?? {}) as Record<string, BasketItem>;
};

export const getCoefTitle = (caption: string, team1: string, team2: string) => {
  if (caption === team1) { return 'П1'; }
  if (caption === team2) { return 'П2'; }
  if (caption === 'Ничья') { return 'X'; }
  return caption;
};

export const getCoefTitleFull = (caption: string, team1: string, team2: string) => {
  if (caption === team1) { return 'Победа 1 команды'; }
  if (caption === team2) { return 'Победа 2 команды'; }
  return caption;
};
