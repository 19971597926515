import React from 'react';
import { useLocation } from 'react-router-dom';

import i18n from 'i18n/i18n';
import NavMenu from 'components/shared/NavMenu/NavMenu';
import { routes } from 'utils/routes';

import './MainNav.scss';

const menuItems = [
  {
    link: '/home',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 1,
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 2,
  },
  {
    link: routes.line.path,
    title: i18n.t('main.nav.Line', routes.line.title),
    id: 3,
  },
  // {
  //   link: routes.slots.path,
  //   title: routes.slots.title,
  //   id: 4,
  // },
  // {
  //   link: '/results',
  //   title: 'Результаты',
  //   id: 5,
  // },
];

const getMenuItems = () => [
  {
    link: '/home',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 1,
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 2,
  },
  {
    link: routes.line.path,
    title: i18n.t('main.nav.Line', routes.line.title),
    id: 3,
  },
  // {
  //   link: routes.slots.path,
  //   title: routes.slots.title,
  //   id: 4,
  // },
  // {
  //   link: '/results',
  //   title: 'Результаты',
  //   id: 5,
  // },
];

const MainNav = () => {
  const location = useLocation();
  const items = getMenuItems();
  const activeMenuItem = items.find((item) => item.link === location.pathname);

  return (
    <div className="main-nav">
      {/* <div className="main-nav__mask" /> */}
      <NavMenu
        className="main-nav__nav-menu"
        menuItems={items}
        activeItemId={activeMenuItem ? activeMenuItem.id : 0}
      />
    </div>
  );
};

export default MainNav;
