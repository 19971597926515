import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'i18n/i18n';
import App from 'app/App';
import ThemeProvider from 'contexts/ThemeProvider';
import { store } from 'store';
import SvgIconSprite from 'components/shared/SvgIcon/SvgIconSprite';
import FlagSprite from 'components/shared/Flag/FlagSprite';
import './index.scss';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://4a02b3407a10450188c2f91612076ba2@o4504519121043456.ingest.sentry.io/4504520445853696',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <SvgIconSprite />
          <FlagSprite />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
