import { Autoplay } from 'swiper';
import { Swiper } from 'swiper/react';

import Loader from 'components/shared/loader/Loader';

import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  children?: React.ReactNode;
  isLoading?: boolean;
}

const TopEventsWidgetSlider = ({ children, isLoading }: Props) => {
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Swiper
      className="top-events-widget-mobile__events-slider"
      spaceBetween={9}
      centeredSlides
      speed={1500}
      slidesPerView="auto"
      modules={[Autoplay]}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      {children}
    </Swiper>
  );
};
export default TopEventsWidgetSlider;
