import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import { useAppSelector } from 'hooks';
import { useGetEventsListQuery, useGetLiveSportsQuery } from 'store/line/lineApi';
import { selectEventsBySport, selectLiveSports } from 'store/line/selectors';
import SearchFilter from 'components/shared/SearchFilter/SearchFilter';
import ButtonToTop from 'components/shared/ButtonToTop/ButtonToTop';
import SportsFilter from 'components/line/SportsFilter/SportsFilter';
import SportGroup from 'components/line/SportGroup/SportGroup';

import type { EventItem } from 'types/line-data';

import './LivePageMobile.scss';

type EventsBySportGroup = {
  [s: string]: {
    sportName: string;
    events: EventItem[];
  };
};

const lineType = 'live';

const LivePageMobile = () => {
  const [activeSportId, setActiveSportId] = useState('1');
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [isRefetch, setIsRefetch] = useState(false);
  const { t } = useTranslation();

  const events = useAppSelector((state) => selectEventsBySport(state, activeSportId));
  const sports = useAppSelector(selectLiveSports);

  const { isLoading: isSportsLoading } = useGetLiveSportsQuery();
  const { isLoading: isEventsLoading, isFetching } = useGetEventsListQuery(
    {
      sportId: activeSportId,
      type: 'live',
    },
    { pollingInterval: 4000 },
  );

  useEffect(() => {
    if (!isFetching) {
      setIsRefetch(false);
    }
  }, [isFetching]);


  const sportsById = sports.reduce<Record<string, string>>((acc, sport) => {
    acc[sport.slug] = sport.name;
    return acc;
  }, {});

  let filteredEvents: EventItem[] = events;

  if (searchFilterValue.length > 0) {
    filteredEvents = events.filter(
      (event) =>
        event.team1.toLowerCase().includes(searchFilterValue.toLowerCase())
        || event.team2.toLowerCase().includes(searchFilterValue.toLowerCase()));
  }

  const groupBySportId = filteredEvents.reduce<EventsBySportGroup>((acc, item) => {
    acc[item.sportId] = acc[item.sportId] || {
      sportName: sportsById[item.sportId] || item.sportName,
      events: []
    };
    acc[item.sportId].events.push(item);
    return acc;
  }, {});

  // TODO: Сделать дебаунсер при переключении выбранного спорта
  const handleSportChange = useCallback((id: string) => {
    setIsRefetch(true);
    setActiveSportId(id);
  }, []);

  const handleSearchChange = (newSearchValue: string) => {
    setSearchFilterValue(newSearchValue);
  };

  const handleSearchCancel = () => {
    setOpenSearch(false);
  };

  return (
    <div className="tournament">
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      {!openSearch && (
        <PageHeader
          id="live"
          title="Live"
          controlPanel={[
            { type: 'star' },
            { type: 'search', onClick: () => setOpenSearch(true) }
          ]}
        />
      )}
      <SearchFilter
        openSearch={openSearch}
        onCancel={handleSearchCancel}
        onChange={handleSearchChange}
      />
      <div className="tournament__content">
        <SportsFilter
          sports={sports}
          activeSportId={activeSportId}
          isLoading={isSportsLoading}
          onChange={handleSportChange}
        />
        <div className="tournament__events-list">
          {isEventsLoading || isRefetch
            ? <Loader />
            : Object.keys(groupBySportId).map((sportId) => (
              <SportGroup
                key={sportId}
                sportName={
                  sportsById[sportId]
                  || groupBySportId[sportId].events[0].sportName
                }
                events={groupBySportId[sportId].events}
                lineType={lineType}
              />
            ))}
        </div>
      </div>
      <ButtonToTop />
    </div>
  );
};

export default LivePageMobile;
