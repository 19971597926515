import React from 'react';

import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import './BalanceInfo.scss';

type BalanceProps = {
  id: 'wallet' | 'casino-bonuses' | 'sport-bonuses';
  title: string;
  balance: string | null;
  size: 'sm' | 'm';
  className: string;
  isLoading?: boolean;
};

const BalanceInfo = (props: BalanceProps) => {
  const { id, title, balance, size, className, isLoading } = props;

  const b = bemCn('balance-info');

  return (
    <div className={b(null, className)}>
      <div className={b('vertical-line', { [`${id}`]: true })} />
      <div>
        <p className={b('title')}>
          {id === 'wallet' ? title : title.split(' ')[1]}
        </p>
        <h3 className={b('balance', { size })}>
          {isLoading ? <Loader /> : balance}
        </h3>
      </div>
    </div>
  );
};

export default BalanceInfo;
