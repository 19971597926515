import React from 'react';

import { useAppDispatch } from 'hooks';
import { deleteBet } from 'store/basket/basketSlice';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import CoefDisplay from '../CoefDisplay/CoefDisplay';

import type { LineType } from 'types/line-data';

import './BasketItem.scss';

type Props = {
  team1: string;
  team2: string;
  coef: number;
  type: string;
  tournament: string;
  lineType: LineType;
  eventId: string;
  // onClick: (bet: BasketBet) => void,
};

const BasketItem = (props: Props) => {
  const { eventId, team1, team2, type, coef, tournament, lineType } = props;
  const dispatch = useAppDispatch();

  const b = bemCn('basket-item');

  return (
    <div className={b()}>
      <div className={b('content')}>

        <div className={b('row')}>
          <div className={b('title-wrapper')}>
            <CoefDisplay value={coef} />
            <p className={b('title')}>{type}</p>
          </div>
          <button className={b('delete-btn')} type='button' onClick={() => dispatch(deleteBet(eventId))}>
            <SvgIcon name="trash" width={15} height={15} />
          </button>
        </div>

        <div className={b('row')}>
          <div className={b('teams')}>
            <h2 className={b('team-name')}>{team1}</h2>
            <h2 className={b('team-name')}>{team2}</h2>
          </div>
        </div>

        <div className={b('row', [b('coefficients'), 'swiper-no-swiping'])}>
          <p>{tournament}</p>
          {lineType === 'live' && (
            <p className={b('live')}>
              <span className={b('bullet')}></span>
              Live
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasketItem;
