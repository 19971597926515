import React from 'react';
import cn from 'classnames';

import Loader from 'components/shared/loader/Loader';

import './Button.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary' | 'outline-primary-inverted' | 'shaded' | 'login';
  size?: 'medium' | 'small';
  disabled?: boolean;
  animated?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
} & typeof defaultProps;

const defaultProps = {
  variant: 'primary',
  size: 'medium',
  disabled: false,
  animated: false,
};

const Button = (props: Props) => {
  const {
    children,
    variant,
    size,
    disabled,
    onClick,
    animated,
    isLoading,
    className,
    type = 'button'
  } = props;


  const btnClassName = cn(
    'button',
    `button--size-${size}`,
    `button--${variant}`,
    { 'button--flare-animated': animated },
    className
  );

  return (
    <button
      type={type}
      className={btnClassName}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {children}
      {isLoading && (
        <Loader
          className={cn('button__loader', `button__loader--${variant}`)}
          wrapperClassName="button__loader-wrapper"
        />
      )}
    </button>
  );
};
Button.defaultProps = defaultProps;

export default React.memo(Button);
