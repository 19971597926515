import cn from 'classnames';

import SportIcon from 'components/shared/icons/SportIcon/SportIcon';

import SideBarCountryDesktop from './SideBarCountryDesktop/SideBarCountryDesktop';

import type { LineType, SportItem } from 'types/line-data';

type Props = {
  item: SportItem;
  isActive: boolean;
  lineType: LineType;
  onClick: () => void;
}

const SideBarFilterItemDesktop = (props: Props) => {
  const { item, isActive, lineType, onClick } = props;
  return (
    <div
      className={cn(
        'side-bar-filter-desktop__button-wrapper',
        { 'active': isActive }
      )}
    >
      <button className="side-bar-filter-desktop__button"
        type="button"
        onClick={onClick}
      >
        <SportIcon sportId={item.slug} />
        <h2>{item.name}</h2>
      </button>
      {isActive && <SideBarCountryDesktop lineType={lineType} />}
    </div>
  );
};

export default SideBarFilterItemDesktop;
