import React from 'react';
import cn from 'classnames';

// import Loader from 'components/shared/loader/Loader';

import './BetTypeButton.scss';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?(): void;
  isActive?: boolean;
};

const BetTypeButton = ({ children, disabled, onClick, isActive }: Props) => (
  <button
    type="button"
    className={cn('bet-type-button', { 'bet-type-button--active': isActive })}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default React.memo(BetTypeButton);
