import { bemCn } from 'utils/bem-cn';

import FooterNavGroup from './FooterNavGroup';

import type { LiltItem } from './FooterNavItem';

import './FooterNav.scss';

type GroupListItems = {
  groupTitle: string;
  items: LiltItem[];
}

type Props = {
  className?: string;
  navItems: GroupListItems[];
}

const FooterNav = (props: Props) => {
  const b = bemCn('footer-nav');

  return (
    <div className={b(null, props.className)}>
      {props.navItems.map((group, index) => {
        const keyVal = `${group.groupTitle}-${index}`;
        return (
          <FooterNavGroup
            key={keyVal}
            title={group.groupTitle}
            items={group.items}
          />
        );
      })}
    </div>
  );
};

export default FooterNav;
