import { combineReducers } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { api } from './api';
import { authData } from './auth/authSlice';
import { basketData } from './basket/basketSlice';
import { lineData } from './line/lineSlice';
import { paymentsData } from './payment/paymentsSlice';
import { slotsData } from './slots/slotsSlice';
import { userData } from './user/userSlice';
import { walletsData } from './wallets/walletsSlice';
import { appData } from './app/appSlice';

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [NameSpace.App]: appData.reducer,
  [NameSpace.Auth]: authData.reducer,
  [NameSpace.Basket]: basketData.reducer,
  [NameSpace.Line]: lineData.reducer,
  [NameSpace.Payments]: paymentsData.reducer,
  [NameSpace.Slots]: slotsData.reducer,
  [NameSpace.User]: userData.reducer,
  [NameSpace.Wallets]: walletsData.reducer,
});
