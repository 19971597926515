import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetTournamentsQuery } from 'store/line/lineApi';
// import { TournamentItem } from 'store/line/types';
import Loader from 'components/shared/loader/Loader';
// import { DesktopFilterType } from 'components/line/DesktopLine/DesktopLine';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setTournament } from 'store/line/lineSlice';
import { selectActiveSport, selectActiveTournament, selectCountry } from 'store/line/selectors';

import SideBarTournamentsItemDesktop from './SideBarTournamentsItemDesktop';

import type { LineType } from 'types/line-data';

import './SideBarTournamentsDesktop.scss';

type Props = {
  lineType: LineType;
};

const SideBarTournamentsDesktop = ({ lineType }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const activeSport = useAppSelector(selectActiveSport);
  const activeCountry = useAppSelector(selectCountry);
  const activeTournament = useAppSelector(selectActiveTournament);

  const { data: tournaments } = useGetTournamentsQuery({
    sportId: activeSport ?? '0',
    countryId: activeCountry ?? '0',
    type: lineType
  });

  useEffect(() => {
    dispatch(setTournament('0'));
  }, [activeCountry, activeSport]);

  const onButtonCLick = (id: string) => (
    id === activeTournament
      ? dispatch(setTournament('0'))
      : dispatch(setTournament(id))
  );

  const layout = tournaments && tournaments[activeCountry ?? 0]
    ? Object.values(tournaments[activeCountry ?? 0]).map((tournament) => (
      <SideBarTournamentsItemDesktop
        key={tournament.slug}
        item={tournament}
        isActive={tournament.name === activeTournament}
        onClick={() => onButtonCLick(tournament.name)}
      />
    ))
    : undefined;

  return (
    <div className="side-bar-tournaments-desktop">
      <h2>{t('bets-page.tournaments', 'Турниры')}</h2>
      <div className="side-bar-tournaments-desktop__list">
        {layout ?? <Loader />}
      </div>
    </div>
  );
};

export default SideBarTournamentsDesktop;
