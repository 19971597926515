import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import Button from 'components/shared/buttons/Button/Button';
// import BetInput from 'components/shared/inputs/BetInput/BetInput';
// import Checkbox from 'components/shared/inputs/Checkbox/Checkbox';
import FormCheckbox from 'components/shared/form/FormCheckbox';
import SumSuggestButton from 'components/shared/buttons/SumSuggestButton/SumSuggestButton';
import FormInputWrapper from 'components/shared/form/FormInput';
// import { defaultLimits, defaultSystem } from 'store/payment/constants';
import { getCurrencySymbol } from 'store/wallets/constants';
import { changeActiveModal } from 'store/auth/authSlice';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { getBetLimits, getBetSuggestions } from 'store/basket/constants';
import { bemCn } from 'utils/bem-cn';

import type { Currency } from 'types/wallets-data';

import './BasketForm.scss';

type FormFields = {
  amount: number | '';
  checked: boolean;
};

const schema = ({ min, max, balance }: { min: number; max: number; balance: number }) => yup.object({
  amount: yup
    .number()
    .min(min, `${i18n.t('cart.errors.min-amount', 'Минимальная сумма ставки - ')}${min}`)
    .max(max, `${i18n.t('cart.errors.max-amount', 'Максимальная сумма ставки - ')}${max}`)
    .test('balanceTest', `${i18n.t('cart.errors.balance', 'Недостаточно средств')}`, (value) => (value ? value <= balance : false))
    .required(`${i18n.t('cart.errors.empty-amount', 'Введите сумму ставки')}`)
    .typeError('Введите сумму'),
  checked: yup.bool()
    .oneOf([true], `${i18n.t('cart.errors.check-agree', 'Подтвердите согласие')}`),
}).required();

type Props = {
  balance: number;
  currency: Currency;
  onPlaceBetClick: (amount: number) => void;
  totalCoef: number;
  isLoading: boolean;
  disabled?: boolean;
};

const BasketForm = (props: Props) => {
  const {
    balance,
    currency,
    onPlaceBetClick,
    totalCoef,
    isLoading,
    disabled,
  } = props;
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const limits = getBetLimits(currency);

  const { handleSubmit, setValue, control, watch } = useForm<FormFields>({
    defaultValues: { amount: '', checked: true },
    mode: 'all',
    resolver: yupResolver(schema({ ...limits, balance })),
  });

  const values = watch();

  const setAmount = (value: number) => {
    setValue('amount', value, { shouldValidate: true, shouldTouch: true });
  };

  const handleButtonClick = ({ amount, checked }: FormFields) => {
    if (!amount || !checked) { return; }
    onPlaceBetClick(amount);
  };

  const suggestButtonsLayout = [...getBetSuggestions(currency), balance]
    .filter((suggest) => balance > 0 && suggest <= balance)
    .map((suggestAmount) => (
      <SumSuggestButton
        key={suggestAmount}
        amount={suggestAmount}
        currency={currency}
        variant="inverse"
        onClick={setAmount}
      />
    ));

  const b = bemCn('basket-buttons');

  return (
    <div className={b()}>
      <div className={b('bets-control')}>
        <div className={b('bet-input-label-wrapper')}>
          <div className={b('bet-input-label')}>
            <p>{t('cart.max-bet', 'Макс сумма ставки')}</p>
            <p>{t('cart.possible-prize', 'Возможный выигрыш')}</p>
          </div>
          <div className={b('bet-input-label')}>
            <p className={b('min')}>
              {`${limits.max} ${getCurrencySymbol(currency)}`}
            </p>
            <p className={b('win')}>
              {`+${values.amount
                ? (values.amount * totalCoef).toFixed(2)
                : (0).toFixed(2)} ${getCurrencySymbol(currency)}`}
            </p>
          </div>
        </div>
        <FormInputWrapper<FormFields>
          showError
          type="money"
          control={control}
          name="amount"
          placeholder={`${t('cart.amount', 'Сумма')}`}
          // disabled={isLoading || disabled}
          currency={currency}
          variant="inverted"
        />
        {balance > 0 && (
          <div className={b('suggestions')}>
            {suggestButtonsLayout}
          </div>
        )}
        {/* <Button variant="shaded">Поставить все</Button> */}
        <div className={b('accept')}>
          <FormCheckbox<FormFields>
            showError
            control={control}
            name="checked"
            disabled={isLoading || disabled}
            variant="inverted"
          />
          <p className={b('accept-text')}>
            {t('cart.coef-change-agree', 'Всегда соглашаться с изменением коэффициента')}
          </p>
        </div>
        {isAuthenticated ? (
          <Button
            isLoading={isLoading}
            disabled={disabled}
            variant="primary"
            onClick={handleSubmit(handleButtonClick)}
          >
            {t('cart.make-bet', 'Сделать ставку')}
          </Button>
        ) : (
          <Button
            isLoading={isLoading}
            variant="primary"
            onClick={() => dispatch(changeActiveModal('sign-in'))}
          >
            {t('cart.make-bet', 'Сделать ставку')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BasketForm;
