import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from 'hooks';
import { useClickRefCodeMutation } from 'store/auth/authApi';
import { changeActiveModal } from 'store/auth/authSlice';

type Props = {
  isAuthenticated: boolean;
};

const ReferralLogin = ({ isAuthenticated }: Props) => {
  const navigate = useNavigate();
  const { refCode } = useParams();
  const [click] = useClickRefCodeMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const setCode = async () => {
      if (refCode && !isAuthenticated) {
        localStorage.setItem('refCode', refCode);
        await click(refCode);
      }
    };

    setCode().finally(() => {
      dispatch(changeActiveModal('sign-up'));
      navigate('/');
    });
  }, [refCode]);

  return null;
};

export default ReferralLogin;
