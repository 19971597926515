import React, { useEffect, useState } from 'react';

import Pagination from 'components/shared/Pagination/Pagination';
import { useSlotsSessioncsQuery } from 'store/user/userApi';
import { scrollToTop } from 'utils/scrollToTop';
import { TRANSACTIONS_ITEMS_PER_PAGE } from 'utils/constants';

import SlotsSectionItem from '../SlotsSeccionItem/SlotsSectionItem';
import NoItems from '../../components/NoItems/NoItems';

import type { SlotsSeccionType } from 'types/user-data';

const SlotsPage = () => {
  const [selectPage, setSelectPage] = useState<number>(1);
  const { data: slotsSessions, isLoading } = useSlotsSessioncsQuery(selectPage);

  useEffect(() => {
    scrollToTop(true);
  }, [selectPage]);

  const allItemsCount = slotsSessions?.count || 0;
  const countPages = Math.ceil(allItemsCount / TRANSACTIONS_ITEMS_PER_PAGE);
  const isEmptyList = !isLoading && !slotsSessions?.items?.length;

  if (isLoading || isEmptyList) {
    return (
      <NoItems isLoading={isLoading} isEmpty={isEmptyList} />
    );
  }

  return (
    <>
      {slotsSessions?.items.map((item: SlotsSeccionType) => (
        <SlotsSectionItem key={item.id} slot={item} />
      ))}

      <Pagination
        selectPage={selectPage}
        countPages={countPages}
        setSelectPage={(value) => setSelectPage(value)}
      />
    </>
  );
};

export default SlotsPage;
