import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import * as React from 'react';

import Checkbox from '../inputs/Checkbox/Checkbox';

type Props<TFormValues extends FieldValues> = {
  showError?: boolean;
  disabled?: boolean;
  variant?: 'inverted';
} & UseControllerProps<TFormValues>;

const FormInput = <TFormValues extends Record<string, unknown>>({
  showError,
  disabled,
  variant,
  ...props
}: Props<TFormValues>) => {
  const { field, fieldState } = useController(props);
  const { value } = field;

  return (
    <div>
      <Checkbox
        {...field}
        variant={variant}
        checked={value as boolean}
        isError={showError && fieldState.isTouched && !!fieldState.error}
        disabled={disabled}
      />
    </div>
  );
};

export default FormInput;
