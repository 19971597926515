import { toLocaleDateString, toLocaleTimeString } from 'utils/time';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardStats from 'components/line/EventCard/components/EventCardStats/EventCardStats';
import EventCardСountdown from 'components/line/EventCard/components/EventCardСountdown/EventCardСountdown';
import EventCardBoard from 'components/line/EventCard/components/EventCardBoard/EventCardBoard';
import { bemCn } from 'utils/bem-cn';

import type { FullEventItem, LineType } from 'types/line-data';

import './FullEventHeader.scss';

type Props = {
  event: FullEventItem;
  lineType: LineType;
};

const FullEventHeader = ({ event, lineType }: Props) => {
  const {
    eventDate,
    tournament,
    periodName,
    gameDesk,
    statList,
  } = event;

  const date = new Date(eventDate);
  const isLive = lineType === 'live';

  const b = bemCn('full-event-header');

  return (
    <div className={b()}>
      <div className={b('time')}>
        <div className={b('title-wrapper')}>
          <h1 className={b('title')}>{toLocaleTimeString(date)}</h1>
          {(isLive && periodName) && (
            <div className={b('period-layout')}>
              <SvgIcon className={b('period-icon')}
                name="clock" width={10} height={10}
              />
              <p className={b('period')}>
                {gameDesk.length > 0 ? gameDesk : periodName}
              </p>
            </div>
          )}
        </div>
        <SvgIcon name="star" width={14} height={14} fill='#706E98' />
      </div>
      <div className={b('time')}>
        <p className={b('date')}>{toLocaleDateString(date)}</p>
        <p>{tournament}</p>
      </div>
      <EventCardBoard
        className={b('board')}
        isLive={isLive}
        event={event}
      />
      <EventCardStats
        className={b('stats')}
        stats={statList}
      />
      <EventCardСountdown
        className={b('start-time')}
        date={eventDate}
        hide={isLive}
      />
    </div>
  );
};

export default FullEventHeader;
