const imagesNames: Record<string, string> = {
  1: 'russia',
  2: 'ukraine',
  4: 'australia',
  5: 'austria',
  6: 'azerbaijan',
  9: 'angola',
  11: 'andorra',
  14: 'argentina',
  15: 'armenia',
  21: 'bahrain',
  24: 'belgium',
  27: 'bulgaria',
  30: 'botswana',
  31: 'brazil',
  34: 'burkina faso',
  36: 'bhutan',
  40: 'hungary',
  50: 'guatemala',
  53: 'germany',
  55: 'gibraltar',
  56: '',
  60: 'greece',
  62: 'denmark',
  66: 'egypt',
  71: 'india',
  76: 'ireland',
  78: 'spain',
  79: 'italy',
  87: 'kenya',
  88: 'cyprus',
  90: 'china',
  91: 'colombia',
  95: 'costa rica',
  96: '',
  98: 'kuwait',
  104: 'liberia',
  105: '',
  109: 'luxembourg',
  113: 'republic of macedonia',
  116: 'mali',
  118: 'malta',
  133: 'netherlands',
  134: 'nicaragua',
  139: 'united arab emirates',
  145: 'peru',
  147: 'poland',
  148: 'portugal',
  151: 'rwanda',
  152: 'romania',
  153: 'united states',
  154: 'el salvador',
  156: 'san marino',
  165: 'senegal',
  168: 'serbia',
  171: 'slovakia',
  172: 'slovenia',
  180: 'thailand',
  181: 'tanzania',
  182: 'togo',
  190: 'turkey',
  191: 'uganda',
  198: 'france',
  201: 'croatia',
  203: 'montenegro',
  204: 'czech republic',
  205: 'chile',
  206: 'switzerland',
  207: 'sweden',
  213: 'ethiopia',
  219: 'scotland',
  223: 'european union',
  225: '',
  230: 'wales',
  231: 'england',
  236: '',
  279: 'kosovo',
};

const imagesNamesByCode: Record<string, string> = {
  RU: 'russia',
  UA: 'ukraine',
  AU: 'australia',
  AT: 'austria',
  AZ: 'azerbaijan',
  AO: 'angola',
  AD: 'andorra',
  AR: 'argentina',
  AM: 'armenia',
  BH: 'bahrain',
  BE: 'belgium',
  BG: 'bulgaria',
  BW: 'botswana',
  BR: 'brazil',
  BF: 'burkina faso',
  BT: 'bhutan',
  HU: 'hungary',
  GT: 'guatemala',
  DE: 'germany',
  GI: 'gibraltar',
  GR: 'greece',
  DK: 'denmark',
  EG: 'egypt',
  IN: 'india',
  IE: 'ireland',
  ES: 'spain',
  IT: 'italy',
  KE: 'kenya',
  CY: 'cyprus',
  HK: 'china',
  CO: 'colombia',
  CR: 'costa rica',
  KW: 'kuwait',
  LR: 'liberia',
  LU: 'luxembourg',
  MK: 'republic of macedonia',
  ML: 'mali',
  MT: 'malta',
  NL: 'netherlands',
  NI: 'nicaragua',
  AE: 'united arab emirates',
  PE: 'peru',
  PL: 'poland',
  PT: 'portugal',
  RW: 'rwanda',
  RO: 'romania',
  US: 'united states',
  SV: 'el salvador',
  SM: 'san marino',
  SN: 'senegal',
  RS: 'serbia',
  SK: 'slovakia',
  SI: 'slovenia',
  TH: 'thailand',
  TZ: 'tanzania',
  TG: 'togo',
  TR: 'turkey',
  UG: 'uganda',
  FR: 'france',
  HR: 'croatia',
  ME: 'montenegro',
  CZ: 'czech republic',
  CL: 'chile',
  CH: 'switzerland',
  SE: 'sweden',
  ET: 'ethiopia',
  KZ: 'kazakhstan',
};

const flagsById: Record<string, string> = {
  1: 'russia',
  2: 'ukraine',
  4: 'australia',
  5: 'austria',
  6: 'azerbaijan',
  9: 'angola',
  11: 'andorra',
  14: 'argentina',
  15: 'armenia',
  21: 'bahrain',
  24: 'belgium',
  27: 'bulgaria',
  30: 'botswana',
  31: 'brazil',
  34: 'burkina-faso',
  36: 'bhutan',
  40: 'hungary',
  50: 'guatemala',
  53: 'germany',
  55: 'gibraltar',
  56: '',
  60: 'greece',
  62: 'denmark',
  66: 'egypt',
  71: 'india',
  76: 'ireland',
  78: 'spain',
  79: 'italy',
  87: 'kenya',
  88: 'cyprus',
  90: 'china',
  91: 'colombia',
  95: 'costa-rica',
  96: '',
  98: 'kuwait',
  104: 'liberia',
  105: '',
  109: 'luxembourg',
  113: 'republic-of-macedonia',
  116: 'mali',
  118: 'malta',
  133: 'netherlands',
  134: 'nicaragua',
  139: 'united-arab-emirates',
  145: 'peru',
  147: 'poland',
  148: 'portugal',
  151: 'rwanda',
  152: 'romania',
  153: 'united-states',
  154: 'el-salvador',
  156: 'san-marino',
  165: 'senegal',
  168: 'serbia',
  171: 'slovakia',
  172: 'slovenia',
  180: 'thailand',
  181: 'tanzania',
  182: 'togo',
  190: 'turkey',
  191: 'uganda',
  198: 'france',
  201: 'croatia',
  203: 'montenegro',
  204: 'czech-republic',
  205: 'chile',
  206: 'switzerland',
  207: 'sweden',
  213: 'ethiopia',
  219: 'scotland',
  223: 'european-union',
  225: '',
  230: 'wales',
  231: 'england',
  236: '',
  279: 'kosovo'
};

const flagsNameByCode:Record<string,string> = {
  'RU': 'russia',
  'UA': 'ukraine',
  'AU': 'australia',
  'AT': 'austria',
  'AZ': 'azerbaijan',
  'AO': 'angola',
  'AD': 'andorra',
  'AR': 'argentina',
  'AM': 'armenia',
  'BH': 'bahrain',
  'BE': 'belgium',
  'BG': 'bulgaria',
  'BW': 'botswana',
  'BR': 'brazil',
  'BF': 'burkina-faso',
  'BT': 'bhutan',
  'HU': 'hungary',
  'GT': 'guatemala',
  'DE': 'germany',
  'GI': 'gibraltar',
  'GR': 'greece',
  'DK': 'denmark',
  'EG': 'egypt',
  'IN': 'india',
  'IE': 'ireland',
  'ES': 'spain',
  'IT': 'italy',
  'KE': 'kenya',
  'CY': 'cyprus',
  'HK': 'china',
  'CO': 'colombia',
  'CR': 'costa-rica',
  'KW': 'kuwait',
  'LR': 'liberia',
  'LU': 'luxembourg',
  'MK': 'republic-of-macedonia',
  'ML': 'mali',
  'MT': 'malta',
  'NL': 'netherlands',
  'NI': 'nicaragua',
  'AE': 'united-arab-emirates',
  'PE': 'peru',
  'PL': 'poland',
  'PT': 'portugal',
  'RW': 'rwanda',
  'RO': 'romania',
  'US': 'united-states',
  'SV': 'el-salvador',
  'SM': 'san-marino',
  'SN': 'senegal',
  'RS': 'serbia',
  'SK': 'slovakia',
  'SI': 'slovenia',
  'TH': 'thailand',
  'TZ': 'tanzania',
  'TG': 'togo',
  'TR': 'turkey',
  'UG': 'uganda',
  'FR': 'france',
  'HR': 'croatia',
  'ME': 'montenegro',
  'CZ': 'czech-republic',
  'CL': 'chile',
  'CH': 'switzerland',
  'SE': 'sweden',
  'ET': 'ethiopia',
  'KZ': 'kazakhstan'
};

export const getCountryIcon = (id: string) => imagesNames[id] || 'world';
export const getCountryIconByCode = (countryCode: string | null) => {
  if (countryCode === null) {
    return 'russia';
  }
  return imagesNamesByCode[countryCode] || 'russia';
};

const sportIconNames: Record<string, string> = {
  0: 'all',
  1: 'football',
  2: 'hockey',
  4: 'tennis',
  5: 'baseball',
  6: 'volleyball',
  8: 'handball',
  10: 'table-tennis',
  11: 'badminton',
  16: 'cricket',
};

export const getSportIcon = (id: string) => sportIconNames[id] || 'all';
