import { NameSpace } from 'utils/constants';

import { Status } from './appSlice';

import type { RootState } from 'types/state';

export const selectAppStatus = (state: RootState) =>
  state[NameSpace.App].status;

export const selectSystemStatus = (state: RootState) =>
  selectAppStatus(state).system;

export const selectIsSystemActive = (state: RootState) =>
  selectSystemStatus(state) === Status.Active;

export const selectLineStatus = (state: RootState) =>
  selectAppStatus(state).line;

export const seletIsLineActive = (state: RootState) =>
  selectLineStatus(state) === Status.Active;

export const selectBetgamesStatus = (state: RootState) =>
  selectAppStatus(state).betgames;

export const seletIsBetgameActive = (state: RootState) =>
  selectBetgamesStatus(state) === Status.Active;

export const selectInbetStatus = (state: RootState) =>
  selectAppStatus(state).inbet;

export const selectIsInbetActive = (state: RootState) =>
  selectInbetStatus(state) === Status.Active;

export const selectTggStatus = (state: RootState) =>
  selectAppStatus(state).tgg;

export const seletIsTggActive = (state: RootState) =>
  selectTggStatus(state) === Status.Active;

export const selectWithdrawalsStatus = (state: RootState) =>
  selectAppStatus(state).withdrawals;

export const selectIsWithdrawalsActive = (state: RootState) =>
  selectWithdrawalsStatus(state) === Status.Active;

export const selectSystemMessage = (state: RootState) =>
  selectAppStatus(state).systemMessage;
