import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CaretIcon } from 'assets/caret.svg';
import Flag from 'components/shared/Flag/Flag';
import Loader from 'components/shared/loader/Loader';
import { topTournamentsID } from 'utils/constants';
import { sortByTopEntries, checkMajorTournament } from 'utils/common';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import type { TournamentItem } from 'types/line-data';

import './CountryItem.scss';

type Props = {
  countryId: string;
  name: string;
  onClick: () => void;
  tournaments: TournamentItem[];
  isLoading: boolean;
  sportId: string;
  lineType?: string;
};

const CountryItem = (props: Props) => {
  const { countryId, name, onClick, tournaments, isLoading, sportId } = props;
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const sortedTournaments = tournaments
    ? sortByTopEntries(tournaments, topTournamentsID, (t) => t.slug)
    : [];

  const b = bemCn('country-item');

  const onItemClick = () => {
    setCollapsed((prev) => !prev);
    onClick();
  };

  const tournamentsLayout = sortedTournaments.map((tournament) => {
    const isMajor = checkMajorTournament(tournament.slug);
    return (
      <Link className={b('tournament', { 'major': isMajor })}
        key={tournament.slug}
        to={`/line/tournament/${sportId}/${tournament.slug}/${tournament.name}`}
      >
        <SvgIcon className={b('favorite-icon')} name='star' width={14} height={14} />
        <p className={b('tournament-title')}>{tournament.name}</p>
      </Link>
    );
  });

  return (
    <div className={b()}>
      <div className={b('wrapper')} onClick={onItemClick}>
        <div className={b('title-wrapper')}>
          <Flag countryId={countryId} />
          <p className={b('title')}>{name}</p>
        </div>
        <CaretIcon className={b('icon')} />
      </div>
      {!isCollapsed && (
        <div className={b('tournaments')}>
          {isLoading
            ? <Loader />
            : tournamentsLayout}
        </div>
      )}
    </div>
  );
};

export default CountryItem;
