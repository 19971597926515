import React from 'react';

import { bemCn } from 'utils/bem-cn';
import './ScorePeriods.scss';

type Props = {
  scorePeriods: string;
  team1: string;
  team2: string;
  scoreFull: string;
  roundName: string;
};

const ScorePeriods = (props: Props) => {
  const { scorePeriods, team1, team2, scoreFull, roundName } = props;
  const periods = scorePeriods.split(';');

  const b = bemCn('score-table');

  return (
    <div className={b()}>
      <div className={b('body')}>
        <div className={b('head')}>
          <div className={b('cell', b('cell-title'))}>{roundName}</div>
          <div className={b('cell')}>{team1}</div>
          <div className={b('cell')}>{team2}</div>
        </div>
        <div className={b('rows')}>
          <div className={b('row')}>
            <div className={b('cell', { 'null': true })} />
            <div className={b('cell', { 'total': true })}>
              {scoreFull.split(':')[0]}
            </div>
            <div className={b('cell', { 'total': true })}>
              {scoreFull.split(':')[1]}
            </div>
          </div>
          {periods.map((period, index) => {
            const scores = period.split(':');
            const keyVal = `${period}-${index}`;
            return (
              <div className={b('row')} key={keyVal}>
                <div className={b('cell', b('cell-title'))}>{index + 1}</div>
                <div className={b('cell')}>{scores[0]}</div>
                <div className={b('cell')}>{scores[1]}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScorePeriods;
