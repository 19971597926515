import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useCountdown } from 'usehooks-ts';
import cn from 'classnames';

import Loader from 'components/shared/loader/Loader';
import { useAppSelector } from 'hooks';
import { useGetBetgamesTokenQuery } from 'store/games/gamesApi';
import { selectPrimaryWalletCurrency } from 'store/user/selectors';
import { useResponsive } from 'hooks/useResponsive';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import './BetgamesCasino.scss';
// import BetgamesCasinoIframe from './BetgamesCasinoIframe/BetgamesCasinoIframe';

type AccessError = {
  data?: {
    detail?: string;
  };
  status?: number;
}

// const clientServer = 'https://webiframe.betgames.tv';
// const productionServer = 'https://game.betgames.tv';
const clientServer = 'https://integrations01-webiframe.betgames.tv';
const productionServer = 'https://integrations01.betgames.tv';
const partnerCode = 'bmp_dev';

const BetgamesCasino = () => {
  const { isMobile } = useResponsive();
  const { i18n } = useTranslation();
  const { gameId } = useParams();
  const [isPageLoading, setPageLoading] = useState(true);
  const [loadingTimer, { startCountdown }] = useCountdown({
    countStart: 3,
    intervalMs: 1000
  });
  const currency = useAppSelector(selectPrimaryWalletCurrency);
  const { data, isLoading, isFetching, isError, error, isSuccess } = useGetBetgamesTokenQuery({
    currency: currency as string
  }, { skip: !currency });

  const errorDetails = error as AccessError;

  let errorDesc = 'Ошибка авторизации игры';
  if (isError && errorDetails?.data?.detail) {
    if (errorDetails?.data?.detail === 'Slots not available for this account') {
      errorDesc = 'Игра не доступна для вашего акканта';
    } else {
      errorDesc = errorDetails?.data?.detail;
    }
  }

  const partnerToken = data?.token ?? '-';
  const defaultGame = gameId ?? '5';
  const language = i18n.resolvedLanguage ?? 'ru';
  const mobile = isMobile ? 1 : 0;

  useEffect(() => {
    startCountdown();
  }, []);

  useEffect(() => {
    if (loadingTimer === 0 && !isLoading && !isFetching) {
      setPageLoading(false);
    }
  }, [isLoading, isFetching, loadingTimer]);

  useEffect(() => {
    if (data?.token && isSuccess) {
      const script = document.createElement('script');

      script.onload = function () {
        // @ts-ignore
        window.BetGames.setup({
          containerId: 'betgames__container',
          clientUrl: clientServer,
          apiUrl: productionServer,
          partnerCode: partnerCode,
          partnerToken: partnerToken,
          language: language,
          timezone: '3',
          defaultGame: defaultGame,
          isMobile: mobile,
        });
      };

      script.type = 'text/javascript';
      script.id = 'betgames_script';
      script.src = `${clientServer}/public/betgames.js?${Date.now()}`;

      document.head.appendChild(script);
    }
    return () => {
      const betgames = document.getElementById('betgames_script');
      betgames?.remove();
      // @ts-ignore
      if (window.BetGames) {
        // @ts-ignore
        window.BetGames = undefined;
      }
    };
  }, [data]);

  return (
    <div className={cn('betgames', { 'betgames--loading': isPageLoading })}>
      <Breadcrumbs
        className='betgames__breadcrumbs'
        link="/games"
        title="Список игр"
      />
      <h1 className='betgames__title'>Betgames TV</h1>
      <Loader wrapperClassName='betgames__spiner' />
      {isError && (
        <div className="betgames__error">
          <p className="betgames__error-title">
            Произошла ошибка
          </p>
          <p>{errorDesc}</p>
        </div>
      )}
      {isSuccess && (
        <div className="betgames__container" id='betgames__container'></div>
      )}
      {/* <div className="betgames__container">
        {isLoading || isFetching ? <Loader /> : (
          <BetgamesCasinoIframe
            className="betgames__iframe"
            clientServer={clientServer}
            productionServer={productionServer}
            partnerToken={partnerToken}
            partnerCode={partnerCode}
            defaultGame={defaultGame}
            language={language}
            mobile={mobile}
          />
        )}
      </div> */}
    </div>
  );
};

export default BetgamesCasino;
