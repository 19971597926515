import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { bemCn } from 'utils/bem-cn';

import DesktopHeaderUser from './DesktopHeaderUser/DesktopHeaderUser';
import DesktopHeaderBalance from './DesktopHeaderBalance/DesktopHeaderBalance';

import './DesktopHeaderAuthorized.scss';

const DesktopHeaderAuthorized = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id: userId } = useAppSelector(selectAccountData);

  const handleTopupClick = () => {
    dispatch(changeActiveModal('topups'));
  };

  const b = bemCn('desktop-header-authorized');
  return (
    <div className={b()}>
      <div className={b('id')}>
        <p>ID:</p>
        <h2>{userId}</h2>
      </div>
      <DesktopHeaderBalance />
      <div className={b('top-up')}>
        <Button onClick={handleTopupClick}>
          {t('user-nav.topup', 'Пополнить')}
        </Button>
      </div>
      <DesktopHeaderUser />
    </div>
  );
};

export default DesktopHeaderAuthorized;
