import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';

// import BonusItem from './components/BonusItem/BonusItem';
import bonusImg1 from './assets/five_procent.jpg';
import BonusList from './components/BonusList/BonusList';
import BonusInfo from './components/BonusInfo/BonusInfo';
import bonusBanner1 from './assets/five_procentBanner.jpg';

import './BonusPage.scss';

export type BonusListType = {
  title: string;
  subTitle: string;
  preview: string;
  banner: string;
  body: JSX.Element;
  id: string;
};

export const bonusList: BonusListType[] = [
  {
    title: 'Бонус +5% к каждому депозиту',
    subTitle: 'При пополнении через оператора бонус +5% к каждому депозиту',
    preview: bonusImg1,
    banner: bonusBanner1,
    body:
      <>
        <p>
          Для получения бонуса +5% к абсолютно каждому депозиту и на
          любую сумму, Вам нужно пополнить счет через <a href="https://t.me/boompari_com" target="_blank" rel="noreferrer">оператора</a>, выполнив следующие действия:
        </p>
        <div className="bonus-page__body-list">
          <p>
            1. Напишите <a href="https://t.me/boompari_com" target="_blank" rel="noreferrer">оператору</a> “Хочу пополнить счет,
            на сумму 2000 рублей через банковскую карту”.
            Оператор уточнит у Вас какой именно банк и после этого выдаст Вам реквизиты.
          </p>
          <p>
            2. Сделайте прямой перевод на реквизиты, которые отправил Вам оператор
          </p>
          <p>
            3. Сообщите оператору Ваш ID.
          </p>
          <p>
            4. Оператор зачислит средства на Ваш счет с учетом бонуса +5% к депозиту!
          </p>
        </div>
        <h3>Правила использования бонуса:</h3>
        <div className="bonus-page__body-list">
          <p>
            - Бонус предоставляется абсолютно каждому клиенту.
          </p>
          <p>
            - Получить бонус можно неограниченное количество раз.
          </p>
          <p>
            - После вывода средств с Вашего счета, бонус возможно получить только через сутки.
            Если с момента крайнего вывода не прошли сутки, то депозит зачислится без бонуса.
          </p>
          <p>
            - Бонус не нужно отыгрывать. Но после получения бонуса нужно делать ставки и
            только потом выводить, так как все выводы обрабатываются оператором вручную.
          </p>
        </div>
      </>,
    id: '1'
  }
];

const BonusPage = () => (
  <Routes>
    <Route path="/" element={<BonusList />} />
    <Route path="/:id" element={<BonusInfo />} />
  </Routes>
);

export default BonusPage;
