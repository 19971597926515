import { useTranslation } from 'react-i18next';

import { countDownTimer } from 'utils/time';
import { bemCn } from 'utils/bem-cn';
import './EventCardСountdown.scss';

type Props = {
  className?: string;
  date: string;
  hide?: boolean;
}

const EventCardСountdown = ({ className, date, hide }: Props) => {
  const { t } = useTranslation();
  const { minutes, hours, days } = countDownTimer(date);

  if (hide || minutes < 0 || hours < 0 || days < 0) {
    return null;
  }

  const timer = `${days} ${t('event.start-event-days', 'дн.')}.
    ${hours} ${t('event.start-event-hours', 'ч.')}.
    ${minutes} ${t('event.start-event-minutes', 'мин.')}. `;

  const b = bemCn('event-card-countdown');

  return (
    <div className={b(null, className)}>
      <p className={b('title')}>
        {t('event.start-event', 'Начало через')}
      </p>
      <p className={b('timer')}>{timer}</p>
    </div>
  );
};

export default EventCardСountdown;
