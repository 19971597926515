import React, { MouseEventHandler, useEffect, useState } from 'react';

import { bemCn } from 'utils/bem-cn';

type BetItemProps = {
  onClick: () => void;
  title: string;
  rate?: number;
  isActive?: boolean;
}

const EventCardMobileBetItem = (props: BetItemProps) => {
  const [diff, setDiff] = useState('');
  const [rate, setRate] = useState(props.rate);

  const isRateShow = rate && rate > 0;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (diff !== '') {
        setDiff('');
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [diff]);

  useEffect(() => {
    setRate((prevRate) => {
      if (prevRate !== undefined && props.rate !== undefined) {
        if (props.rate > prevRate) {
          setDiff('increase');
        } else if (props.rate < prevRate) {
          setDiff('decrease');
        }
      }
      return props.rate;
    });
  }, [props.rate]);

  const handleClick: MouseEventHandler = (evt) => {
    evt.preventDefault();
    props.onClick();
  };

  const b = bemCn('event-card-mobile');

  return (
    <button className={b(
      'bet-item',
      {
        'active': props.isActive,
        'increase': diff === 'increase',
        'decrease': diff === 'decrease'
      }
    )} type="button" onClick={handleClick}
    >
      <span className={b('bet-name')}>{props.title}</span>
      <span className={b('bet-rate')}>{isRateShow && rate}</span>
    </button>
  );
};

export default EventCardMobileBetItem;
