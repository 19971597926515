import React from 'react';

import { selectRestoreError } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';
import DetailingModal from 'components/profile/DetailingModal/DetailinModal';
import SettingsModal from 'components/profile/SettingsModal/SettingsModal';
import TopUpsDesktop from 'components/profile/TopUpsDesktop/TopUpsDesktop';
import WithdrawalsDesktop from 'components/profile/WithdrawalsDesktop/WithdrawalsDesktop';
import WalletManageModal from 'components/profile/WalletManage/WalletManageModal/WalletManageModal';
import { selectIsWithdrawalsActive } from 'store/app/selectores';

import ResetPassword from '../ResetPassword/ResetPassword';
import Confirmation from '../Confirmation/Confirmation';
import SignUp from '../SignUp/SignUp';
import SignIn from '../SignIn/SignIn';
import ChangePassword from '../ChangePassword/ChangePassword';

import type { AuthModalType } from 'types/auth-data';

import './AuthModalsGroup.scss';

type Props = {
  activeModal: AuthModalType | null;
  closeModal(): void;
};

const AuthModalsGroup = ({ activeModal, closeModal }: Props) => {
  const restoreError = useAppSelector(selectRestoreError);
  const isWithdrawalsActive = useAppSelector(selectIsWithdrawalsActive);
  return (
    <>
      <Confirmation
        modalIsOpen={activeModal === 'confirm-email'}
        closeModal={closeModal}
        type="confirmation"
      />
      <Confirmation
        modalIsOpen={activeModal === 'success'}
        closeModal={closeModal}
        type="success"
      />
      <Confirmation
        modalIsOpen={activeModal === 'error'}
        closeModal={closeModal}
        type="error"
      />
      <Confirmation
        modalIsOpen={activeModal === 'reset-success'}
        closeModal={closeModal}
        type="success"
      />
      <Confirmation
        modalIsOpen={activeModal === 'reset-error'}
        closeModal={closeModal}
        type="reset-error"
        error={restoreError}
      />
      <Confirmation
        modalIsOpen={activeModal === 'success-send-reset-link'}
        closeModal={closeModal}
        type="success-send-reset-link"
      />
      <SignUp
        modalIsOpen={activeModal === 'sign-up'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <SignIn
        modalIsOpen={activeModal === 'sign-in'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <ResetPassword
        modalIsOpen={activeModal === 'reset-password'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <ChangePassword
        modalIsOpen={activeModal === 'change-password'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <DetailingModal
        modalIsOpen={activeModal === 'detailing'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <SettingsModal
        modalIsOpen={activeModal === 'settings'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <TopUpsDesktop
        modalIsOpen={activeModal === 'topups'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <WithdrawalsDesktop
        modalIsOpen={activeModal === 'withdrawals-modal' && isWithdrawalsActive}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
      <WalletManageModal
        modalIsOpen={activeModal === 'wallet-manage'}
        closeModal={closeModal}
        contentClassName="authModalContent"
        overlayClassName="authModalOverlay"
      />
    </>
  );
};

export default AuthModalsGroup;
