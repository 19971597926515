import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInputWrapper from 'components/shared/form/FormInput';
import FormSelect from 'components/shared/form/FormSelect';

import type { FormFields } from '../../SignUp';
import type { Control } from 'react-hook-form';
import type { Currency } from 'types/wallets-data';

import './EmailTab.scss';

type Props = {
  id: number;
  availableCurrencies: Currency[];
  control?: Control<FormFields>;
  isLoading: boolean;
  isCurrenciesLoading: boolean;
};

const EmailTab = (props: Props) => {
  const {
    control,
    availableCurrencies,
    id,
    isLoading,
    isCurrenciesLoading
  } = props;
  const { t } = useTranslation();

  return (
    <div className="email-tab" key={id}>
      <FormSelect<FormFields, Currency>
        showError
        options={availableCurrencies}
        control={control}
        name="currency"
        disabled={isLoading}
        isLoading={isCurrenciesLoading}
        type="currency"
      />
      <FormInputWrapper<FormFields>
        showError
        type="text"
        control={control}
        name="email"
        placeholder={`${t('reg.modal.email', 'E-mail')}`}
        disabled={isLoading}
      />
      <FormInputWrapper<FormFields>
        showError
        type="text"
        control={control}
        name="name"
        placeholder={`${t('reg.modal.user-name', 'ФИО')}`}
        disabled={isLoading}
      />
      <FormInputWrapper<FormFields>
        showError
        type="password"
        control={control}
        name="password"
        autoComplete="new-password"
        placeholder={`${t('reg.modal.password', 'Пароль')}`}
        disabled={isLoading}
      />
    </div>
  );
};

export default EmailTab;
