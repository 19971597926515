import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { bemCn } from 'utils/bem-cn';

import RulesItem from './components/rulesItem/RulesItem';
import './RulesPage.scss';

export type rulesListType = {
  title: string;
  items?: {
    text: string;
    items?: {
      text: string;
    }[];
  }[];
};

const rulesList: rulesListType[] = [
  {
    title: 'Общие положения',
    items: [
      {
        text: 'Ставки принимаются у лиц старше 18 лет.',
      },
      {
        text: `Ставки принимаются только у лиц, которые согласны с правилами, установленными
        букмекерской компанией. Любая ставка является подтверждением того, что клиент знает
        настоящие правила и с ними согласен. Администрация имеет право признать недействительными
        ставки, сделанные с нарушением настоящих Правил. Администрация не несет никакой ответственности
        относительно того, когда именно ей стало известно, либо установлено впоследствии, что ставки были
        сделаны с нарушением Правил.`,
      },
      {
        text: ' Администрация имеет право не принимать ставки у лиц, не выполняющих настоящие правила.',
      },
      {
        text: `В случае выявления мошеннических действий, связанных с
        приемом ставок или финансовыми операциями, администрация имеет право
        пресечь такие действия путем признания ставок недействительными, закрытия
        счета без возврата остатка денежных средств и обращения в правоохранительные
         органы по факту совершения мошеннических действий.`,
      },
      {
        text: 'В случае выявления ставок на опережение аккаунт блокируется без возврата средств.',
      },
      {
        text: 'В случае ставок на “вилки” со стороны клиента - аккаунт блокируется без возврата средств.',
      },
      {
        text: `Претензии по спорным вопросам принимаются в течение 10 дней после
        окончания события на основании письменного заявления.
        Окончательное решение по всем спорным ситуациям принимает администрация.`
      },
      {
        text: `Настоящие правила и изменения к ним вступают в силу с даты публикации на сайте и
        распространяются на все ставки и действия, сделанные начиная с даты публикации,
        независимо от того, знают ли клиенты о вступлении в силу данной редакции правил или
        нет. Все предыдущие правила считаются утратившими силу.`
      }
    ]
  },
  {
    title: 'Открытие, пополнение депозита и получение выигрышей',
    items: [
      {
        text: `Прежде всего, клиент должен зарегистрироваться в системе.
         При регистрации обязательно указывается фамилия, имя,
        отчество, e-mail. В случае неправильно указанных регистрационных данных администрация оставляет за собой право
         приостановить операции на игровом счете и признать ставки недействительными. В случае успешной регистрации
          клиенту присваивается уникальный регистрационный номер (ID), который будет использоваться для управления
           счетом. Клиент может использовать свой игровой счет только для ставок и других сервисов букмекерской конторы.
            Все другие операции (перевод денежных средств в другие платежные системы, оплата различных услуг и т.д.)
             запрещаются. Игры на деньги в интернете могут быть незаконными
              в юрисдикции страны, в которой находится клиент.`
      },
      {
        text: 'Минимальный первоначальный депозит зависит от способа осуществления платежа.'
      },
      {
        text: `Клиент полностью отвечает за секретность своего счета, сохранность регистрационного номера и пароля.
         Администрация не несет никакой ответственности за последствия, вызванные попаданием персональных данных
          клиента к посторонним лицам. Утеря пароля не может служить поводом для отмены ставок или отмены запроса
           на выплату выигрыша. Как только клиенту станет известно (или у него возникнет подозрение) об утере
            персональных данных следует немедленно поменять пароль и известить администрацию. Для повышения безопасности
             рекомендуется периодически менять пароль. Поменять пароль можно в личном кабинете.`
      },
      {
        text: `Одному физическому лицу разрешается иметь только один игровой счет.
         Повторная регистрация допускается только с разрешения администрации. Клиент
          не вправе разрешать третьим лицам использовать свой игровой счет.
           Зарегистрированный ранее клиент не может пройти повторную регистрацию
            как новый клиент, в том числе с указанием других регистрационных
             данных (с новым именем, с новым адресом электронной почты и т.д.).
              Администрация имеет право произвести процедуру верификации и затребовать документы,
               подтверждающие личные данные клиента, указанные им в соответствии с п.п. 2.1 в
                любом из стандартных компьютерных форматов с требуемым качеством документов,
                 а также приостановить операции на игровом счете на время проведения процедуры
                  верификации, сроки которой устанавливаются в индивидуальном порядке. В случае
                   подтверждения факта повторной регистрации (в том числе под новым именем),
                    предоставления чужих, недействительных, поддельных документов (в том числе,
                         измененных при помощи различных программ и графических редакторов),
                          администрация оставляет за собой право признать недействительными ставки,
                           сделанные с такого счета, без возврата первоначального депозита. В случае
                            отказа от процедуры верификации администрация имеет право признать ставки
                             недействительными. В вышеперечисленных случаях счет замораживается без
                              возврата средств владельцу до тех пор, пока администрация не получит
                              удовлетворяющие их документы.`
      },
      {
        text: `Выплата выигрышей осуществляется по требованию клиента в течение 3-х
         рабочих дней со дня получения запроса. Минимальная сумма перевода при получении
          выигрыша зависит от способа перевода. Запрос оформляется в разделе "Личный кабинет"
           в подразделе "Вывод". В тексте запроса следует заполнить все необходимые поля.
            В некоторых случаях, на усмотрение администрации, клиентам может быть предложено
             представить определенные документы для подтверждения регистрационных данных.`,
        items: [
          {
            text: `В случае пополнения счета и запроса на получение выигрыша одним способом,
                 вам необходимо проставить сумму внесенных средств. При этом коэффициент выигрыша
                  по каждой ставке должен быть не менее 1.7. Мы не обязываем вас ставить всю сумму
                   одной ставкой. Общая сумма принятых ставок должна быть не меньше вашего депозита.`
          },
          {
            text: `В случае пополнения счета одним способом и запроса на получение выигрыша другим,
             либо в случае пополнения с одного расчетного счета в платежной системе и запроса на
              получение выигрыша на другой расчетный счет в той же платежной системе, Контора имеет
               право попросить Вас дважды проставить сумму внесенных средств. При этом коэффициент
                выигрыша по каждой ставке должен быть не менее 1.7. Мы не обязываем вас ставить всю
                 сумму одной ставкой. Общая сумма принятых ставок должна быть не меньше вашего депозита.`
          },
          {
            text: `Ограничение для систем интернет-платежей и выплат на
            банковские карты. Получение выигрыша может быть задержано на неделю,
            но не в обязательном порядке. Выигрыши выплачиваются на банковские
            карты любых банков-эмитентов, но есть ограничение по MasterCard и Maestro,
            на типы этих карт средства могут поступать до 3-х рабочих дней.`
          },
          {
            text: `Кошельки в разной валюте системы WebMoney
             (WMR WMZ) рассматриваются для целей проверки ограничений как разные платежные системы.`
          },
          {
            text: 'Количество запросов на выигрыш в сутки может быть не более пяти.'
          },
          {
            text: `Администрация не рекомендует пополнять счет с чужих электронных кошельков.
             Администрация имеет право вернуть средства на счета владельцев электронных кошельков
              без предварительного уведомления.`
          },
          {
            text: `Комиссия за обработку платежей, взимаемая платежными системами
            за вывод средств, обычно покрывается букмекерской конторой. В случае
            превышения лимитов на запросы по выплате выигрышей, установленных
            Администрацией, компенсация может быть отменена.`
          }
        ]
      },
      {
        text: `В случаях неправильно рассчитанных ставок (например, из-за ошибочно введенных результатов),
         такие ставки перерасчитываются. При этом ставки, сделанные между ошибочным расчетом
          и перерасчетом, считаются действительными. В случае если после перерасчета баланс
           игрока оказался отрицательным, он не может делать ставки, пока не пополнит свой счет.`
      },
      {
        text: `Администрация не рекомендует двум или нескольким участникам размещать
         ставки с одного IP-адреса (с одного компьютера, или из одной локальной сети)
          во избежание подозрения в сговоре (мошенничестве). В таких случаях необходимо
           согласовать возможность игры с Администрацией, с объяснением причин.
            Администрация вправе заблокировать счет такого игрока и попросить его
             выслать документы, удостоверяющие личность.`
      },
      {
        text: `Ставки не принимаются у лиц, являющихся участниками событий на которые
         делаются ставки (спортсмены, тренеры, судьи и др.), а также действующих по
          поручению участников событий. Ставки не принимаются у лиц, представляющих
           интересы других букмекерских компаний. В случае выявления связи между
            игроком и поставившим ставку, либо при постановке ставки на себя или
            против себя, либо будет установлено участие игрока в сговоре, счёт
            будет заблокирован без возврата первоначального депозита.`
      },
      {
        text: `При размещении ставок запрещается применять технические и программные средства,
         использующие алгоритмы автоматического принятия решений, а также программы и скрипты,
          выполняющие автозаполнение форм и автоматическую отправку запросов, включая т.н. «боты».
           Администрация имеет право блокировать счета, владельцы которых нарушили данное правило,
            аннулировать сделанные с таких счетов ставки, а также блокировать либо конфисковать
             средства, имеющиеся на балансе.`
      }
    ]
  },
  {
    title: 'Основные правила приема ставок',
    items: [
      {
        text: `Ставки принимаются на основе линии - списка предстоящих событий
             с предложенными букмекерской конторой котировками - коэффициентами
              выигрыша. Котировки могут изменяться после любой ставки, но условия
               ранее сделанных ставок остаются прежними. Перед ставкой игроки должны
                выяснить все изменения в линии.`
      },
      {
        text: ` Сделанные ставки, после размещения их на сервере и
        получения игроком сообщения о приеме ставки и номера купона,
        по требованию игрока отмене или исправлениям не подлежат,
        однако, согласно п. 4.5 настоящих Правил, могут быть аннулированы
        администрацией. Обрыв соединения или другие сбои в коммуникациях
        не являются основанием для отмены ставки, если ставка зарегистрирована
        на сервере. Ставки принимаются только в сумме, не превышающей текущий
        остаток на счету клиента. После регистрации ставки, сумма ставки списывается
         со счета. После расчета ставок сумма выигрыша заносится на счет клиента`
      },
      {
        text: `Различаются следующие типы ставок: "одиночная", "экспресс" и "система".
        Одиночная ставка - ставка, в которой требуется предугадать исход только одного
        события. Одиночные ставки принимаются на любое событие. Выигрыш по одиночным
        ставкам равен сумме ставки умноженной на коэффициент выигрыша.
        Экспресс - ставка, в которой требуется предугадать исход более чем одного
        события одновременно. В случае если хотя бы одно событие в экспрессе угадано
        неверно, весь экспресс считается проигранным. В экспресс можно включать любую
        комбинацию событий любых видов спорта. В экспресс нельзя включать зависимые
        события: одно и то же событие не может участвовать в экспрессе более одного
        раза, нельзя связывать в экспрессе результат матча и ставки на команду,
        участвующую в этом матче (например: проход в следующий круг и т.д.). Если
        в экспрессе оказались зависимые события, то для целей расчета оставляется
        только одно из них, имеющее наибольший коэффициент. Коэффициент выигрыша
        экспресса вычисляется путем перемножения коэффициентов выигрыша всех событий,
        входящих в экспресс. Выигрыш по экспрессам равен сумме ставки умноженной на
        коэффициент выигрыша экспресса. Коэффициент выигрыша не ограничен.
        Система - полная комбинация экспрессов заданного размера из заранее
        определенного количества событий (максимальное количество событий в системе - 16).
         Каждая комбинация в системе рассчитывается как отдельный экспресс.
        `
      },
      {
        text: `Минимальная ставка определяется администрацией. Максимальная
        выплата на одну ставку по линии – 3 млн рублей, в лайве - 1 млн рублей.
        Для ставок в другой валюте максимальная выплата определяется пересчетом
        по курсу конторы. Для некоторых категорий игр и ставок могут быть установлены
        особые лимиты на максимальные выигрыши, которые указываются в соответствующих
        разделах настоящих Правил.`
      },
      {
        text: `Администрация имеет право ограничивать максимальную
        ставку на отдельные события, а также ограничивать или повышать
        максимальную ставку конкретному клиенту без уведомления и
        объяснения причин. Администрация имеет право ограничить или
        отказать конкретным клиентам в доступе к предоставляемым
        сервисам или услугам без уведомления и объяснения причин.`
      },
      {
        text: ` Ставки принимаются до начала события. В случае если по каким-либо
        причинам ставка сделана после фактического начала события, такая ставка
        считается недействительной. Исключение составляют ставки на "Live"-события,
        то есть ставки по ходу матча. Такие ставки считаются действительными, если они сделаны до окончания матча`
      },
      {
        text: `Администрация оставляет за собой право аннулировать
        «Live»-ставку, если у Администрации возникают подозрения, что
        клиент на момент ставки обладал информацией об исходе события,
        указанного в ставке. При этом Администрация не обязана доказывать причину возникновения таких подозрений.`
      },
      {
        text: ` Если в процессе оформления "Live"-ставки клиент согласился с
        изменением коэффициентов или параметров ставки (фора, тотал и т.п.),
        то ставка принимается, даже если счет события изменился к моменту регистрации ставки.`
      }
    ]
  },
  {
    title: 'Особенности расчета ставок',
    items: [
      {
        text: `Дата и время начала события, указываемые в линии,
            носят информативный характер. Ошибочно указанная дата, время или
            дополнительная информация (статус или стадия турнира, счет первого матча и т.п.)
             не является основанием для отмены ставки. В случае если событие состоялось раньше
              или позже первоначально объявленной даты, результат считается действительным, а
              ставки считаются действительными, если они сделаны до начала события. В случае
              переноса не начавшегося события на 3-и и более суток ставки возвращаются. В случае
               переноса не начавшегося события менее чем на 3-е суток ставки сохраняют силу,
               но администрация вправе принять решение о возврате ставок, не дожидаясь окончания
                события, о чем игроки информируются в официальных результатах. Если матч НБА,
                НХЛ, МЛБ или НФЛ не состоялся или не доигран в назначенный день, то возврат
                ставок производится сразу же на следующий день, кроме случаев, когда
                дата события была указана ошибочно.`
      },
      {
        text: `В списке событий на первом месте указана команда, на поле которой проводится матч.
         В случае проведения матча на поле команды, указанной на втором месте в списке событий
          (кроме матчей команд из одного города или области), ставка на этот матч возвращается,
           а из экспрессов исключается. Проведение матча на нейтральном поле не является основанием
            для отмены ставок. В случае указания в названии соревнования в линии места проведения
             турнира, допускается запись спортсмена или команды хозяев не первой.
              В этом случае ставки не подлежат возврату.`
      },
      {
        text: 'Прерванные события:',
        items: [
          {
            text: `Событие считается состоявшимся, если оно прервано и в нем сыграно
                не менее определенной части регламентированного правилами полного времени.
                Для ставок на матч в целом это: футбол - 65 мин. (если формат матча не стандартный,
                    то 70 процентов игрового времени); баскетбол - 35 мин. (для НБА и других турниров с
                         48-минутными матчами) и 28 мин. для турниров с 40-минутными матчами; хоккей
                          - 45 мин.; хоккей с мячом - 60 мин.; американский футбол - 50 мин.; бейсбол
                           - 5 иннингов; прочие виды спорта - не менее 70% игрового времени. Исключение
                            составляют случаи равного счета на момент остановки матчей в тех видах,
                             где регламентом не допускается ничьей (баскетбол, бейсбол, американский
                                 футбол и т.д.). В этих случаях событие считается несостоявшимся.
                                  Если событие признано несостоявшимся, то по ставкам на «Проход»
                                  – возврат. Если событие признано состоявшимся, то ставки на «Проход»
                                   рассчитываются по фактическому счету на момент остановки игры, а в
                                   случае равного счета – возврат. На события, проводимые под эгидой
                                   крупнейших международных организаций (например, FIFA и UEFA в футболе),
                                   когда в общем доступе оперативно появляется информация о сроках
                                   проведения доигровки (до 30 часов), решением администрации все ставки
                                   могут быть рассчитаны согласно итоговому результату. Для ставок на
                                   отдельные части матча (четверти в баскетболе, таймы в футболе, периоды
                                    в хоккее и тому подобное), если они оказались прерванными, то
                                    рассчитываются как сыгранные, если в них прошло не менее 70% игрового
                                     времени, регламентированного правилами для данной части матча.
                                      Расчет прошедшего игрового времени происходит с учетом только целых
                                       сыгранных минут и округлением дробей в меньшую сторону. Например,
                                        матч по хоккею был прерван на 14-й минуте какого-либо периода,
                                        при этом было сыграно 13 минут и 20 секунд. В этом случае было
                                         сыграно 13 полных минут, что составляет 65%, то есть меньше,
                                          чем 70%. Поэтому ставки на данный период подлежат отмене.`
          },
          {
            text: ` Прерванное событие, не попадающее под определение состоявшегося события,
             может быть признано несостоявшимся, если оно не доиграно в течение 3-х часов.`
          },
          {
            text: ` Для прерванных событий, признанных несостоявшимися, возврату подлежат
             все ставки, кроме ставок, результат которых к моменту остановки события
              однозначно определен (например, кто забьет 1-й гол, получит ж/карту,
               состоялся тотал «на больше» и т.п.). Для прерванных событий подлежат
                безусловному возврату ставки на сравнительную результативность таймов (периодов и пр.).`
          },
          {
            text: ` Прерванные события, признанные состоявшимися, рассчитываются на общих основаниях.
             Ставки в прерванном матче (игре, состязании и т.д.), отсчет розыгрыша которых не начался
              (например, гол с 76 по 90 мин, если матч прерван на 70-й минуте), подлежат возврату.
               Ставки на начавшиеся, но не завершенные события (например, будет ли гол в интервале
                с 61 по 75 мин, если матч прерван на 70-й минуте) рассчитываются как состоявшиеся.
                4.3.5 По отдельным видам спорта возможны дополнительные особенности расчета ставок,
                 если они указаны в соответствующих пунктах правил.`
          }
        ]
      },
      {
        text: `Если первоначальный результат завершенного события позднее отменен по каким-либо причинам,
         отмена во внимание не принимается, и ставки подлежат оплате в соответствии с первоначальным
          (фактическим) результатом. Фактическим считается результат, объявленный на основании официальных
          протоколов и других официальных источников информации непосредственно после завершения события.
          В случае отсутствия результатов в официальных источниках администрация вправе использовать
          альтернативные источники информации, в том числе информацию собственного представителя на матче.
           Если в линии в названии соревнования явно указан источник (например, телетрансляция), то
           он является приоритетным при определении результата. Если событие прервано и на следующий день
            играется с самого начала, то для целей расчета первый матч считается прерванным событием и
             рассчитывается согласно п 4.3`
      },
      {
        text: 'Администрация имеет право объявить ставку недействительной вслучаях:',
        items: [
          {
            text: ` ошибок персонала или программных сбоев при приеме ставок (очевидные опечатки в коэффициентах,
                 несоответствие коэффициентов в различных позициях и т.п.),`
          },
          {
            text: `при наличии очевидных признаков неспортивной борьбы. В случае возникновения
            подозрений в неспортивном характере матчей у Fonbet либо у международных организации
             по их предупреждению EWS-FIFA, Federbet и Tennis Integrity Unit, администрация
              имеет право заблокировать ставки Клиента, совершившего подозрительную ставку
              до заключения международной организации, и признать ставки недействительными
               в случае установления ей факта неспортивной борьбы. Администрация не обязана
               представлять игрокам доказательства и заключения.`
          },
          {
            text: `выявления мошеннических действий, связанных с приемом ставок или
            операциями, предъявлением поддельных документов;`
          },
          {
            text: `подтверждения факта игры с чужого аккаунта или возникновения подозрений об использовании
            личного кабинета посторонними лицами.`
          },
          {
            text: ` отказа от прохождения процедуры верификации, а также в случае подтверждения
            факта повторной регистрации (в том числе, используя чужие персональные данные, документы,
                в т.ч. недействительные и поддельные).`
          },
          {
            text: 'иных случаях нарушения настоящих правил'
          }
        ]
      },
      {
        text: `В случае, если ставка признана недействительной, происходит возврат этой ставки,
         а если она включена в экспресс или систему, то для целей расчета она учитывается с коэффициентом 1.`
      },
      {
        text: ` Все текущие результаты, время игры, другая дополнительная информация, которые отображаются на сайте,
        служат только в качестве справочной информации. Администрация стремится, чтобы информация была
         точной, однако не несет ответственности за последствия ставок, сделанных на основании этой
         информации. Клиенту рекомендуется при проверке правильности расчета ставок, в том числе
          live-ставок, использовать официальные источники информации
          (официальные сайты организаторов спортивных мероприятий).`
      },
      {
        text: `Одновременные ставки, а также ставки, позволяющие обойти существующие максимальные размеры
         ставки или выплаты, на один и тот же исход от группы лиц (синдиката игроков и т.п.) не допускаются.
          Не разрешается ставить повторные ставки на одну и ту же комбинацию исходов в случае, если суммарная
           потенциальная выплата превышает максимальную. Администрация имеет право объявить
           такие ставки недействительными.`
      },
      {
        text: `Любое изменение счета и других параметров матча, вызванное официальным судейским решением
         (например, отмена гола из-за офсайда, после видео-просмотра, отмена или пересмотр очка в теннисе,
           волейболе и др.) или взаимной договоренностью участников (например, игра в равных составах после
             удаления и др.) не является основанием для отмены ставок`
      },
      {
        text: ' В спорных ситуациях, не имеющих прецедента, окончательное решение принимает букмекерская контора'
      }
    ]
  },
  {
    title: 'Основные виды ставок',
    items: [
      {
        text: `Ставки на фактический исход. Можно сделать ставки на матчи без учета форы, если указаны соответствующие
         коэффициенты (на первой позиции - за выигрыш первой команды ("1"), на второй позиции - коэффициент
          за ничью ("Х"), на третьей позиции - коэффициент за выигрыш второй команды ("2"). В этом случае,
           исходом ставки является фактический результат матча. Также можно сделать ставку на то, что 1-я
            команда не проиграет ("1X"), 2-я команда не проиграет ("X2") или не будет ничьей ("12").`
      },
      {
        text: `Ставки на победу каждой из команд в матче с учетом форы (гандикапа). Фора определяется
         букмекерской конторой и может быть положительной, отрицательной или равной нулю. Для определения
          результата ставки к фактически забитым голам команды, на которую сделана ставка,
           отнимается (если фора отрицательная) или прибавляется (если фора положительная)
            фора на выбранную команду (фора на вторую команду во внимание не принимается).
             Если после этого результат матча получается в пользу выбранной команды, то ставка
              считается выигранной. В случае ничейного результата - ставка возвращается. Например,
               если на первую команду установлена отрицательная фора "-1", и матч закончился:
                а) со счетом 0:0, ставка проиграна; б) со счетом 1:0, ставка возвращается;
                в) со счетом 2:0, ставка выиграна. A если установлена положительная фора "+0.5" и матч закончился
                а) со счетом 0:1, ставка проиграна; б) со счетом 0:0 или 1:0, ставка выиграна.`
      },
      {
        text: ` Азиатский гандикап. Ставка на победу или тотал с учетом форы (гандикапа),
         кратного 0.25 (но не кратного 0.5). Такая ставка рассчитывается, как две ставки
          в размере половины суммы, с одинаковыми коэффициентами, и с ближайшими значениями
           "обычных фор" и «обычных тоталов» (целочисленными, или кратными 0.5). Такие
            события в линии помечаются префиксом «Asian». В случае если ставка с азиатским
             гандикапом включена в экспресс или систему, то при расчете этой ставки используется
              коэффициент выигрыша, который бы получился при ее расчете в одинаре. В случае выигрыша
               обеих половинных ставок при расчетах учитывается коэффициент ставки (К). Если одна
                половинная ставка выиграла, а по другой расход, в расчете принимается коэффициент
                 (К+1)/2. Если одна половинная ставка проиграла, а по другой расход, в экспрессах
                  считается коэффициент 0.5. Если обе половинные ставки проиграны, то вся ставка считается проигранной.`
      },
      {
        text: `Ставки на общее количество голов, очков, угловых, штрафных, карточек и иных событий,
         установленных конторой ("Больше - Меньше"). Количество вышеуказанных событий определяется
          как общее значение, называется «тотал» и определяется конторой. В случае
          попадания в тотал ставка возвращается.`
      },
      {
        text: `Ставки на победу в соревнованиях или выход в следующий круг турниров.
         Если заявленный в соревновании участник по какой-либо причине не смог начать
          соревнование, ставки на этого участника подлежат возврату. В случае если два
           или более участника поделили победу в соревновании, выигрыш на каждого из
            них выплачивается исходя из коэффициента выигрыша, рассчитанного по формуле
             К=(Кисх-1)/N+1, где Кисх-исходный коэффициент на участника, N-количество победителей соревнования.`
      },
      {
        text: `Ставки на конкретный счет матча и тайм-матч. В ставке вида "тайм-матч" необходимо
         угадать одновременно исход первого тайма и всего матча. В линии для обозначения этих
          исходов используются начальные буквы: П - победа, Н - ничья, при этом на 1-м месте
           ставится исход 1-го тайма, а на втором - матча. Исход 2-го тайма для расчета этой
            ставки значения НЕ имеет. Например, если матч закончился со счетом 1:1, а первый
             тайм 1:0, то выигрывает исход П1Н.`
      },
      {
        text: ` Ставки на статистику тура или игрового дня. В случае если в туре признан несостоявшимся
         (по критериям п.4.3) или отменен (по критериям п.4.1) хотя бы один матч, то ставки на туровую
          статистику подлежат возврату, кроме ставок, результат которых однозначно определен (например,
             будет ли ничья 0:0, все команды забьют и т.п.). Букмекерская контора вправе принять решение
              о возврате ставок на статистику тура или игрового дня в случае появления достоверной
               информации об отмене одного из матчей до начала тура, о чем игроки информируются в
                официальных результатах. Для расчета показателей на игровой день теннисных матчей игроку,
                 который не доиграл матч (по любой причине), во всех оставшихся сетах и геймах засчитывается
                  поражение. Хозяевами считаются команды или игроки, указанные в линии на первом месте.`
      },
      {
        text: `Ставки на чет или нечет общего количества голов или очков в матче.
         Коэффициенты указаны в линии. Счет 0:0 – чет.`
      },
      {
        text: `Ставки на индивидуальные показатели или сравнения игроков (участников). Если игрок не
        выходил на поле (не участвовал), то ставки на события с его участием подлежат возврату. Для
         участников, которые не закончили событие, расчет показателей производится по фактическому
          результату на момент снятия (схода), и для целей расчета таким участникам присваивается
           последнее место в итоговом протоколе. Ставки "будет ли хет-трик" считаются выигранными,
            если игрок забил три или более гола. Ставки "будет ли дубль" считаются выигранными,
             если игрок забил два или более гола. В ставках на показатели игроков голы, забитые
              игроком в свои ворота, в его показателях не учитываются. В ставках, относящихся
               к командной результативности, голы, забитые командой в свои ворота, учитываются
                как забитые ее оппонентом. Особенности расчета ставок на показатели игрока в
                 теннисе указаны в п. 10.3. В неигровых видах спорта (лыжи, биатлон, легкая
                   атлетика и т.д.) в сравнениях "Кто выше" если участник (для эстафеты - команда)
                    стартовал, но не финишировал, то ставки на него в сравнении с другим участником
                     проигрывают. Если оба участника сошли с дистанции или один из участников
                      не стартовал - возврат ставок.`
      },
      {
        text: `Ставки на исход таймов, периодов, четвертей и т.п. В случае если событие было
         признано несостоявшимся, но первый тайм (период и т.п.) завершился, то ставки на
          первый тайм рассчитываются в обычном порядке, даже если ставки на событие в целом подлежат возврату.`
      },
      {
        text: `Ставки на время, когда произойдет то или иное событие, например, время 1-го гола,
         время 1-й ж/карты, время лидирования одной из команд и т.д. Предлагается угадать
          порядковую минуту, на которой произойдет событие. Точное время для расчета таких
           ставок определяется без учета секунд. Например, время 1-го гола, если первый
            гол забит когда с начала матча прошло 00мин10сек – «1-я минута»,
            04мин10сек – «5-я минута», 04мин59сек – «5-я минута», 05мин00сек – «6-я мин».`
      },
      {
        text: `Cтавки на показатели, выраженные в процентах (% первой подачи, % владения мячом и т.д.)
         Для расчета берется значение показателя с официального сайта, округленное до целого по правилам арифметики.`
      },
      {
        text: `Ставки на количество одного показателя (п1), которые будут предшествовать наступлению
         другого показателя (п2). Например "количество ударов в створ до первого гола", "количество
          фолов до первой ж/карты" и т.п. Удар в створ, с которого забит гол, или фол, за который
           дали ж/карту, считается. Если голов или ж/карт не было, то результатом является общее
            количество показателей п1 (т.е. общее кол-во ударов в створ или голов).`
      }
    ]
  },
  {
    title: 'Особенности приема и расчета ставок на чемпионатах мира и Европы по футболу и Олимпийских играх.',
    items: [
      {
        text: `Лучший бомбардир. Лучшим бомбардиром считается игрок, который забил больше всех
         голов в основное и дополнительное время. Послематчевые пенальти и автоголы не
          учитываются. Критерии определения лучшего бомбардира турнира или сборной при
           равенстве забитых голов: а) большее количество голов, забитых на более поздней
            стадии, б) большее количество ударов в створ ворот, в) меньшее количество
             проведенных на поле минут. Для игры на сравнения "Кто больше забьет" учитываются
              только голы, забитые в основное и дополнительное время. Равные показатели считаются как ничья.
               `
      },
      {
        text: `Статистические показатели. Все ставки принимаются на основное время, кроме случаев,
         оговоренных отдельно. Статистические показатели по каждому матчу берутся только с
          официального сайта чемпионата (ссылка на формат PDF) в 10:00 утра московского времени
           на следующий день после окончания матча. В случае если основное время на стадии
            плей-офф закончилось вничью, то статистические показатели берутся на основании
             онлайн-трансляции на официальном сайте, непосредственно после окончания
             основного времени. Последующие изменения показателей на сайте во внимание не
              принимаются. Также не принимаются во внимание статистические данные газет,
               других средств массовой информации и данные других букмекерских контор. В
                случае отсутствия какого-либо показателя на официальном сайте, расчет
                 производится по фактическому результату. Общие показатели на весь турнир,
                  групповой этап , конкретную сборную или игрока определяются суммированием
                   протоколов каждого матча, взятых с сайта в 10:00 утра на следующий день
                    после окончания матча. Если один или несколько матчей прерваны и признаны
                     не состоявшимися в соответствии с Правилами п. 4.3, то статистические
                      показатели таких матчей учитываются в суммарных статистических показателях
                       (на весь чемпионат, групповой турнир, по отдельной команде) по результатам на
                        момент остановки игры. В случае дисквалификации (снятия) сборной на групповом
                         этапе для расчета места, очков, голов в группе, ей засчитывается поражение
                          0-3 во всех несыгранных матчах.
        `
      },
      {
        text: ` Компенсированное время. Расчёт компенсированного времени производится по табло,
         показанному судьей по телетрансляции. В случае, если по трансляции судьей не было показано
          табло с компенсированным временем, расчет производится по протоколу, а если в протоколе
           отсутствует информация о компенсированном времени, то в этом случае расчет производится
            по фактически переигранному времени.
        `
      },
      {
        text: `Игра "Первым произойдет" и "Последним произойдет". Для целей расчета данного вида игры
         (а также других аналогичных игр), произошедшими считаются события: угловой : в случае
          фактически выполненного удара с угловой отметки удар от ворот : в случае фактически
           выполненного удара от ворот аут : в случае фактически выполненного вброса аута офсайд
            : в случае фактически выполненного свободного удара после фиксации офсайда фол :
             в случае фактически выполненного штрафного или свободного удара после фиксации нарушения правил
        `
      },
      {
        text: `Игра "Что с игроком произойдет раньше". Предлагается угадать какое именно событие из
         указанного набора произойдет с игроком в течение матча. Если игрок не вышел в стартовом
          составе, то ставки на него возвращаются. Ставка "отыграет весь матч" выигрывает только
           в том случае если игрок отыграл весь матч и с ним не произошло ни одно из других
            указанных событий (не забил гол, не получил ж/карту и т.п.)
        `
      },
      {
        text: `Игра "Кто выше?". Предлагается угадать какая команда окажется выше по итогам части
         чемпионата или всего первенства в сравнении с предложенным соперником. Критерии сравнения,
          по которым будет произведен расчет ставки (написаны по степени значимости):
          1. Стадия чемпионата, на которой выбыла команда; 2. Место в группе;
          3. Все забитые мячи. В случае равенства показателей во всех трех пунктах – расход
        `
      },
      {
        text: ` Олимпийские игры. Окончательный результат по количеству медалей определяется
         на основании данных официального сайта Олимпийских игр на момент начала церемонии
          закрытия. В случае равенства золотых медалей выше считается страна, у которой
           больше серебряных, а при равенстве и серебряных - бронзовых медалей.
        `
      }
    ]
  }
];


const RulesPage = () => {
  const { t } = useTranslation();
  const b = bemCn('rules-page');
  return (
    <div className={b()}>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <div className={b('content')}>
        <h1 className={b('title')}>
          {t('footer.nav.item.Rules', 'Правила')}
        </h1>
        <div className={b('list')}>
          {rulesList.map((item, i) => (
            <RulesItem key={`rules-item-${item.title}`} rule={item} id={i + 1} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RulesPage;
