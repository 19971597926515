import type { AvailableTopUpMethods, AvailableWithdrawalMethods } from 'types/payments-data';

export const getDefaultSystemName = (name: string) =>
  `Основной метод (${name})`;

export const checkUserCanUseMethod = (method: AvailableTopUpMethods, userCurrency: string | null) => {
  const { systems, isActive } = method;
  const isCurrencyValid = Object.values(systems).some((system) => Object.keys(system.limits).some((currency) => currency === userCurrency));
  return isActive && isCurrencyValid;
};

export const checkUserCanUseWithdrawl =
  (method: AvailableWithdrawalMethods, userCurrency: string | null) => {
    const { limits, isActive } = method;
    const isValidCurrency = Object.keys(limits).some((currency) => currency === userCurrency);
    return isActive && isValidCurrency;
  };
