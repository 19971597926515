import { bemCn } from 'utils/bem-cn';

import logoVisa from '../assets/payments/logo-visa-mono.svg';
import logoTether from '../assets/payments/logo-tether-mono.svg';
import logoMastercard from '../assets/payments/logo-mastercard-mono.svg';
import logoGooglePay from '../assets/payments/logo-google-pay-mono.svg';
import logoBitcoin from '../assets/payments/logo-bitcoin-mono.svg';
import logoApplepay from '../assets/payments/logo-apple-pay-mono.svg';

const footerPayments = [
  {
    image: logoVisa,
    name: 'VISA'
  },
  {
    image: logoMastercard,
    name: 'Mastercard'
  },
  {
    image: logoGooglePay,
    name: 'Google Pay'
  },
  {
    image: logoApplepay,
    name: 'Apple Pay'
  },
  {
    image: logoBitcoin,
    name: 'Bitcoin'
  },
  {
    image: logoTether,
    name: 'Tether'
  },
];

const PageFooterPayments = () => {
  const b = bemCn('page-footer');
  return (
    <ul className={b('payments')}>
      {footerPayments.map(({ name, image }) => (
        <li key={`${name}-${image}`} className={b('payments-item')}>
          <img className={b('payments-logo')} src={image} alt={name} />
        </li>
      ))}
    </ul>
  );
};

export default PageFooterPayments;
