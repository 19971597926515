import type {
  AvailableTopUpMethodsObject,
  AvailableWithdrawalMethodsObject,
  Limit,
  LimitTransformed,
  ServerSystems,
  ServerWithdrawalMethod
} from 'types/payments-data';

export const transformLimits = (limits: Limit) => {
  const result: Partial<LimitTransformed> = {};
  Object.entries(limits).forEach(([currency, limitsV]) => {
    result[currency] = {
      min: limitsV[0],
      max: limitsV[1],
    };
  });
  return result as LimitTransformed;
};

export const transformWithdrawalMethods = (methods: ServerWithdrawalMethod) => {
  const result: Partial<AvailableWithdrawalMethodsObject> = {};
  Object.entries(methods).forEach(([key, value]) => {
    const limits: LimitTransformed = transformLimits(value.limits);

    result[key] = {
      title: value.displayed_name,
      limits,
      isActive: value.isActive
    };
  });
  return result as AvailableWithdrawalMethodsObject;
};

export const transformTopupSystems = (systems: ServerSystems) => {
  const result: Partial<AvailableTopUpMethodsObject> = {};

  Object.entries(systems).forEach(([systemId, methods]) => {
    Object.entries(methods).forEach(([methodId, data]) => {
      if (methodId in result) {
        // @ts-ignore
        result[methodId].systems[systemId] = {
          limits: transformLimits(data.limits),
        };
      } else {
        result[methodId] = {
          isActive: data.isActive,
          title: data.displayed_name.replace('UAH', '₴'),
          systems: {
            [systemId]: {
              limits: transformLimits(data.limits),
            }
          }
        };
      }
    });
  });

  return result as AvailableTopUpMethodsObject;
};
