import React from 'react';

import Loader from 'components/shared/loader/Loader';
import { useResponsive } from 'hooks/useResponsive';

import HeaderAuthorized from './HeaderAuthorized/HeaderAuthorized';
import HeaderNoAuthorizedSingle from './HeaderNoAuthorizedSingle/HeaderNoAuthorizedSingle';
import DesktopHeader from './DesktopHeader/DesktopHeader';

import './Header.scss';

type Props = {
  isAuthenticated: boolean;
  isAccountLoading: boolean;
};

const Header = ({ isAuthenticated, isAccountLoading }: Props) => {
  const { Mobile, Desktop } = useResponsive();

  if (isAccountLoading) {
    return <div className="main-header"><Loader /></div>;
  }

  return (
    <>
      <Mobile>
        {isAuthenticated ? <HeaderAuthorized /> : <HeaderNoAuthorizedSingle />}
      </Mobile>
      <Desktop>
        <DesktopHeader isAuthenticated={isAuthenticated} />
      </Desktop>
    </>
  );
};

export default Header;
