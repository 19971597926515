import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useLoginMutation } from 'store/auth/authApi';
import Button from 'components/shared/buttons/Button/Button';
import { changeActiveModal } from 'store/auth/authSlice';
import { selectLoginError } from 'store/auth/selectors';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import CloseIcon from 'components/shared/icons/CloseIcon/CloseIcon';
import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';

import SocialLogin from '../components/SocialLogin/SocialLogin';

import type { Entries } from 'types/common';
import type { LoginErrors } from 'types/auth-data';

import './SignIn.scss';

type FormFields = {
  email: string;
  password: string;
};

type Props = {
  modalIsOpen: boolean;
  closeModal(): void;
  contentClassName?: string;
  overlayClassName?: string;
};

const schema = yup.object({
  password: yup.string()
    .min(3, `${i18n.t('auth.modal.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('auth.modal.errors.empty-pass', 'Введите пароль')}`),
  email: yup.string()
    .required(`${i18n.t('auth.modal.errors.empty-email', 'Введите E-mail или ID')}`),
}).required();

const SignIn = ({ modalIsOpen, closeModal, contentClassName, overlayClassName }: Props) => {
  const { t } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();
  const loginErrors = useAppSelector(selectLoginError);
  const dispatch = useAppDispatch();

  const { handleSubmit, setError, control } = useForm<FormFields>({
    defaultValues: { email: '', password: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fieldsErrors: Partial<LoginErrors> = { ...loginErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<FormFields>;
    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [loginErrors]);

  const onSubmit = async (data: FormFields) => {
    await login(data);
  };

  const handleRegisterClick = () => {
    dispatch(changeActiveModal('sign-up'));
  };

  const b = bemCn('login-modal');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={b(null, contentClassName)}
      overlayClassName={overlayClassName}
    >
      <form className={b('content')} onSubmit={handleSubmit(onSubmit)}>
        <div className={b('close-section')}>
          <CloseIcon closeModal={closeModal} />
        </div>

        <div className={b('header')}>
          <h1 className={b('header-title')}>
            {t('auth.modal.title', 'Вход')}
          </h1>
          <p className={b('header-description')}>
            {t('auth.modal.description', 'Введите логин и пароль, чтобы войти')}
          </p>
        </div>
        {!!loginErrors.common && (
          <ErrorBlock isDisplayed={!!loginErrors.common} message={loginErrors.common} align="center" />
        )}
        <div className={b('fields')}>
          <FormInputWrapper<FormFields>
            showError
            type="text"
            control={control}
            name="email"
            placeholder={`${t('auth.modal.email', 'E-mail или ID')}`}
            autoComplete="email"
            disabled={isLoading}
          />
          <FormInputWrapper<FormFields>
            showError
            type="password"
            control={control}
            name="password"
            placeholder={`${t('auth.modal.password', 'Пароль')}`}
            autoComplete="current-password"
            disabled={isLoading}
          />
        </div>
        <div
          className={b('remember-password')}
          onClick={() => dispatch(changeActiveModal('reset-password'))}
        >
          {t('auth.modal.forget-pass', 'Забыли пароль?')}
        </div>
        <div className={b('shared-group')}>
          <div className={b('buttons-sections')}>
            <div className={b('button-wrapper')}>
              <Button
                isLoading={isLoading}
                type='submit'
              >
                {t('auth.modal.sign-in', 'Войти')}
              </Button>
            </div>
            <div className={b('button-wrapper')}>
              <Button
                variant="outline-primary-inverted"
                onClick={handleRegisterClick}
              >
                {t('auth.modal.registration', 'Зарегистрироваться')}
              </Button>
            </div>
          </div>
        </div>

        <div className={b('social-group')}>
          <p className={b('social-group-title')}>
            {t('auth.modal.social-description', 'Войти с помощью социальных сетей')}
          </p>
          <SocialLogin onClick={() => undefined} />
        </div>
      </form>
    </Modal>
  );
};

export default SignIn;
