export const countDownTimer = (date: string) => {
  const end = new Date(date);

  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;

  const now = new Date();
  const distance = end.getTime() - now.getTime();

  const days = Math.floor(distance / _day);
  const hours = Math.floor((distance % _day) / _hour);
  const minutes = Math.floor((distance % _hour) / _minute);
  const seconds = Math.floor((distance % _minute) / _second);
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const toLocaleDateString = (date: Date, options?: Intl.DateTimeFormatOptions, lang?: string) => (
  date.toLocaleDateString(lang ?? 'ru-RU', { timeZone: 'Europe/Moscow', ...options })
);

export const toLocaleTimeString = (date: Date, options?: Intl.DateTimeFormatOptions, lang?: string) => (
  date.toLocaleTimeString(lang ?? 'ru-RU', { timeZone: 'Europe/Moscow', ...options })
);

export const getDateTimeCommaFormat = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);
  const createdDate = toLocaleDateString(dateTime, { day: 'numeric', month: 'long' });
  const createdTime = toLocaleTimeString(dateTime, { hour: '2-digit', minute: '2-digit' });
  return `${createdDate}, ${createdTime}`;
};

export const getDateTimeSlashFormat = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);
  const createdDate = toLocaleDateString(dateTime);
  const createdTime = toLocaleTimeString(dateTime, { hour: '2-digit', minute: '2-digit' });
  return `${createdDate} / ${createdTime}`;
};

export const monthList = ['Янв', 'Фев', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
