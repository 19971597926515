import { createSelector } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import type { RootState } from 'types/state';

export const selectBasketItems = (state: RootState) => state[NameSpace.Basket].bets;

export const selectTotalCoef = createSelector(
  selectBasketItems,
  (stateBets) => {
    const bets = Object.values(stateBets);
    if (bets.length === 0) {
      return 0;
    }
    return bets.reduce<number>((acc, item) => item.coef * acc, 1);
  }
);

export const selectAmount = (state: RootState) => state[NameSpace.Basket].amount;

export const selectBasketItemsCount = createSelector(
  selectBasketItems,
  (bets) => Object.keys(bets).length
);

export const selectActiveModal = (state: RootState) => state[NameSpace.Basket].activeModal;
