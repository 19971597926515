import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
// import { useTypedSelector } from 'store/store';
// import { selectCoupons, selectCouponsCount, selectCouponsIds } from 'store/user/userSlice';
import { useCouponsQuery, useFullCouponsInfoQuery } from 'store/user/userApi';
import Button from 'components/shared/buttons/Button/Button';
import { BETS_ITEMS_PER_PAGE } from 'utils/constants';
import Pagination from 'components/shared/Pagination/Pagination';
import { scrollToTop } from 'utils/scrollToTop';
import { selectCoupons, selectCouponsTotalCount } from 'store/user/selectors';
import { useAppSelector } from 'hooks';
import NoItems from 'components/profile/components/NoItems/NoItems';
import { bemCn } from 'utils/bem-cn';

import BetHistoryCouponDesktop from './BetHistoryCouponDesktop/BetHIstoryCouponDesktop';

import type { PlaceType } from 'types/user-data';

import './BetHistoryDesktop.scss';

type filterButtonsType = {
  title: string;
  id: number;
  filter?: PlaceType;
};

const filterButtons: filterButtonsType[] = [
  {
    title: i18n.t('bet-history.all-bets', 'Все ставки'),
    id: 0,
  },
  {
    title: i18n.t('bet-history.single', 'Ординар'),
    id: 1,
    filter: 'ordinar'
  },
  {
    title: i18n.t('bet-history.express', 'Экспресс'),
    id: 2,
    filter: 'express'
  }
];

const BetHistoryDesktop = () => {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [filter, setFiler] = useState<PlaceType | undefined>(undefined);
  const [couponsIds, setCouponIds] = useState<string[]>([]);

  const coupons = useAppSelector(selectCoupons);
  const couponsCount = useAppSelector(selectCouponsTotalCount);
  const { refetch: refetchCoupons, isLoading: isCouponstLoading } = useCouponsQuery({
    pageSize: BETS_ITEMS_PER_PAGE,
    pageNumber: selectedPage,
    type: filter
  });
  const { data, isFetching: isBetsLoading } = useFullCouponsInfoQuery(couponsIds, {
    skip: couponsIds.length === 0,
  });

  useEffect(() => {
    refetchCoupons();
  }, [selectedPage, filter]);

  useEffect(() => {
    const couponsIdsCurrent = coupons.map((item) => item.remoteId);
    setCouponIds(couponsIdsCurrent);
    scrollToTop(true);
  }, [coupons]);

  useEffect(() => {
    setSelectedPage(1);
  }, [filter]);

  const countPages = Math.ceil(couponsCount / BETS_ITEMS_PER_PAGE);
  const isEmptyList = !isCouponstLoading && !coupons?.length;

  const renderCoupons = () => {
    if (isCouponstLoading || isEmptyList) {
      return (
        <NoItems
          isLoading={isCouponstLoading}
          isEmpty={isEmptyList}
          text={`${t('bet-history.bets-empty', 'Список ставок пуст')}`}
          buttonText={`${t('bet-history.make-bet', 'Сделать ставку')}`}
          withButton
        />
      );
    }

    return coupons.map((coupon) => (
      <BetHistoryCouponDesktop
        key={coupon.id}
        coupon={coupon}
        bets={data?.[coupon.remoteId]?.bets}
        isBetsLoading={isBetsLoading}
        expectedWinAmount={data?.[coupon.remoteId]?.expectedWinAmount}
      />
    ));
  };

  const b = bemCn('bet-history-desktop');

  return (
    <div className={b()}>
      <Breadcrumbs link="/" title={t('main.nav.Home', 'Главная')} />
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <h2 className={b('top-title')}>
            {t('bet-history.title', 'История ставок')}
          </h2>
          <div className={b('filter')}>
            {filterButtons.map((item) => (
              <Button className={b('filter-btn')}
                key={item.id}
                variant={item.filter !== filter ? 'shaded' : undefined}
                onClick={() => setFiler(item.filter)}
              >
                {item.title}
              </Button>
            ))}
          </div>
        </div>
        <div className={b('list')}>
          {renderCoupons()}
        </div>
        <div>
          <Pagination
            selectPage={selectedPage}
            countPages={countPages}
            setSelectPage={(value) => setSelectedPage(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default BetHistoryDesktop;
