import { AppStatusResponse } from './appApi';
import { AppStatus, Status } from './appSlice';


export const transformAppStatus = (res: AppStatusResponse): AppStatus => ({
  system: res.system ? Status.Active : Status.NotActive,
  line: res.system && res.line ? Status.Active : Status.NotActive,
  betgames: res.system && res.betgames ? Status.Active : Status.NotActive,
  tgg: res.system && res.tgg ? Status.Active : Status.NotActive,
  inbet: res.system && res.inbet ? Status.Active : Status.NotActive,
  withdrawals: res.system && res.withdrawals ? Status.Active : Status.NotActive,
  systemMessage: res.status_message,

  // system: Status.NotActive,
  // line: Status.NotActive,
  // betgames: Status.NotActive,
  // tgg: Status.NotActive,
  // inbet: Status.NotActive,
});
