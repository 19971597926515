import React, { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { changeActiveModal } from 'store/basket/basketSlice';
import { selectBasketItems, selectTotalCoef } from 'store/basket/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import BasketItem from 'components/line/components/BasketItem/BasketItem';
import { selectPrimaryWallet } from 'store/user/selectors';
import { usePlaceBetsMutation } from 'store/basket/basketApi';
import TotalCoef from 'components/line/components/TotalCoef/TotalCoef';
import { routes } from 'utils/routes';
import { seletIsLineActive } from 'store/app/selectores';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import BetTypeButton from '../components/BetTypeButton/BetTypeButton';
import BasketForm from './BasketForm/BasketForm';


import type { LineType } from 'types/line-data';

import './Basket.scss';

const isLiveInBasket = (bets: { lineType: LineType; betId: string }[]) =>
  bets.some((bet) => bet.lineType === 'live');

const liveTimeDelay = 10000;

const Basket = () => {
  const { t } = useTranslation();
  const [isWaiting, setIsWaiting] = useState(false);
  const basketItems = useAppSelector(selectBasketItems);
  const isLineActive = useAppSelector(seletIsLineActive);
  const { currency, amount: balance } = useAppSelector(selectPrimaryWallet);

  const totalCoef = useAppSelector(selectTotalCoef);

  const dispatch = useAppDispatch();

  const [placeBets, { isLoading }] = usePlaceBetsMutation();

  const handlePlaceBetClick = async (amount: number) => {
    const basketTransformed = Object.values(basketItems).map((bItem) => ({
      betId: bItem.betId,
      lineType: bItem.lineType,
    }));

    const placeBet = async () => {
      if (amount) {
        await placeBets({
          bets: basketTransformed,
          amount,
        });
      }
    };

    if (isLiveInBasket(basketTransformed)) {
      setIsWaiting(true);
      setTimeout(async () => {
        await placeBet();
        setIsWaiting(false);
      }, liveTimeDelay);
    } else {
      await placeBet();
    }
  };

  const basketLayout = Object.keys(basketItems).map((eventId) => {
    const betInfo = basketItems[eventId];

    const { team1, team2, coef, groupName, tournament, lineType } = betInfo;
    return (
      <BasketItem
        key={eventId}
        team1={team1}
        team2={team2}
        coef={coef}
        type={groupName}
        tournament={tournament}
        eventId={eventId}
        lineType={lineType}
      />
    );
  });

  const isEmpty = Object.keys(basketItems).length === 0;
  const isOrdinar = Object.keys(basketItems).length === 1;
  const isExpress = Object.keys(basketItems).length > 1;

  const b = bemCn('basket');
  return (
    <div className={b()}>
      {/* <Breadcrumbs link={routes.live.path} title={routes.live.title} /> */}

      <div className={b('content')}>
        <div className={b('header')}>
          <h1>{t('cart.title', 'Корзина')}</h1>
          {currency && (
            <Link
              to="/bet-history"
              onClick={() => dispatch(changeActiveModal(null))}
              className={b('header-history')}
            >
              {t('cart.bet-history', 'История ставок')}
              <SvgIcon className={b('history-icon')} name='time-machine' width={15} height={15} />
            </Link>
          )}
        </div>
        <div className={b('bet-type')}>
          <BetTypeButton isActive={isOrdinar}>
            {t('cart.single', 'Ординар')}
          </BetTypeButton>
          <BetTypeButton isActive={isExpress}>
            {t('cart.express', 'Экспресс')}
          </BetTypeButton>
        </div>
        {isEmpty
          ? <TotalCoef coef={totalCoef} />
          : (
            <div className={b('items-list')}>
              {basketLayout}
              <TotalCoef coef={totalCoef} isActive />
            </div>
          )}
        {currency ? (
          <BasketForm
            totalCoef={totalCoef}
            balance={balance}
            currency={currency}
            onPlaceBetClick={handlePlaceBetClick}
            isLoading={isLoading || isWaiting}
            disabled={isEmpty || !isLineActive}
          />
        ) : (
          <BasketForm
            totalCoef={totalCoef}
            balance={balance}
            currency="RUB"
            onPlaceBetClick={handlePlaceBetClick}
            isLoading={isLoading || isWaiting}
            disabled={isEmpty || !isLineActive}
          />
        )}
      </div>
    </div>
  );
};

export default Basket;
