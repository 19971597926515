import React from 'react';
import cn from 'classnames';

import './SupportIcon.scss';
import { telegramLink } from 'utils/constants';

type Props = {
  className?: string;
};

const SupportIcon = ({ className }: Props) => (
  <a href={telegramLink}>
    <div className={cn('support-icon', className)} />
  </a>
);

export default SupportIcon;
