import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import WithdrawalForm from './WithdrawalForm/WithdrawalForm';
import CloseIcon from '../../shared/icons/CloseIcon/CloseIcon';

import type { Currency } from 'types/wallets-data';
import type { WithdrawalMethod } from 'types/payments-data';

import './WithdrawalModal.scss';

type Props = {
  isOpen: boolean;
  activeMethod: { id: WithdrawalMethod; title: string } | null;
  closeModal: () => void;
  currency: Currency | null;
  balance: number;
};

const WithdrawalModal = (props: Props) => {
  const {
    activeMethod,
    closeModal,
    currency,
    balance,
    isOpen
  } = props;
  const { t } = useTranslation();

  const b = bemCn('withdrawal-modal');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={b()}
      overlayClassName={b('overlay')}
    >
      <div className={b('content')}>
        <div className={b('close-section')}>
          <CloseIcon closeModal={closeModal} />
        </div>
        <div className={b('header')}>
          <h1 className={b('header-title')}>
            {t('withdrawals.modal.title', 'Вывод')}
          </h1>
          <p className={b('header-description')}>
            {activeMethod?.title}
          </p>
        </div>
        {activeMethod && (
          <WithdrawalForm
            activeMethod={activeMethod.id}
            currency={currency}
            balance={balance}
          />
        )}
      </div>
    </Modal>
  );
};

export default WithdrawalModal;
