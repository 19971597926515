import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SupportIcon from 'components/shared/SupportIcon/SupportIcon';
import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import { selectActiveModal } from 'store/auth/selectors';
import { selectUserGeo } from 'store/user/selectors';
import { logoText } from 'utils/constants';
import Button from 'components/shared/buttons/Button/Button';
import AuthModalsGroup from 'components/auth/AuthModalsGroup/AuthModalsGroup';
import Flag from 'components/shared/Flag/Flag';
import { bemCn } from 'utils/bem-cn';
import './HeaderNoAuthorizedSingle.scss';

const HeaderNoAuthorizedSingle = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(selectActiveModal);
  const userGeo = useAppSelector(selectUserGeo);

  const handleLoginClick = () => dispatch(changeActiveModal('sign-in'));
  const handleRegClick = () => dispatch(changeActiveModal('sign-up'));

  const b = bemCn('main-header-no-authorized');

  return (
    <header className={b()}>
      <div className={b('top')}>
        <Link to="/home">
          <h1 className={b('logo')}>
            {logoText}
            {userGeo.country && <Flag countryCode={userGeo.country} />}
          </h1>
        </Link>
        <div className="main-header-authorized__buttons-group">
          <div className={b('btn-wrapper', b('login-btn-wrapper'))}>
            <Button
              onClick={handleLoginClick}
              variant="login"
            >
              {t('user-nav.login', 'Вход')}
            </Button>
          </div>
          <div className={b('btn-wrapper', b('reg-btn-wrapper'))}>
            <Button onClick={handleRegClick} animated>
              {t('user-nav.registration', 'Регистрация')}
            </Button>
          </div>
          <SupportIcon />
        </div>
      </div>
      <AuthModalsGroup
        activeModal={activeModal}
        closeModal={() => dispatch(changeActiveModal(null))}
      />
    </header>
  );
};

export default HeaderNoAuthorizedSingle;
