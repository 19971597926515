import React, { useMemo, useState } from 'react';
import { useToggle } from 'usehooks-ts';

import { selectBasketItems } from 'store/basket/selectors';
import { useGetEventDetailsQuery } from 'store/line/lineApi';
import { FullEventItem } from 'types/line-data';
import { useAppSelector } from 'hooks';
import Loader from 'components/shared/loader/Loader';
import EvetnCardTabs from 'components/line/EventCard/components/EvetnCardTabs/EvetnCardTabs';

import GroupItem from '../GroupItem/GroupItem';

import type { LineType } from 'types/line-data';


import './FullEventBets.scss';

type Props = {
  event: FullEventItem | undefined;
  lineType: LineType;
};

const POLLING_INTERVAL = 1000;

const FullEventBets = ({ event, lineType }: Props) => {
  const [isBetsClosed, toggleBetsClosed] = useToggle(true);

  const [activeTab, setTab] = useState<number>(0);
  const basketItems = useAppSelector(selectBasketItems);

  const { data: subGamesEvent, isLoading: isLoadingSubGames } = useGetEventDetailsQuery(
    { eventId: String(activeTab), type: lineType },
    {
      pollingInterval: lineType === 'live' ? POLLING_INTERVAL : 0, skip: activeTab === 0
    },
  );

  const groups = event?.groups ?? [];

  const currentCoeffs = !activeTab ? groups : subGamesEvent?.groups;

  const layout = useMemo(() => (
    event && currentCoeffs && (
      <div>
        {currentCoeffs.map((group) => {
          const id = activeTab || event.id;
          return (
            <GroupItem
              key={group.name}
              group={group}
              eventId={`${id}`}
              tournament={event.tournament}
              team1={event.team1}
              team2={event.team2}
              basketItem={id in basketItems ? basketItems[id].betId : null}
              lineType={lineType}
              tab={activeTab}
              groupName={group.name}
              isCollapsedAll={isBetsClosed}
            />
          );
        })}
      </div>
    )
  ), [event, currentCoeffs, basketItems, activeTab, isBetsClosed]);

  return (
    <div>
      <EvetnCardTabs
        className='full-event-bets__tabs'
        tabs={event?.subGames || []}
        activeTab={activeTab}
        onToggleClose={toggleBetsClosed}
        onTabChange={(tabId) => setTab(tabId)}
      />
      {!isLoadingSubGames ? layout : <Loader />}
    </div>
  );
};

export default FullEventBets;
