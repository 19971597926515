import React from 'react';

import { availableSocial, SignUpMethod } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import './SocialLogin.scss';

type Props = {
  onClick: (id: SignUpMethod) => void;
  className?: string;
};

const b = bemCn('social-login');

const SocialLogin = ({ onClick, className }: Props) => (
  <div className={b(null, className)}>
    {availableSocial.map((socialId) => (
      <button
        className={b('button', { [`${socialId}`]: true })}
        type="button"
        aria-label={socialId}
        key={socialId}
        onClick={() => onClick(socialId)}
      />
    ))}
  </div>
);

export default SocialLogin;
