import { createSelector} from '@reduxjs/toolkit';

import { selectPrimaryWalletCurrency } from 'store/user/selectors';
import { NameSpace } from 'utils/constants';

import { checkUserCanUseMethod, checkUserCanUseWithdrawl } from './utils';

import type {TopUpMethod, WithdrawalMethod} from 'types/payments-data';
import type { RootState } from 'types/state';

export const selectAvailableTopUpMethods = (state: RootState) => state[NameSpace.Payments].availableTopUpMethods;

export const selectTopupOverviewList = createSelector(
  selectAvailableTopUpMethods,
  selectPrimaryWalletCurrency,
  (methods, userCurrency) => Object.entries(methods)
    .filter(([key, val]) => checkUserCanUseMethod(val, userCurrency))
    .map(([key, value]) => ({
      id: key,
      title: value.title,
      isActive: value.isActive
    }))
);

export const selectAvailableWithdrawalMethods = (state: RootState) => state[NameSpace.Payments].availableWithdrawalMethods;

export const selectWithdrawalOverviewList = createSelector(
  selectAvailableWithdrawalMethods,
  selectPrimaryWalletCurrency,
  (methods, userCurrency) => Object.entries(methods)
    .filter(([key, method]) => checkUserCanUseWithdrawl(method, userCurrency))
    .map(([key, value]) => ({
      id: key,
      title: value.title,
    }))
);

export const selectTopupMethodDetails = createSelector(
  [
    selectAvailableTopUpMethods,
    (_, category: TopUpMethod): TopUpMethod => category,
  ],
  (items, category: TopUpMethod) => items[category]
);

export const selectWithdrawalMethodDetails = createSelector(
  [
    selectAvailableWithdrawalMethods,
    (_, category: WithdrawalMethod): WithdrawalMethod => category,
  ],
  (items, category: WithdrawalMethod) => items[category]
);

export const selectPaymentUrl = (state: RootState) => state[NameSpace.Payments].paymentUrl;

export const selectInitError = (state: RootState) => state[NameSpace.Payments].initError;

export const selectActiveModal = (state: RootState) => state[NameSpace.Payments].activeModal;

export const selectActiveTopUpMethod = (state: RootState) => state[NameSpace.Payments].activeTopUpMethod;
