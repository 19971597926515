import React from 'react';

import { useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';

import './ModalsHead.scss';

type Props = {
  title: string;
  descr?: string;
};

const ModalsHead = ({ title, descr }: Props) => {
  const accountData = useAppSelector(selectAccountData);
  const { id: userId } = accountData;

  return (
    <div className="modals-head__top">
      <div className="modals-head__title">{title}</div>
      <div className="modals-head__id">ID: {userId}</div>
      {descr && <div className="modals-head__descr">{descr}</div>}
    </div>
  );
};

export default ModalsHead;
