import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
// import Basket from 'components/basket/Basket/Basket';
import { useWalletsQuery } from 'store/user/userApi';
// import CasinoWidget from 'components/casino/CasinoWidget/CasinoWidget';
import { setCountry, setSport, setTournament } from 'store/line/lineSlice';
import { useAppDispatch } from 'hooks';
import Basket from 'components/basket/Basket/Basket';

import SideBarFilterDesktop from '../SideBarFilterDesktop/SideBarFilterDesktop';
import FullEventDesktop from '../FullEventDesktop/FullEventDesktop';
import DesktopLineList from './components/DesktopLineList/DesktopLineList';
// import CasinoWidget from 'components/casino/CasinoWidget/CasinoWidget';

import type { LineType } from 'types/line-data';

import './DesktopLine.scss';

export type DesktopFilterType = {
  activeCountry: string;
  activeSport: string;
  activeTournament: string;
};

type Props = {
  type?: LineType;
  previewItem: boolean;
};

const DesktopLine = ({ type, previewItem }: Props) => {
  const dispatch = useAppDispatch();
  useWalletsQuery();
  const { t } = useTranslation();

  const { lineType: typeUrl, eventId } = useParams();

  const lineType: LineType = type ?? typeUrl as LineType ?? 'line';

  useEffect(() => {
    dispatch(setSport('0'));
    dispatch(setCountry('0'));
    dispatch(setTournament('0'));
  }, [type]);

  return (
    <>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <div className="desktop-live">
        <SideBarFilterDesktop lineType={lineType} />
        <div className="desktop-live__content">
          {!previewItem
            ? <DesktopLineList lineType={type as LineType} />
            : (
              <FullEventDesktop
                eventId={eventId ?? ''}
                lineType={typeUrl as LineType}
              />
            )}
        </div>
        <div className="desktop-live__right">
          <div className="desktop-live__basket">
            <Basket />
          </div>
          {/* <div className="desktop-live__casino">
            <CasinoWidget />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DesktopLine;
