import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

interface TransferField {
  amount: number | '';
  send_to: string;
}

export const transferApi = api.injectEndpoints({
  endpoints: (builder) => ({
    transfer: builder.mutation<TransferField, TransferField>({
      query: (data:TransferField) => ({
        url: APIRoute.TransferToUser,
        method: 'POST',
        body: data
      })
    })
  })
});

export const { useTransferMutation } = transferApi;
