export const routes = {
  live: {
    title: 'Live',
    path: '/live',
  },
  line: {
    title: 'Линия',
    path: '/line',
  },
  slots: {
    title: 'Слоты',
    path: '/slots',
  },
  fullevent: {
    title: 'Фулевент',
    path: '/fullevent',
  },
  betHistory: {
    title: 'История ставок',
    path: '/profile/bet-history'
  }
};

export enum AppRoute {
  Main = '/',
  Home = '/home',
  Basket = '/basket',
  LivePage = '/live',
  LinePage = '/line/*',
  SlotsPage = '/slots/*',
  BonusPage = '/bonus/*',
  RulesPage = '/rules',
  ProfilePage = '/profile/*',
  BetHistory = '/bet-history/*',
  Referal = '/ref_code=:refCode',
  VerifyEmail = '/email/verify/:id',
  ResetPassword = '/password/reset/:id',
  FulleventPage = '/fullevent/:eventId/:lineType',
  NotFound = '*',
}

export enum ProfileRoute {
  Settings = '/settings',
  Topups = '/topups',
  Withdrawals = '/withdrawals',
  BetHistory = '/bet-history',
  BetHistoryDetails = '/bet-history/:id',
  WalletManage = '/wallet-manage',
  TransactionsPage = '/transactions/*',
}

export enum TransactionsRoute {
  Main = '/',
  Slots = '/slots',
  Transfer = '/transfer',
  Bets = '/bets',
  Widthrawal = '/widthrawal',
  Deposits = '/deposits'
}

export enum APIRoute {
  IpAPI = 'https://ipapi.co/json/',

  Login = 'v1/auth/login',
  Register = 'v1/auth/register/email',
  VerifyEmail = 'v1/auth/login/email/verify',
  ResetPass = 'v1/auth/login/password/reset',
  ReferCode = 'v1/partner/ref_code',
  PlaceBet = 'v1/line/bet/place',
  PaymentSystems = 'v1/payment_systems',
  Payment = 'v1/payment',
  WithdrawalMethods = 'v1/withdrawal_methods',
  WithdrawalInit = 'v1/user/withdrawals/init',
  TransferToUser = 'v1/user/transfer/to_user',

  UserMe = 'v1/user/me',
  Transactions = 'v1/user/transactions',
  Deposits = 'v1/user/transactions/deposits',
  Bets = 'v1/user/transactions/bets',
  Transfers = 'v1/user/transactions/transfers',
  Withdrawals = 'v1/user/withdrawals',
  Coupons = 'v1/user/coupons',
  CancelWithdrawal = 'v1/user/withdrawals/cancel',
  CouponsInfo = 'bet/info',
  Wallets = 'v1/user/accounts',

  Currencies = 'v1/currencies',

  LineStatus = '/status',
  LineSports = '/sports',
  LineCountries = '/countries',
  LineTournaments = '/tournaments',
  LineEvents = '/events_list',
  LineEvent = '/event',

  SlotsGames = 'v1/tgg',
  SlotSessions = 'v1/sessions',
  SlotsCreateSession = 'v1/tgg/request'
}
