import React from 'react';
import { Link } from 'react-router-dom';

import { monthList, toLocaleDateString, toLocaleTimeString } from 'utils/time';
import { getCoefTitle } from 'store/line/utils';
import { addBet } from 'store/basket/basketSlice';
import Coefficient from 'components/line/components/Coefficient/Coefficient';
import { useAppDispatch } from 'hooks';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import type { Bet, EventItem, LineType } from 'types/line-data';

import './EventCardDesktop.scss';

const EmptyCoeffs = () => (
  <>
    <Coefficient title="-" onClick={() => undefined} />
    <Coefficient title="-" onClick={() => undefined} />
    <Coefficient title="-" onClick={() => undefined} />
  </>
);

type Props = {
  event: EventItem;
  bets: Bet[];
  basketItem?: string | null;
  groupName: string;
  lineType: LineType;
  className?: string;
};

const EventCardDesktop = (props: Props) => {
  const {
    event,
    bets,
    basketItem,
    groupName,
    lineType,
    className
  } = props;

  const dispatch = useAppDispatch();
  const date = toLocaleDateString(new Date(event.eventDate));
  const time = toLocaleTimeString(new Date(event.eventDate));
  const dateFormatText = `${date.split('.')[0]} ${monthList[Number(date.split('.')[1]) - 1]}`;
  const timeWithoutSeconds = `${time.split(':')[0]}:${time.split(':')[1]}`;
  const scorePeriodFormatSeparator = event.scorePeriod.split(';').join(' | ');
  const isLive = lineType === 'live';
  const isBetsStoped = groupName === 'stopped';

  const handleAddBetToBasket = (bet: Bet) => {
    const newBet = {
      eventId: event.id,
      betId: bet.betId,
      coef: bet.rate,
      team1: event.team1,
      team2: event.team2,
      groupName: `${groupName} : ${bet.caption}`,
      tournament: event.tournament,
      lineType,
    };

    dispatch(addBet(newBet));
  };

  const b = bemCn('event-card-desktop');

  return (
    <div className={b(null, className)}>
      <Link to={`/fullevent/${event.id}/${lineType}`} className={b('link')}>
        <p className={b('date')}>
          <span>{dateFormatText}</span>
          <span className={b('date-time')}>{timeWithoutSeconds}</span>
        </p>
        <div className={b('info', { center: lineType === 'line' })}>
          <div className={b('teams')}>
            <h2 className={b('team')}>{event.team1}</h2>
            <h2 className={b('team')}>{event.team2}</h2>
          </div>
          {isLive && (
            <div className={b('stats')}>
              <p className={b('score')}>
                <span className={b('score-total')}>{event.scoreFull}</span>
                <span className={b('score-periods')}>{scorePeriodFormatSeparator}</span>
              </p>
              <p className={b('timer')}>
                <SvgIcon name='time-machine' width={11} height={11} />
                <span>{Math.floor(event.timer / 60)}′</span>
              </p>
            </div>
          )}
        </div>
      </Link>
      <div className={b('bets')}>
        {isBetsStoped
          ? <EmptyCoeffs />
          : bets.slice(0, 3).map((bet) => (
            <Coefficient
              className={b('coeff')}
              key={bet.betId}
              title={getCoefTitle(bet.caption, event.team1, event.team2)}
              value={bet.rate}
              isActive={basketItem === bet.betId}
              onClick={() => handleAddBetToBasket(bet)}
            />
          ))}
      </div>
    </div>
  );
};

export default EventCardDesktop;
