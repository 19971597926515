import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import NavMenu, { MenuItem } from 'components/shared/NavMenu/NavMenu';
import Pagination from 'components/shared/Pagination/Pagination';
import { useCouponsQuery, useFullCouponsInfoQuery } from 'store/user/userApi';
import { useAppSelector } from 'hooks';
import { selectCoupons, selectCouponsIds, selectCouponsTotalCount } from 'store/user/selectors';
import { scrollToTop } from 'utils/scrollToTop';
import { COUPON_ITEMS_PER_PAGE } from 'utils/constants';
import { PlaceType } from 'types/user-data';
import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';

import HistoryItem from './HistoryItem/HistoryItem';
import NoItems from '../../components/NoItems/NoItems';

import './BetHistoryMobile.scss';

// const menuItems: MenuItem[] = [
//   {
//     link: '',
//     title: i18n.t('bet-history.all-bets', 'Все ставки'),
//     id: 0,
//   },
//   {
//     link: '',
//     title: i18n.t('bet-history.single', 'Ординар'),
//     id: 1,
//   },
//   {
//     link: '',
//     title: i18n.t('bet-history.express', 'Экспресс'),
//     id: 2,
//   },
// ];

const getMenuItems = (): MenuItem[] => [
  {
    title: i18n.t('bet-history.all-bets', 'Все ставки'),
    id: 0,
  },
  {
    title: i18n.t('bet-history.single', 'Ординар'),
    id: 1,
  },
  {
    title: i18n.t('bet-history.express', 'Экспресс'),
    id: 2,
  },
];

const getCouponType = (typeId: number): PlaceType | undefined => {
  switch (typeId) {
    case 1:
      return 'ordinar';
    case 2:
      return 'express';
    default:
      return undefined;
  }
};

const b = bemCn('bet-history');

const BetHistoryMobile = () => {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [filter, setFiler] = useState<number>(0);

  const couponsIds = useAppSelector(selectCouponsIds);
  const coupons = useAppSelector(selectCoupons);
  const couponsCount = useAppSelector(selectCouponsTotalCount);
  const { refetch: refetchCoupons, isLoading: isCouponstLoading } = useCouponsQuery({
    pageSize: COUPON_ITEMS_PER_PAGE,
    pageNumber: selectedPage,
    type: getCouponType(filter),
  });

  useEffect(() => {
    refetchCoupons();
    scrollToTop(true);
  }, [selectedPage, filter]);

  const {
    data,
    isFetching: isBetsLoading
  } = useFullCouponsInfoQuery(couponsIds, {
    skip: !couponsIds.length
  });

  const countPages = Math.ceil(couponsCount / COUPON_ITEMS_PER_PAGE);
  const isEmptyList = !isCouponstLoading && !coupons?.length;

  const handleNavClick = (id: number) => {
    setSelectedPage(1);
    setFiler(id);
  };

  const renderCoupons = () => {
    if (isCouponstLoading || isEmptyList) {
      return (
        <NoItems
          isLoading={isCouponstLoading}
          isEmpty={isEmptyList}
          text={`${t('bet-history.bets-empty', 'Список ставок пуст')}`}
          buttonText={`${t('bet-history.make-bet', 'Сделать ставку')}`}
          withButton
        />
      );
    }

    return coupons.map((coupon) => (
      <HistoryItem
        key={coupon.id}
        coupon={coupon}
        bets={data?.[coupon.remoteId]?.bets}
        isBetsLoading={isBetsLoading}
        expectedWinAmount={data?.[coupon.remoteId]?.expectedWinAmount}
      />
    ));
  };

  return (
    <div className={b()}>
      <Breadcrumbs link="/profile" title={t('Profile', 'Личный кабинет')} />
      <h3 className={b('header')}>
        {t('bet-history.title', 'История ставок')}
      </h3>
      <div className={b('nav')}>
        <NavMenu
          menuItems={getMenuItems()}
          activeItemId={filter}
          onClick={handleNavClick}
        />
      </div>
      <div className={b('content')}>
        {renderCoupons()}
        <Pagination
          selectPage={selectedPage}
          countPages={countPages}
          setSelectPage={(value) => setSelectedPage(value)}
        />
      </div>
    </div>
  );
};

export default BetHistoryMobile;
