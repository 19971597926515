import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { lineApi } from './lineApi';

import type {
  TournamentCollection,
  FullEventItem,
  CountryItem,
  EventItem,
  SportItem,
} from 'types/line-data';
import type { LineState } from 'types/state';

const initialState: LineState = {
  sportsList: {
    live: [],
    line: [],
  },
  countries: [],
  selectedSport: null,
  selectedCountry: null,
  selectedTournament: null,
  tournaments: {},
  events: [],
  fullEvent: null,
  lineLoadingCounter: 0,
};

export const lineData = createSlice({
  name: NameSpace.Line,
  initialState,
  reducers: {
    setCountry: (state, action: PayloadAction<string | null>) => {
      if (state.selectedCountry !== action.payload) {
        state.selectedCountry = action.payload;
      }
    },
    setTournament: (state, action: PayloadAction<string | null>) => {
      if (state.selectedTournament !== action.payload) {
        state.selectedTournament = action.payload;
      }
    },
    setSport: (state, action: PayloadAction<string | null>) => {
      if (state.selectedSport !== action.payload) {
        state.selectedSport = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        lineApi.endpoints.getLiveSports.matchFulfilled,
        (state, action: PayloadAction<SportItem[]>) => {
          state.sportsList.live = action.payload;
        },
      )
      .addMatcher(
        lineApi.endpoints.getLineSports.matchFulfilled,
        (state, action: PayloadAction<SportItem[]>) => {
          state.sportsList.line = action.payload;
        },
      )
      .addMatcher(
        lineApi.endpoints.getCountries.matchFulfilled,
        (state, action: PayloadAction<CountryItem[]>) => {
          state.countries = action.payload;
        },
      )
      .addMatcher(
        lineApi.endpoints.getTournaments.matchFulfilled,
        (state, action: PayloadAction<TournamentCollection>) => {
          state.tournaments = action.payload;
        },
      )
      .addMatcher(
        lineApi.endpoints.getEventsList.matchFulfilled,
        (state, action: PayloadAction<EventItem[]>) => {
          state.events = action.payload;
          state.lineLoadingCounter -= 1;
        },
      )
      .addMatcher(
        lineApi.endpoints.getEventsList.matchPending,
        (state) => {
          state.lineLoadingCounter += 1;
        }
      )
      .addMatcher(
        lineApi.endpoints.getEventsList.matchRejected,
        (state) => {
          state.lineLoadingCounter -= 1;
        }
      )
      .addMatcher(
        lineApi.endpoints.getEventDetails.matchFulfilled,
        (state, action: PayloadAction<FullEventItem>) => {
          state.fullEvent = action.payload;
        },
      );
  },
});

export const {
  setCountry,
  setTournament,
  setSport,
} = lineData.actions;
