import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/shared/buttons/Button/Button';
import Coefficient from 'components/line/components/Coefficient/Coefficient';
import { monthList, toLocaleDateString, toLocaleTimeString } from 'utils/time';
import { useAppDispatch } from 'hooks';
import { addBet } from 'store/basket/basketSlice';
import { getCoefTitle } from 'store/line/utils';
import { bemCn } from 'utils/bem-cn';

import timer from './assets/timer.svg';

import type { Bet, EventItem, LineType } from 'types/line-data';
import type { BasketItem } from 'types/basket-data';

import './DesktopWidgetItem.scss';

const EmptyCoeffs = () => (
  <>
    <Coefficient title="-" value="-" onClick={() => undefined} />
    <Coefficient title="-" value="-" onClick={() => undefined} />
    <Coefficient title="-"
      value="-" onClick={() => undefined}
    />
  </>
);

type Props = {
  event: EventItem;
  lineType: LineType;
  basketItems: Record<string, BasketItem>;
  className?: string;
};

const DesktopWidgetItem = ({ event, lineType, basketItems, className }: Props) => {
  const dispatch = useAppDispatch();
  const date = toLocaleDateString(new Date(event.eventDate));
  const time = toLocaleTimeString(new Date(event.eventDate));

  const basketItemsEvent = event.id in basketItems
    ? basketItems[event.id].betId
    : null;

  const dateFormatText = `${date.split('.')[0]} ${monthList[Number(date.split('.')[1]) - 1]}`;
  const timeWithoutSeconds = `${time.split(':')[0]}:${time.split(':')[1]}`;
  const scorePeriodFormatSeparator = event.scorePeriod.split(';').join('-');

  const handleAddBetToBasket = (bet: Bet) => {
    const newBet = {
      eventId: event.id,
      betId: bet.betId,
      coef: bet.rate,
      team1: event.team1,
      team2: event.team2,
      groupName: `${event.group.name} : ${bet.caption}`,
      tournament: event.tournament,
      lineType,
    };

    dispatch(addBet(newBet));
  };

  const buttonWithCoef = event.group.name === '1x2'
    ? event.group.bets.map((item) => (
      <Coefficient
        key={item.betId}
        title={getCoefTitle(item.caption, event.team1, event.team2)}
        value={item.rate}
        onClick={() => handleAddBetToBasket(item)}
        isActive={item.betId === basketItemsEvent}
      />
    ))
    : <EmptyCoeffs />;

  const allBetsCount = event.allGroups.reduce((summ, item) => summ + item.bets.length, 0);

  const b = bemCn('desktop-widget-item');

  return (
    <div className={b(null, className)}>
      <Link to={`/fullevent/${event.id}/${lineType}`} className={b('link')}>
        <div className={b('time')}>
          <p>{dateFormatText}</p>
          <h2>{timeWithoutSeconds}</h2>
        </div>
        <div className={b('info')}>
          <div className={b('teams')}>
            <h2>{event.team1}</h2>
            <h2>{event.team2}</h2>
          </div>
          <div className={b('stats')}>
            <div className={b('score')}>
              <h2>{event.scoreFull}</h2>
              <p>{scorePeriodFormatSeparator}</p>
            </div>
            <div className={b('timer')}>
              <img src={timer} alt="" />
              <p>{Math.floor(event.timer / 60)}′</p>
            </div>
          </div>
        </div>
      </Link>
      <div className={b('bets')}>
        <Link to={`/fullevent/${event.id}/${lineType}`}>
          <Button variant="secondary">
            {allBetsCount} +
          </Button>
        </Link>
        {buttonWithCoef}
      </div>
    </div>
  );
};

export default DesktopWidgetItem;
