import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

import Button from 'components/shared/buttons/Button/Button';
import { bemCn } from 'utils/bem-cn';

import banner1 from './assets/banner.png';

import './DesktopLiveBanner.scss';

const DesktopLiveBanners = () => {
  const b = bemCn('desktop-line-banner');
  return (
    <div className={b()}>
      <Swiper
        pagination
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={1500}
        slidesPerView={1}
        spaceBetween={30}
        modules={[Pagination, Autoplay]}
        className={b('swiper', 'mySwiper')}
      >
        <SwiperSlide className={b('item')}>
          <Link to="#" className={b('banner')}>
            <img src={banner1} alt="" className={b('bg')} />
            <div className={b('info')}>
              <h2>Фрибет до 10 000 руб.</h2>
              <p>Пополни счёт на любую сумму. Напиши &quot;Хочу фрибет&quot; и получи фрибет 50% от суммы пополнения</p>
              <Button>
                Получить бонус
              </Button>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={b('item')}>
          <Link to="#" className={b('banner')}>
            <img src={banner1} alt="" className={b('bg')} />
            <div className={b('info')}>
              <h2>Фрибет до 10 000 руб.</h2>
              <p>Пополни счёт на любую сумму. Напиши &quot;Хочу фрибет&quot; и получи фрибет 50% от суммы пополнения</p>
              <Button>
                Получить бонус
              </Button>
            </div>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default DesktopLiveBanners;
