import { createSelector } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import type { RootState } from 'types/state';

export const selectAccountData = (state: RootState) => state[NameSpace.User].account;

export const selectPrimaryWallet = (state: RootState) => state[NameSpace.User].primaryWallet;

export const selectAdditionalWallets = (state: RootState) => state[NameSpace.User].additionalWallets;

export const selectPrimaryWalletCurrency = createSelector(
  selectPrimaryWallet,
  (primaryWallet) => primaryWallet.currency
);

export const selectCoupons = (state: RootState) => state[NameSpace.User].coupons;

export const selectCouponById = (state: RootState, id: string | undefined) => state[NameSpace.User].coupons.find((c) => c.remoteId === id);

export const selectCouponsIds = (state: RootState) => state[NameSpace.User].coupons.map((item) => item.remoteId);

export const selectCouponsTotalCount = (state: RootState) => state.user.totalCouponsCount;

export const selectTransactions = (state: RootState) => state[NameSpace.User].transactions;

export const selectShowBalanceHeader = (state: RootState) => state[NameSpace.User].showBalanceHeader;

export const selectUserGeo = (state: RootState) => state[NameSpace.User].geo;
export const selectAccountLoading = (state: RootState) => state[NameSpace.User].isAccaountLoading;
