import { useState } from 'react';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import './EventCardTeam.scss';

type Props = {
  name: string;
  logo: string;
  className?: string;
}

const EventCardTeam = (props: Props) => {
  const [isError, setError] = useState(false);

  const b = bemCn('event-card-team');

  return (
    <div className={b(null, props.className)}>
      <div className={b('logo')}>
        <div className={b('logo-wrapper')}>
          {isError
            ? <SvgIcon className={b('logo-img')} name='cup' />
            : (
              <img className={b('logo-img')}
                onError={() => setError(true)}
                src={props.logo}
                alt={props.name}
              />
            )}
        </div>
      </div>
      <p className={b('name')}>{props.name}</p>
    </div>
  );
};
export default EventCardTeam;
