import { Route, Routes } from 'react-router-dom';

import BetgamesCasino from 'pages/BetgamesCasino/BetgamesCasino';
import BetgamesTwainSport from 'pages/BetgamesTwainSport/BetgamesTwainSport';
import { useAppSelector } from 'hooks';
import { useAccountQuery } from 'store/user/userApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import NotFounded from 'pages/NotFound/NotFound';
import ProtectedRoute from 'components/shared/ProtectedRoute/ProtectedRoute';
import { selectIsInbetActive, seletIsBetgameActive } from 'store/app/selectores';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Maintenance } from 'utils/constants/maintenanceMessage';

import GamesList from './GamesList/GamesList';
import InbetGames from './InbetGames/InbetGames';

const GamesPage = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isBetgameActive = useAppSelector(seletIsBetgameActive);
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const { isLoading: isAccountLoading } = useAccountQuery();

  return (
    <main>
      <Routes>
        <Route index element={<GamesList />} />
        <Route path="/betgames" element={
          <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
            {isBetgameActive
              ? <BetgamesCasino />
              : <SiteMaintenance message={Maintenance.games} />}
          </ProtectedRoute>
        }
        />
        <Route path="/betgames/:gameId" element={
          <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
            {isBetgameActive
              ? <BetgamesCasino />
              : <SiteMaintenance message={Maintenance.games} />}
          </ProtectedRoute>
        }
        />
        <Route path="/twain-sport" element={
          <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
            {isBetgameActive
              ? <BetgamesTwainSport />
              : <SiteMaintenance message={Maintenance.games} />}
          </ProtectedRoute>
        }
        />
        <Route path="/twain-sport/:gameID" element={
          <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
            {isBetgameActive
              ? <BetgamesTwainSport />
              : <SiteMaintenance message={Maintenance.games} />}
          </ProtectedRoute>
        }
        />

        <Route path="/inbetgames/:gameId" element={
          <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
            {isInbetActive
              ? <InbetGames />
              : <SiteMaintenance message={Maintenance.games} />}
          </ProtectedRoute>
        }
        />
        <Route path="*" element={<NotFounded />} />
      </Routes>
    </main>
  );
};

export default GamesPage;
