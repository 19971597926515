import React from 'react';
import cn from 'classnames';
import Modal from 'react-modal';

import './ResultModal.scss';
import CloseIcon from '../icons/CloseIcon/CloseIcon';

export type Status = 'confirmation' | 'success' | 'error';

type Props = {
  title: string;
  status: Status;
  contentLabel?: string;
  description?: string;
  modalIsOpen: boolean;
  closeModal: () => void;
  // contentClassName?: string;
  // overlayClassName?: string;
  error?: string | null;
};

const ResultModal = (props: Props) => {
  const {
    modalIsOpen,
    closeModal,
    contentLabel,
    // contentClassName,
    // overlayClassName,
    title,
    description,
    status,
    error,
  } = props;
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel={contentLabel}
      className="result-modal"
      overlayClassName="result-modal__overlay"
    >
      <div className="result-modal__content">
        <div className="result-modal__close-section">
          <CloseIcon closeModal={closeModal} />
        </div>
        <div className="result-modal__message">
          <div className={cn('result-modal__icon', `result-modal__icon--${status}`)} />
          <div className="result-modal__header-title">{title}</div>
          <p className="result-modal__description">{error ?? description}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ResultModal;
