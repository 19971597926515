import React from 'react';

import { getCurrencyTitle } from 'store/wallets/constants';
import { getBalanceDiffType } from 'store/user/utils';
import { getDateTimeSlashFormat } from 'utils/time';
import { useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import { bemCn } from 'utils/bem-cn';

import type { Currency } from 'types/wallets-data';
import type { Transaction } from 'types/user-data';

import './Transactions.scss';

type Props = {
  primaryCurrency?: Currency | null;
  transfer: Transaction;
};

const TransferItem = (props: Props) => {
  const {
    primaryCurrency,
    transfer,
  } = props;

  const {
    id,
    created,
    currency,
    type,
    amount,
    userFrom = 0,
    userTo = 0,
  } = transfer;

  const diffType = getBalanceDiffType(type);

  const { id: userId } = useAppSelector(selectAccountData);

  const title = Number(userId) === userTo ? `Перевод от ${userFrom}` : `Перевод к ${userTo}`;

  const b = bemCn('transaction-item');

  return (
    <div className={b()}>
      <div className={b('row')}>
        <h2>{title}</h2>
        <p>{getDateTimeSlashFormat(created)}</p>
      </div>
      <div className={b('row')}>
        <p>{currency === primaryCurrency ? 'Основной счет' : getCurrencyTitle(currency)}</p>
        <h2 className={b('amount', { 'increase': Number(userId) === userTo, 'decrease': Number(userId) !== userTo })}>
          {Number(userId) === userTo ? '+' : '-'}
          {`${amount} ${currency}`}
        </h2>
      </div>
    </div>
  );
};

export default TransferItem;
