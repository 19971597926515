import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import SearchFilter from 'components/shared/SearchFilter/SearchFilter';
import { useAppSelector } from 'hooks';
import { selectLineSportsBySearch } from 'store/line/selectors';
import { useGetLineSportsQuery, useGetLineStatusQuery } from 'store/line/lineApi';
import { seletIsLineActive } from 'store/app/selectores';
import { bemCn } from 'utils/bem-cn';

import SportListItem from './SportListItem/SportListItem';

import type { LineType } from 'types/line-data';

import './SportsList.scss';

const pageTitle: Record<LineType, string> = {
  line: 'Линия',
  live: 'Live',
};

const lineType = 'line';

const SportsList = () => {
  const { t } = useTranslation();
  const [openSearch, setOpenSearch] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const isLineActive = useAppSelector(seletIsLineActive);
  const sportsList = useAppSelector(selectLineSportsBySearch(searchFilterValue));
  const { isLoading } = useGetLineSportsQuery(undefined, { skip: !isLineActive });
  useGetLineStatusQuery(undefined, { skip: !isLineActive });

  const handleSearchChange = (newSearchValue: string) => {
    setSearchFilterValue(newSearchValue);
  };

  const handleSearchCancel = () => {
    setOpenSearch(false);
  };

  const b = bemCn('sports');

  return (
    <div className={b()}>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      {!openSearch && (
        <PageHeader
          id={lineType}
          title={pageTitle[lineType]}
          controlPanel={[{ type: 'star' }, { type: 'search', onClick: () => setOpenSearch(true) }]}
        />
      )}
      <SearchFilter
        openSearch={openSearch}
        onCancel={handleSearchCancel}
        onChange={handleSearchChange}
      />
      <div className={b('content')}>
        {isLoading
          ? <Loader />
          : (
            <div className={b('list')}>
              {sportsList.map((sportItem) => (
                <SportListItem
                  key={sportItem.slug}
                  sportId={sportItem.slug}
                  sportName={sportItem.name}
                  link={`/line/sport/${sportItem.slug}/${sportItem.name}`}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default SportsList;
