import React, { forwardRef } from 'react';
import cn from 'classnames';

import TextInput, { InputProps } from 'components/shared/inputs/TextInput/TextInput';
import { getCurrencySymbol } from 'store/wallets/constants';

import type { Currency } from 'types/wallets-data';

import './MoneyInput.scss';

interface Props extends InputProps {
  value: number | '';
  currency?: Currency | null;
  onChange?(value: number | ''): void;
  variant?: 'default' | 'inverted';
  className?: string;
}

// eslint-disable-next-line react/display-name
const MoneyInput = forwardRef<HTMLInputElement, Props>(({
  placeholder = 'Ваш пароль',
  value,
  onChange,
  currency,
  isTouched,
  variant,
  className,
  ...props
}, ref) => {
  const handleMoneyChange = (valueAsString: string) => {
    const valueAsNumber = parseInt(valueAsString, 10);

    if (valueAsString === '') {
      onChange?.('');
    }

    if (!(Number.isNaN(valueAsNumber)) && valueAsNumber !== value) {
      onChange?.(valueAsNumber);
    }
  };

  return (
    <div className={cn('money-input', className)}>
      <TextInput
        {...props}
        value={value}
        type="text"
        variant={variant}
        ref={ref}
        pattern="\d*"
        onChange={handleMoneyChange}
        placeholder={placeholder}
        isTouched={isTouched}
      />
      <div className={cn('money-input__currency', variant && `money-input__currency--${variant}`)}>
        {getCurrencySymbol(currency)}
      </div>
    </div>

  );
});
export default MoneyInput;
