import { Link } from 'react-router-dom';
import { MouseEventHandler } from 'react';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import SportIcon from 'components/shared/icons/SportIcon/SportIcon';
import { bemCn } from 'utils/bem-cn';
import './SportListItem.scss';

type Props = {
  className?: string;
  sportName: string;
  sportId: string;
  link?: string;
  onClick?: (sportId: string) => void;
}

const SportListItem = (props: Props) => {
  const { className, sportName, sportId, link = '', onClick } = props;

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (evt) => {
    if (onClick) {
      evt.preventDefault();
      onClick(sportId);
    }
  };

  const b = bemCn('sport-list-item');

  return (
    <Link to={link} className={b(null, className)} onClick={handleClick}>
      <SportIcon className={b('icon')} sportId={sportId} />
      <p className={b('title')}>{sportName}</p>
      <SvgIcon className={b('arrow')}
        name='chevron-right'
        width={8}
        height={8}
      />
    </Link>
  );
};

export default SportListItem;
