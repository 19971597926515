import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

import type { CurrenciesResponse } from 'types/wallets-data';

export const walletsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query<CurrenciesResponse, void>({
      query: () => APIRoute.Currencies,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrenciesQuery } = walletsApi;
