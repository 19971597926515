import React, { SVGProps, useContext } from 'react';
import cn from 'classnames';

import { ReactComponent as HomeIcon } from 'assets/sectionsIcons/home.svg';
import { ReactComponent as LiveIcon } from 'assets/sectionsIcons/live.svg';
import { ReactComponent as BasketIcon } from 'assets/sectionsIcons/basket.svg';
import { ReactComponent as LineIcon } from 'assets/sectionsIcons/line.svg';
import { ReactComponent as CasinoIcon } from 'assets/sectionsIcons/casino.svg';
import { ReactComponent as TopLineIcon } from 'assets/sectionsIcons/top-line.svg';
import { SiteSection } from 'types/siteSections';

import './SectionIcon.scss';
import ThemeContext from '../../../contexts/ThemeContext';

type Props = {
  id: SiteSection;
  isBackgroundShowing: boolean;
  variant?: 'rounded-shadow';
  className?: string;
};

type Icons = Record<string, React.FunctionComponent<SVGProps<SVGSVGElement>>>;

const icons: Icons = {
  home: HomeIcon,
  live: LiveIcon,
  basket: BasketIcon,
  line: LineIcon,
  'top-line': TopLineIcon,
  casino: CasinoIcon,
};

const defaultIcon = 'home';

const SectionIcon = ({ id, isBackgroundShowing, variant, className }: Props) => {
  const { theme } = useContext(ThemeContext);
  const bgVariant = theme === 'light-blue' ? 'blue' : id;

  const Icon = icons[id] || icons[defaultIcon];

  return (
    <div className={cn(
      'section-icon__wrapper',
      variant && `section-icon__wrapper--${variant}`,
      isBackgroundShowing && `section-icon__wrapper--${bgVariant}`,
      className,
    )}
    >
      <Icon className={cn('section-icon__icon', { 'section-icon__icon--active': isBackgroundShowing })} />
    </div>
  );
};

export default SectionIcon;
