import React from 'react';

import { getSportIcon } from 'store/line/data';
import { useAppSelector } from 'hooks';
import { selectBasketItems } from 'store/basket/selectors';
import { bemCn } from 'utils/bem-cn';
import EventCardDesktop from 'components/line/EventCard/EventCardDesktop/EventCardDesktop';

import type { EventItem, LineType } from 'types/line-data';

import './TournamentCardDesktop.scss';

type Props = {
  title: string;
  events: EventItem[];
  lineType: LineType;
};

const TournamentCardDesktop = ({ title, events, lineType }: Props) => {
  const sportSlug = events.find((event) => event.sportId.length > 0);
  const icon = getSportIcon(sportSlug?.sportId ?? '');
  const basketItems = useAppSelector(selectBasketItems);

  const b = bemCn('tournament-card-desktop');

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h2 className={b('title')}>
          <img
            className={b('icon')}
            style={{ width: 20, height: 20 }}
            src={`${process.env.PUBLIC_URL}/sports/${icon}.svg`}
            alt=""
            loading="lazy"
          />
          {title}
        </h2>
      </div>
      <div className={b('events')}>
        {events.map((event) => (
          <EventCardDesktop
            className={b('event')}
            key={event.id}
            lineType={lineType}
            event={event}
            bets={event.group.bets}
            basketItem={basketItems[event.id]?.betId}
            groupName={event.group.name}
          />
        ))}
      </div>
    </div>
  );
};

export default TournamentCardDesktop;
