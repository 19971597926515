import React from 'react';
import { Link } from 'react-router-dom';

import { useResponsive } from 'hooks/useResponsive';
import { bemCn } from 'utils/bem-cn';
import './NavMenu.scss';

export type MenuItem = {
  link?: string;
  title: string;
  id: number;
  disabled?: boolean;
};

type Props = {
  alignment?: 'center' | 'left';
  className?: string;
  menuItems: MenuItem[];
  colorScheme?: '' | 'inverse';
  onClick?: (id: number) => void;
  activeItemId?: number;
  onClickLink?: (id: number) => void;
};

const NavMenu = (props: Props) => {
  const b = bemCn('nav-menu');
  const {
    alignment = 'center',
    className,
    menuItems,
    colorScheme,
    activeItemId,
    onClick,
    onClickLink
  } = props;
  const { Mobile } = useResponsive();

  const navLayout = menuItems.map((menuItem) => (
    <div key={menuItem.id}
      className={b(
        'item-wrapper',
        {
          'active': menuItem.id === activeItemId,
          'inverse': colorScheme === 'inverse'
        }
      )}
    >
      {menuItem.link ? (
        <Link
          className={b('item', b('item-link'))}
          to={menuItem.link}
          onClick={() => onClickLink?.(menuItem.id)}
        >
          {menuItem.title}
        </Link>
      ) : (
        <button
          type="button"
          className={b('item', b('item-button'))}
          onClick={onClick && !menuItem.disabled ? () => onClick(menuItem.id) : () => undefined}
          tabIndex={menuItem.disabled ? -1 : 0}
        >
          {menuItem.title}
        </button>
      )}
    </div>
  ));

  return (
    <div className={b('wrapper')}>
      <nav className={b({ alignment: alignment }, className)}>
        {navLayout}
        <Mobile><div className={b('separator')} /></Mobile>
      </nav>
    </div>

  );
};

export default NavMenu;
