import { SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GradientIcon from 'components/shared/icons/GradientIcon/GradientIcon';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardMobile from 'components/line/EventCard/EventCardMobile/EventCardMobile';
import { useAppSelector } from 'hooks';
import { selectBasketItems } from 'store/basket/selectors';
import { filterByEntries } from 'utils/common';
import { topTournamentsID } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';

import WidgetSlider from './components/TopEventsWidgetSlider';

import type { EventItem, LineType } from 'types/line-data';

import './TopEventsWidgetMobile.scss';

type Props = {
  title: string;
  dataType: LineType;
  iconName?: string;
  iconColor?: string;
  allLink: string;
  isLoading?: boolean;
  events: EventItem[];
  isAvailable?: boolean;
  notAvailableMessage?: string;
}

const TopEventsWidgetMobile = (props: Props) => {
  const {
    title,
    dataType,
    iconName,
    iconColor,
    allLink,
    isLoading,
    events,
    isAvailable,
    notAvailableMessage = 'Временно недоступно'
  } = props;
  const { t } = useTranslation();
  const basketItems = useAppSelector(selectBasketItems);
  const filteredEvents = filterByEntries(
    events || [],
    topTournamentsID,
    (e) => e.tournamentId
  );
  const shownEvents = filteredEvents.length > 10 ? filteredEvents : events;
  const eventsCount = (shownEvents && shownEvents.length) ?? 0;

  const b = bemCn('top-events-widget-mobile');

  return (
    <div className={b()}>
      <div className={b('head')}>
        <GradientIcon className={b('icon')} color={iconColor} iconName={iconName} />
        <p className={b('title')}>{title}</p>
        <div className={b('meta')}>
          <Link className={b('all')} to={allLink} >
            {t('top-events-widget.all', 'Все')}
            <SvgIcon className={b('all-icon')} name="arrow-right" width={9} height={9} />
          </Link>
          <p className={b('count')}>
            {eventsCount} {t('top-events-widget.events', 'события')}
          </p>
        </div>
      </div>
      <div className={b('events')}>
        {isAvailable !== undefined && !isAvailable
          ? <p>{notAvailableMessage}</p>
          : (
            <WidgetSlider isLoading={isLoading}>
              {shownEvents?.map((event) => (
                <SwiperSlide key={event.id} className={b('events-slide')}>
                  <EventCardMobile
                    basketItem={basketItems[event.id]?.betId}
                    event={event}
                    lineType={dataType}
                  />
                </SwiperSlide>
              ))}
            </WidgetSlider>
          )}
      </div>
    </div>
  );
};

export default TopEventsWidgetMobile;
