import { api } from 'store/api';
import {
  AvailableTopUpMethodsObject,
  AvailableWithdrawalMethodsObject,
  ServerSystems,
  ServerWithdrawalMethod,
  InitResponse,
  TopUpData,
  WithdrawalData
} from 'types/payments-data';
import { APIRoute } from 'utils/routes';

import { transformTopupSystems, transformWithdrawalMethods } from './converters';

export const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getTopupMethods: builder.query<AvailableTopUpMethodsObject, void>({
      query: () => APIRoute.PaymentSystems,
      transformResponse: (response: ServerSystems) =>
        transformTopupSystems(response)
    }),

    getWithdrawalMethods: builder.query<AvailableWithdrawalMethodsObject, void>({
      query: () => APIRoute.WithdrawalMethods,
      transformResponse: (response: ServerWithdrawalMethod) => transformWithdrawalMethods(response)
    }),

    initTopUp: builder.mutation<InitResponse, TopUpData>({
      query: ({ system, amount, method }: TopUpData) => ({
        url: `${APIRoute.Payment}/${system}/init`,
        method: 'POST',
        body: {
          'amount': amount,
          'pay_with': method,
        }
      }),
      transformResponse: (response: { payment_url: string }) => ({
        paymentUrl: response.payment_url,
      })
    }),
    initWithdrawal: builder.mutation<InitResponse, WithdrawalData>({
      query: (data: WithdrawalData) => ({
        url: APIRoute.WithdrawalInit,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { payment_url: string; order_id: string }) => ({
        paymentUrl: response.payment_url,
        orderId: response.order_id,
      }),
      invalidatesTags: ['Transactions', 'Wallets'],
    }),
  }),
});

export const {
  useInitTopUpMutation,
  useInitWithdrawalMutation,
  useGetWithdrawalMethodsQuery,
  useGetTopupMethodsQuery,
} = paymentsApi;
