import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { changeActiveModal } from 'store/auth/authSlice';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { useAppDispatch } from 'hooks';
import './DropdownWallet.scss';
import { bemCn } from 'utils/bem-cn';

type Props = {
  onTransferClick: () => void;
};

const DropdownWallet = ({ onTransferClick }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownBlock = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  // TODO: Вынести хендлер клика, и использовать хуть useOnClickOutside
  useEffect(() => {
    window.addEventListener('click', (e) => {
      const tar = e?.target as HTMLElement;
      if (dropdownBlock.current && !tar.closest(`.${dropdownBlock.current.className}`)) {
        setIsOpen(false);
      }
    });

    return () => {
      window.removeEventListener('click', (e) => {
        if (e.target !== dropdownBlock.current) {
          setIsOpen(false);
        }
      });
    };
  }, []);

  const handleWithdrawalClick = () => {
    dispatch(changeActiveModal('withdrawals-modal'));
  };

  const handleTopupClick = () => {
    dispatch(changeActiveModal('topups'));
  };

  const handleTransferClick = () => {
    // TODO: Есть баг с открытием окна перевода на десктопе
    onTransferClick();
  };

  const b = bemCn('dropdown-wallet');

  return (
    <div className={b()} ref={dropdownBlock}>
      <button type="button" onClick={() => setIsOpen(!isOpen)}>
        <SvgIcon className={b('icon')} name="dots-menu" width={11} height={11} />
      </button>
      {isOpen && (
        <div className={b('dropdown')}>
          <button type="button" onClick={handleWithdrawalClick}>
            {t('wallets.withdrawal', 'Вывод')}
          </button>
          <button type="button" onClick={handleTopupClick}>
            {t('wallets.topup', 'Пополнить')}
          </button>
          <button type="button" onClick={handleTransferClick}>
            {t('wallets.transfer', 'Перевод')}
          </button>
        </div>
      )}
    </div>
  );
};

export default DropdownWallet;
