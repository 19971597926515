import { useTranslation } from 'react-i18next';

import { countDownTimer, toLocaleDateString, toLocaleTimeString } from 'utils/time';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardСountdown from 'components/line/EventCard/components/EventCardСountdown/EventCardСountdown';
import { bemCn } from 'utils/bem-cn';

import type { LineType } from 'types/line-data';

import './FullEventInfoDesktop.scss';

type Props = {
  eventDate: string;
  tournament: string;
  scoreFull: string;
  periodName: string;
  lineType: LineType;
};

const FullEventInfoDesktop = (props: Props) => {
  const { eventDate, tournament, lineType, scoreFull, periodName } = props;
  const { t } = useTranslation();
  const b = bemCn('full-event-info-desktop');

  const timeFormatText = toLocaleTimeString(new Date(eventDate));
  const timeWithoutSeconds = `${timeFormatText.split(':')[0]}:${timeFormatText.split(':')[1]}`;

  const dateFormatText = lineType === 'line' ? toLocaleDateString(new Date(eventDate)) : `Сегодня, ${timeWithoutSeconds}`;


  const timeAfterStart = countDownTimer(eventDate);

  const renderTime = () => {
    if (lineType === 'line') {
      return <h2>{timeWithoutSeconds}</h2>;
    } else {
      return (
        <div className={b('stats')}>
          <h2>{scoreFull}</h2>
          <div className="full-event-header__period-layout">
            <SvgIcon className="full-event-header__period-icon" name="clock" width={10} height={10} />
            <p className="full-event-header__period">{periodName}</p>
          </div>
        </div>
      );
    }
  };

  const renderCountDoun = () => {
    if (lineType === 'line') {
      return (
        // TODO: Исправить цвет цифр на светлой теме
        <div className={b('time-after-start')}>
          <h2>{t('event.start-event', 'Начало через')}</h2>
          <div className={b('times')}>
            <div>{timeAfterStart.days}</div>
            :
            <div>{timeAfterStart.hours}</div>
            :
            <div>{timeAfterStart.minutes}</div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={b()}>
      <div className={b('date')}>
        {renderTime()}
        <p>{dateFormatText}</p>
      </div>
      <div className={b('content')}>
        <p>{tournament}</p>
        <EventCardСountdown
          className={b('countdown')}
          hide={lineType !== 'line'}
          date={eventDate}
        />
      </div>
    </div>
  );
};

export default FullEventInfoDesktop;
