import React, { useEffect } from 'react';
import { useToggle } from 'usehooks-ts';

import { ReactComponent as CaretIcon } from 'assets/caret.svg';
import { addBet } from 'store/basket/basketSlice';
import { useAppDispatch } from 'hooks';
import { getCoefTitle } from 'store/line/utils';
import { bemCn } from 'utils/bem-cn';

import Coefficient from '../../components/Coefficient/Coefficient';

import type { LineType, Group, Bet } from 'types/line-data';

import './GroupItem.scss';

type Props = {
  group: Group;
  eventId: string;
  team1: string;
  team2: string;
  tournament: string;
  lineType: LineType;
  basketItem: string | null;
  tab: number;
  groupName: string;
  isCollapsedAll: boolean;
};

const GroupItem = (props: Props) => {
  const {
    group,
    eventId,
    team1,
    team2,
    lineType,
    tournament,
    basketItem,
    tab,
    groupName,
    isCollapsedAll
  } = props;
  const dispatch = useAppDispatch();

  const [isCollapsed, toggleCollapsed, setCollapsed] = useToggle(isCollapsedAll);

  useEffect(() => {
    setCollapsed(isCollapsedAll);
  }, [isCollapsedAll]);

  useEffect(() => {
    setCollapsed(true);
  }, [tab]);

  const handleAddBetToBasket = (bet: Bet) => {
    const newBet = {
      eventId,
      betId: bet.betId,
      coef: bet.rate,
      team1,
      team2,
      groupName: `${groupName} : ${bet.caption}`,
      tournament,
      lineType
    };

    dispatch(addBet(newBet));
  };

  const b = bemCn('group-item');

  return (
    <div className={b()}>
      <div className={b('title-wrapper')} onClick={toggleCollapsed}>
        <p className={b('title')}>
          {group.name}
        </p>
        <CaretIcon className={b('collapse-icon', { 'active': !isCollapsed })} />
      </div>
      {!isCollapsed && (
        <div>
          {group.bets.map((bet) => (
            <Coefficient
              key={bet.betId}
              title={getCoefTitle(bet.caption, team1, team2)}
              value={bet.rate}
              isActive={bet.betId === basketItem}
              variant="fullwidth"
              onClick={() => handleAddBetToBasket(bet)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupItem;
