import React from 'react';
import cn from 'classnames';

import './ErrorBlock.scss';

export type Props = {
  isDisplayed?: boolean;
  message?: string | null;
  align?: 'left' | 'right' | 'center';
};

const ErrorBlock = ({ isDisplayed, message, align = 'right' }: Props) => (
  <div className={cn('error-message', `error-message--align-${align}`)}>
    {isDisplayed && message}
  </div>
);

export default ErrorBlock;
