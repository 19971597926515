import React from 'react';
import cn from 'classnames';

import { getMethodIcons } from 'components/shared/icons/PaymentMethodIcons';
import { defaultSystem } from 'store/payment/constants';
import { getDefaultSystemName } from 'store/payment/utils';
import { useAppSelector } from 'hooks';
import { selectActiveTopUpMethod } from 'store/payment/selectors';

import type { PaymentSystem } from 'types/payments-data';

import './PaymentSystemOption.scss';

type Props = {
  value: PaymentSystem | null;
  isActive?: boolean;
};

// const activeMethod = useTypedSelector(selectActiveMethod);
//
// const systems = useTypedSelector(selectSystems);
//
// const layout = getMethodIcons(activeMethod);
// const isIconsDisplayed = (isActive && value);

// {value && systems[value].title}

const PaymentSystemOption = ({ value, isActive }: Props) => {
  const activeMethod = useAppSelector(selectActiveTopUpMethod);

  const layout = activeMethod && getMethodIcons(activeMethod.id);
  const isIconsDisplayed = (isActive && value);

  return (
    <div className="payment-system-selector__options">
      <div className="payment-system-selector__wrapper">
        <span className={cn(
          'payment-system-selector__value',
          { 'payment-system-selector__value--default': !value }
        )}
        >
          {value === defaultSystem ? getDefaultSystemName(value) : value}
        </span>
      </div>
      {isIconsDisplayed && (
        <div className="payment-system-selector__icons">
          {layout}
        </div>
      )}
    </div>
  );
};

export default React.memo(PaymentSystemOption);
