import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { changeActiveModal } from 'store/auth/authSlice';
import { useAppDispatch } from 'hooks';


type Props = {
  isAuthenticated: boolean;
}

const ClickLogin = ({ isAuthenticated }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clickId } = useParams();

  useEffect(() => {
    if (clickId && !isAuthenticated) {
      localStorage.setItem('clickId', clickId);
      navigate('/', { replace: true });
      dispatch(changeActiveModal('sign-up'));
    }
  }, [clickId,]);

  return null;
};

export default ClickLogin;
