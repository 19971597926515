import { useState } from 'react';

import { bemCn } from 'utils/bem-cn';

import iconFlagRu from '../assets/icon-flag-ru.svg';
import iconFlagUS from '../assets/icon-flag-us.svg';
import iconFlagUk from '../assets/icon-flag-uk.svg';
import SiteSettingsLangBtn from '../SiteSettingsLangBtn/SiteSettingsLangBtn';

const langIcons: Record<string, string> = {
  ru: iconFlagRu,
  en: iconFlagUS,
  uk: iconFlagUk
};

type Props = {
  activeLang: string;
  langList: string[];
  onLangChange: (lang: string) => void;
  below?: boolean;
}

const SiteSettingsLang = ({ langList, onLangChange, activeLang, below }: Props) => {
  const [isOpened, setOpened] = useState(false);

  const handleOpenClick = () => {
    setOpened(true);
  };

  const handleLangChange = (lang: string) => {
    setOpened(false);
    onLangChange(lang);
  };

  const b = bemCn('site-settings');
  return (
    <div className={b('lang', { opened: isOpened, below })}>
      <div className={b('lang-selected')}>
        <SiteSettingsLangBtn
          className={b('lang-selected')}
          onClick={handleOpenClick}
          title={activeLang}
          icon={langIcons[activeLang]}
        />
      </div>
      <div className={b('lang-list')}>
        {langList.map((l) => (
          <SiteSettingsLangBtn
            key={l}
            className={b('lang-item')}
            onClick={() => handleLangChange(l)}
            title={l}
            icon={langIcons[l] ?? ''}
          />
        ))}
      </div>
    </div>
  );
};


export default SiteSettingsLang;
