import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrencySymbol, getCurrencyTitle } from 'store/wallets/constants';
import Button from 'components/shared/buttons/Button/Button';
import { useUpdateWalletMutation } from 'store/user/userApi';
import { bemCn } from 'utils/bem-cn';

import type { Wallet } from 'types/wallets-data';

import './WalletOperation.scss';

type Props = {
  currency: Wallet;
};

const WalletOperation = ({ currency }: Props) => {
  const [addWallet] = useUpdateWalletMutation();
  const { t } = useTranslation();
  const updateWallet = async () => {
    await addWallet(currency.currency);
  };

  const b = bemCn('wallet-operation');

  return (
    <div className={b()}>
      <div className={b('main')}>
        <div className={b('symbol')}>
          <p>{getCurrencySymbol(currency.currency)}</p>
        </div>
        <div>
          <h5 className={b('title')}>
            {getCurrencyTitle(currency.currency)}
          </h5>
          <p className={b('amount')}>{currency.amount}</p>
        </div>
      </div>
      <Button onClick={() => { updateWallet(); }}>
        {t('wallets.actions.set-main', 'Сделать основным')}
      </Button>
    </div>
  );
};

export default WalletOperation;
