import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import SearchFilter from 'components/shared/SearchFilter/SearchFilter';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setCountry } from 'store/line/lineSlice';
import { selectCountriesList, selectCountry, selectTournaments } from 'store/line/selectors';
import { useGetCountriesQuery, useGetTournamentsQuery } from 'store/line/lineApi';
import { routes } from 'utils/routes';
import { topCountriesId, topSportsId } from 'utils/constants';
import SportIcon from 'components/shared/icons/SportIcon/SportIcon';
import { sortByTopEntries } from 'utils/common';
import { bemCn } from 'utils/bem-cn';

import CountryItem from './CountryItem/CountryItem';

import './Sport.scss';

const type = 'line';

const MAX_REFETCH_COUNT = 6;

const Sport = () => {
  const [isSearchOpened, setSearchOpened] = useState<boolean>(false);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [refetchCount, setRefetchCount] = useState(0);
  const { id, name } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tournaments = useAppSelector(selectTournaments);
  const countryId = useAppSelector(selectCountry);
  let countries = useAppSelector(selectCountriesList);

  const isIdValid = id !== undefined;

  const {
    isFetching,
    data: countriesData,
    refetch: refetchCountries
  } = useGetCountriesQuery({ sportId: id, type, }, { skip: !isIdValid });

  const {
    isFetching: isTournamentsFetching,
    data,
    refetch
  } = useGetTournamentsQuery({ sportId: id, type }, { skip: !isIdValid });


  useEffect(() => {
    if (data && Object.keys(data).length === 0 && refetchCount <= MAX_REFETCH_COUNT) {
      refetch();
      setRefetchCount((count) => count + 1);
    }
  }, [data]);

  useEffect(() => {
    if (countriesData && countriesData.length === 0 && refetchCount <= MAX_REFETCH_COUNT) {
      refetchCountries();
      setRefetchCount((count) => count + 1);
    }
  }, [countriesData]);

  if (!isIdValid) {
    navigate(-1);
    return (<div />);
  }

  if (topSportsId.includes(id)) {
    countries = sortByTopEntries(countries, topCountriesId, (country) => country.slug);
  }

  if (searchFilterValue.length > 0) {
    countries = countries.filter((country) =>
      country.name
        .toLowerCase()
        .includes(searchFilterValue.toLowerCase())
    );
  }

  const handleCountryClick = (countrySlug: string) => {
    if (countryId !== countrySlug) {
      dispatch(setCountry(countrySlug));
    }
  };

  const handleSearchChange = (newSearchValue: string) => {
    setSearchFilterValue(newSearchValue);
  };

  const handleSearchCancel = () => {
    setSearchOpened(false);
  };


  const b = bemCn('sport');

  return (
    <div className={b()}>
      <Breadcrumbs link={routes[type].path} title={routes[type].title} />
      {!isSearchOpened && (
        <PageHeader
          icon={<SportIcon sportId={id} />}
          id="line"
          title={name ?? id}
          controlPanel={[
            { type: 'star' },
            { type: 'search', onClick: () => setSearchOpened(true) }
          ]}
        />
      )}
      <SearchFilter
        openSearch={isSearchOpened}
        onCancel={handleSearchCancel}
        onChange={handleSearchChange}
      />
      <div className={b('content')}>
        <div className={b('countries-list')}>
          {isFetching
            ? <Loader />
            : countries.map((country) => (
              <CountryItem
                key={country.slug}
                countryId={country.slug}
                sportId={id}
                tournaments={tournaments[country.slug]}
                name={country.name}
                isLoading={isTournamentsFetching}
                onClick={() => handleCountryClick(country.slug)}
                lineType={type}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Sport;
