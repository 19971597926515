import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { useResetPasswordMutation } from 'store/auth/authApi';
import { selectLoginError } from 'store/auth/selectors';
import Button from 'components/shared/buttons/Button/Button';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { telegramLink } from 'utils/constants';
import CloseIcon from 'components/shared/icons/CloseIcon/CloseIcon';
import { bemCn } from 'utils/bem-cn';

import type { Entries } from 'types/common';
import type { LoginErrors } from 'types/auth-data';

import './ResetPassword.scss';

type FormFields = {
  email: string;
};

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  contentClassName?: string;
  overlayClassName?: string;
};

const schema = yup.object({
  email: yup.string()
    .email(`${i18n.t('reset-pass.errors.wrong-email', 'Введите корректный E-mail')}`)
    .required(`${i18n.t('reset-pass.errors.empty-email', 'Введите E-mail')}`),
}).required();

const ResetPassword = (props: Props) => {
  const { modalIsOpen, closeModal, contentClassName, overlayClassName } = props;
  const { t } = useTranslation();
  const [reset, { isLoading }] = useResetPasswordMutation();
  const loginErrors = useAppSelector(selectLoginError);

  const { handleSubmit, setError, control } = useForm<FormFields>({
    defaultValues: { email: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fieldsErrors: Partial<LoginErrors> = { ...loginErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<FormFields>;
    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [loginErrors]);

  const handleLoginClick = async (data: FormFields) => {
    await reset(data.email);
  };

  const b = bemCn('login-modal');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={b(null, contentClassName)}
      overlayClassName={overlayClassName}
    >
      <div className={b('content')}>
        <div className={b('close-section')}>
          <CloseIcon closeModal={closeModal} />
        </div>
        <div className={b('header')}>
          <h1 className={b('header-title')}>
            {t('reset-pass.title', 'Восстановление пароля')}
          </h1>
          <p className={b('header-description')}>
            {t('reset-pass.email-description', 'Введите адрес электронной почты  вашего аккаунта, мы отправим вам ссылку для восстановления пароля')}
          </p>
        </div>
        {!!loginErrors.common && (
          <ErrorBlock isDisplayed={!!loginErrors.common} message={loginErrors.common} align="center" />
        )}
        <div className={b('fields')}>
          <FormInputWrapper<FormFields>
            showError
            type="text"
            control={control}
            name="email"
            placeholder="E-mail"
            autoComplete="email"
            disabled={isLoading}
          />
        </div>

        <a href={telegramLink}>
          <div className={b('remember-password')}>
            {t('reset-pass.forgot-email', 'Не помню e-mail')}
          </div>
        </a>

        <div className={b('shared-group')}>
          <div className={b('buttons-sections')}>
            <div className={b('button-wrapper')}>
              <Button onClick={handleSubmit(handleLoginClick)} isLoading={isLoading}>
                {t('reset-pass.send-request', 'Отправить')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPassword;
