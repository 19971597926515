import { bemCn } from 'utils/bem-cn';

import FooterNavItem, { LiltItem } from './FooterNavItem';


type Props = {
  title: string;
  items: LiltItem[];
}

const FooterNavGroup = (props: Props) => {
  const b = bemCn('footer-nav');

  return (
    <div className={b('group')}>
      <p className={b('group-title')}>{props.title}</p>
      <ul className={b('list')}>
        {props.items.map((item, index) => {
          const keyVal = `${item.name}-${index}`;
          return (
            <FooterNavItem
              key={keyVal}
              item={item}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default FooterNavGroup;
