import React, { useState } from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import NavMenu, { MenuItem } from 'components/shared/NavMenu/NavMenu';
import i18n from 'i18n/i18n';

import TransactionsPage from '../Transactions/TransactionsPage/TransactionsPage';
import DepositsPage from '../Transactions/DepositsPage/DepositsPage';
import WidthrawalPage from '../Transactions/WidthrawalPage/WidthrawalPage';
import BetsPage from '../Transactions/BetsPage/BetsPage';
import TransferPage from '../Transactions/TransferPage/TransferPage';
import SlotsPage from '../Transactions/SlotsPage/SlotsPage';
import CloseIcon from '../../shared/icons/CloseIcon/CloseIcon';
import ModalsHead from '../ModalsHead/ModalsHead';


import './DetailingModal.scss';

const menuItems: (MenuItem & { component: JSX.Element })[] = [
  {
    link: '',
    title: i18n.t('transactions.all', 'Все'),
    id: 0,
    component: <TransactionsPage />
  },
  {
    link: '',
    title: i18n.t('transactions.deposits', 'Пополнения'),
    id: 1,
    component: <DepositsPage />
  },
  {
    link: '',
    title: i18n.t('transactions.withdrawal', 'Вывод'),
    id: 2,
    component: <WidthrawalPage />
  },
  {
    link: '',
    title: i18n.t('transactions.bets', 'Ставки'),
    id: 3,
    component: <BetsPage />
  },
  {
    link: '',
    title: i18n.t('transactions.transfer', 'Переводы'),
    id: 4,
    component: <TransferPage />
  },
  {
    link: '',
    title: i18n.t('transactions.slots', 'Слоты'),
    id: 5,
    component: <SlotsPage />
  }
];

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  contentClassName?: string;
  overlayClassName?: string;
};

const DetailingModal = (props: Props) => {
  const { modalIsOpen, closeModal, contentClassName, overlayClassName } = props;
  const [filter, setFiler] = useState<number>(0);
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={cn(contentClassName, 'detailing-modal')}
      overlayClassName={overlayClassName}
    >
      <div>
        <CloseIcon closeModal={closeModal} />
      </div>
      <ModalsHead title={t('transactions.title', 'Детализация')} />
      <div className="detailin-modal__menu">
        <NavMenu menuItems={menuItems} activeItemId={filter} onClick={(id) => setFiler(id)} />
      </div>
      <div className="detailin-modal__list">
        {menuItems.find((item) => item.id === filter)?.component}
      </div>
    </Modal>
  );
};

export default DetailingModal;
