

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GamesCard, { GameCardItemType } from 'components/shared/GamesCardsWidget/GamesCard/GamesCard';
import { batgamesList, twainSportList } from 'utils/constants';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useGetInbetGamesQuery } from 'store/slots/slotsApi';
import ButtonToTop from 'components/shared/ButtonToTop/ButtonToTop';
import Button from 'components/shared/buttons/Button/Button';
import { useAppSelector } from 'hooks';
import { selectIsInbetActive, seletIsBetgameActive } from 'store/app/selectores';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Maintenance } from 'utils/constants/maintenanceMessage';

import GamesFilter from '../GamesFilter/GamesFilter';

import './GamesList.scss';

const gamesFilterList = [
  // 'Все',
  'BetGames TV',
  'Twain Sport',
  'Inbet Games Slots',
  'Inbet Games Egame',
  'Inbet Games Betting',
];

const GamesList = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get('filter') ?? gamesFilterList[0]);
  const [gamesList, setGamesList] = useState<GameCardItemType[]>([]);
  const [listSize, setListSize] = useState(18);
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const isBetgameActive = useAppSelector(seletIsBetgameActive);
  const slotsList = useGetInbetGamesQuery({ gameType: 'slot' }, { skip: !isInbetActive });
  const egamesList = useGetInbetGamesQuery({ gameType: 'egame' }, { skip: !isInbetActive });
  const betting = useGetInbetGamesQuery({ gameType: 'betting' }, { skip: !isInbetActive });

  const getTabs = () => gamesFilterList.filter((tab) => {
    switch (tab) {
      case 'BetGames TV':
        return isBetgameActive;
      case 'Twain Sport':
        return isBetgameActive;
      case 'Inbet Games Slots':
        return isInbetActive;
      case 'Inbet Games Egame':
        return isInbetActive;
      case 'Inbet Games Betting':
        return isInbetActive;
    }
    return false;
  });


  useEffect(() => {
    switch (filter) {
      case 'Все':
        setGamesList(
          batgamesList.concat(
            twainSportList,
            slotsList.data ?? [],
            egamesList.data ?? [],
            betting.data ?? []
          ));
        break;
      case 'BetGames TV':
        if (isBetgameActive) {
          setGamesList(batgamesList);
        } else {
          setGamesList([]);
        }
        break;
      case 'Twain Sport':
        if (isBetgameActive) {
          setGamesList(twainSportList);
        } else {
          setGamesList([]);
        }
        break;
      case 'Inbet Games Slots':
        if (isInbetActive) {
          setGamesList(slotsList.data ?? []);
        } else {
          setGamesList([]);
        }
        break;
      case 'Inbet Games Egame':
        if (isInbetActive) {
          setGamesList(egamesList.data ?? []);
        } else {
          setGamesList([]);
        }
        break;
      case 'Inbet Games Betting':
        if (isInbetActive) {
          setGamesList(betting.data ?? []);
        } else {
          setGamesList([]);
        }
        break;
      default:
        setGamesList([]);
    }

    setListSize(18);
  }, [filter, slotsList.data, egamesList.data, betting.data]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
    searchParams.set('filter', newFilter);
    setSearchParams(searchParams);
  };

  const handleShowMoreClick = () => {
    setListSize((prevListSize) => {
      const nextSize = prevListSize + 18;
      if (gamesList.length <= nextSize) {
        return gamesList.length;
      }

      return nextSize;
    });
  };

  const isBtnShown = gamesList.length > listSize;

  if (!isInbetActive && !isBetgameActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <div className="games-list">
      <Breadcrumbs
        link="/home"
        title={t('main.nav.Home', 'Главная')}
      />
      <h1 className="games-list__title">
        {t('games.list', 'Список игр')}
      </h1>
      <GamesFilter
        className='games-list__filter'
        items={getTabs()}
        activeItem={filter}
        onChange={handleFilterChange}
      />
      <div className="games-list__items">
        {gamesList.slice(0, listSize).map((game) => (
          <GamesCard
            key={`game-${game.id ?? '123'}-${game.name}`}
            item={game}
          />
        ))}
      </div>
      {isBtnShown && (
        <Button
          className='games-list__show-more'
          variant='shaded'
          onClick={handleShowMoreClick}
        >
          {t('common.load-more', 'Загрузить ещё')}
        </Button>
      )}
      <ButtonToTop />
    </div>
  );
};

export default GamesList;
