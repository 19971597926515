import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';
import { getSavedUserGeo, saveUserGeo } from 'utils/userGeo';

import { userApi } from './userApi';
import { getShowBalanceHeader, setShowBalanceHeader } from './utils';

import type {
  FullCoupon,
  Wallet,
  AccountData,
  Coupon,
} from 'types/user-data';
import type { UserState } from 'types/state';


const initialState: UserState = {
  isAccaountLoading: true,
  account: {
    id: '',
    email: '',
    role: null,
    name: '',
  },
  additionalWallets: [],
  primaryWallet: {
    currency: null,
    amount: 0,
  },
  totalCouponsCount: 0,
  coupons: [],
  couponsDetails: {},
  transactions: [],
  showBalanceHeader: getShowBalanceHeader(),
  geo: {
    country: null,
    countryName: null,
    isLoading: true,
  }
};

export const userData = createSlice({
  name: NameSpace.User,
  initialState,
  reducers: {
    changeShowBalance: (state, action: PayloadAction<boolean>) => {
      setShowBalanceHeader(action.payload);
      state.showBalanceHeader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.account.matchFulfilled,
        (state, action: PayloadAction<AccountData>) => {
          state.account = action.payload;
          state.isAccaountLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.account.matchRejected,
        (state) => {
          state.isAccaountLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.wallets.matchFulfilled,
        (state, action: PayloadAction<Wallet[]>) => {
          const primary = action.payload.find((wallet) => wallet.isPrimary);
          if (primary) {
            state.primaryWallet = {
              amount: primary.amount,
              currency: primary.currency,
            };
          }
          state.additionalWallets = action.payload.filter((wallet) => !wallet.isPrimary);
        },
      )
      .addMatcher(
        userApi.endpoints.coupons.matchFulfilled,
        (state, action: PayloadAction<{ totalCount: number; items: Coupon[]}>) => {
          state.coupons = action.payload.items;
          state.totalCouponsCount = action.payload.totalCount;
        },
      )
      .addMatcher(
        userApi.endpoints.getUserCountry.matchPending,
        (state) => {
          state.geo.isLoading = true;
        },
      )
      .addMatcher(
        userApi.endpoints.getUserCountry.matchFulfilled,
        (state, action) => {
          saveUserGeo({
            country: action.payload.country,
            countryName: action.payload.countryName,
          });
          state.geo.country = action.payload.country;
          state.geo.countryName = action.payload.countryName;
          state.geo.isLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.getUserCountry.matchRejected,
        (state) => {
          const savedUserGeo = getSavedUserGeo();
          state.geo.country = savedUserGeo.country;
          state.geo.countryName = savedUserGeo.countryName;
          state.geo.isLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.fullCouponsInfo.matchFulfilled,
        (state, action: PayloadAction<FullCoupon>) => {
          state.couponsDetails = action.payload;
        },
      );
  },
});

export const { changeShowBalance } = userData.actions;
