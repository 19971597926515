import React, { useEffect, useState } from 'react';

import { ReactComponent as CaretFilledIcon } from 'assets/caret-fill.svg';
import { bemCn } from 'utils/bem-cn';
import './Coefficient.scss';

export type DiffType = 'increased' | 'decreased' | 'same';

type Props = {
  title: string;
  value?: number | string;
  isActive?: boolean;
  onClick?: () => void;
  variant?: 'fullwidth';
  className?: string;
};

const Coefficient = (props: Props) => {
  const {
    title,
    value,
    onClick,
    isActive,
    variant,
    className
  } = props;
  const [diff, setDiff] = useState<DiffType>('same');
  const [rate, setRate] = useState(value);

  useEffect(() => {
    setRate((prevRate) => {
      if (prevRate !== undefined && value !== undefined) {
        if (value > prevRate) {
          setDiff('increased');
        } else if (value < prevRate) {
          setDiff('decreased');
        }
      }
      return value;
    });
  }, [value]);


  useEffect(() => {
    if (diff !== 'same') {
      const timer = setTimeout(() => {
        setDiff('same');
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [diff]);

  const b = bemCn('coefficient-cell');

  return (
    <button
      className={b({
        active: isActive,
        'same': diff === 'same',
        'increased': diff === 'increased',
        'decreased': diff === 'decreased',
        'fullwidth': Boolean(variant)
      }, className)}
      type="button"
      onClick={onClick}
    >
      <div className={b('container')}>
        <p className={b('title')}>{title}</p>
        <div className={b('value-wrapper')}>
          <p className={b('value')}>{rate}</p>
          <CaretFilledIcon className={b('indicator')} />
        </div>
      </div>
    </button>
  );
};

export default React.memo(Coefficient);
