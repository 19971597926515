import { GamesList, GamesListResponse } from 'types/slots-data';

import type { Entries } from 'types/common';

export const transformGamesList = (response: GamesListResponse): GamesList => {
  const gamesList = Object.entries(response) as Entries<GamesListResponse>;

  return gamesList.filter(([, value]) => value.mobile === 1)
    .map(([key, value]) => ({
      id: key,
      name: value.name,
    }));
};
