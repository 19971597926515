import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { ReactComponent as CaretIcon } from 'assets/caret.svg';
import { moveToStart } from 'utils/common';

import PaymentSystemOption from './PaymentSystemOption/PaymentSystemOption';
import CurrencyOption from './CurrencyOption/CurrencyOption';
import './Selector.scss';

import type { MouseEvent, KeyboardEvent } from 'react';
import type { PaymentSystem as PaymentSystemType } from 'types/payments-data';
import type { Currency } from 'types/wallets-data';

declare module 'react' {
  function forwardRef<T, P = unknown>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type SelectType = 'currency' | 'payment-system' | 'default';

type Props<T> = {
  value?: T | null;
  options: T[];
  onChange?(value: T): void;
  onBlur?(): void;
  error?: { message?: string };
  showError?: boolean;
  isTouched?: boolean;
  name?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type: SelectType;
};

const Selector = <T,>({
  value,
  options,
  onChange,
  onBlur,
  error,
  showError,
  isTouched,
  disabled,
  name,
  isLoading,
  type,
}: Props<T>, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [selectedOption, setSelectedOption] = useState<T | null>(null);
  const [isOptionsDisplay, setOptionsDisplay] = useState<boolean>(false);

  const optionsList: T[] = useMemo(() => moveToStart<T>(options, value), [value, options]);
  const isSingleItem = value && optionsList.length < 2;

  const handleBlur = () => {
    if (!disabled) { onBlur?.(); }
  };

  const handleListDisplay = () => {
    if (disabled || isSingleItem) { return; }
    setOptionsDisplay(!isOptionsDisplay);
  };

  const handleListDisplayKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleListDisplay();
    }
  };

  const handleOptionClick = (event: MouseEvent<HTMLLIElement | SVGSVGElement> | KeyboardEvent<HTMLLIElement>) => {
    const target = event.currentTarget;
    const selected = target.getAttribute('data-name');
    setSelectedOption(selected as unknown as T);
    setOptionsDisplay(!isOptionsDisplay);
  };

  const handleOptionKeyDown = (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      handleOptionClick(e);
    }
  };

  const handleClickOutside = () => {
    setOptionsDisplay(false);
  };

  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (selectedOption) {
      onChange?.(selectedOption);
    }
  }, [selectedOption]);

  const optionsLayout = optionsList.map((option) => (
    <li
      className="selector__option-item"
      data-name={option}
      key={option as unknown as string}
      onClick={handleOptionClick}
      onKeyDown={handleOptionKeyDown}
      tabIndex={isOptionsDisplay ? 0 : 1}
      role="button"
    >
      {type === 'currency' && (
        <CurrencyOption isLoading={false} value={option as unknown as Currency} />
      )}
      {type === 'payment-system' && (
        <PaymentSystemOption value={option as unknown as PaymentSystemType} />
      )}
    </li>
  ));

  return (
    <>
      {showError && <ErrorBlock isDisplayed={isTouched && !isOptionsDisplay} message={error?.message} />}
      <div ref={wrapperRef} className={cn('selector', `selector--${type}`)}>
        <div
          className={cn(
            'selector__selected-option',
            { 'selector__selected-option--single': isSingleItem },
            { 'selector__selected-option--active': isOptionsDisplay },
            showError && isTouched && !!error && 'selector__selected-option--invalid',
            showError && isTouched && !error && 'selector__selected-option--valid',
            { 'selector__selected-option--disabled': disabled }
          )}
          ref={ref}
          onClick={handleListDisplay}
          onKeyDown={handleListDisplayKeyDown}
          role="button"
          onBlur={handleBlur}
          tabIndex={0}
          key={name}
        >
          {type === 'currency' && (
            <CurrencyOption isLoading={isLoading} value={value as unknown as Currency} isActive />
          )}
          {type === 'payment-system' && (
            <PaymentSystemOption value={value as unknown as PaymentSystemType} isActive />
          )}
        </div>
        {isOptionsDisplay && (
          <ul className="selector__options-list">
            {optionsLayout}
          </ul>
        )}
        {!isSingleItem && !disabled && (
          <CaretIcon
            onClick={handleOptionClick}
            className={cn(
              'currency-selector__caret-icon',
              { 'currency-selector__caret-icon--rotated': isOptionsDisplay }
            )}
          />
        )}
      </div>
    </>
  );
};

const SelectorWithRef = React.forwardRef(Selector);
export default SelectorWithRef;
