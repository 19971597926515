import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import * as React from 'react';

import TextInput from 'components/shared/inputs/TextInput/TextInput';
import PasswordInput from 'components/shared/inputs/PasswordInput/PasswordInput';

import MoneyInput from '../inputs/MoneyInput/MoneyInput';
import PhoneInput from '../inputs/PhoneInput/PhoneInput';

import type { Currency } from 'types/wallets-data';

export type InputType = 'text' | 'password' | 'money' | 'phone';

type Props<TFormValues extends FieldValues> = {
  type: InputType;
  disabled?: boolean;
  showError?: boolean;
  placeholder?: string;
  autoComplete?: string;
  currency?: Currency | null;
  variant?: 'default' | 'inverted';
  CustomOnChange?: (value: unknown) => void;
} & UseControllerProps<TFormValues>;

const FormInput = <TFormValues extends Record<string, unknown>>({
  type,
  showError,
  disabled,
  placeholder,
  autoComplete,
  currency,
  variant,
  ...props
}: Props<TFormValues>) => {
  const { field, fieldState } = useController(props);
  const { value } = field;

  return (
    <div>
      {type === 'text' && (
        <TextInput
          {...field}
          value={value as string}
          type={type}
          disabled={disabled}
          placeholder={placeholder ?? field.name}
          showError={showError}
          error={fieldState.error}
          isTouched={fieldState.isTouched}
          autoComplete={autoComplete}
        />
      )}
      {type === 'password' && (
        <PasswordInput
          {...field}
          value={value as string}
          type={type}
          disabled={disabled}
          placeholder={placeholder ?? field.name}
          showError={showError}
          error={fieldState.error}
          isTouched={fieldState.isTouched}
          autoComplete={autoComplete}
        />
      )}
      {type === 'money' && (
        <MoneyInput
          {...field}
          value={value as number | ''}
          disabled={disabled}
          placeholder={placeholder ?? field.name}
          showError={showError}
          error={fieldState.error}
          isTouched={fieldState.isTouched}
          autoComplete={autoComplete}
          currency={currency}
          variant={variant}
        />
      )}
      {type === 'phone' && (
        <PhoneInput
          {...field}
          value={value as string}
          disabled={disabled}
          placeholder={placeholder ?? field.name}
          showError={showError}
          error={fieldState.error}
          isTouched={fieldState.isTouched}
          autoComplete={autoComplete}
        />
      )}
    </div>
  );
};

export default FormInput;
