import { useTranslation } from 'react-i18next';

import TopEventsWidgetMobile from 'components/line/TopEventsWidget/TopEventsWidgetMobile/TopEventsWidgetMobile';
import { useGetEventsListQuery } from 'store/line/lineApi';
import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { batgamesList, twainSportList } from 'utils/constants';
import { useGetInbetGamesQuery } from 'store/slots/slotsApi';
import { useAppSelector } from 'hooks';
import { selectIsInbetActive, seletIsBetgameActive, seletIsLineActive } from 'store/app/selectores';

import MainNav from '../components/MainNav/MainNav';
import BannerSlider from '../components/BannerSlider/BannerSlider';

// import CasinoWidget from 'components/casino/CasinoWidget/CasinoWidget';
// import PromotionsWidget from 'components/promotions/PromotionsWidget/PromotionsWidget';

const HomeMobile = () => {
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const isBetgameActive = useAppSelector(seletIsBetgameActive);
  const isLineActive = useAppSelector(seletIsLineActive);
  const liveEvents = useGetEventsListQuery({ type: 'live', count: 99 }, { skip: !isLineActive });
  const lineEvents = useGetEventsListQuery({ type: 'line', count: 99 }, { skip: !isLineActive });
  const slotsList = useGetInbetGamesQuery({ gameType: 'slot' }, { skip: !isInbetActive });
  const { t } = useTranslation();

  return (
    <main className="home-page">
      <MainNav />
      <BannerSlider />
      {/* <PromotionsWidget /> */}
      <TopEventsWidgetMobile
        title={t('top-events-widget.top-live', 'Топ live матч')}
        dataType='live'
        iconName='live'
        iconColor='purple'
        allLink="/live"
        isLoading={liveEvents.isLoading || !liveEvents.isSuccess}
        events={liveEvents.data || []}
        isAvailable={isLineActive}
        notAvailableMessage='Спортивные события временно недоступны'
      />
      <TopEventsWidgetMobile
        title={t('top-events-widget.top-line', 'Топ линия матч')}
        dataType='line'
        iconName='fire'
        iconColor='orange'
        allLink="/line"
        isLoading={lineEvents.isLoading || !lineEvents.isSuccess}
        events={lineEvents.data || []}
        isAvailable={isLineActive}
        notAvailableMessage='Спортивные события временно недоступны'
      />
      {/* <CasinoWidget /> */}
      <GamesCardsWidgetMobile
        title="BetGames TV"
        iconColor="orange"
        iconName='coin'
        items={batgamesList}
        linkToAll={isBetgameActive ? '/games?filter=BetGames+TV' : ''}
        isAvailable={isBetgameActive}
        notAvailableMessage='Игры временно недоступны'
      />
      <GamesCardsWidgetMobile
        title="Twain Sport"
        iconColor="blue"
        iconName='cup'
        items={twainSportList}
        linkToAll={isBetgameActive ? '/games?filter=Twain+Sport' : ''}
        isAvailable={isBetgameActive}
        notAvailableMessage='Игры временно недоступны'
      />
      <GamesCardsWidgetMobile
        title="Inbet Games"
        iconColor="pink"
        iconName='coin-flat'
        itemsCounter={slotsList.data?.length}
        items={slotsList.data?.slice(0, 50) ?? []}
        linkToAll={isInbetActive ? '/games?filter=Inbet+Games+Slots' : ''}
        isLoading={slotsList.isLoading || !slotsList.isSuccess}
        isAvailable={isInbetActive}
        notAvailableMessage='Игры временно недоступны'
      />
    </main>
  );
};

export default HomeMobile;
