import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { paymentsApi } from './paymentsApi';
import { BackendError } from '../auth/utils';

import type {
  AvailableTopUpMethodsObject,
  AvailableWithdrawalMethodsObject,
  InitError,
  TopUpMethod,
  PaymentsModalType,
  InitResponse,
} from 'types/payments-data';
import type { PaymentsState } from 'types/state';


const initialState : PaymentsState = {
  availableTopUpMethods: {},
  availableWithdrawalMethods: {},
  paymentUrl: null,
  initError: null,
  activeModal: null,
  activeTopUpMethod: null,
};

export const paymentsData = createSlice({
  name: NameSpace.Payments,
  initialState,
  reducers: {
    changeActiveModal: (state, action: PayloadAction<PaymentsModalType | null>) => {
      state.activeModal = action.payload;
      state.initError = null;
    },
    changeActiveTopupMethod: (state, action: PayloadAction<{
      id: TopUpMethod;
      title: string;
    } | null>) => {
      state.activeTopUpMethod = action.payload;
    },
    paymentLinkOpened: (state) => {
      state.paymentUrl = null;
    },

  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        paymentsApi.endpoints.getWithdrawalMethods.matchFulfilled,
        (state, action: PayloadAction<AvailableWithdrawalMethodsObject>) => {
          state.availableWithdrawalMethods = action.payload;
        }
      )

      .addMatcher(
        paymentsApi.endpoints.getTopupMethods.matchFulfilled,
        (state, action: PayloadAction<AvailableTopUpMethodsObject>) => {
          state.availableTopUpMethods = action.payload;
        }
      )

      .addMatcher(
        paymentsApi.endpoints.initTopUp.matchFulfilled,
        (state, action: PayloadAction<InitResponse>) => {
          state.activeModal = null;
          state.paymentUrl = action.payload.paymentUrl;
        },
      )
      .addMatcher(
        paymentsApi.endpoints.initTopUp.matchRejected,
        (state, action) => {
          if (!action.payload) { return; }
          const data = action.payload.data as InitError;
          state.initError = data.detail ?? data;
        },
      )
      .addMatcher(
        paymentsApi.endpoints.initWithdrawal.matchFulfilled,
        (state, action: PayloadAction<InitResponse>) => {
          state.activeModal = 'success';
          state.initError = null;
        },
      )
      .addMatcher(
        paymentsApi.endpoints.initWithdrawal.matchRejected,
        (state, action) => {
          if (!action.payload) { return; }
          const data = action.payload.data as BackendError;
          state.initError = Array.isArray(data.detail) ? data.detail[0]?.msg : 'Ошибка';
        },
      );
  },
});

export const {
  paymentLinkOpened,
  changeActiveModal,
  changeActiveTopupMethod,
} = paymentsData.actions;
