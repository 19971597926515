import { api } from 'store/api';

import { AppStatus } from './appSlice';
import { transformAppStatus } from './convert';


export type AppStatusResponse = {
  system: boolean;
  line: boolean;
  betgames: boolean;
  tgg: boolean;
  inbet: boolean;
  withdrawals: boolean;
  status_message: {
    enable: boolean;
    message: string;
  };
}

export const appApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAppStatus: build.query<AppStatus, void>({
      query: () => '/v1/status',
      transformResponse: transformAppStatus
    })
  })
});

export const {
  useGetAppStatusQuery
} = appApi;
