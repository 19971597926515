import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCountriesQuery } from 'store/line/lineApi';
import Loader from 'components/shared/loader/Loader';
// import { DesktopFilterType } from 'components/line/DesktopLine/DesktopLine';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setCountry, setTournament } from 'store/line/lineSlice';
import { selectActiveSport, selectCountry } from 'store/line/selectors';
import { topCountriesId } from 'utils/constants';
import { sortByTopEntries } from 'utils/common';

import SideBarTournamentsDesktop from '../SideBarTournamentsDesktop/SideBarTournamentsDesktop';
import SideBarCountryItemDesktop from './SideBarCountryItemDesktop';

import type { LineType } from 'types/line-data';

import './SideBarCountryDesktop.scss';

type Props = {
  lineType: LineType;
};

const SideBarCountryDesktop = ({ lineType }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeSport = useAppSelector(selectActiveSport);
  const activeCountry = useAppSelector(selectCountry);
  const { data: countryes, isFetching } = useGetCountriesQuery({
    sportId: activeSport ?? '0',
    type: lineType
  });

  const filteredSportByTopCountry = sortByTopEntries(countryes ?? [], topCountriesId, (country) => country.slug);

  useEffect(() => {
    dispatch(setCountry('0'));
  }, [activeSport]);

  const onButtonCLick = (id: string) => (
    id === activeCountry ? (
      dispatch(setCountry('0')),
      dispatch(setTournament('0'))
    ) : dispatch(setCountry(id))
  );

  return (
    <>
      <div>
        {activeCountry && activeCountry !== '0'
          && (
            <SideBarTournamentsDesktop
              lineType={lineType}
            />
          )}
      </div>
      <div className="side-bar-country-desktop">
        <h2>{t('bets-page.countries', 'Страны')}</h2>
        <div className="side-bar-country-desktop__list">
          {isFetching
            ? <Loader />
            : filteredSportByTopCountry.map((country) => (
              <SideBarCountryItemDesktop
                key={country.slug}
                item={country}
                isActive={activeCountry === country.slug}
                onClick={() => onButtonCLick(country.slug)}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default SideBarCountryDesktop;
