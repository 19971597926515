import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useWalletsQuery } from 'store/user/userApi';
import { useAppSelector } from 'hooks';
import { selectAccountData, selectPrimaryWallet } from 'store/user/selectors';
import Button from 'components/shared/buttons/Button/Button';
import SupportIcon from 'components/shared/SupportIcon/SupportIcon';
import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';

import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as MenuIcon } from '../assets/Menu.svg';
import './HeaderAuthorized.scss';

const b = bemCn('main-header-authorized');

const HeaderAuthorized = () => {
  const { t } = useTranslation();
  const [lastVisitedPage, setRoute] = useState('');
  const [isHeaderTranslated, setIsHeaderTranslated] = useState(false);

  const accountData = useAppSelector(selectAccountData);
  const { amount, currency } = useAppSelector(selectPrimaryWallet);
  const { isLoading: isWalletsLoading } = useWalletsQuery();

  const roundedAmount = Math.floor((amount * 100)) / 100;

  const navigate = useNavigate();
  const location = useLocation();

  const { id: userId } = accountData;

  const onMenuClick = () => {
    setIsHeaderTranslated(isHeaderTranslated);
    setRoute(location.pathname);
    navigate('/profile');
  };

  const onCloseMenuClick = () => {
    setIsHeaderTranslated(!isHeaderTranslated);
    setRoute('');
  };

  const profileRe = /^\/profile/;

  const isProfilePage = profileRe.test(location.pathname);

  if (!isHeaderTranslated && isProfilePage) {
    setIsHeaderTranslated(true);
  }

  if (isHeaderTranslated && !isProfilePage) {
    setIsHeaderTranslated(false);
  }

  return (
    <div className={b()}>
      <div className={b('container')}>
        <header className={cn(
          'main-header-authorized__slider',
          'main-header-authorized__translate-anim',
          { 'main-header-authorized__translate-header': isHeaderTranslated }
        )}
        >
          <div className={b('left-container')}>
            <MenuIcon className={b('menu-icon')} onClick={onMenuClick} />
          </div>
          <div className={b('right-container')}>
            <div className={b('user-info')}>
              {isWalletsLoading ? <Loader /> : (
                <>
                  <div className={b('user-id')}>{`ID: ${userId}`}</div>
                  <div className={b('user-balance')}>{`${roundedAmount} ${currency ?? ''}`}</div>
                </>
              )}
            </div>
            <Link to="/profile/topups" className={b('top-up-btn-wrapper')}>
              <Button size="small" animated>
                {t('user-nav.topup', 'Пополнить')}
              </Button>
            </Link>
            <SupportIcon className={b('support-icon')} />
            <Link
              className={b('close-icon-wrapper', { displayed: isHeaderTranslated })}
              to={lastVisitedPage}
              onClick={onCloseMenuClick}
            >
              <CloseIcon className={b('close-icon')} />
            </Link>
          </div>
        </header>
      </div>
    </div>
  );
};

export default HeaderAuthorized;
