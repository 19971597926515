import { bemCn } from 'utils/bem-cn';

import SvgIcon from '../SvgIcon/SvgIcon';

import './FavoriteBtn.scss';

type Props = {
  className?: string;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
}

const FavoriteBtn = (props: Props) => {
  const b = bemCn('favorite-btn');
  return (
    <button className={b({ 'active': props.active }, props.className)}
      type='button'
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <SvgIcon className={b('icon')}
        name='star'
        width={14}
        height={14}
      />
    </button>
  );
};

export default FavoriteBtn;
