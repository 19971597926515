
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { getDateTimeSlashFormat } from 'utils/time';
import { getCoefTitle } from 'store/line/utils';
import { useAppDispatch } from 'hooks';
import { addBet } from 'store/basket/basketSlice';
import { routes } from 'utils/routes';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { bemCn } from 'utils/bem-cn';

import CardBetItem from './EventCardBetItem';

import type { Bet, EventItem, LineType } from 'types/line-data';

import './EventCardMobile.scss';

type Props = {
  basketItem?: string | null;
  event: EventItem;
  simple?: boolean;
  className?: string;
  lineType: LineType;
}


const EventCardMobile = (props: Props) => {
  const {
    event,
    simple,
    className,
    lineType,
    basketItem,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const timeMinutes = event.timer ? `${Math.floor(event.timer / 60)} ${t('event.time-minute', 'мин.')}` : '';
  const isTimeShow = lineType === 'live' && (event.periodName || timeMinutes);

  const handleFavoriteClick = () => {
    // ? Когда будет реализация добавления в избранное?
  };

  const handleBetClick = (bet: Bet) => {
    const newBet = {
      eventId: event.id,
      betId: bet.betId,
      coef: bet.rate,
      team1: event.team1,
      team2: event.team2,
      groupName: `${event.group.name} : ${bet.caption}`,
      tournament: event.tournament,
      lineType,
    };
    dispatch(addBet(newBet));
  };

  const b = bemCn('event-card-mobile');
  return (
    <div className={b({ simple: simple }, className)}>
      <Link className={b('link')}
        to={`${routes.fullevent.path}/${event.id}/${lineType}`}
      >
        <div className={b('meta')}>
          <p className={b('date')}>
            {getDateTimeSlashFormat(event.eventDate)}
          </p>
          {isTimeShow && (
            <p className={b('time')}>
              <SvgIcon className={b('time-icon')}
                name='clock'
                width={11}
                height={11}
              />
              <span className={b('time-period')}>
                {timeMinutes} {event.periodName}
              </span>
            </p>
          )}
          <FavoriteBtn
            className={b('favorite')}
            onClick={handleFavoriteClick}
            disabled
          />
        </div>
        <div className={b('teams')}>
          <p className={b('team')}>{event.team1}</p>
          <p className={b('team')}>{event.team2}</p>
        </div>
        <div className={b('meta-sport')}>
          <p className={b('sport')}>{event.sportName}</p>
          <p className={b('tournament')}>{event.tournament}</p>
        </div>
        <div className={b('bets')}>
          {event.group.name === 'stopped'
            ? <EmptyBets />
            : event.group.bets.slice(0, 3).map((bet) => (
              <CardBetItem
                key={bet.betId}
                rate={bet.rate}
                title={getCoefTitle(bet.caption, event.team1, event.team2)}
                isActive={basketItem === bet.betId}
                onClick={() => handleBetClick(bet)}
              />
            ))}
        </div>
      </Link>
    </div>
  );
};

const EmptyBets = () => (
  <>
    <CardBetItem title='-' onClick={() => undefined} />
    <CardBetItem title='-' onClick={() => undefined} />
    <CardBetItem title='-' onClick={() => undefined} />
  </>
);

export default React.memo(EventCardMobile);
