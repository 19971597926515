import React from 'react';
import Modal from 'react-modal';

import CloseIcon from 'components/shared/icons/CloseIcon/CloseIcon';
import ModalsHead from 'components/profile/ModalsHead/ModalsHead';
import WalletsManagement from 'components/profile/components/WalletsManagement/WalletsManagement';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import OtherWallets from 'components/Layout/Header/DesktopHeaderAuthorized/DesktopHeaderBalance/components/OtherWallets/OtherWallets';
import { bemCn } from 'utils/bem-cn';

import AddWalletForm from '../components/AddWalletForm/AddWalletForm';
import './WalletManageModal.scss';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  contentClassName?: string;
  overlayClassName?: string;
};

const WalletManageModal = (props: Props) => {
  const { modalIsOpen, closeModal, contentClassName, overlayClassName } = props;
  const { amount, currency } = useAppSelector(selectPrimaryWallet);

  const b = bemCn('wallet-manage-modal');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className={b(null, contentClassName)}
      overlayClassName={overlayClassName}
    >
      <div>
        <CloseIcon closeModal={closeModal} />
      </div>
      <ModalsHead title="Управление счетами" />
      <div>
        <div className={b('main')}>
          <WalletsManagement
            balance={amount}
            currency={currency}
            isWalletsLoading={false}
          />
        </div>
        <div className={b('other')}>
          <OtherWallets />
        </div>
        <div className={b('add')}>
          <AddWalletForm />
        </div>
      </div>
    </Modal>
  );
};

export default WalletManageModal;
