import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useCouponsQuery, useFullCouponsInfoQuery } from 'store/user/userApi';
import { useAppSelector } from 'hooks';
import { selectCouponById } from 'store/user/selectors';
import { bemCn } from 'utils/bem-cn';

import HistoryDetails from './FullHistoryDetails/HistoryDetails';
import './FullHistory.scss';

const FullHistory = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const coupon = useAppSelector((state) => selectCouponById(state, id));
  useCouponsQuery({});
  const { data, isFetching: isBetsLoading, isError } = useFullCouponsInfoQuery([id as string]);

  if (isError) {
    return <Navigate to="/bet-history" />;
  }

  const b = bemCn('full-history');
  return (
    <div className={b()}>
      <Breadcrumbs link="/bet-history" title={t('bet-history.title', 'История ставок')} />
      <h3 className={b('header')}>{t('bet-history.title', 'История ставок')}</h3>
      <div className={b('content')}>
        {coupon && (
          <HistoryDetails
            key={coupon.id}
            id={coupon.id}
            type={coupon.type}
            winAmount={coupon.winAmount}
            created={coupon.created}
            status={coupon.status}
            betAmount={coupon.betAmount}
            currency={coupon.currency}
            bets={data?.[coupon.remoteId]?.bets}
            isBetsLoading={isBetsLoading}
            expectedWinAmount={data?.[coupon.remoteId]?.expectedWinAmount}
            remoteId={coupon.remoteId}
          />
        )}
      </div>
    </div>
  );
};

export default FullHistory;
