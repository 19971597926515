import React from 'react';

import Adaptive from 'components/Layout/Adaptive/Adaptive';

import HomeMobile from './HomeMobile/HomeMobile';
import HomeDesktop from './HomeDesktop/HomeDesktop';

const Home = () => (
  <Adaptive
    desktop={<HomeDesktop />}
    mobile={<HomeMobile />}
  />
);

export default Home;
