import { bemCn } from 'utils/bem-cn';

type Props = {
  onClick: () => void;
  title: string;
  icon: string;
  className?: string;
}

const SiteSettingsLangBtn = (props: Props) => {
  const b = bemCn('site-settings');
  return (
    <button
      className={b('button', { lang: true }, props.className)}
      type="button"
      onClick={props.onClick}
    >
      {props.title.toLocaleUpperCase()}
      <img className="site-settings__button-icon" src={props.icon} width="15" height="15" alt='' />
    </button>
  );
};

export default SiteSettingsLangBtn;
