import React from 'react';

import './Container.scss';

type Props = {
  children: React.ReactNode;
};

const Container = ({ children }: Props) => (
  <div className="app-container">
    {children}
  </div>
);

export default Container;
