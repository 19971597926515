import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authApi } from 'store/auth/authApi';
import { NameSpace } from 'utils/constants';

import {
  deleteToken,
  handleLoginErrors,
  handleRegisterErrors,
  handleRestoreErrors,
  setToken,
} from './utils';
import { userApi } from '../user/userApi';

import type { BackendError, ShortError } from './utils';
import type { AuthState } from 'types/state';
import type { AuthModalType, LoginErrors, RegisterErrors } from 'types/auth-data';

export const initialLoginError:LoginErrors = {
  email: null,
  password: null,
  common: null
};

export const initialRegisterError:RegisterErrors = {
  email: null,
  password: null,
  name: null,
  common: null
};

const initialState: AuthState = {
  token: null,
  isAuthenticated: false,
  activeModal: null,
  loginError: initialLoginError,
  registerError: initialRegisterError,
  restoreError: null,
  passwordResetId: null,
};

export const authData = createSlice({
  name: NameSpace.Auth,
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      deleteToken();
    },
    changeActiveModal: (state, action: PayloadAction<AuthModalType | null>) => {
      state.activeModal = action.payload;
    },
    openChangePasswordModal: (state, action: PayloadAction<string>) => {
      state.passwordResetId = action.payload;
      state.activeModal = 'change-password';
    },
  },
  extraReducers: (builder) => {
    builder

    /* Login */
      .addMatcher(authApi.endpoints.login.matchPending, (state) => {
        state.loginError = initialLoginError;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const token = action.payload.access_token;
        state.activeModal = null;
        state.token = token;
        setToken(token);
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as BackendError;
        const status = action.payload.status as number;
        // state.loginError = handleLoginErrors(status, data);
        state.loginError = handleLoginErrors(status, data);
      })

    /*  Registration */
      .addMatcher(authApi.endpoints.emailRegister.matchPending, (state) => {
        state.registerError = initialRegisterError;
      })
      .addMatcher(authApi.endpoints.emailRegister.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as BackendError;
        const status = action.payload.status as number;
        state.registerError = handleRegisterErrors(status, data);
      })
      .addMatcher(authApi.endpoints.emailRegister.matchFulfilled, (state) => {
        state.activeModal = 'confirm-email';
      })

    /*  Email Verify */
      .addMatcher(authApi.endpoints.emailVerify.matchFulfilled, (state) => {
        state.activeModal = 'success';
      })
      .addMatcher(authApi.endpoints.emailVerify.matchPending, (state) => {
        state.activeModal = null;
      })
      .addMatcher(authApi.endpoints.emailVerify.matchRejected, (state) => {
        state.activeModal = 'error';
      })

      /*  Reset Password Request */
      .addMatcher(authApi.endpoints.resetPassword.matchFulfilled, (state) => {
        state.activeModal = 'success-send-reset-link';
      })
      .addMatcher(authApi.endpoints.resetPassword.matchPending, (state) => {
        state.activeModal = null;
        state.restoreError = null;
      })
      .addMatcher(authApi.endpoints.resetPassword.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        state.activeModal = 'reset-error';
        const data = action.payload.data as ShortError;
        const status = action.payload.status as number;
        state.restoreError = handleRestoreErrors(status, data);
      })

      /*  Change Password */
      .addMatcher(authApi.endpoints.changePassword.matchFulfilled, (state) => {
        state.activeModal = 'reset-success';
      })
      .addMatcher(authApi.endpoints.changePassword.matchPending, (state) => {
        state.activeModal = null;
      })
      .addMatcher(authApi.endpoints.changePassword.matchRejected, (state) => {
        state.activeModal = 'reset-error';
      })

    /*  Authentication test */
      .addMatcher(userApi.endpoints.account.matchFulfilled, (state) => {
        state.isAuthenticated = true;
      })
      .addMatcher(userApi.endpoints.account.matchRejected, (state, action) => {
        if (action.payload && action.payload.status === 401) {
          state.token = null;
          deleteToken();
        }
      });
  },
});

export const {
  logout,
  changeActiveModal,
  openChangePasswordModal,
} = authData.actions;

export { initialState as initialAuthState };
