import React from 'react';
import cn from 'classnames';

import { ReactComponent as IconWorld } from 'assets/flags/flag-world.svg';
import { getCountryIcon, getCountryIconByCode } from 'store/line/data';

import './Flag.scss';

type Props = {
  countryId?: string;
  countryCode?: string;
  className?: string;
  width?: number;
  height?: number;
};

const Flag = (props: Props) => {
  const {
    countryId,
    countryCode,
    className,
    width = 18,
    height = 18,
  } = props;
  let icon = '';
  if (countryId) {
    icon = getCountryIcon(countryId);
  } else if (countryCode) {
    icon = getCountryIconByCode(countryCode);
  }

  return (
    <img
      className={cn('flag', className)}
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/flags/${icon}.svg`}
      alt=""
      loading="lazy"
    />
  );

  // return <IconWorld className={cn('flag', className)} width={width} height={height} />;

  // return (
  //   <svg className={cn('flag', className)} width={width} height={height}>
  //     <use xlinkHref={`#flag-${icon}`}></use>
  //   </svg>
  // );
};

export default Flag;
