
export type MaintenanceMessage = {
  title: string;
  description: string;
}

const DefaultMaintenanceMessage = {
  title: 'Технические работы',
  description: 'Сайт временно недоступен, ведётся техническое обслуживание. Приносим свои извинения за доставленные неудобства. =('
};

const LineMaintenanceMessage = {
  title: 'Технические работы',
  description: 'Спортивные события временно недоступны, ведётся техническое обслуживание. Приносим свои извинения за доставленные неудобства. =('
};

const GamesMaintenanceMessage = {
  title: 'Технические работы',
  description: 'Игры временно недоступны, ведётся техническое обслуживание. Приносим свои извинения за доставленные неудобства. =('
};

const WithdrawalsMaintenanceMessage = {
  title: 'Технические работы',
  description: 'Вывод средст временно недоступен, ведётся техническое обслуживание. Приносим свои извинения за доставленные неудобства. =('
};

export const Maintenance = {
  default: DefaultMaintenanceMessage,
  line: LineMaintenanceMessage,
  games: GamesMaintenanceMessage,
  withdrawals: WithdrawalsMaintenanceMessage
};
