import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';

import './NotFound.scss';

const NotFounded = () => {
  const { t } = useTranslation();
  return (
    <div className="not-founded">
      <div className="not-founded__icon" />
      <div className="not-founded__title">
        <h3>{t('not-found.title', 'Страница не найдена')}</h3>
        <p>{t('not-found.text-1', 'Упс...Что-то пошло не так.')}</p>
        <p>{t('not-found.text-2', 'Страница, которую вы искали, не найдена')}</p>
      </div>
      <Link className="not-founded__to-home" to="/home">
        <Button>На главную</Button>
      </Link>
    </div>
  );
};

export default NotFounded;
