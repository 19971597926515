import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { getCurrencySymbol } from 'store/wallets/constants';
import Button from 'components/shared/buttons/Button/Button';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { selectInitError, selectTopupMethodDetails } from 'store/payment/selectors';
import SumSuggestButton from 'components/shared/buttons/SumSuggestButton/SumSuggestButton';
import { useInitTopUpMutation } from 'store/payment/paymentsApi';
import FormSelect from 'components/shared/form/FormSelect';
import { defaultLimits, defaultSystem, suggestValues } from 'store/payment/constants';

import type { Currency } from 'types/wallets-data';
import type { PaymentSystem, TopUpMethod } from 'types/payments-data';

import './TopupForm.scss';

const getSuggestions = (currency: Currency): number[] => suggestValues[currency] ?? suggestValues.USD;

type FormFields = {
  amount: number | '';
  system: PaymentSystem | null;
};

type Props = {
  activeMethod: TopUpMethod;
  currency: Currency;
};

const schema = ({ min, max }: { min: number; max: number }) => yup.object({
  amount: yup
    .number()
    .min(min, `${i18n.t('topups.modal.errors.min-amount', 'Минимальная сумма пополнения')} - ${min}`)
    .max(max, `${i18n.t('topups.modal.errors.max-amount', 'Максимальная сумма пополнения')} - ${max}`)
    .required('Введите сумму пополнения')
    .typeError(`${i18n.t('topups.modal.errors.empty-amount', 'Введите сумму')}`),
  system: yup.string().required(),
}).required();

const TopupForm = ({ activeMethod, currency }: Props) => {
  const { t } = useTranslation();
  const [init, { isLoading }] = useInitTopUpMutation();
  const initError = useAppSelector(selectInitError);

  const { systems } = useAppSelector((state) => selectTopupMethodDetails(state, activeMethod));

  const systemsList: PaymentSystem[] = Object.keys(systems);

  const initSystem = defaultSystem in systems ? defaultSystem : systemsList[0];

  const { handleSubmit, setValue, control } = useForm<FormFields>({
    defaultValues: { amount: '', system: initSystem },
    mode: 'all',
    resolver: yupResolver(schema(systems[defaultSystem].limits[currency] ?? defaultLimits)),
  });

  const setAmount = (value: number) => {
    setValue('amount', value, { shouldValidate: true, shouldTouch: true });
  };

  const handleSumClick = useCallback((suggestAmount: number) => () => {
    if (!isLoading) { setAmount(suggestAmount); }
  }, [currency]);

  const suggestButtonsLayout = currency && getSuggestions(currency).map((suggestAmount) => (
    <SumSuggestButton
      key={suggestAmount}
      onClick={handleSumClick(suggestAmount)}
      amount={suggestAmount}
      currency={currency}
    />
  ));

  const handleLoginClick = async ({ amount, system, ...responseData }: FormFields) => {
    if (!amount || !system || !currency) { return; }

    await init({
      ...responseData,
      amount,
      system,
      method: activeMethod,
    });
  };

  const minLimit = systems[defaultSystem]?.limits[currency]?.min ?? '1 000';
  const maxLimit = systems[defaultSystem]?.limits[currency]?.max ?? '50 000';

  return (
    <div>
      <ErrorBlock isDisplayed message={initError} align="center" />
      <div className="topup-modal__system-input-wrapper">
        <FormSelect<FormFields, PaymentSystem>
          name="system"
          options={systemsList}
          isLoading={isLoading}
          control={control}
          disabled={isLoading}
          type="payment-system"
        />
        {systemsList.length > 1 && (
          <p className="topup-modal__additional-methods-info">
            {t('topups.modal.choose-method-description', 'Воспользуйтесь запасным способом оплаты, если у Вас не получилось пополнить счет основным способом.')}
          </p>
        )}
      </div>
      <div className="topup-modal__fields">
        <FormInputWrapper<FormFields>
          showError
          type="money"
          control={control}
          name="amount"
          placeholder={`${t('topups.modal.amount', 'Сумма')}`}
          disabled={isLoading}
          currency={currency}
        />
        <div className="topup-modal__input-info">
          <p>{t('topups.modal.limit-description', 'Сумма одного депозита')}</p>
          <p>От {minLimit} {getCurrencySymbol(currency)} до {maxLimit} {getCurrencySymbol(currency)}</p>
        </div>
      </div>

      <div className="topup-modal__suggestions">
        {suggestButtonsLayout}
      </div>

      <div className="topup-modal__shared-group">
        <div className="topup-modal__buttons-sections">
          <div className="topup-modal__button-wrapper">
            <Button onClick={handleSubmit(handleLoginClick)} isLoading={isLoading}>
              {t('topups.modal.submit-btn', 'Пополнить')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopupForm;
