import { Navigate, Route, Routes } from 'react-router-dom';

import BetHistoryMobile from 'components/profile/BetHistory/BetHistoryMobile/BetHistoryMobile';
import BetHistoryDesktop from 'components/profile/BetHistory/BetHistoryDesktop/BetHistoryDesktop';
import FullHistory from 'components/profile/FullHistoryItem/FullHistory';
import Adaptive from 'components/Layout/Adaptive/Adaptive';

const BetHistoryPage = () => (
  <Routes>
    <Route index
      element={
        <Adaptive
          desktop={<BetHistoryDesktop />}
          mobile={<BetHistoryMobile />}
        />
      }
    />
    <Route path='/:id'
      element={
        <Adaptive
          desktop={<Navigate to="/bet-history" />}
          mobile={<FullHistory />}
        />
      }
    />
  </Routes>
);

export default BetHistoryPage;
