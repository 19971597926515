import { Link } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';


export type LiltItem = {
  name: string;
  link?: string;
  href?: string;
};

type Props = {
  item: LiltItem;
}

const FooterNavItem = ({ item }: Props) => {
  const b = bemCn('footer-nav');
  return (
    <li className={b('item')}>
      {item.link && (
        <Link to={item.link} className={b('link')}>{item.name}</Link>
      )}
      {item.href && (
        <a className={b('link')}
          href={item.href}
          target='_blank'
          rel="noreferrer"
        >
          {item.name}
        </a>
      )}
    </li>
  );
};

export default FooterNavItem;
