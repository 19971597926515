import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import { selectActiveModal } from 'store/auth/selectors';
import { selectUserGeo } from 'store/user/selectors';
import { logoText, telegramLink } from 'utils/constants';
import Button from 'components/shared/buttons/Button/Button';
import AuthModalsGroup from 'components/auth/AuthModalsGroup/AuthModalsGroup';
import Flag from 'components/shared/Flag/Flag';
import Container from 'components/Layout/Container/Container';
import MainNav from 'pages/Home/components/MainNav/MainNav';
import SiteSettings from 'components/Layout/PageFooter/components/SiteSettings/SiteSettings';

import supportIcon from './assets/supportIcon.svg';
import DesktopHeaderNoAuthorizedSingle from '../DesktopHeaderNoAuthorizedSingle/DesktopHeaderNoAuthorizedSingle';
import DesktopHeaderAuthorized from '../DesktopHeaderAuthorized/DesktopHeaderAuthorized';

import './DesktopHeader.scss';

type Props = {
  isAuthenticated: boolean;
};

const DesktopHeader = ({ isAuthenticated }: Props) => {
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(selectActiveModal);
  const userGeo = useAppSelector(selectUserGeo);

  return (
    <header className="main-header-no-authorized">
      <Container>
        <div className="main-header-no-authorized__top">
          <div className="main-header-no-authorized-desktop__left">
            <Link to="/home">
              <h2 className="main-header-no-authorized__logo desktop-header__logo">
                {logoText}
                {userGeo.country && <Flag countryCode={userGeo.country} />}
              </h2>
            </Link>
            <MainNav />
          </div>
          <div className="main-header-authorized__buttons-group">
            <div className="main-header-no-authorized-desktop__support">
              <Button variant="shaded">
                <a href={telegramLink}>
                  <img src={supportIcon} alt="" />
                </a>
              </Button>
            </div>
            {!isAuthenticated
              ? <DesktopHeaderNoAuthorizedSingle />
              : <DesktopHeaderAuthorized />}
            <SiteSettings below />
          </div>
        </div>
        <AuthModalsGroup
          activeModal={activeModal}
          closeModal={useCallback(() => dispatch(changeActiveModal(null)), [])}
        />
      </Container>
    </header>
  );
};

export default DesktopHeader;
