import React, { useEffect } from 'react';
import { useToggle } from 'usehooks-ts';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardMobile from 'components/line/EventCard/EventCardMobile/EventCardMobile';
import { useAppSelector } from 'hooks';
import { selectBasketItems } from 'store/basket/selectors';
import { bemCn } from 'utils/bem-cn';

import type { EventItem, LineType } from 'types/line-data';

import './TournamentCardMobile.scss';

type Props = {
  className?: string;
  sportName: string;
  tournament: string;
  closed: boolean;
  onClose: () => void;
  events: EventItem[];
  lineType: LineType;
}

const TournamentCardMobile = (props: Props) => {
  const {
    className,
    closed,
    onClose,
    events,
    sportName,
    tournament,
    lineType
  } = props;
  const [isClosed, toggleClosed, setClosed] = useToggle(closed);
  const basketItems = useAppSelector(selectBasketItems);

  useEffect(() => {
    if (closed !== isClosed) {
      setClosed(closed);
    }
  }, [closed]);

  const handleClosedToggle = () => {
    toggleClosed();
    onClose();
  };

  const b = bemCn('tournament-card-mobile');

  return (
    <div className={b({ 'closed': isClosed }, className)}>
      <button className={b('head')}
        type="button"
        onClick={handleClosedToggle}
      >
        <span className={b('sport')}>{sportName}</span>
        <span className={b('title')}>{tournament}</span>
        <SvgIcon className={b('close-icon')}
          name="chevron-down"
          width={10} height={10}
        />
      </button>
      {!isClosed && (
        <div className={b('events')}>
          {events.map((event) => (
            <EventCardMobile
              className={b('event')}
              key={event.id}
              event={event}
              lineType={lineType}
              basketItem={basketItems[event.id]?.betId}
              simple
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(TournamentCardMobile);
