import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

// import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import i18n from 'i18n/i18n';
import FormInputWrapper from 'components/shared/form/FormInput';
import Button from 'components/shared/buttons/Button/Button';
import { useTransferMutation } from 'store/transfer/transferApi';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import { useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';

import type { Currency } from 'types/wallets-data';

type FormFields = {
  amount: number | '';
  send_to: string;
};

type Props = {
  balance: number;
  currency: Currency | null;
};

const schema = ({ min, max, balance, userId }: { min: number; max: number; balance: number; userId: string }) => yup
  .object({
    amount: yup
      .number()
      .min(min, `${i18n.t('transfer.modal.errors.min-amount', 'Минимальная сумма перевода')} ${min}`)
      .max(max, `${i18n.t('transfer.modal.errors.max-amount', 'Максимальная сумма перевода')} ${max}`)
      .test('balanceTest', `${i18n.t('transfer.modal.errors.zero-amount', 'Перевод должен быть больше 0')}`, (value) => (value ? value > 0 : false))
      .test('balanceTest', `${i18n.t('transfer.modal.errors.balance', 'Не достаточно средств')}`, (value) => (value ? value <= balance : false))
      .required(`${i18n.t('transfer.modal.errors.empty-amount', 'Введите сумму перевода')}`)
      .typeError('Введите сумму'),
    'send_to': yup
      .string().required(`${i18n.t('transfer.modal.errors.empty-user-id', 'Введите ID')}`)
      .test('idTest', `${i18n.t('transfer.modal.errors.self-user-id', 'Вы не можете переводить самому себе')}`, (value) => (value !== userId)),
  })
  .required();

const TransferForm = ({ balance, currency }: Props) => {
  const { t } = useTranslation();
  const { id: userId } = useAppSelector(selectAccountData);
  const [transferSuccess, setTransferSuccess] = useState<boolean>(false);
  const { handleSubmit, control } = useForm<FormFields>({
    defaultValues: { 'amount': '', 'send_to': '' },
    mode: 'all',
    resolver: yupResolver(
      schema({
        min: 0,
        max: balance,
        balance,
        userId
      }),
    ),
  });

  const [sendTransfer, { isError: isSendError }] = useTransferMutation();
  const handleTransferClick = async (transfer: FormFields) => {
    await sendTransfer(transfer);
    setTransferSuccess(true);
  };

  return (
    <div>
      <div className="transfer-model__fields">
        <FormInputWrapper<FormFields>
          showError
          type="text"
          control={control}
          name="send_to"
          placeholder={`${t('transfer.modal.sentd-to', 'ID пользователя')}`}
          currency={currency}
        />
      </div>
      <div className="transfer-model__fields">
        <FormInputWrapper<FormFields>
          showError
          type="money"
          control={control}
          name="amount"
          placeholder={`${t('transfer.modal.amount', 'Сумма')}`}
          currency={currency}
        />
        <div className="transfer-model__input-info">
          <p>{t('transfer.modal.max-amount', 'Максимальная сумма перевода')}</p>
          <p>{balance} {currency}</p>
        </div>
      </div>
      <div className="transfer-model__shared-group">
        <div className="transfer-model__buttons-sections">
          <div className="transfer-model__button-wrapper">
            <Button onClick={handleSubmit(handleTransferClick)}>
              {t('transfer.modal.submit-btn', 'Перевести')}
            </Button>
          </div>
        </div>
      </div>
      <ResultModal
        title={
          isSendError
            ? t('result-modal.transfer.error.title', 'Произошла ошибка во время перевода')
            : t('result-modal.transfer.success.title', 'Перевод проведен успешно')
        }
        status={isSendError ? 'error' : 'success'}
        modalIsOpen={transferSuccess}
        closeModal={() => setTransferSuccess(false)}
      />
    </div>
  );
};

export default TransferForm;
