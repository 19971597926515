import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { getCurrencyTitleWithoutAbbreviation } from 'store/wallets/constants';
import { getBalanceDiffType, getTransactionTitle } from 'store/user/utils';
import { getDateTimeSlashFormat } from 'utils/time';

import type { Currency } from 'types/wallets-data';
import type { Transaction } from 'types/user-data';

import './Transactions.scss';

type Props = {
  transaction: Transaction;
  primaryCurrency?: Currency | null;
};

const TransactionItem = (props: Props) => {
  const {
    primaryCurrency,
    transaction,
  } = props;

  const {
    id,
    type,
    currency,
    created,
    amount,
  } = transaction;

  const diffType = getBalanceDiffType(type);
  const { t } = useTranslation();

  return (
    <div className="transaction-item">
      <div className="transaction-item__row">
        <h2>{getTransactionTitle(type)}</h2>
        <p>{getDateTimeSlashFormat(created)}</p>
      </div>
      <div className="transaction-item__row">
        <p>{t('transactions.account', 'Счет')}: {getCurrencyTitleWithoutAbbreviation(currency)}</p>
        <h2
          className={cn(
            'transaction-item__amount',
            diffType && `transaction-item__amount--${diffType}`,
          )}
        >
          {diffType === 'increase' && '+'}
          {diffType === 'decrease' && '-'}
          {`${amount} ${currency}`}
        </h2>
      </div>
    </div>
  );
};

export default TransactionItem;
