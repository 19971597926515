import React, { MouseEventHandler } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';
import Loader from 'components/shared/loader/Loader';
import { ReactComponent as PlacedIcon } from 'assets/bets/inProcess.svg';
import { ReactComponent as WinIcon } from 'assets/bets/win.svg';
import { ReactComponent as LossIcon } from 'assets/bets/defeat.svg';
import { getCurrencySymbol } from 'store/wallets/constants';
import { domainName } from 'utils/constants';
import { getDateTimeSlashFormat } from 'utils/time';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import BetDetails from './BetDetails/BetDetails';

import type { BetStatus, PlaceType, BetInfo } from 'types/user-data';
import type { Currency } from 'types/wallets-data';

import './HistoryDetails.scss';

type Props = {
  id: number;
  created: string;
  type: PlaceType;
  currency: Currency;
  betAmount: number;
  winAmount?: number | null;
  bets?: BetInfo[];
  isBetsLoading: boolean;
  status: BetStatus;
  expectedWinAmount?: number;
  remoteId: string;
};

const typeTitles: Record<PlaceType, string> = {
  ordinar: i18n.t('bet-history.single', 'Ординар'),
  express: i18n.t('bet-history.express', 'Экспресс'),
};

export const statusTitles = {
  placed: i18n.t('bet-history.not-calculated', 'Не рассчитано'),
  win: i18n.t('bet-history.win', 'Выигрыш'),
  loss: i18n.t('bet-history.loss', 'Проигрыш'),
  refund: i18n.t('bet-history.refund', 'Возврат')
};

export const statusIcons = {
  placed: <PlacedIcon />,
  win: <WinIcon />,
  loss: <LossIcon />,
  refund: <WinIcon />
};

const HistoryDetails = (props: Props) => {
  const {
    id,
    created,
    betAmount,
    currency,
    type,
    winAmount,
    bets,
    isBetsLoading,
    status,
    expectedWinAmount,
  } = props;
  const [copiedValue, copyBarCode] = useCopyToClipboard();
  const { t } = useTranslation();
  const isOrdinar = type === 'ordinar';

  const isWin = status === 'win';
  const isPlaced = status === 'placed';
  const isLoss = status === 'loss';
  const isRefund = status === 'refund';

  const coef = Array.isArray(bets) && isOrdinar ? bets[0].coef : bets?.reduce<number>((acc, item) => item.coef * acc, 1);

  const totalCoef = (coef ?? 0);

  const expectedAmountLayout = expectedWinAmount
    && `${expectedWinAmount.toFixed(2)} ${getCurrencySymbol(currency)}`;

  const handleBarCodeCopyClick: MouseEventHandler = (evt) => {
    evt.preventDefault();
    copyBarCode(`${id}`);
  };

  const b = bemCn('history-details');
  return (
    <div className={b()}>
      <p className={b('status', { [`${status}`]: Boolean(status) })}>
        {statusTitles[status] ?? 'Проигрыш'}
        {statusIcons[status]}
      </p>
      <div className={b('wrapper')}>
        <div className={b('content')}>
          <div className={b('header')}>
            <div className={b('row', b('place-type-wrapper'))}>
              <h2 className={b('place-type')}>{typeTitles[type]}</h2>
              {totalCoef && <CoefDisplay value={totalCoef.toFixed(2)} variant="secondary" />}
            </div>
            <div className={b('row')}>
              <p className={b('title')}>{getDateTimeSlashFormat(created)}</p>
              <button className={b('bar-code')}
                type='button'
                onClick={handleBarCodeCopyClick}
              >
                № {id} <SvgIcon className={b('bar-code-icon')} name='copy' width={13} height={13} />
              </button>
            </div>
          </div>
          <div className={b('header')}>
            <div className={b('site')}>{domainName}</div>
            <div className={b('row', b('row-bet'))}>
              <p className={b('bet')}>
                {t('bet-history.bet', 'Ставка')}:
              </p>
              <div>
                {betAmount
                  ? <h2>{`${betAmount} ${getCurrencySymbol(currency)}`}</h2>
                  : <PlacedIcon />}
              </div>
            </div>
            <div className={b('row', { [`${status}`]: Boolean(status) })}>
              <h2 className={b('win-amount-title')}>
                {isPlaced
                  ? t('bet-history.possible-prize', 'Возможный выигрыш')
                  : isRefund
                    ? t('bet-history.refund', 'Возврат')
                    : t('bet-history.prize', 'Выигрыш')}
              </h2>
              <p className={b('win-amount')}>
                {isPlaced && expectedAmountLayout}
                {(isWin || isLoss) && `${winAmount ?? 0} ${getCurrencySymbol(currency)}`}
                {isRefund && `${betAmount} ${getCurrencySymbol(currency)}`}
              </p>
            </div>
          </div>
          <div className={b('bets')}>
            {isBetsLoading
              ? <Loader />
              : bets?.map((bet, index) => (
                <BetDetails
                  key={bet.barcode}
                  bet={bet}
                  isOrdinar={isOrdinar}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryDetails;
