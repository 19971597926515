import React from 'react';

import { bemCn } from 'utils/bem-cn';
import './PersonalInfo.scss';

type Props = {
  name: string;
  userId: string;
};

const PersonalInfo = ({ name, userId }: Props) => {
  const b = bemCn('personal-info');
  return (
    <div className={b()}>
      <div className={b('avatar')}>
        <h3 className={b('avatar-literals')}>
          {name.substring(0, 1)}
        </h3>
      </div>
      <div className={b('info-wrapper')}>
        <h4 className={b('personal-name')}>
          {name}
        </h4>
        <p className={b('user-id')}>
          {`ID: ${userId}`}
        </p>
      </div>
    </div>
  );
};

export default PersonalInfo;
