import { useTranslation } from 'react-i18next';

import Logotype from 'components/shared/Logotype/Logotype';
import SocialList from 'components/shared/SocialList/SocialList';
import DownloadAppButton from 'components/shared/DownloadAppButton/DownloadAppButton';
import { logoText, getFooterItems, socialLinks, downloadApp } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';

import FooterNav from './components/FooterNav/FooterNav';
import PageFooterPayments from './components/PageFooterPayments';
import PageFooterRatingSites from './components/PageFooterRatingSites';
import PageFooterSportOrganisations from './components/PageFooterSportOrganisations';
import SiteSettings from './components/SiteSettings/SiteSettings';

import './PageFooter.scss';

const PageFooter = () => {
  const { t } = useTranslation();
  const b = bemCn('page-footer');
  return (
    <footer className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top-wrapper')}>
          <Logotype className={b('logo')} />
          <FooterNav className={b('nav')} navItems={getFooterItems()} />
          <div className={b('download-app')}>
            <DownloadAppButton type='ios' link={downloadApp.ios} />
            <DownloadAppButton type='android' link={downloadApp.android} />
          </div>
          <PageFooterSportOrganisations />
        </div>

        <div className={b('center-wrapper')}>
          <PageFooterPayments />

          <div className={b('small-wrapper')}>
            <PageFooterRatingSites />
            <SiteSettings className={b('site-settings')} />
          </div>
        </div>

        <div className={b('bottom-wrapper')}>
          <p className={b('copyright')}>
            &copy;&nbsp;2023&nbsp;{logoText} {t('copyright')}
          </p>
          <SocialList className={b('social')} items={socialLinks} />
        </div>

      </div>
    </footer>
  );
};
export default PageFooter;
