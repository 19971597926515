import { Link } from 'react-router-dom';
import { MouseEventHandler } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { bemCn } from 'utils/bem-cn';
import './GamesCard.scss';

export type GameCardItemType = {
  id?: number | string;
  name: string;
  preview: string;
  link: string;
}

type Props = {
  className?: string;
  item: GameCardItemType;
}

const GamesCard = ({ className, item }: Props) => {
  const { name, preview, link } = item;
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const handleCardClick: MouseEventHandler<HTMLAnchorElement> = (evt) => {
    if (!isAuthenticated) {
      evt.preventDefault();
      dispatch(changeActiveModal('sign-in'));
    }
  };

  const b = bemCn('games-card');

  return (
    <div className={b(null, className)}>
      <Link className={b('link')} to={link} onClick={handleCardClick}>
        <div className={b('thumbnail')}>
          <img className={b('image')}
            src={preview}
            width={120}
            alt=""
            loading='lazy'
          />
        </div>
        <p className={b('name')}>{name}</p>
      </Link>
    </div>
  );
};

export default GamesCard;
