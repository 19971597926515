import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

import { transformEvent, transformFullEvent, transformTournaments } from './converters';

import type {
  CountryItem,
  EventItem,
  FullEventItem,
  ServerEventItem,
  SportItem,
  TournamentItem,
  TournamentsParams,
  CountriesParams,
  EventsListParams,
  EventDetailsParams,
  TournamentCollection
} from 'types/line-data';

export const lineApi = api.injectEndpoints({
  endpoints: (build) => ({

    getLineStatus: build.query<unknown, void>({
      query: () => APIRoute.LineStatus,
    }),

    getLiveSports: build.query<SportItem[], void>({
      query: () => ({
        url: APIRoute.LineSports,
        params: {
          'lang': 'ru',
          'data_type': 'live',
        }
      }),
      keepUnusedDataFor: 0,
    }),

    getLineSports: build.query<SportItem[], void>({
      query: () => ({
        url: APIRoute.LineSports,
        params: {
          'lang': 'ru',
          'data_type': 'line',
        }
      }),
      keepUnusedDataFor: 0,
    }),

    getCountries: build.query<CountryItem[], CountriesParams>({
      query: ({ sportId = '0', type } : CountriesParams) => ({
        url: APIRoute.LineCountries,
        params: {
          'lang': 'ru',
          'data_type': type,
          'sport': sportId
        }
      }),
    }),

    getTournaments: build.query<TournamentCollection, TournamentsParams>({
      query: ({ sportId = '0', countryId = '0', type }: TournamentsParams) => ({
        url: APIRoute.LineTournaments,
        params: {
          'lang': 'ru',
          'data_type': type,
          'sport': sportId,
          'country': countryId
        }
      }),
      transformResponse: (response: TournamentItem[]): TournamentCollection =>
        transformTournaments(response),
      keepUnusedDataFor: 0,
    }),

    getEventsList: build.query<EventItem[], EventsListParams>({
      query: ({ sportId = '0', tournamentId = '0', type, count = 1000 }: EventsListParams) => ({
        url: APIRoute.LineEvents,
        params: {
          'lang': 'ru',
          'data_type': type,
          'sport': sportId,
          'tournament': tournamentId,
          'page_length': count
        }
      }),
      transformResponse: (response: ServerEventItem[]): EventItem[] =>
        response.map((event) => transformEvent(event)),
      keepUnusedDataFor: 0,
    }),

    getEventDetails: build.query<FullEventItem, EventDetailsParams>({
      query: ({ eventId, type }: EventDetailsParams) => ({
        url: APIRoute.LineEvent,
        params: {
          'lang': 'ru',
          'data_type': type,
          'event': eventId,
        }
      }),
      transformResponse: (event : ServerEventItem): FullEventItem => transformFullEvent(event),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLineStatusQuery,
  useGetLiveSportsQuery,
  useGetLineSportsQuery,
  useGetCountriesQuery,
  useGetTournamentsQuery,
  useGetEventsListQuery,
  useGetEventDetailsQuery,
} = lineApi;
