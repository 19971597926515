import i18n from 'i18n/i18n';
import tKickPreview from 'assets/twain-sport/twain-sport-t-kick.jpg';
import tBasketPreview from 'assets/twain-sport/twain-sport-t-basket.jpg';
import betGamesPreviews from 'assets/betgames';

import type { GameCardItemType } from 'components/shared/GamesCardsWidget/GamesCard/GamesCard';

export enum NameSpace {
  BaseApi = 'baseApi',
  App = 'app',
  Auth = 'auth',
  Basket = 'basket',
  Line = 'line',
  Payments = 'payments',
  Slots = 'slots',
  User = 'user',
  Wallets = 'wallets',
}

export const availableLang = ['ru', 'uk'];

export type Social = 'vk' | 'telegram' | 'ok' | 'gmail' | 'yandex' | 'mail' | 'steam';
export type SignUpMethod = Social | 'fast';
export const availableSocial : Social[] = ['vk', 'telegram', 'ok', 'gmail', 'yandex', 'mail', 'steam'];

export const telegramLink = 'https://t.me/boompari_com';
export const domainName = window.location.host;
export const logoText = 'BOOMPARI';
export const TRANSACTIONS_ITEMS_PER_PAGE = 20;
export const BETS_ITEMS_PER_PAGE = 10;
export const COUPON_ITEMS_PER_PAGE = 10;

export const footerNavItems = [
  {
    groupTitle: i18n.t('footer.nav.title.Information', 'Информация'),
    items: [
      {
        name: i18n.t('footer.nav.item.Rules', 'Правила'),
        link: '/rules'
      },
      {
        name: i18n.t('footer.nav.item.Bonus and actions', 'Бонусы и Акции'),
        link: '/bonus'
      },
    ]
  },
  {
    groupTitle: i18n.t('footer.nav.title.Categories', 'Категории'),
    items: [
      {
        name: i18n.t('footer.nav.item.Live', 'Live'),
        link: '/live'
      },
      {
        name: i18n.t('footer.nav.item.Line', 'Линия'),
        link: '/line'
      },
      // {
      //   name: 'Слоты',
      //   link: '/slots'
      // },
    ]
  },
];

export const getFooterItems = () => [
  {
    groupTitle: i18n.t('footer.nav.title.Information', 'Информация'),
    items: [
      {
        name: i18n.t('footer.nav.item.Rules', 'Правила'),
        link: '/rules'
      },
      {
        name: i18n.t('footer.nav.item.Bonus and actions', 'Бонусы и Акции'),
        link: '/bonus'
      },
    ]
  },
  {
    groupTitle: i18n.t('footer.nav.title.Categories', 'Категории'),
    items: [
      {
        name: i18n.t('footer.nav.item.Live', 'Live'),
        link: '/live'
      },
      {
        name: i18n.t('footer.nav.item.Line', 'Линия'),
        link: '/line'
      },
      // {
      //   name: 'Слоты',
      //   link: '/slots'
      // },
    ]
  },
];

export const socialLinks = [
  { type: 'telegram', link: 'https://t.me/boompari' },
  { type: 'vkontakte', link: 'https://vk.com/boompari' },
  // { type: 'instagram', link: '' },
];

export const downloadApp = {
  ios: undefined,
  android: 'https://drive.google.com/file/d/1zJEBgwf-Wjc5WIzN82LQ4ErBpmUG9X4E/view?usp=sharing'
};

export const topTournamentsID = [
  '88637', // Чемпионат Англии. Премьер-лига
  '12821', // Чемпионат Франции. Первая лига
  '110163', // Чемпионат Италии. Серия А
  '127733', // Чемпионат Испании. Примера
  '96463', // Чемпионат Германии. Бундеслига
  '30619', // NHL
  '1246295', // NHL. Награды
  '1364013', // NHL. Итоги чемпионата
  '1890662', // NHL. Специальные ставки
  '2535190',
  '466371',
  '534531',
  '2549929',
  '1189035',
  '1190421',
  '2402865',
  '2543195',
  '2549933',
  '7456',
  '120925',
  '2549403',
  '59629',
  '2543176',
  '1501475',
  '1503583',
  '2549402'
];

export const topCountriesId = [
  '225', // Мир
  '223', // Европа
  '231', // Англия
  '198', // Франция
  '79', // Италия
  '78', // Испания
  '53', // Германия
  '1', // Россия
  '2', // Украина
];

export const topSportsId = [
  '1', // Футбол
  '2', // Баскетбол
  '3' // Хоккей
];

export const batgamesList: GameCardItemType[] = [
  {
    id: 5,
    name: i18n.t('games.betgames.Bet-On-Poker', 'Покер'),
    preview: betGamesPreviews.poker,
    link: '/games/betgames/5',
  },
  {
    id: 17,
    name: i18n.t('games.betgames.Football-Grid', 'Пенальти'),
    preview: betGamesPreviews.football,
    link: '/games/betgames/17',
  },
  {
    id: 7,
    name: i18n.t('games.betgames.Wheel-Of-Fortune', 'Колесо Фортуны'),
    preview: betGamesPreviews.wheel,
    link: '/games/betgames/7',
  },
  {
    id:12,
    name: i18n.t('games.betgames.6+-Poker', '6+ Покер'),
    preview: betGamesPreviews.poker6,
    link: '/games/betgames/12',
  },
  {
    id:13,
    name: i18n.t('games.betgames.Andar-Bahar', 'Андар Бахар'),
    preview: betGamesPreviews.anderBaha,
    link: '/games/betgames/13',
  },
  {
    id: 10,
    name: i18n.t('games.betgames.Dice-Duel', 'Дуэль Костей'),
    preview: betGamesPreviews.diceDuel,
    link: '/games/betgames/10',
  },
  {
    id: 11,
    name: i18n.t('games.betgames.Speedy-7', 'Горячая 7'),
    preview: betGamesPreviews.speedy7,
    link: '/games/betgames/11',
  },
  {
    id: 8,
    name: i18n.t('games.betgames.War-Of-Bets', 'Битва Ставок'),
    preview: betGamesPreviews.warOfBets,
    link: '/games/betgames/8',
  },
  {
    id: 6,
    name: i18n.t('games.betgames.Bet-On-Baccarat', 'Баккара'),
    preview: betGamesPreviews.baccarat,
    link: '/games/betgames/6',
  },
  {
    id: 16,
    name: i18n.t('games.betgames.Classic-Wheel', 'Колесо Фортуны Классическое'),
    preview: betGamesPreviews.wheelRNG,
    link: '/games/betgames/16',
  },
  {
    id: 18,
    name: i18n.t('games.betgames.Satta-Matka', 'Сатта Матка'),
    preview: betGamesPreviews.sattaMatka,
    link: '/games/betgames/18',
  },
  {
    id: 3,
    name: i18n.t('games.betgames.Lucky-5', '5 из 36'),
    preview: betGamesPreviews.lucky5,
    link: '/games/betgames/3',
  },
  {
    id: 9,
    name: i18n.t('games.betgames.Lucky-6', '6 из 60'),
    preview: betGamesPreviews.lucky6,
    link: '/games/betgames/9',
  },
  {
    id: 1,
    name: i18n.t('games.betgames.Lucky-7', '7 из 42'),
    preview: betGamesPreviews.lucky7,
    link: '/games/betgames/1',
  },
];

export const twainSportList: GameCardItemType[] = [
  {
    id: 24,
    name: i18n.t('games.betgames.T-Kick-24/7', 'Футбол'),
    preview: tKickPreview,
    link: '/games/twain-sport/24',
  },
  {
    id: 25,
    name: i18n.t('games.betgames.T-Basket-24/7', 'Баскетбол'),
    preview: tBasketPreview,
    link: '/games/twain-sport/25',
  },
];
