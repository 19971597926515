import React, { useState } from 'react';

import TournamentsGroupDesktop from 'components/line/TournamentsGroupDesktop/TournamentsGroupDesktop';
import Loader from 'components/shared/loader/Loader';
import SearchFilter from 'components/shared/SearchFilter/SearchFilter';
import { useAppSelector } from 'hooks';
import { selectActiveSport, selectEvents } from 'store/line/selectors';
import { useGetEventsListQuery } from 'store/line/lineApi';

import DesktopLiveBanners from '../DesktopLiveBanners/DesktopLiveBanners';

import type { LineType } from 'types/line-data';

type Props = {
  lineType: LineType;
};

const DesktopLineList = ({ lineType }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const activeSport = useAppSelector(selectActiveSport);

  let allEvents = useAppSelector(selectEvents);

  const { data, isLoading } = useGetEventsListQuery(
    {
      sportId: activeSport ?? '0',
      type: lineType
    },
    { pollingInterval: lineType === 'live' ? 1000 : 100000 },
  );

  if (searchValue.length > 0 && data) {
    allEvents = data.filter((item) =>
      item.team1.toLowerCase().includes(searchValue.toLowerCase())
      || item.team2.toLowerCase().includes(searchValue.toLowerCase()));
  }

  const handleSearchChange = (newSearchValue: string) => {
    setSearchValue(newSearchValue);
  };

  return (
    <>
      <DesktopLiveBanners />
      <div className="desktop-live__search">
        <SearchFilter
          openSearch
          onChange={handleSearchChange}
        />
      </div>
      <div className="desktop-live__list">
        {(!allEvents || isLoading)
          ? <Loader />
          : (
            <TournamentsGroupDesktop
              events={allEvents}
              lineType={lineType}
            />
          )}
      </div>
    </>
  );
};

export default DesktopLineList;
