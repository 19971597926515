import { useNavigate } from 'react-router-dom';

import bannerImg from 'assets/banners/banner.png';
import banner2Img from 'assets/banners/banner2.png';
import banner3Img from 'assets/banners/banner3.png';
import bannerRUMob1 from 'assets/banners/bannerRUMob1.jpg';
import bannerUAMob1 from 'assets/banners/bannerUAMob1.jpg';
import bannerKZMob1 from 'assets/banners/bannerKZMob1.jpg';
import bannerDesk1 from 'assets/banners/bannerDesk1.png';
import bannerDesk2 from 'assets/banners/bannerDesk2.png';
import bannerDesk3 from 'assets/banners/bannerDesk3.png';
import bannerRUDesk1 from 'assets/banners/bannerRUDesk1.jpg';
import bannerUADesk1 from 'assets/banners/bannerUADesk1.jpg';
import bannerUADesk2 from 'assets/banners/bannerUADesk2.jpg';
import bannerKZDesk1 from 'assets/banners/bannerKZDesk1.jpg';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { selectAccountLoading, selectPrimaryWalletCurrency, selectUserGeo } from 'store/user/selectors';
import { useWalletsQuery } from 'store/user/userApi';
import { changeActiveModal } from 'store/auth/authSlice';

const BannersListMobile: Record<string, string[]> = {
  'DEFAULT': [bannerRUMob1, bannerImg, banner2Img, banner3Img],
  'RU': [bannerRUMob1, bannerImg, banner2Img, banner3Img],
  'RUB': [bannerRUMob1, bannerImg, banner2Img, banner3Img],
  'UA': [bannerUAMob1, bannerImg, banner2Img, banner3Img],
  'UAH': [bannerUAMob1, bannerImg, banner2Img, banner3Img],
  'KZ': [bannerKZMob1, bannerImg, banner2Img, banner3Img],
  'KZT': [bannerKZMob1, bannerImg, banner2Img, banner3Img],
};

const BannersListDesktop:Record<string, {big: string[]; small: string[]}> = {
  'DEFAULT': {
    big: [bannerRUDesk1, bannerDesk1],
    small: [bannerDesk2, bannerDesk3],
  },
  'RU': {
    big: [bannerRUDesk1, bannerDesk1],
    small: [bannerDesk2, bannerDesk3],
  },
  'RUB': {
    big: [bannerRUDesk1, bannerDesk1],
    small: [bannerDesk2, bannerDesk3],
  },
  'UA': {
    big: [bannerUADesk2, bannerDesk1],
    small: [bannerDesk2, bannerDesk3],
  },
  'UAH': {
    big: [bannerUADesk2, bannerDesk1],
    small: [bannerDesk2, bannerDesk3],
  },
  'KZ': {
    big: [bannerKZDesk1, bannerDesk1],
    small: [bannerDesk2, bannerDesk3],
  },
  'KZT': {
    big: [bannerKZDesk1, bannerDesk1],
    small: [bannerDesk2, bannerDesk3],
  },
};

export const useLocalizedBanners = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAccaountLoading = useAppSelector(selectAccountLoading);
  const userGeo = useAppSelector(selectUserGeo);
  const userCurrency = useAppSelector(selectPrimaryWalletCurrency);
  const wallets = useWalletsQuery(undefined, {skip: !isAuthenticated || isAccaountLoading });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const onSlideClick = (link: string) => {
    if (isAuthenticated) {
      navigate(link);
      return;
    }
    dispatch(changeActiveModal('sign-up'));
  };

  const banersListMobile = BannersListMobile[userCurrency as string]
  || BannersListMobile[userGeo.country as string]
    || BannersListMobile.DEFAULT;

  const banersListDesktop = BannersListDesktop[userCurrency as string]
  || BannersListDesktop[userGeo.country as string]
  || BannersListDesktop.DEFAULT;

  return {
    isLoading: isAccaountLoading || userGeo.isLoading || wallets.isLoading,
    bannersMobile: banersListMobile,
    bannersDesktop: banersListDesktop,
    onSlideClick
  };
};
