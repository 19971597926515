import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useEmailRegisterMutation } from 'store/auth/authApi';
import { selectCurrencies, selectIsCurrenciesLoading } from 'store/wallets/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { selectRegisterError } from 'store/auth/selectors';
import Button from 'components/shared/buttons/Button/Button';
import FormCheckbox from 'components/shared/form/FormCheckbox';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import Tabs, { TabType } from 'components/shared/Tabs/Tabs';
import CloseIcon from 'components/shared/icons/CloseIcon/CloseIcon';
import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';

import EmailTab from './components/EmailTab/EmailTab';
import AddPromoCode from './components/AddPromocode/AddPromoCode';

import type { RegisterRequest, RegisterErrors } from 'types/auth-data';
import type { Entries } from 'types/common';
import type { Currency } from 'types/wallets-data';

import './SignUp.scss';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  contentClassName?: string;
  overlayClassName?: string;
};

const TabsIds = {
  email: 1,
  oneClick: 2,
  telegram: 3,
  phone: 4,
} as const;

const tabs: TabType[] = [
  {
    title: 'E-mail',
    id: TabsIds.email,
    disabled: false,
  },
  {
    title: 'В 1 клик',
    id: TabsIds.oneClick,
    disabled: true,
  },
  {
    title: 'Telegram',
    id: TabsIds.telegram,
    disabled: true,
  },

  {
    title: 'Телефон',
    id: TabsIds.phone,
    disabled: true,
  },
];

export type FormFields = {
  name: string;
  email: string;
  currency: Currency | null;
  password: string;
  checked: boolean;
};

const currencyFilter = (currencies: string[]) => currencies.filter((currency) => currency !== 'BTC' && currency !== 'BNB' && currency !== 'ETH' && currency !== 'DEMO');

const schema = yup.object({
  email: yup.string()
    .email(`${i18n.t('reg.modal.errors.wrong-email', 'Введите корректный E-mail')}`)
    .required(`${i18n.t('reg.modal.errors.empty-email', 'Введите E-mail')}`),
  name: yup.string()
    .min(3, `${i18n.t('reg.modal.errors.short-user-name', 'Недостаточно символов')}`)
    .required(`${i18n.t('reg.modal.errors.empty-user-name', 'Введите ФИО')}`),
  password: yup.string()
    .min(3, `${i18n.t('reg.modal.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reg.modal.errors.empty-pass', 'Введите пароль')}`),
  checked: yup.bool()
    .oneOf([true], `${i18n.t('reg.modal.errors.check-agreement', 'Подтвердите согласие')}`),
  currency: yup.string()
    .required(`${i18n.t('reg.modal.errors.empty-currency', 'Выберите валюту')}`)
    .typeError('Выберите валюту'),
}).required();

const SignUp = (props: Props) => {
  const { modalIsOpen, closeModal, contentClassName, overlayClassName } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState<number>(1);
  const availableCurrencies = useAppSelector(selectCurrencies);
  const filteredAvailableCurrencies = currencyFilter(availableCurrencies);
  const isCurrenciesLoading = useAppSelector(selectIsCurrenciesLoading);
  const registerErrors = useAppSelector(selectRegisterError);

  const handleLoginClick = () => {
    dispatch(changeActiveModal('sign-in'));
  };

  const [register, { isLoading }] = useEmailRegisterMutation();

  const onRegSubmit = async (data: FormFields) => {
    const { currency, email, name, password, checked } = data;
    if (currency && checked) {
      const registerData: RegisterRequest = {
        currency,
        email,
        name,
        password,
      };
      const refCode = localStorage.getItem('refCode');
      const clickId = localStorage.getItem('clickId');

      await register({
        ...registerData,
        'ref_code': refCode ? refCode : undefined,
        'click_id': clickId ? clickId : undefined,
      });
    }
  };

  const { handleSubmit, setError, formState: { touchedFields, errors }, control } = useForm<FormFields>({
    defaultValues: { email: '', password: '', currency: null, checked: false, name: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fieldsErrors: Partial<RegisterErrors> = { ...registerErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<Omit<RegisterErrors, 'common'>>;
    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [registerErrors]);

  const b = bemCn('registration-modal');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Example Modal"
      className={b(null, contentClassName)}
      overlayClassName={overlayClassName}
    >
      <form className={b('content')} onSubmit={handleSubmit(onRegSubmit)}>
        <div className={b('close-section')}>
          <CloseIcon closeModal={closeModal} />
        </div>

        <div className={b('header')}>
          <h1 className={b('header-title')}>
            {t('reg.modal.title', 'Регистрация')}
          </h1>
          <p className={b('header-description')}>
            {t('reg.modal.description', 'Выберите удобный способ регистрации')}
          </p>
        </div>

        <ErrorBlock isDisplayed={!!registerErrors.common} message={registerErrors.common} />

        <Tabs tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId}>
          <EmailTab
            control={control}
            id={TabsIds.email}
            availableCurrencies={filteredAvailableCurrencies}
            isCurrenciesLoading={isCurrenciesLoading}
            isLoading={isLoading}
          />
        </Tabs>

        <div className={b('shared-group')}>
          <AddPromoCode />
          <div className={b('accept-rules-wrapper')}>
            <ErrorBlock isDisplayed={touchedFields.checked} message={errors.checked?.message} align="left" />
            <div className={b('accept-rules')}>
              <div className={b('checkbox-wrapper')}>
                <FormCheckbox<FormFields>
                  showError
                  control={control}
                  name="checked"
                  disabled={isLoading}
                />
              </div>

              <p className={b('accept-text')}>
                {t('reg.modal.agreement', 'Я согласен на обработку персональных данных и согласен с')}
                <Link to="/privacy-policy" className={b('privacy-policy-link')}>
                  {t('reg.modal.agreement-privacy', 'политикой конфиденциальности')}
                </Link>
              </p>
            </div>
          </div>

          <div className={b('buttons-sections')}>
            <div className={b('button-wrapper')}>
              <Button
                type='submit'
                isLoading={isLoading}
              >
                <span>{t('reg.modal.submit-btn', 'Зарегистрироваться')}</span>
              </Button>
            </div>
            <div className={b('button-wrapper')}>
              <Button
                onClick={handleLoginClick}
                variant="outline-primary-inverted"
              >
                {t('reg.modal.sign-in', 'Войти')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SignUp;
