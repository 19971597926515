import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { bemCn } from 'utils/bem-cn';

import ThemePicker from './components/ThemePicker/ThemePicker';

import './Settings.scss';

const Settings = () => {
  const { t } = useTranslation();
  const b = bemCn('profile-settings');
  return (
    <div className={b()}>
      <Breadcrumbs link="/profile" title={t('main.nav.Profile', 'Личный кабинет')} />
      <h3 className={b('header')}>
        {t('settings.edit-profile', 'Редактировать профиль')}
      </h3>
      <h3 className={b('section-header')}>
        {t('settings.site-theme', 'Цвет сайта')}
      </h3>
      <ThemePicker />
    </div>
  );
};

export default Settings;
