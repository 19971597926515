import React from 'react';

import { ReactComponent as OperatorIcon } from 'assets/paymentSystems/operator.svg';
import { ReactComponent as VisaIcon } from 'assets/paymentSystems/visa.svg';
import { ReactComponent as MastercardIcon } from 'assets/paymentSystems/mastercard-colored.svg';
import { ReactComponent as QiwiIcon } from 'assets/paymentSystems/qiwi.svg';
import { ReactComponent as WebmoneyIcon } from 'assets/paymentSystems/webmoney.svg';
import { ReactComponent as UmoneyIcon } from 'assets/paymentSystems/umoney.svg';
import { ReactComponent as SbpIcon } from 'assets/paymentSystems/sbp.svg';
import { ReactComponent as PrivatBankIcon } from 'assets/paymentSystems/privatbank.svg';
import simImg from 'assets/paymentSystems/sim12.png';
import vsibankiImg from 'assets/paymentSystems/vsi-banki.png';

import type { WithdrawalMethod, TopUpMethod } from 'types/payments-data';

const iconsComponents: Record<string, (key: string) => JSX.Element> = {
  visa: (key) => <VisaIcon key={key} className="payment-system__icon payment-system__icon--visa" />,
  mastercard: (key) => <MastercardIcon key={key} className="payment-system__icon" />,
  qiwi: (key) => <QiwiIcon key={key} className="payment-system__icon" />,
  mobile: (key) => <img alt="mobile" key={key} src={simImg} className="payment-system__icon" />,
  webmoney: (key) => <WebmoneyIcon key={key} className="payment-system__icon" />,
  umoney: (key) => <UmoneyIcon key={key} className="payment-system__icon" />,
  operator: (key) => <OperatorIcon key={key} className="payment-system__icon" />,
  sbp: (key) => <SbpIcon key={key} className="payment-system__icon" />,
  privat: (key) => <PrivatBankIcon key={key} className="payment-system__icon" />,
  vsibanki: (key) => <img alt="mobile" key={key} src={vsibankiImg} className="payment-system__icon" />,
};

const methodIcons: Record<WithdrawalMethod | TopUpMethod, string[]> = {
  'card': ['visa', 'mastercard'],
  'qiwi': ['qiwi'],
  'mobile': ['mobile'],
  'webmoney': ['webmoney'],
  'umoney': ['umoney'],
  'operator': ['operator'],
  'card_kzt': ['visa', 'mastercard'],
  'card_uah': ['visa', 'mastercard'],
  'sbp': ['sbp'],
  'card_uah_1000': ['privat', 'vsibanki']
};

export const getMethodIcons = (method?: WithdrawalMethod | TopUpMethod | null): JSX.Element[] | null => {
  if (method && methodIcons[method]) {
    return methodIcons[method].map((icon) => iconsComponents[icon](icon));
  }

  return null;
};
