import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useGetEventDetailsQuery } from 'store/line/lineApi';
import { routes } from 'utils/routes';

import FullEventHeader from './FullEventHeader/FullEventHeader';
import FullEventBets from './FullEventBets/FullEventBets';

import type { LineType } from 'types/line-data';

import './FullEvent.scss';

interface Props {
  lineType: LineType;
  eventId: string;
}

const POLLING_INTERVAL = 10000;

const FullEvent = ({ eventId, lineType }: Props) => {
  const { isLoading, data: event } = useGetEventDetailsQuery(
    { eventId, type: lineType },
    {
      pollingInterval: lineType === 'live' ? POLLING_INTERVAL : 0,
    },
  );

  return (
    <div className="tournament">
      <Breadcrumbs link={routes[lineType].path} title={routes[lineType].title} />
      <div className="tournament__content">
        {isLoading
          ? <Loader />
          : (
            <>
              {event && <FullEventHeader event={event} lineType={lineType} />}
              <FullEventBets event={event} lineType={lineType} />
            </>
          )}
      </div>
    </div>
  );
};

export default FullEvent;
