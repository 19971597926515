import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { bemCn } from 'utils/bem-cn';
import { availableLang } from 'utils/constants';
// import iconDesktop from './assets/icon-desktop.svg';
// import iconMobile from './assets/icon-mobile.svg';

import SiteSettingsLang from './SiteSettingsLang/SiteSettingsLang';

import './SiteSettings.scss';

type Props = {
  className?: string;
  below?: boolean;
}

const SiteSettings = (props: Props) => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState('ru');

  useEffect(() => {
    if (!!i18n.resolvedLanguage && availableLang.includes(i18n.resolvedLanguage)) {
      document.documentElement.lang = i18n.resolvedLanguage;
      setCurrentLang(i18n.resolvedLanguage);
    }
  }, [i18n.resolvedLanguage]);

  const handleLangChange = (lang: string) => {
    if (lang !== i18n.resolvedLanguage) {
      i18n.changeLanguage(lang);
    }
  };

  const b = bemCn('site-settings');
  return (
    <div className={b(null, props.className)}>
      {/* <button className={b('button', {mobile: true})} type="button" disabled>
        <span className="visually-hidden">Переключить версию сайта</span>
        <img src={iconMobile} alt="Russia" className={b('button-icon')} />
      </button>
      <button className={b('button', {desktop: true})} type="button" disabled>
        <span className="visually-hidden">Переключить версию сайта</span>
        <img src={iconDesktop} alt="Russia" className={b('button-icon')} />
      </button> */}
      <SiteSettingsLang
        activeLang={currentLang}
        langList={availableLang}
        below={props.below}
        onLangChange={handleLangChange}
      />
    </div>
  );
};

export default SiteSettings;
