import { Link } from 'react-router-dom';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import GamesCard, { GameCardItemType } from '../GamesCard/GamesCard';

import './GamesCardsWidgetDesktop.scss';

type Props = {
  className?: string;
  title: string;
  items: GameCardItemType[];
  linkToAll?: string;
  isAvailable?: boolean;
  notAvailableMessage?: string;
}

const GamesCardsWidgetDesktop = (props: Props) => {
  const b = bemCn('games-cards-widget-desktop');
  return (
    <div className={b(null, props.className)}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          <p className={b('title')}>{props.title}</p>
          <Link to={props.linkToAll ?? '/games'} className={b('all')}>
            Все
            <SvgIcon className={b('all-icon')} name="arrow-right" width={9} height={9} />
          </Link>
        </div>
        {props.isAvailable !== undefined && !props.isAvailable
          ? <p>{props.notAvailableMessage}</p>
          : (
            <div className={b('list')}>
              {props.items.map((game) => (
                <GamesCard
                  key={game.name}
                  className={b('item')}
                  item={game}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default GamesCardsWidgetDesktop;
