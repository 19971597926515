import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'components/shared/icons/CloseIcon/CloseIcon';

import TransferForm from './TransferForm/TransferForm';

import type { Currency } from 'types/wallets-data';

import './TransferModal.scss';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  balance: number;
  currency: Currency | null;
};

const TransferModal = ({ isOpen, closeModal, balance, currency }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel='Sign In'
      className="transfer-modal"
      overlayClassName="transfer-modal_overlay"
    >
      <div className="transfer-modal__content">
        <div className="transfer-modal__close-section">
          <CloseIcon closeModal={closeModal} />
        </div>
        <div className="transfer-modal__header">
          <h1 className="transfer-modal__header-title">
            {t('transfer.modal.title', 'Перевод')}
          </h1>
          <p className="transfer-modal__header-description">
            {t('transfer.modal.description', 'Перевод по ID')}
          </p>
        </div>
        <TransferForm balance={balance} currency={currency} />
      </div>
    </Modal>
  );
};

export default TransferModal;
