import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { getCurrencySymbol } from 'store/wallets/constants';
import TransferModal from 'components/profile/TransferModal/TransferModal';
import { useResponsive } from 'hooks/useResponsive';
import { bemCn } from 'utils/bem-cn';

import BalanceInfo from './BalanceInfo/BalanceInfo';
import DropdownWallet from './DropdownWallet/DropdownWallet';

import type { Currency } from 'types/wallets-data';

import './WalletsManagement.scss';

type Props = {
  currency: Currency | null;
  balance: number;
  isWalletsLoading: boolean;
};

const casinoBonuses = 0;
const sportBonuses = 0;

const WalletsManagement = ({ currency, balance, isWalletsLoading }: Props) => {
  const { t } = useTranslation();
  const { Mobile, Desktop } = useResponsive();
  const balanceDisplay = `${balance} ${getCurrencySymbol(currency)}`;
  const casinoBonusesDisplay = `${casinoBonuses} ${getCurrencySymbol(currency)}`;
  const sportBonusesDisplay = `${sportBonuses} ${getCurrencySymbol(currency)}`;

  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);

  const closeTransferModal = () => {
    setOpenTransferModal(false);
  };

  const b = bemCn('wallets-management');

  return (
    <div className={b()}>
      <div className={b('wallet-info')}>
        <BalanceInfo
          className={b('wallet-info-cell')}
          id="wallet"
          title={t('wallets.main-wallet', 'Основной счет')}
          balance={balanceDisplay}
          size="m"
          isLoading={isWalletsLoading}
        />
        <Mobile>
          <div className={b('wallet-info-cell')}>
            <div className={b('cash-icon-wrapper')}>
              <div className={b('cash-icon')} />
            </div>
          </div>
        </Mobile>
        <Desktop>
          <div className={b('wallet-open-operation')}>
            {/* TODO: Есть баг с открытием окна перевода на десктопе */}
            <DropdownWallet onTransferClick={() => setOpenTransferModal(true)} />
          </div>
        </Desktop>
      </div>

      <Mobile>
        <div className={b('manage-buttons')}>
          <div className={b('button-sm-wrapper')}>
            <Button variant="outline-secondary" onClick={() => setOpenTransferModal(true)}>
              {t('wallets.transfer', 'Перевод')}
            </Button>
          </div>
          <Link className={b('button-sm-wrapper')} to="/profile/withdrawals" >
            <Button variant="outline-primary">
              {t('wallets.withdrawal', 'Вывод')}
            </Button>
          </Link>
          <Link className={b('button-wrapper')} to="/profile/topups" >
            <Button>
              {t('wallets.topup', 'Пополнить')}
            </Button>
          </Link>
        </div>
      </Mobile>

      <div className={b('bonuses')}>
        <BalanceInfo
          className={b('bonus-item')}
          id="casino-bonuses"
          title={t('wallets.casino-bonuses', 'Бонусы казино')}
          balance={casinoBonusesDisplay}
          size="sm"
        />
        <BalanceInfo
          className={b('bonus-item')}
          id="sport-bonuses"
          title={t('wallets.sport-bonuses', 'Бонусы спорт')}
          balance={sportBonusesDisplay}
          size="sm"
        />
      </div>

      <TransferModal
        isOpen={openTransferModal}
        closeModal={closeTransferModal}
        balance={balance}
        currency={currency}
      />
    </div>
  );
};

export default WalletsManagement;
