import type { Currency } from 'types/wallets-data';
// import { suggestValues } from '../payment/constants';

type Limit = {
  min: number;
  max: number;
};

export const betLimits: Record<Currency, Limit> = {
  RUB: {
    min: 50,
    max: 300000
  },
  USD: {
    min: 1,
    max: 10000
  },
  EUR: {
    min: 1,
    max: 10000
  },
  KZT: {
    min: 100,
    max: 1000000,
  },
  UAH: {
    min: 10,
    max: 100000
  },
  TJS: {
    min: 5,
    max: 30000
  },
};

export const defaultLimits = {
  min: 1,
  max: 999999,
};

export const betSuggests: Record<Currency, number[]> = {
  RUB: [1000, 3000, 5000],
  USD: [15, 30, 50],
  EUR: [1000, 5000, 10000],
  KZT: [5000, 1000, 25000],
  UAH: [500, 1000, 5000],
  TJS: [100, 500, 1000],
};

export const getBetSuggestions = (currency: Currency): number[] => betSuggests[currency] ?? betSuggests.USD;

export const getBetLimits = (currency: Currency) : { min: number; max: number } => betLimits[currency] ?? defaultLimits;
