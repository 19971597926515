import React from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { setCountry, setSport, setTournament } from 'store/line/lineSlice';
import { selectActiveSport, selectSportsByType } from 'store/line/selectors';

import SideBarFilterItemDesktop from './components/SideBarFilterItemDesktop';

import type { LineType } from 'types/line-data';

import './SideBarFilterDesktop.scss';

type Props = {
  lineType: LineType;
};

const SideBarFilterDesktop = ({ lineType }: Props) => {
  const dispatch = useAppDispatch();
  const activeSport = useAppSelector(selectActiveSport);
  const sports = useAppSelector((state) => selectSportsByType(state, lineType));

  const onButtonClick = (id: string) => (
    id === activeSport ? (
      dispatch(setCountry('0')),
      dispatch(setSport('0')),
      dispatch(setTournament('0'))
    ) : dispatch(setSport(id))
  );

  return (
    <div className="side-bar-filter-desktop">
      <div className="side-bar-filter-desktop__sports">
        {sports.map((sport) => (
          <SideBarFilterItemDesktop
            key={sport.slug}
            item={sport}
            isActive={activeSport === sport.slug}
            onClick={() => onButtonClick(sport.slug)}
            lineType={lineType}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(SideBarFilterDesktop);
