import React from 'react';
import Modal from 'react-modal';

import CloseIcon from 'components/shared/icons/CloseIcon/CloseIcon';

import Basket from '../Basket/Basket';
import './BasketModal.scss';

type Props = {
  isOpen: boolean;
  closeModal(): void;
};

const BasketModal = ({ isOpen, closeModal }: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    className="basket-modal__content"
    overlayClassName="basket-modal__overlay"
    appElement={document.getElementById('root') || undefined}
    parentSelector={() => document.getElementById('app') ?? document.body}
  >
    <CloseIcon closeModal={closeModal} variant="basket" />
    <Basket />
  </Modal>
);

export default BasketModal;
