import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { getCurrencySymbol, getCurrencyTitle } from 'store/wallets/constants';
import CloseIcon from 'components/shared/icons/CloseIcon/CloseIcon';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { useResponsive } from 'hooks/useResponsive';

import WalletOperation from '../../WalletOperation/WalletOperation';

import type { Wallet } from 'types/wallets-data';

import './AddedWalletItem.scss';

type Props = {
  currency: Wallet;
};

const AddedWalletItem = ({ currency }: Props) => {
  const { Desktop } = useResponsive();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="added-wallet__item profile__section">
      <div className="added-wallet__symbol">
        <p>{getCurrencySymbol(currency.currency)}</p>
      </div>
      <div className="added-wallet__info">
        <p>{getCurrencyTitle(currency.currency)}</p>
        <h3>
          {currency.amount}
          <Desktop><span>{getCurrencySymbol(currency.currency)}</span></Desktop>
        </h3>
      </div>
      <div className="added-wallet__more">
        <button type="button" onClick={() => setIsOpen(true)}>
          <SvgIcon className='added-wallet__more-icon' name='dots-menu' width={10} height={10} />
        </button>

      </div>
      <div />
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root') || undefined}
        parentSelector={() => document.getElementById('app') ?? document.body}
        contentLabel="Sign In"
        className="transfer-modal"
        overlayClassName="transfer-modal_overlay"
      >
        <div className="transfer-modal__content">
          <div className="transfer-modal__close-section">
            <CloseIcon closeModal={closeModal} />
          </div>
          <div className="transfer-modal__header">
            <h1 className="transfer-modal__header-title">
              {t('wallets.actions.title', 'Операции со счетом')}
            </h1>
            <p className="transfer-modal__header-description">
              {t('wallets.actions.descriptions', 'Вы можете сделать')}
            </p>
          </div>
          <WalletOperation currency={currency} />
        </div>
      </Modal>
    </div>
  );
};

export default AddedWalletItem;
