import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import { getWidgetSubtitle } from 'utils/widgetHeader';
import { bemCn } from 'utils/bem-cn';

import GradientIcon from '../../icons/GradientIcon/GradientIcon';
import SvgIcon from '../../SvgIcon/SvgIcon';
import GamesCard, { GameCardItemType } from '../GamesCard/GamesCard';
import GamesCardsWidgetSwiper from './GamesCardsWidgetSwiper/GamesCardsWidgetSwiper';

import './GamesCardsWidgetMobile.scss';

const grammaticalCases = {
  nominative: 'игра',
  genitive: 'игры',
  genitivePlural: 'игр',
};

type Props = {
  className?: string;
  title: string;
  iconColor?: string;
  iconName?: string;
  items: GameCardItemType[];
  itemsCounter?: number;
  linkToAll?: string;
  isLoading?: boolean;
  isAvailable?: boolean;
  notAvailableMessage?: string;
}

const GamesCardsWidgetMobile = (props: Props) => {
  const b = bemCn('games-cards-widget-mobile');
  const { t } = useTranslation();
  return (
    <div className={b(null, props.className)}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          <GradientIcon className={b('icon')} color={props.iconColor} iconName={props.iconName} />
          <p className={b('title')}>
            {props.title}
          </p>
          <div className={b('meta')}>
            <Link to={props.linkToAll ?? '/games'} className={b('all')}>
              {t('common.all', 'Все')}
              <SvgIcon className={b('all-icon')} name="arrow-right" width={9} height={9} />
            </Link>
            <div className={b('count')}>
              {getWidgetSubtitle(
                props.itemsCounter ?? props.items.length,
                grammaticalCases
              )}
            </div>
          </div>
        </div>
        <div className={b('games')}>
          {props.isAvailable !== undefined && !props.isAvailable
            ? <p>{props.notAvailableMessage}</p>
            : (
              <GamesCardsWidgetSwiper isLoading={props.isLoading} itemsCount={props.items.length}>
                {props.items.map((game) => (
                  <SwiperSlide key={`${game.id ?? ''}-${game.name}`} className={b('swiper-item')}>
                    <GamesCard
                      className={b('item')}
                      item={game}
                    />
                  </SwiperSlide>
                ))}
              </GamesCardsWidgetSwiper>
            )}
        </div>
      </div>
    </div>
  );
};

export default GamesCardsWidgetMobile;
