import React, { useState, useEffect, useMemo } from 'react';

import ThemeContext, { initialThemeState, ThemeContextInterface } from './ThemeContext';

type Props = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState(initialThemeState.theme);
  const themeValue = useMemo<ThemeContextInterface>(() => ({ theme, setTheme }), [theme, setTheme]);
  const { localStorage } = window;

  useEffect(() => {
    localStorage.setItem('globalTheme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={themeValue}>
      <div className={`theme--${theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
