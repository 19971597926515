import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { changeActiveModal, logout } from 'store/auth/authSlice';
import { useLogoutMutation } from 'store/auth/authApi';
import { useAppDispatch } from 'hooks';
import { clearSession } from 'store/slots/slotsSlice';

import userIcon from './assets/user.svg';

import './DesktopHeaderUser.scss';

const DesktopHeaderUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [serverLogout] = useLogoutMutation();
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const userBLock = useRef<HTMLDivElement>(null);

  const handleLogoutClick = () => {
    serverLogout()
      .then(() => {
        dispatch(logout());
        dispatch(clearSession());
      })
      .catch((err) => console.log('<HeaderLogoutError>:', err));
  };

  useEffect(() => {
    window.addEventListener('click', (e) => {
      const tar = e?.target as HTMLElement;
      if (userBLock.current && !tar.closest(`.${userBLock.current.className}`)) {
        setOpenMenu(false);
      }
    });

    return () => {
      window.removeEventListener('click', (e) => {
        if (e.target !== userBLock.current) {
          setOpenMenu(false);
        }
      });
    };
  }, []);

  return (
    <div className="desktop-header-authorized__user" ref={userBLock}>
      <Button variant="shaded" onClick={() => setOpenMenu(!openMenu)}>
        <img src={userIcon} alt="" />
      </Button>
      {openMenu && (
        <div className="desktop-headr-authorized__user-menu-down">
          <button
            type="button"
            className="desktop-headr-authorized__user-menu-down-button"
            onClick={() => { navigate('/bet-history'); setOpenMenu(false); }}
          >
            {t('profile.nav.bet-history', 'История ставок')}
          </button>
          <button
            type="button"
            className="desktop-headr-authorized__user-menu-down-button"
            onClick={() => dispatch(changeActiveModal('detailing'))}
          >
            {t('profile.nav.transactions', 'Детализация')}
          </button>
          {/* <button type="button" className="desktop-headr-authorized__user-menu-down-button" >
            {t('profile.nav.promo-codes', 'Промокоды')}
          </button> */}
          <button
            type="button"
            className="desktop-headr-authorized__user-menu-down-button"
            onClick={() => dispatch(changeActiveModal('settings'))}
          >
            {t('profile.nav.settings', 'Настройки')}
          </button>
          {/* <button type="button" className="desktop-headr-authorized__user-menu-down-button">
            {t('profile.nav.support', 'Служба поддержки')}
          </button>
          <button type="button" className="desktop-headr-authorized__user-menu-down-button">
            {t('profile.nav.documents', 'Мои документы')}
          </button> */}
          <button type="button" className="desktop-headr-authorized__user-menu-down-button"
            onClick={handleLogoutClick}
          >
            {t('user-nav.logout', 'Выйти')}
          </button>
        </div>
      )}
    </div>
  );
};

export default DesktopHeaderUser;
