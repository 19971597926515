import { createSelector } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';
import { LineType } from 'types/line-data';

import type { RootState } from 'types/state';

export const selectCountry = (state: RootState) => state[NameSpace.Line].selectedCountry;

export const selectActiveSport = (state: RootState) => state[NameSpace.Line].selectedSport;

export const selectActiveTournament = (state: RootState) => state[NameSpace.Line].selectedTournament;

export const selectCountriesList = (state: RootState) => state[NameSpace.Line].countries;

export const selectTournaments = (state: RootState) => state[NameSpace.Line].tournaments;

export const selectEvents = (state: RootState) => state[NameSpace.Line].events;
export const selectEventsBySport = (state: RootState, sportId: string) => {
  const events = selectEvents(state);
  if (!sportId || sportId === '0') {
    return events;
  }
  return events.filter((event) => event.sportId === sportId);
};

export const selectIsWidgetsLoading = (state: RootState) => state[NameSpace.Line].lineLoadingCounter > 0;

export const selectLiveSports = (state: RootState) => state[NameSpace.Line].sportsList.live;
export const selectLineSports = (state: RootState) => state[NameSpace.Line].sportsList.line;

export const selectLineSportsBySearch = (search: string) =>
  createSelector( selectLineSports, (sports) =>
    search.length <= 0
      ? sports
      : sports.filter(
        (sport) => sport.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
  );

export const selectSportsByType = (state: RootState, lineType: LineType) => {
  switch (lineType) {
    case 'live':
      return selectLiveSports(state);
    case 'line':
      return selectLineSports(state);
  }
};
