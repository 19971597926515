import { FullEventItem } from 'types/line-data';
import { bemCn } from 'utils/bem-cn';

import Timer from '../EventCardTimer/EventCardTimer';
import Team from '../EventCardTeam/EventCardTeam';
import Score from '../EventCardScore/EventCardScore';

import './EventCardBoard.scss';

type Props = {
  event: FullEventItem;
  className?: string;
  isLive?: boolean;
}

const EventCardBoard = ({ event, className, isLive }: Props) => {
  const periodScors = event.scorePeriod.split(';');

  const isTimerStoped =
    event.finale
    || event.gameDesk === 'Игра завершена'
    || event.gameDesk === 'Game finished'
    || event.gameDesk === 'Перерыв'
    || event.gameDesk === 'Break';

  const b = bemCn('event-card-board');

  return (
    <div className={b(null, className)}>
      <div className={b('time')}>
        <p className={b('period-name')}>{event.gameDesk}</p>
        {event.timer > 0 && isLive && (
          <Timer
            timer={event.timer}
            stoped={isTimerStoped}
          />
        )}
      </div>
      <div className={b('teams')}>
        <Team
          name={event.team1}
          logo={event.team1Icon}
        />
        {isLive
          ? <Score className={b('total-score')} score={event.scoreFull} />
          : <p className={b('total-score')}>VS</p>}
        <Team
          name={event.team2}
          logo={event.team2Icon}
        />
      </div>
      {isLive && (
        <div className={b('periods-score')}>
          {periodScors.map((score, i) => {
            const keyVal = `${score}-${i}`;
            return (
              <Score
                className={b('period-score')}
                key={keyVal}
                score={score}
              />
            );
          })}
        </div>
      )}

    </div>
  );
};


export default EventCardBoard;
