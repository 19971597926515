import { useToggle } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { StatItem } from 'types/line-data';
import { bemCn } from 'utils/bem-cn';
import './EventCardStats.scss';

type StatItemProps = {
  stat: StatItem;
}

const b = bemCn('event-card-stats');


const EventCardStatItem = ({ stat }: StatItemProps) => (
  <li className={b('item')}>
    <span className={b('t1')}>{stat.opp1}</span>
    <span className={b('name')}>{stat.name}</span>
    <span className={b('t2')}>{stat.opp2}</span>
  </li>
);

type Props = {
  stats: StatItem[];
  className?: string;
}

const EventCardStats = ({ stats, className }: Props) => {
  const { t } = useTranslation();
  const [isClosed, toggleClosed] = useToggle(false);

  if (stats.length <= 0) {
    return null;
  }

  return (
    <div className={b({ closed: isClosed }, className)}>
      <button type='button' className={b('title')}
        onClick={toggleClosed}
      >
        {t('event.statistics', 'Статистика')}
        <SvgIcon
          className={b('toggle-icon')}
          name='chevron-down'
          width={10}
          height={10}
        />
      </button>
      <ul className={b('list')}>
        {stats.map((stat) => (
          <EventCardStatItem key={stat.name} stat={stat} />
        ))}
      </ul>
    </div>
  );
};

export default EventCardStats;
