import React, { forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';

import './Checkbox.scss';

type Props = {
  checked?: boolean;
  className?: string;
  onChange?(value: boolean): void;
  onBlur?(): void;
  name?: string;
  isError?: boolean;
  disabled?: boolean;
  variant?: 'inverted';
};

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef<HTMLDivElement, Props>(({
  checked,
  className,
  onChange,
  onBlur,
  name,
  isError,
  disabled,
  variant
}, ref) => {
  const [isChecked, setChecked] = useState(checked ?? false);

  const clickHandler = () => {
    if (disabled) { return; }
    setChecked(!isChecked);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      clickHandler();
    }
  };

  useEffect(() => {
    onChange?.(isChecked);
  }, [isChecked]);

  const handleBlur = () => {
    if (disabled) { return; }
    onBlur?.();
  };

  return (
    <div
      className={cn(
        'checkbox',
        { 'checkbox--error': isError },
        { 'checkbox--disabled': disabled },
        variant && `checkbox--${variant}`,
        className
      )}
      ref={ref}
      role="button"
      tabIndex={0}
      onClick={clickHandler}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    >
      <input name={name} type="checkbox" checked={checked} readOnly disabled={disabled} />
      <span className="checkmark" />
    </div>
  );
});

export default Checkbox;
