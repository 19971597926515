import cn from 'classnames';

import './SocialList.scss';

// const getLabel = (type: string) => {
//   switch (type) {
//     case 'telegram':
//       return <span className="visually-hidden">Сообщество в телеграм</span>;
//     case 'vkontakte':
//       return <span className="visually-hidden">Группа во вконтакте</span>;
//     case 'instagram':
//       return <span className="visually-hidden">Профиль в инстаграм</span>;
//     default:
//       return '';
//   }
// };

const SocialLink = ({ type, link }: { type: string; link?: string | undefined }) => (
  <li className="social-list__item">
    <a
      href={link}
      target='_blank'
      rel="noreferrer"
      className={`social-list__link social-list__link--${type}`}
    >
      {/* {getLabel(type)} */}
    </a>
  </li>
);

type SocialLinkType = {
  type: string;
  link: string;
}

type Props = {
  className?: string;
  items: SocialLinkType[];
}

const SocialList = (props: Props) => (
  <ul className={cn('social-list', props.className)}>
    {props.items.map((item) => (
      <SocialLink key={`${item.type}-${item.link}`} type={item.type} link={item.link} />
    ))}
  </ul>
);


export default SocialList;
