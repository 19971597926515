import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

import type {
  LoginResponse,
  LoginRequest,
  RegisterResponse,
  RegisterRequest,
  ChangePasswordParams
} from 'types/auth-data';


export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (data: LoginRequest) => ({
        url: APIRoute.Login,
        method: 'POST',
        body: {
          login: data.email,
          password: data.password,
        },
      }),
      invalidatesTags: (result) => result ? ['Auth'] : [],
    }),

    logout: build.mutation<void, void>({
      query: () => ({
        url: 'v1/auth/logout',
        method: 'POST'
      })
    }),

    emailRegister: build.mutation<RegisterResponse, RegisterRequest>({
      query: (data: RegisterRequest) => ({
        url: APIRoute.Register,
        method: 'POST',
        body: {...data},
      }),
    }),

    emailVerify: build.query<unknown, string>({
      query:(id: string) => ({
        url: `${APIRoute.VerifyEmail}/${id}`,
        method: 'POST',
      }),
    }),

    resetPassword: build.mutation<unknown, string>({
      query: (email: string) => ({
        url: APIRoute.ResetPass,
        params: { email }
      }),
    }),

    changePassword: build.mutation<unknown, ChangePasswordParams>({
      query: (params: ChangePasswordParams) => ({
        url: `${APIRoute.ResetPass}/${params.passwordResetId}`,
        method: 'POST',
        params: {
          'new_password': params.password,
        }
      }),
    }),

    clickRefCode: build.mutation<unknown, string>({
      query:(refCode:string) => ({
        url: `${APIRoute.ReferCode}/${refCode}`,
        method: 'POST'
      })
    })
  }),
});

export const {
  useLoginMutation,
  useEmailRegisterMutation,
  useEmailVerifyQuery,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useClickRefCodeMutation,
  useLogoutMutation,
} = authApi;
