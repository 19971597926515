import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { api } from 'store/api';
import { initialAuthState } from 'store/auth/authSlice';
import { getToken } from 'store/auth/utils';
import { initialBasketState } from 'store/basket/basketSlice';
import { NameSpace } from 'utils/constants';

import { rootReducer } from './root-reducer';
import { getBasket } from './line/utils';

const preloadedState = {
  [NameSpace.Auth]: { ...initialAuthState, token: getToken() },
  [NameSpace.Basket]: { ...initialBasketState, bets: getBasket() }
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  preloadedState,
});

setupListeners(store.dispatch);
