import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResponsive } from 'hooks/useResponsive';
import { bemCn } from 'utils/bem-cn';

import Button from '../buttons/Button/Button';
import SvgIcon from '../SvgIcon/SvgIcon';
import './SearchFilter.scss';

type Props = {
  openSearch: boolean;
  onCancel?: () => void;
  onChange: (searchValue: string) => void;
};

const SearchFilter = ({ openSearch, onCancel, onChange }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');


  useEffect(() => {
    if (openSearch && isMobile) {
      searchInputRef.current?.focus();
    }
  }, [openSearch]);

  useEffect(() => {
    const changeSearchFilter = setTimeout(() => {
      onChange(searchValue);
    }, 500);

    return () => {
      clearTimeout(changeSearchFilter);
    };
  }, [searchValue]);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleCancel = () => {
    onCancel?.();
    setSearchValue('');
  };

  const b = bemCn('search-filter');

  return (
    <div className={b({ opened: openSearch })}>
      <div className={b('input-wrapper')}>
        <input
          className={b('input')}
          type="text"
          placeholder={`${t('search', 'Поиск')}...`}
          ref={searchInputRef}
          value={searchValue}
          onChange={(e) => handleChangeValue(e)}
        />
        <Button className={b('button')}>
          <SvgIcon name='search' width={13} height={13} />
        </Button>
      </div>
      {onCancel && (
        <button
          type="button"
          className={b('cancel')}
          onClick={handleCancel}
        >
          {t('Сancel', 'Отменить')}
        </button>
      )}
    </div>
  );
};

export default SearchFilter;
