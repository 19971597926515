import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import SearchFilter from 'components/shared/SearchFilter/SearchFilter';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useGetEventsListQuery } from 'store/line/lineApi';
import { setSport, setTournament } from 'store/line/lineSlice';
import { selectBasketItems } from 'store/basket/selectors';
import EventCardMobile from 'components/line/EventCard/EventCardMobile/EventCardMobile';
import { bemCn } from 'utils/bem-cn';
import './Tournament.scss';

const lineType = 'line';

const Tournament = () => {
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const { t } = useTranslation();
  const { sportId, tournamentId, tournamentName } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const basketItems = useAppSelector(selectBasketItems);

  const isSportIdValid = sportId !== undefined;
  const isTournamentIdValid = tournamentId !== undefined;

  const { isFetching: isEventsFetching, data: events } = useGetEventsListQuery(
    { sportId, tournamentId, type: lineType },
    { skip: !isSportIdValid || !isTournamentIdValid },
  );

  useEffect(() => {
    if (isSportIdValid && isTournamentIdValid) {
      dispatch(setTournament(tournamentId));
      dispatch(setSport(sportId));
    } else {
      // navigate(-1);
    }
  }, []);

  if (!isSportIdValid || !isTournamentIdValid) {
    navigate(-1);
    return <div />;
  }

  let filteredEvents = events;

  if (searchFilterValue.length > 0) {
    filteredEvents = events?.filter(
      (event) =>
        event.team1.toLowerCase().includes(searchFilterValue.toLowerCase())
        || event.team2.toLowerCase().includes(searchFilterValue.toLowerCase()));
  }

  const handleSearchChange = (newSearchValue: string) => {
    setSearchFilterValue(newSearchValue);
  };

  const handleSearchCancel = () => {
    setOpenSearch(false);
  };

  const b = bemCn('tournament');

  return (
    <div className={b('')}>
      <Breadcrumbs link="/line" title={t('main.nav.Line', 'Линия')} />
      {!openSearch && (
        <PageHeader
          id="line"
          title={tournamentName ?? `Турнир ${tournamentId ?? ''}`}
          controlPanel={[
            { type: 'star' },
            { type: 'search', onClick: () => setOpenSearch(true) }
          ]}
        />
      )}
      <SearchFilter
        openSearch={openSearch}
        onCancel={handleSearchCancel}
        onChange={handleSearchChange}
      />
      <div className={b('content')}>
        <div className={b('events-list')}>
          {isEventsFetching
            ? <Loader />
            : filteredEvents?.map((event) => (
              <EventCardMobile
                key={event.id}
                event={event}
                basketItem={basketItems[event.id]?.betId}
                lineType={lineType}
                simple
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tournament;
