import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/basket/basketSlice';
import { selectActiveModal, selectBasketItemsCount } from 'store/basket/selectors';
import { changeActiveModal as changeAuthModal } from 'store/auth/authSlice';
import { selectIsAuthenticated } from 'store/auth/selectors';
import SectionIcon from 'components/shared/SectionIcon/SectionIcon';
import BasketModal from 'components/basket/BasketModal/BasketModal';
import ResultModal from 'components/shared/ResultModal/ResultModal';

import './BasketItem.scss';

type Props = {
  isActive: boolean;
};

const BasketTabItem = ({ isActive }: Props) => {
  const basketCount = useAppSelector(selectBasketItemsCount);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const activeModal = useAppSelector(selectActiveModal);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleBasketClick = () => {
    if (isAuth) {
      dispatch(changeActiveModal('basket'));
    } else {
      dispatch(changeAuthModal('sign-in'));
    }
  };

  return (
    <>
      <div
        className="tab-bar-item"
        onClick={handleBasketClick}
        key="basket"
      >
        <SectionIcon className="tab-item__section-icon" id="basket" isBackgroundShowing={isActive} variant="rounded-shadow" />
        <p className={cn('tab-item__title', { 'tab-item__title--active': isActive })}>
          {t('main.nav.Cart', 'Корзина')}
        </p>
        <p className="tab-bar-item__basket-count">{basketCount}</p>
      </div>
      <BasketModal isOpen={activeModal === 'basket'} closeModal={() => dispatch(changeActiveModal(null))} />
      <ResultModal
        modalIsOpen={activeModal === 'success'}
        closeModal={() => dispatch(changeActiveModal(null))}
        status="success"
        title={t('result-modal.basket.success.title', 'Ставка успешно поставлена')}
      />
      <ResultModal
        modalIsOpen={activeModal === 'error'}
        closeModal={() => dispatch(changeActiveModal(null))}
        status="error"
        title={t('result-modal.basket.error.title', 'Ошибка постановки ставки')}
      />
    </>

  );
};

export default React.memo(BasketTabItem);
