import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import TopupForm from './TopupForm/TopupForm';
import CloseIcon from '../../shared/icons/CloseIcon/CloseIcon';

import type { Currency } from 'types/wallets-data';
import type { TopUpMethod } from 'types/payments-data';

import './TopupModal.scss';

type Props = {
  isOpen: boolean;
  activeMethod: { id: TopUpMethod; title: string } | null;
  closeModal: () => void;
  currency: Currency | null;
};

const TopupModal = ({ activeMethod, closeModal, currency, isOpen }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel="Sign In"
      className="topupModalContent topup-modal"
      overlayClassName="topupModalOverlay"
    >
      <div className="topup-modal__content">
        <div className="topup-modal__close-section">
          <CloseIcon closeModal={closeModal} />
        </div>
        <div className="topup-modal__header">
          <h1 className="topup-modal__header-title">
            {t('topups.modal.title', 'Пополнить')}
          </h1>
          <p className="topup-modal__header-description">
            {activeMethod?.title}
          </p>
        </div>
        {activeMethod && currency && (
          <TopupForm
            activeMethod={activeMethod.id}
            currency={currency}
          />
        )}
      </div>
    </Modal>
  );
};

export default TopupModal;
