import { useContext, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useToggle } from 'usehooks-ts';

import { ReactComponent as CaretIcon } from 'assets/caret.svg';
import ThemeContext, { AVAILABLE_THEMES } from 'contexts/ThemeContext';

import './ThemePicker.scss';

type ThemeOptionsProps = {
  onClick: (option: string) => void;
  options: string[];
}

const ThemOptions = ({ onClick, options }: ThemeOptionsProps) => (
  <div className="theme-test__options">
    {options.map((option, i) => (
      <button key={`theme-option-${option}`} onClick={() => onClick(option)}>
        {option}
      </button>
    ))}
  </div>
);

const ThemePicker = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [isOpened, toggleOpened, setOpened] = useToggle(false);
  const themeBlock = useRef<HTMLDivElement>(null);

  const onChangeTheme = (value: string) => {
    setTheme(value);
    setOpened(false);
  };

  // TODO: Переписать с использованием useOnClickOutside 'usehooks-ts'
  const handleOutsideClick = (e: Event) => {
    const tar = e?.target as HTMLElement;
    if (themeBlock.current && !tar.closest(`.${themeBlock.current.className}`)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, []);

  return (
    <div className="theme-test" ref={themeBlock}>
      <button className='theme-test__button' type="button"
        onClick={toggleOpened}
      >
        <p>{theme}</p>
      </button>
      <CaretIcon
        // TODO: Исправить использование className из другого компонента
        className={cn(
          'currency-selector__caret-icon',
          { 'currency-selector__caret-icon--rotated': isOpened },
        )}
      />
      {/* TODO: Переписать скрытие раскрытие на CSS */}
      {isOpened && (
        <ThemOptions
          onClick={onChangeTheme}
          options={AVAILABLE_THEMES.filter((item) => item !== theme)}
        />
      )}
    </div>
  );
};

export default ThemePicker;
