import { useResponsive } from 'hooks/useResponsive';
import DesktopLine from 'components/line/DesktopLine/DesktopLine';

import EventPageMobile from './EventPageMobile';


const EventPage = () => {
  const { isDesktop } = useResponsive();

  return isDesktop
    ? <DesktopLine previewItem />
    : <EventPageMobile />;
};


export default EventPage;
