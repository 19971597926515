import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/shared/loader/Loader';
import { useGetEventsListQuery } from 'store/line/lineApi';
import { useAppSelector } from 'hooks';
import { selectBasketItems } from 'store/basket/selectors';
import { seletIsLineActive } from 'store/app/selectores';
import { bemCn } from 'utils/bem-cn';

import DesktopWidgetItem from '../DesktopWidgetItem/DesktopWidgetItem';

import type { LineType } from 'types/line-data';
import './DesktopListWidget.scss';

type Props = {
  type: LineType;
  activeSport: string;
  isLoading?: string;
  className?: string;
};

const DesktopListWidget = ({ activeSport, type, isLoading, className }: Props) => {
  const { t } = useTranslation();
  const basketItems = useAppSelector(selectBasketItems);
  const isLineActive = useAppSelector(seletIsLineActive);
  const { data: events, isFetching } = useGetEventsListQuery({
    sportId: activeSport,
    type,
    count: 7
  }, { skip: !isLineActive });

  if (isLoading || isFetching || !events) {
    return <Loader />;
  }

  const b = bemCn('desktop-list-widget');

  return (
    <div className={b(null, className)}>
      <div className={b('top')}>
        <h2 className={b('timer')}>
          {t('top-events-widget.date-time', 'Время')}
        </h2>
        <h2 className={b('teams')}>
          {t('top-events-widget.teams', 'Команды')}
        </h2>
        <div className={b('bets')}>
          <h2>1</h2>
          <h2>X</h2>
          <h2>2</h2>
        </div>
      </div>
      <div className={b('events')}>
        {events?.map((event) => (
          <DesktopWidgetItem
            className={b('event')}
            key={event.id}
            lineType={type}
            event={event}
            basketItems={basketItems}
          />
        ))}
      </div>
    </div>
  );
};

export default DesktopListWidget;
