import React from 'react';
import cn from 'classnames';

import './GamesFilter.scss';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';


type Props = {
  className?: string;
  items: string[];
  activeItem: string;
  onChange?: (filter: string) => void;
}

const GamesFilter = ({ className, items, onChange, activeItem }: Props) => {
  const containerRef = useHorizontalScroll<HTMLDivElement>();
  const handleTabChange = (newTab: string) => {
    if (newTab !== activeItem) {
      onChange?.(newTab);
    }
  };
  return (
    <div className={cn('games-filter', className)}>
      <div className="games-filter__list" ref={containerRef}>
        {items.map((item) => (
          <button
            key={`game-filter-${item}`}
            className={cn(
              'games-filter__item',
              { 'games-filter__item--active': activeItem === item }
            )}
            type="button"
            onClick={() => handleTabChange(item)}
          >
            {item}
          </button>
        ))}

      </div>
    </div>
  );
};

export default GamesFilter;
