import cn from 'classnames';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import type { TournamentItem } from 'types/line-data';

type Props = {
  item: TournamentItem;
  isActive: boolean;
  onClick: () => void;
}

const SideBarTournamentsItemDesktop = ({ item, isActive, onClick }: Props) => (
  <button
    className={cn(
      'side-bar-tournaments-desktop__button',
      { 'side-bar-tournaments-desktop__button--active': isActive }
    )}
    type="button"
    onClick={onClick}
  >
    <SvgIcon className='side-bar-tournaments-desktop__favorite-icon' name='star' width={14} height={14} />
    <h2>{item.name}</h2>
  </button>
);

export default SideBarTournamentsItemDesktop;
