import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal, paymentLinkOpened, } from 'store/payment/paymentsSlice';
import { selectActiveModal, selectPaymentUrl, selectWithdrawalOverviewList } from 'store/payment/selectors';
import { selectPrimaryWallet } from 'store/user/selectors';
import Loader from 'components/shared/loader/Loader';
import { useGetWithdrawalMethodsQuery } from 'store/payment/paymentsApi';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import { useResponsive } from 'hooks/useResponsive';
import { bemCn } from 'utils/bem-cn';

import PaymentMethod from '../PaymentSystem/PaymentMethod';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import WithdrawalModal from '../WithdrawalModal/WithdrawalModal';

import type { WithdrawalMethod } from 'types/payments-data';

import './Withdrawals.scss';

const withdrawalsDelay = `Вывод занимает от 15 минут до 24 часов.\n
        На выходных заявки могут обрабатываться дольше.\n
        Проверяйте статус Вашей заявки в личном кабинете, в разделе «детализация».\n
        «Создана» — Ваш вывод в обработке.\n
        «Отклонена» — Ваш вывод отклонили по какой-то причине.\n
        «Одобрена» — Ваш вывод одобрен оператором и отправлен в платежную систему, время зачисления зависит в данном случае от банка.`;

const Withdrawals = () => {
  const { Mobile } = useResponsive();
  const { t } = useTranslation();
  const [activeMethod, setActiveMethod] = useState<{ id: WithdrawalMethod; title: string } | null>(null);
  const dispatch = useAppDispatch();

  const methods = useAppSelector(selectWithdrawalOverviewList);
  const activeModal = useAppSelector(selectActiveModal);
  const { currency, amount: balance } = useAppSelector(selectPrimaryWallet);
  const paymentUrl = useAppSelector(selectPaymentUrl);

  const { isLoading } = useGetWithdrawalMethodsQuery();

  useEffect(() => {
    if (paymentUrl) {
      window.open(paymentUrl, '_self');
      dispatch(paymentLinkOpened);
    }
  }, [paymentUrl]);

  const layout = methods.map((method) => (
    <PaymentMethod
      id={method.id}
      title={method.title}
      key={method.id}
      onClick={() => {
        if (currency) {
          dispatch(changeActiveModal('withdrawal'));
          setActiveMethod({ ...method });
        }
      }}
    />
  ));

  const b = bemCn('withdrawals');

  return (
    <div className={b()}>
      <Mobile>
        <ProfileHeader
          breadcrumbsLink="/profile"
          breadcrumbsTitle={t('Profile', 'Личный кабинет')}
          title={t('withdrawals.title', 'Вывод')}
          subtitle={
            paymentUrl
              ? `${t('withdrawals.redirect-description', 'Переход на страницу платежной системы')}`
              : `${t('withdrawals.description', 'Выберите метод для вывода')}`
          }
        />
      </Mobile>
      {isLoading || paymentUrl ? (
        <div className={b('loader-wrapper')}>
          <Loader />
        </div>
      ) : (
        <div className={b('systems-list')}>
          {layout}
        </div>
      )}
      <small className={b('delay')}>
        Вывод занимает от&nbsp;15&nbsp;минут до&nbsp;24&nbsp;часов.<br />
        На&nbsp;выходных заявки могут обрабатываться дольше.<br />
        Проверяйте статус Вашей заявки в&nbsp;личном кабинете, в&nbsp;разделе &laquo;детализация&raquo;.
        &laquo;Создана&raquo;&nbsp;&mdash; Ваш вывод в&nbsp;обработке.
        &laquo;Отклонена&raquo;&nbsp;&mdash; Ваш вывод отклонили по&nbsp;какой-то причине.
        &laquo;Одобрена&raquo;&nbsp;&mdash; Ваш вывод одобрен оператором и&nbsp;отправлен в&nbsp;платежную систему, время зачисления зависит в&nbsp;данном случае от&nbsp;банка.
      </small>
      <WithdrawalModal
        activeMethod={activeMethod}
        isOpen={activeModal === 'withdrawal'}
        closeModal={useCallback(() => dispatch(changeActiveModal(null)), [])}
        currency={currency}
        balance={balance}
      />
      <ResultModal
        title={t('result-modal.withdrawal.success.title', 'Заявка на вывод успешно создана')}
        status="success"
        modalIsOpen={activeModal === 'success'}
        closeModal={useCallback(() => {
          dispatch(changeActiveModal(null));
          dispatch(changeActiveModal(null));
        }, [])}
      />
    </div>
  );
};

export default Withdrawals;
