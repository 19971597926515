import { bemCn } from 'utils/bem-cn';

import logoUfc from '../assets/sport-organisations/logo-ufc.svg';
import logoUefa from '../assets/sport-organisations/logo-uefa.svg';
import logoAtp from '../assets/sport-organisations/logo-atp.svg';
import logoNba from '../assets/sport-organisations/logo-nba.svg';
import logoFifa from '../assets/sport-organisations/logo-fifa.svg';
import logoNhl from '../assets/sport-organisations/logo-nhl.svg';

const footerSportOrganisations = [
  {
    image: logoUfc,
    name: 'UFC',
  },
  {
    image: logoUefa,
    name: 'UEFA',
  },
  {
    image: logoAtp,
    name: 'ATP',
  },
  {
    image: logoNba,
    name: 'NBA',
  },
  {
    image: logoFifa,
    name: 'FIFA',
  },
  {
    image: logoNhl,
    name: 'NHL',
  },
];

const PageFooterSportOrganisations = () => {
  const b = bemCn('page-footer');
  return (
    <ul className={b('sport-organisations')}>
      {footerSportOrganisations.map(({ name, image }) => (
        <li key={`${name}-${image}`} className={b('sport-organisations-item')}>
          <img src={image} alt={name} className={b('sport-organisations-img')} />
        </li>
      ))}
    </ul>
  );
};

export default PageFooterSportOrganisations;
