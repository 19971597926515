import React from 'react';
import cn from 'classnames';

import { getCurrencySymbol } from 'store/wallets/constants';

import type { Currency } from 'types/wallets-data';

import './SumSuggestButton.scss';

type Props = {
  amount: number;
  currency: Currency | null;
  onClick: (value: number) => void;
  variant?: 'default' | 'inverse';
};

const SumSuggestButton = ({ onClick, currency, amount, variant }: Props) => (
  <button
    type="button"
    className={cn('sum-suggest-button', variant && `sum-suggest-button--${variant}`)}
    onClick={() => onClick(amount)}
  >
    {amount} {getCurrencySymbol(currency)}
  </button>
);

export default React.memo(SumSuggestButton);
