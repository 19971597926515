import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';

import { bonusList } from '../../BonusPage';

const BonusInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const bonusData = bonusList.find((item) => item.id === id);
  return (
    <div>
      <Breadcrumbs link="/bonus" title={t('footer.nav.item.Bonus and actions', 'К бонусам и акциям')} />
      <div className="bonus-page__wrapper">
        <h1>{bonusData?.title}</h1>
        <img src={bonusData?.banner} alt="banner" className="bonus-page__banner" />
        {bonusData?.body}
      </div>
    </div>

  );
};

export default BonusInfo;
